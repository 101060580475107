import { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { OnChange } from "react-final-form-listeners";
import { Trackable, useTracker } from "@dpdgroupuk/react-event-tracker";

import NavigationBar from "../../../components/molecules/WizardNavigationBar/WizardNavigationBar";
import { SafePlace as SafePlaceIcon } from "../../../components/atoms/icons";
import SafePlace from "../../../components/organisms/SafePlace/SafePlace";
import { WizardForm } from "../../../features/Wizard";
import styles from "../Onboarding.module.scss";
import * as M from "../../../constants/strings";
import Template from "../../../components/templates/OnboardingStepContainer";
import { Fields } from "../../../constants/forms";
import OnboardingTemplate from "../../../components/templates/Onboarding/Onboarding";
import { SafePlaceModel } from "../../../models";
import { SAFE_PLACE_ONBOARDING } from "../../../constants/analytics";
import { SAFE_PLACE_CODE } from "../../../constants/safePlace";
import { Validators } from "../../../utils";

const initialState = {
  [Fields.SAFE_PLACE_CODE]: null,
  [Fields.SAFE_PLACE_CUSTOM]: null,
  [Fields.IMAGE_URL]: null,
  [Fields.SAFE_PLACE_RESPONSIBILITY]: false,
};

const SafePlaceStep = ({ values, setValues, nextStep }) => {
  const initialValues = useMemo(
    () => ({
      ...initialState,
      ...values,
    }),
    []
  );
  const tracker = useTracker();

  const handleSetupLaterClick = useCallback(async () => {
    tracker.logEvent(SAFE_PLACE_ONBOARDING.ON_SETUP_LATER);
    setValues(initialState);
    nextStep();
  }, [setValues, nextStep, tracker]);

  const handleSubmit = useCallback(() => {
    tracker.logEvent(SAFE_PLACE_ONBOARDING.ON_NEXT);
    nextStep();
  }, [nextStep, tracker]);

  const handleClickBack = useCallback(() => {
    tracker.logEvent(SAFE_PLACE_ONBOARDING.ON_BACK);
  }, [tracker]);

  const handleSafePlaceSelect = useCallback(
    (value) => {
      if (value && value !== SAFE_PLACE_CODE.OTHER) {
        tracker.logEvent(SAFE_PLACE_ONBOARDING.ON_SELECT);
      }
    },
    [tracker]
  );

  const handleSafePlaceCustomChange = useCallback(
    (value) => {
      if (value && Validators.isNonEmptyString(value)) {
        tracker.logEvent(SAFE_PLACE_ONBOARDING.ON_TEXT_INPUT);
      }
    },
    [tracker]
  );

  const handleTermsChange = useCallback(() => {
    tracker.logEvent(SAFE_PLACE_ONBOARDING.ON_TERMS);
  }, [tracker]);

  const handleUploadClick = useCallback(() => {
    tracker.logEvent(SAFE_PLACE_ONBOARDING.ON_CLICK_UPLOAD);
  }, [tracker]);

  const handleDeleteClick = useCallback(() => {
    tracker.logEvent(SAFE_PLACE_ONBOARDING.ON_CLICK_DELETE);
  }, [tracker]);

  const handleImageClick = useCallback(() => {
    tracker.logEvent(SAFE_PLACE_ONBOARDING.ON_CLICK_IMAGE);
  }, [tracker]);

  return (
    <Trackable
      interfaceId={SAFE_PLACE_ONBOARDING.INTERFACE_ID}
      loadId={SAFE_PLACE_ONBOARDING.LOAD}
    >
      <OnboardingTemplate>
        <WizardForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
          Nav={NavigationBar}
          formClassName={styles.stepContent}
          validate={SafePlaceModel.validateSafePlaceValues}
          onClickBack={handleClickBack}
        >
          <Template
            fluid
            title={M.SELECT_SAFE_PLACE}
            subtitle={M.WE_CAN_LEAVE_IN_SAFE_PLACE}
            icon={SafePlaceIcon}
            onSetupLaterClick={handleSetupLaterClick}
          >
            <SafePlace
              onUploadClick={handleUploadClick}
              onDeleteClick={handleDeleteClick}
              onImagePreviewClick={handleImageClick}
            />
            <OnChange name={Fields.SAFE_PLACE_CODE}>
              {handleSafePlaceSelect}
            </OnChange>
            <OnChange name={Fields.SAFE_PLACE_CUSTOM}>
              {handleSafePlaceCustomChange}
            </OnChange>
            <OnChange name={Fields.SAFE_PLACE_RESPONSIBILITY}>
              {handleTermsChange}
            </OnChange>
          </Template>
        </WizardForm>
      </OnboardingTemplate>
    </Trackable>
  );
};

SafePlace.SafePlaceStep = {
  setValues: PropTypes.func,
  values: PropTypes.object,
  nextStep: PropTypes.func,
};

export default SafePlaceStep;
