const LockerWindowSticker = (props) => (
  <svg
    width="3.375rem" // 54px
    height="3.375rem"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_48_991)">
      <path d="M200 0H0V117.625H200V0Z" fill="white" />
      <path d="M200 117.625H0V200H200V117.625Z" fill="#DC0032" />
      <rect x="42" y="20" width="116" height="79" fill="#F2F2F2" />
      <path
        d="M94.3755 73.9096H105.625C106.662 73.9096 107.5 73.0765 107.5 72.0459C107.5 71.0154 106.662 70.1823 105.625 70.1823H94.3755C93.3405 70.1823 92.5005 71.0154 92.5005 72.0459C92.5005 73.0765 93.3405 73.9096 94.3755 73.9096Z"
        fill="#808285"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M160 100V23.5909C160 20.5085 157.476 18 154.375 18H45.625C42.5237 18 40 20.5085 40 23.5909V100H160ZM111.249 91H88.7491V21.7273H111.249V91ZM43.75 91V73.9096H84.9991V91H43.75ZM156.25 91H114.999V73.9096H156.25V91ZM84.9991 47.8187V70.1823H43.75V47.8187H84.9991ZM84.9991 44.0914H43.75V23.5909C43.75 22.564 44.5919 21.7273 45.625 21.7273H84.9991V44.0914ZM114.999 21.7273V44.0914H156.25V23.5909C156.25 22.564 155.41 21.7273 154.375 21.7273H114.999ZM115 70.1823H156.25V47.8187H115V70.1823Z"
        fill="#808285"
      />
      <path
        d="M107.5 62.7267H92.5005V40.363H107.5V62.7267ZM96.2505 58.9994H103.75V44.0903H96.2505V58.9994Z"
        fill="#CAC4BE"
      />
      <path d="M93 41H107V62H93V41Z" fill="#CAC4BE" />
      <mask
        id="mask0_48_991"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="46"
        y="21"
        width="38"
        height="34"
      >
        <rect x="46" y="21.75" width="38" height="33" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask0_48_991)">
        <mask
          id="mask1_48_991"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="48"
          y="16"
          width="32"
          height="38"
        >
          <rect x="48" y="16" width="32" height="37.6471" fill="#C4C4C4" />
        </mask>
        <g mask="url(#mask1_48_991)">
          <path
            d="M79.0837 24.1511L78.6422 24.4314C75.4388 26.3149 72.2441 28.2072 69.0321 30.0819C68.6507 30.2556 68.4259 30.6607 68.4779 31.0806C68.5386 32.2019 68.4779 32.0617 69.3957 32.6399C69.7213 32.8975 70.1754 32.9082 70.5125 32.6662C73.4562 30.9141 76.4171 29.162 79.3608 27.41L79.8976 27.1033C79.9041 27.2259 79.9041 27.3487 79.8976 27.4713V43.5116C79.909 44.0559 79.6041 44.5564 79.1184 44.7906L69.1619 50.7565C67.7161 51.6325 66.2616 52.5085 64.833 53.3846C64.3266 53.7345 63.6599 53.7345 63.1534 53.3846C58.3916 50.5112 53.6298 47.6611 48.8681 44.8344C48.3083 44.5473 47.9688 43.953 48.0023 43.3189C48.0023 38.0626 48.0023 32.8327 48.0023 27.629C48.0023 27.4713 48.0023 27.3224 48.0023 27.1033L48.4785 27.3662L62.0279 35.3381C62.3365 35.474 62.5223 35.7967 62.4868 36.1353C62.4868 40.025 62.4868 43.9058 62.4868 47.7954V48.1897C62.4755 48.4493 62.6186 48.6907 62.8504 48.8029L63.5776 49.2146C63.8065 49.3549 64.0934 49.3549 64.3222 49.2146L65.0148 48.8292C65.2644 48.7209 65.4177 48.4635 65.3958 48.1897C65.3958 48.0845 65.3958 47.9794 65.3958 47.8743V34.6636C65.3958 34.5409 65.3958 34.427 65.3958 34.3044C65.4041 34.064 65.2764 33.8397 65.0668 33.7262L63.9672 33.0779L49.3183 24.4314L48.8681 24.1598L49.2317 23.9058L63.0841 16.2492C63.6146 15.9169 64.2852 15.9169 64.8157 16.2492C69.4476 18.8248 74.0622 21.3565 78.6768 23.9058L79.0837 24.1511Z"
            fill="#DC0032"
          />
        </g>
      </g>
      <path d="M30.5 170V139.4H32.48V168.2H50.66V170H30.5Z" fill="white" />
      <path
        d="M53.5688 158.3C53.5688 156.5 53.8688 154.85 54.4688 153.35C55.0988 151.82 55.9538 150.5 57.0338 149.39C58.1438 148.28 59.4488 147.41 60.9488 146.78C62.4788 146.15 64.1588 145.835 65.9888 145.835C67.8188 145.835 69.4988 146.15 71.0288 146.78C72.5888 147.41 73.9088 148.28 74.9888 149.39C76.0988 150.5 76.9538 151.82 77.5538 153.35C78.1838 154.85 78.4988 156.5 78.4988 158.3C78.4988 160.07 78.1838 161.705 77.5538 163.205C76.9538 164.705 76.0988 166.01 74.9888 167.12C73.9088 168.23 72.5888 169.1 71.0288 169.73C69.4988 170.33 67.8188 170.63 65.9888 170.63C64.1588 170.63 62.4788 170.33 60.9488 169.73C59.4488 169.1 58.1438 168.23 57.0338 167.12C55.9538 166.01 55.0988 164.705 54.4688 163.205C53.8688 161.705 53.5688 160.07 53.5688 158.3ZM55.5038 158.3C55.5038 159.8 55.7588 161.18 56.2688 162.44C56.7788 163.7 57.4988 164.81 58.4288 165.77C59.3588 166.7 60.4688 167.435 61.7588 167.975C63.0488 168.485 64.4588 168.74 65.9888 168.74C67.5488 168.74 68.9738 168.485 70.2638 167.975C71.5838 167.435 72.7088 166.7 73.6388 165.77C74.5688 164.81 75.2888 163.7 75.7988 162.44C76.3088 161.18 76.5638 159.8 76.5638 158.3C76.5638 156.8 76.3088 155.405 75.7988 154.115C75.2888 152.825 74.5688 151.715 73.6388 150.785C72.7088 149.825 71.5838 149.075 70.2638 148.535C68.9738 147.995 67.5488 147.725 65.9888 147.725C64.4588 147.725 63.0488 147.995 61.7588 148.535C60.4688 149.075 59.3588 149.825 58.4288 150.785C57.4988 151.715 56.7788 152.825 56.2688 154.115C55.7588 155.405 55.5038 156.8 55.5038 158.3Z"
        fill="white"
      />
      <path
        d="M83.369 158.21C83.369 156.44 83.669 154.805 84.269 153.305C84.899 151.775 85.754 150.47 86.834 149.39C87.944 148.28 89.264 147.41 90.794 146.78C92.324 146.15 94.004 145.835 95.834 145.835C97.604 145.835 99.224 146.12 100.694 146.69C102.164 147.26 103.439 148.04 104.519 149.03L103.484 150.56C102.524 149.69 101.414 149 100.154 148.49C98.894 147.98 97.469 147.725 95.879 147.725C94.319 147.725 92.894 147.995 91.604 148.535C90.314 149.045 89.204 149.78 88.274 150.74C87.374 151.67 86.669 152.78 86.159 154.07C85.649 155.33 85.394 156.71 85.394 158.21C85.394 159.71 85.649 161.105 86.159 162.395C86.669 163.655 87.374 164.765 88.274 165.725C89.204 166.655 90.314 167.39 91.604 167.93C92.894 168.47 94.319 168.74 95.879 168.74C97.469 168.74 98.894 168.485 100.154 167.975C101.414 167.465 102.524 166.775 103.484 165.905L104.519 167.435C103.439 168.425 102.164 169.205 100.694 169.775C99.224 170.345 97.604 170.63 95.834 170.63C94.004 170.63 92.324 170.315 90.794 169.685C89.264 169.055 87.944 168.185 86.834 167.075C85.754 165.965 84.899 164.66 84.269 163.16C83.669 161.63 83.369 159.98 83.369 158.21Z"
        fill="white"
      />
      <path
        d="M128.567 146.78L114.797 157.49L129.332 169.685V170H126.767L112.772 158.075V170H110.792V138.05H112.772V156.95L126.182 146.465H128.567V146.78Z"
        fill="white"
      />
      <path
        d="M154.651 167.435C153.421 168.395 152.011 169.175 150.421 169.775C148.861 170.345 147.091 170.63 145.111 170.63C142.891 170.63 140.986 170.3 139.396 169.64C137.806 168.98 136.501 168.095 135.481 166.985C134.461 165.845 133.711 164.54 133.231 163.07C132.751 161.57 132.511 159.98 132.511 158.3C132.511 156.59 132.781 154.985 133.321 153.485C133.891 151.985 134.701 150.665 135.751 149.525C136.831 148.385 138.121 147.485 139.621 146.825C141.121 146.165 142.816 145.835 144.706 145.835C146.506 145.835 148.051 146.105 149.341 146.645C150.631 147.155 151.696 147.86 152.536 148.76C153.406 149.66 154.036 150.71 154.426 151.91C154.846 153.08 155.056 154.325 155.056 155.645C155.056 156.275 155.011 156.905 154.921 157.535C154.861 158.135 154.771 158.645 154.651 159.065H134.491C134.551 160.415 134.791 161.69 135.211 162.89C135.661 164.09 136.306 165.14 137.146 166.04C138.016 166.91 139.111 167.6 140.431 168.11C141.751 168.62 143.311 168.875 145.111 168.875C146.971 168.875 148.591 168.59 149.971 168.02C151.381 167.42 152.626 166.715 153.706 165.905L154.651 167.435ZM152.941 157.355C153.001 157.055 153.046 156.74 153.076 156.41C153.106 156.08 153.121 155.75 153.121 155.42C153.121 154.4 152.956 153.425 152.626 152.495C152.296 151.535 151.786 150.695 151.096 149.975C150.436 149.255 149.566 148.685 148.486 148.265C147.436 147.815 146.176 147.59 144.706 147.59C143.146 147.59 141.751 147.86 140.521 148.4C139.291 148.91 138.241 149.615 137.371 150.515C136.501 151.385 135.811 152.42 135.301 153.62C134.821 154.79 134.566 156.035 134.536 157.355H152.941Z"
        fill="white"
      />
      <path
        d="M161.648 146.465H163.628V151.775C163.988 150.935 164.408 150.17 164.888 149.48C165.398 148.79 165.953 148.19 166.553 147.68C167.183 147.17 167.873 146.78 168.623 146.51C169.403 146.21 170.258 146.06 171.188 146.06C172.328 146.06 173.273 146.24 174.023 146.6C174.773 146.93 175.478 147.38 176.138 147.95L175.193 149.57C174.653 149.09 174.023 148.7 173.303 148.4C172.613 148.1 171.833 147.95 170.963 147.95C170.033 147.95 169.178 148.13 168.398 148.49C167.648 148.82 166.958 149.285 166.328 149.885C165.728 150.485 165.203 151.19 164.753 152C164.303 152.78 163.928 153.62 163.628 154.52V170H161.648V146.465Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_48_991">
        <rect width="200" height="200" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default LockerWindowSticker;
