const Check = (props) => (
  <svg
    width="1.3125rem" // 21px
    height="1.375rem" // 22px
    viewBox="0 0 21 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.8751 14.675L4.2001 11L2.9751 12.225L7.8751 17.125L18.3751 6.62502L17.1501 5.40002L7.8751 14.675Z"
      fill="currentColor"
    />
  </svg>
);

export default Check;
