import { useCallback } from "react";
import { find } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useSearchParams } from "react-router-dom";

import { Trackable } from "@dpdgroupuk/react-event-tracker";

import { Loader } from "../../components/molecules/Loader";
import { EXPIRED_AUTH_CODES } from "../../constants";
import { VERIFY_EMAIL_LINK } from "../../constants/analytics";
import {
  EMAIL_VERIFIED,
  LINK_EXPIRED,
  WE_CANT_VERIFY_EMAIL,
} from "../../constants/strings";
import { useAuth } from "../../features/Auth";
import {
  ConsumerEmailsActions,
  ConsumerEmailsSelector,
} from "../../features/Profile";
import { useToaster } from "../../features/Toaster";
import { Paths } from "../../router";

function VerifyProfileEmail() {
  const [searchParams] = useSearchParams();
  const auth = useAuth();
  const dispatch = useDispatch();
  const toaster = useToaster();
  const emails = useSelector(ConsumerEmailsSelector.getConsumerEmails);

  const verifyEmail = useCallback(async ({ verificationId, id }) => {
    if (verificationId) {
      try {
        const verifyingEmailDoc = find(emails, ["id", id]);
        if (!verifyingEmailDoc) {
          toaster.showError({
            body: WE_CANT_VERIFY_EMAIL,
          });
          return;
        }
        await auth.verifyEmail(verificationId).unwrap();

        dispatch(
          ConsumerEmailsActions.updateEmail([
            auth.currentSession.uid,
            { id, verified: true },
          ])
        ).unwrap();

        toaster.showSuccess({
          body: EMAIL_VERIFIED,
        });
      } catch (e) {
        if (EXPIRED_AUTH_CODES.indexOf(e.code) >= 0) {
          return toaster.showError({
            body: LINK_EXPIRED,
          });
        }
        throw e;
      }
    }
  }, []);

  return (
    <Loader
      promiseFn={verifyEmail}
      id={searchParams.get("id")}
      verificationId={searchParams.get("verificationId")}
    >
      <Trackable
        loadId={VERIFY_EMAIL_LINK.LOAD}
        interfaceId={VERIFY_EMAIL_LINK.INTERFACE_ID}
      >
        <Navigate to={Paths.PROFILE_PERSONAL_INFO} replace />;
      </Trackable>
    </Loader>
  );
}

export default VerifyProfileEmail;
