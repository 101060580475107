const Late = (props) => (
  <svg
    width="2rem" // 32px
    height="2rem"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_510_9549)">
      <path
        d="M7.79324 18.7045V20H3.03413V18.7045H7.79324ZM3.48986 10.5209V20H1.85574V10.5209H3.48986Z"
        fill="black"
      />
      <path
        d="M12.6112 11.7839L9.77918 20H8.06694L11.6346 10.5209H12.7284L12.6112 11.7839ZM14.981 20L12.1425 11.7839L12.0188 10.5209H13.119L16.6998 20H14.981ZM14.8443 16.4844V17.78H9.68804V16.4844H14.8443Z"
        fill="black"
      />
      <path
        d="M20.5737 10.5209V20H18.9526V10.5209H20.5737ZM23.549 10.5209V11.823H16.0034V10.5209H23.549Z"
        fill="black"
      />
      <path
        d="M30.7367 18.7045V20H25.7042V18.7045H30.7367ZM26.1664 10.5209V20H24.5323V10.5209H26.1664ZM30.0792 14.4792V15.7552H25.7042V14.4792H30.0792ZM30.7042 10.5209V11.823H25.7042V10.5209H30.7042Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_510_9549">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Late;
