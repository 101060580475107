import { find } from "lodash";

import { ADDRESS_TYPE_NAME } from "../../../constants";
import * as M from "../../../constants/strings";
import { ERROR_CODE } from "../../ErrorBoundary/utils";

export const createAddressModel = ({
  deliveryAddress,
  deliveryAddressValues,
  pinpointLocation,
  neighbours,
  safePlace,
  pickupPoint,
  confirmDetails,
} = {}) => ({
  ...deliveryAddress,
  preferences: {
    firstDeliveryAttempt: confirmDetails?.firstDeliveryAttempt,
    safePlace: {
      code: safePlace?.code || null,
      custom: safePlace?.custom || null,
      imageUrl: safePlace?.imageUrl || null,
    },
    neighbours: {
      avoid: neighbours?.avoid || null,
      preferred: neighbours?.preferred || null,
    },
    pickupLocationCode: pickupPoint?.pickupLocation?.pickupLocationCode || null,
  },
  type:
    deliveryAddressValues?.customAddressType ||
    deliveryAddressValues?.type ||
    ADDRESS_TYPE_NAME.HOME,
  avoidSchoolRun: false, // there is no UI yet
  needMoreTime: deliveryAddressValues?.needMoreTime || false,
  note: deliveryAddressValues?.note || null,
  imageUrl: deliveryAddressValues?.imageUrl || null,
  pinpoint: {
    what3words: pinpointLocation?.what3words || null,
    latitude: pinpointLocation?.lngLat?.lat || null,
    longitude: pinpointLocation?.lngLat?.lng || null,
  },
});

export const validateAddressOnAdd = (udprn, addresses) => {
  if (addresses.length >= 10) {
    throw new Error(M.ADD_TEN_ADDRESSES);
  } else if (find(addresses, ["udprn", udprn])) {
    throw new Error(M.THIS_ADDRESS_HAS_ALREADY_BEEN_ADDED);
  }
};

export const validateAddressOnDelete = (udprn, addresses) => {
  if (addresses.length === 1 && addresses[0].udprn === udprn) {
    throw new Error("At least one address must be present");
  } else if (!find(addresses, ["udprn", udprn])) {
    // eslint-disable-next-line no-throw-literal
    throw { code: ERROR_CODE.NOT_FOUND, message: M.THIS_ADDRESS_DELETED };
  }
};
