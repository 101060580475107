import { combineReducers } from "redux";
import { ConsumerReducer } from "./Consumer";
import { ConsumerAddressesReducer } from "./Addresses";
import { ConsumerEmailsReducer } from "./Emails";

const profileReducer = combineReducers({
  consumer: ConsumerReducer,
  addresses: ConsumerAddressesReducer,
  emails: ConsumerEmailsReducer,
});

export default profileReducer;
