import classNames from "classnames";

import styles from "./HomePin.module.scss";

const HomePin = ({ className, ...props }) => (
  <svg
    width="42"
    height="46"
    viewBox="0 0 42 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className={classNames(className, styles.container)}
  >
    <g filter="url(#filter0_d_837_16708)">
      <rect
        width="28.36"
        height="32.86"
        transform="translate(6.81995 6.57007)"
        fill="url(#paint0_radial_837_16708)"
      />
      <g filter="url(#filter1_d_837_16708)">
        <path
          d="M8.5 14.25C8.5 10.9363 11.1863 8.25 14.5 8.25H27.5C30.8137 8.25 33.5 10.9363 33.5 14.25V27.25C33.5 30.5637 30.8137 33.25 27.5 33.25H27.25H24.125L21 37.75L17.875 33.25H14.75H14.5C11.1863 33.25 8.5 30.5637 8.5 27.25V14.25Z"
          fill="#438DED"
        />
        <path
          d="M23.3036 32.6796L21 35.9968L18.6964 32.6796L18.398 32.25H17.875H14.75H14.5C11.7386 32.25 9.5 30.0114 9.5 27.25V14.25C9.5 11.4886 11.7386 9.25 14.5 9.25H27.5C30.2614 9.25 32.5 11.4886 32.5 14.25V27.25C32.5 30.0114 30.2614 32.25 27.5 32.25H27.25H24.125H23.602L23.3036 32.6796Z"
          stroke="white"
          strokeWidth="2"
        />
      </g>
      <mask
        id="mask0_837_16708"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="12"
        y="11"
        width="18"
        height="18"
      >
        <rect x="12" y="11" width="18" height="18" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_837_16708)">
        <path
          d="M16.5 25.25H18.75V20.75H23.25V25.25H25.5V18.5L21 15.125L16.5 18.5V25.25ZM15 26.75V17.75L21 13.25L27 17.75V26.75H21.75V22.25H20.25V26.75H15Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_837_16708"
        x="2.81995"
        y="2.57007"
        width="36.36"
        height="40.8601"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="4"
          operator="dilate"
          in="SourceAlpha"
          result="effect1_dropShadow_837_16708"
        />
        <feOffset />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.262745 0 0 0 0 0.552941 0 0 0 0 0.929412 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_837_16708"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_837_16708"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_837_16708"
        x="0.5"
        y="0.25"
        width="41"
        height="45.5"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="4" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.262745 0 0 0 0 0.552941 0 0 0 0 0.929412 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_837_16708"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_837_16708"
          result="shape"
        />
      </filter>
      <radialGradient
        id="paint0_radial_837_16708"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(14.18 16.43) rotate(90) scale(16.43 14.18)"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
);

export default HomePin;
