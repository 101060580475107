import React, { useCallback } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Button, Col, Row } from "react-bootstrap";
import { OAuthProvider } from "firebase/auth";
import { Trackable, useTracker } from "@dpdgroupuk/react-event-tracker";
import { PROVIDER_ID, useAuth } from "../../features/Auth";
import LinkButtonWithIcon from "../../components/molecules/LinkButtonWithIcon";
import Google from "../../components/atoms/icons/Google";
import * as M from "../../constants/strings";
import {
  SIGN_IN_WITH_APPLE_ID,
  SIGN_IN_WITH_EMAIL,
  SIGN_IN_WITH_FACEBOOK,
  SIGN_IN_WITH_GOOGLE,
  // SIGN_IN_WITH_MICROSOFT,
  // SIGN_IN_WITH_YAHOO,
} from "../../constants/strings";
import { Paths } from "../../router";
import Divider from "../../components/atoms/Divider";
import ContentHeader from "../../components/molecules/ContentHeader";
import Apple from "../../components/atoms/icons/Apple";
// import Microsoft from "../../components/atoms/icons/Microsoft";
import { useToaster } from "../../features/Toaster";
import Container from "../../components/templates/Login/LoginContainer";
// import Yahoo from "../../components/atoms/icons/Yahoo";
import {
  getRedirectPathBySessionType,
  getSessionType,
} from "../../features/Auth/model";
import { LOGIN } from "../../constants/analytics";
import Facebook from "../../components/atoms/icons/Facebook";

const SAML_SIGN_IN_PROVIDERS = [
  // {
  //   icon: Microsoft,
  //   buttonText: SIGN_IN_WITH_MICROSOFT,
  //   providerId: PROVIDER_ID.MICROSOFT,
  //   analyticsId: LOGIN.ON_MICROSOFT_LOGIN,
  // },
  // removed to match the providers available on the app
  {
    icon: Google,
    buttonText: SIGN_IN_WITH_GOOGLE,
    providerId: PROVIDER_ID.GOOGLE,
    analyticsId: LOGIN.ON_GOOGLE_LOGIN,
  },
  {
    icon: Apple,
    buttonText: SIGN_IN_WITH_APPLE_ID,
    providerId: PROVIDER_ID.APPLE,
    analyticsId: LOGIN.ON_APPLE_LOGIN,
  },
  {
    icon: Facebook,
    buttonText: SIGN_IN_WITH_FACEBOOK,
    providerId: PROVIDER_ID.FACEBOOK,
    analyticsId: LOGIN.ON_FACEBOOK_LOGIN,
  },
  // {
  //   icon: Yahoo,
  //   buttonText: SIGN_IN_WITH_YAHOO,
  //   providerId: PROVIDER_ID.YAHOO,
  //   analyticsId: LOGIN.ON_YAHOO_LOGIN,
  // },
  // removed to match the providers available on the app
];

function SignIn() {
  const auth = useAuth();
  const toaster = useToaster();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const tracker = useTracker();

  React.useEffect(() => {
    if (auth.currentSession) {
      const sessionType = getSessionType(auth.currentSession);
      const returnUrl = searchParams.get("returnUrl");
      const to = returnUrl || getRedirectPathBySessionType(sessionType);

      if (!location.pathname.startsWith(to)) {
        navigate(to, {
          replace: true,
        });
      }
    }
  }, []);

  const handleSignInWithProvider = useCallback(
    async (event) => {
      tracker.logEvent(
        SAML_SIGN_IN_PROVIDERS.find(
          ({ providerId }) => providerId === event.target.id
        )?.analyticsId
      );

      try {
        event.preventDefault();
        await auth
          .signInWithRedirect(new OAuthProvider(event.target.id))
          .unwrap();
      } catch (err) {
        toaster.showError({
          body: err.message,
        });
      }
    },
    [auth, toaster, tracker]
  );
  const handleSignInWithEmail = useCallback(() => {
    tracker.logEvent(LOGIN.ON_EMAIL_LOGIN);
    navigate(Paths.VERIFY_EMAIL);
  }, [navigate, tracker]);

  return (
    <Trackable loadId={LOGIN.LOAD} interfaceId={LOGIN.INTERFACE_ID}>
      <Container>
        <ContentHeader
          title={M.MANAGE_DPD_PREFERENCES}
          subtitle={M.SIGN_IN_TO_ACCOUNT}
        />
        {SAML_SIGN_IN_PROVIDERS.map(
          ({ providerId, icon, buttonText }, index) => (
            <Row key={index}>
              <Col>
                <LinkButtonWithIcon
                  icon={icon}
                  buttonText={buttonText}
                  id={providerId}
                  className="mt-3 w-100"
                  onClick={handleSignInWithProvider}
                />
              </Col>
            </Row>
          )
        )}
        <Row className="py-3">
          <Col className="mx-auto">
            <Divider color="dark">or</Divider>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              variant="outline-danger"
              className="w-100"
              onClick={handleSignInWithEmail}
            >
              {SIGN_IN_WITH_EMAIL}
            </Button>
          </Col>
        </Row>
      </Container>
    </Trackable>
  );
}

export default SignIn;
