import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { includes } from "lodash";

import { Trackable, useTracker } from "@dpdgroupuk/react-event-tracker";

import AddressLabel from "../../components/organisms/AddressLabel";
import ProfilePage from "../../components/templates/ProfilePage";
import { EDIT_ADDRESS_TYPE } from "../../constants/analytics";
import { Fields } from "../../constants/forms";
import { CHANGES_SAVED } from "../../constants/strings";
import { ADDRESS_LABEL, CREATE_OWN_LABEL } from "../../constants/strings";
import { ADDRESS_TYPE_NAME } from "../../constants";
import { useAuth } from "../../features/Auth";
import { useOverlay } from "../../features/Overlay";
import {
  ConsumerAddressesActions,
  ConsumerAddressesSelector,
} from "../../features/Profile";
import { useToaster } from "../../features/Toaster";
import EditProfileForm from "../../components/organisms/EditProfileForm";

const EditType = () => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const overlay = useOverlay();
  const toaster = useToaster();
  const { udprn } = useParams();
  const tracker = useTracker();

  const address = useSelector(
    ConsumerAddressesSelector.getConsumerAddressById(udprn)
  );

  const onClickBack = useCallback(() => {
    tracker.logEvent(EDIT_ADDRESS_TYPE.ON_CLICK_BACK);
  }, [tracker]);

  const handleSaveType = useCallback(
    async ({ type, customAddressType }, form) => {
      try {
        overlay.show();
        tracker.logEvent(EDIT_ADDRESS_TYPE.ON_CLICK_SAVE);

        await dispatch(
          ConsumerAddressesActions.updateAddress([
            auth.currentSession.uid,
            {
              udprn,
              type: type || customAddressType,
            },
          ])
        ).unwrap();

        toaster.showSuccess({
          body: CHANGES_SAVED,
          autoHide: true,
        });
        form.reset();
      } catch (e) {
        toaster.showError({
          body: e.message,
          reload: e.reload,
        });
      } finally {
        overlay.hide();
      }
    },
    [udprn, auth, dispatch, overlay, toaster, tracker]
  );

  return (
    <Trackable
      loadId={EDIT_ADDRESS_TYPE.LOAD}
      interfaceId={EDIT_ADDRESS_TYPE.INTERFACE_ID}
    >
      <ProfilePage
        title={ADDRESS_LABEL}
        subtitle={CREATE_OWN_LABEL}
        showBackButtonDesktop
        onClickBack={onClickBack}
      >
        <EditProfileForm
          initialValues={{
            [includes(ADDRESS_TYPE_NAME, address.type)
              ? Fields.TYPE
              : Fields.CUSTOM_ADDRESS_TYPE]: address.type,
          }}
          onSubmit={handleSaveType}
        >
          <AddressLabel className={"p-4"} />
        </EditProfileForm>
      </ProfilePage>
    </Trackable>
  );
};

export default EditType;
