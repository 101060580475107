import { useCallback, useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Field, useFormState } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import PropTypes from "prop-types";
import classNames from "classnames";

import { Fields } from "../../../constants/forms";
import * as M from "../../../constants/strings";
import { PLEASE_SELECT_AVOID_NEIGHBOUR } from "../../../constants/strings";
import { Dropdown } from "../../atoms/Dropdown";
import * as AddressModel from "../../../models/address";
import Alert from "../../atoms/Alert";

const PreferredNeighbour = ({ fluid, className, addresses }) => {
  const formState = useFormState();

  const initialAvoidAddresses = useMemo(() => {
    const initialPreferredNeighbour =
      formState.values[Fields.PREFERRED_NEIGHBOUR];

    return addresses.filter((a) => a.udprn !== initialPreferredNeighbour);
  }, []);

  const initialPrefferedAddresses = useMemo(() => {
    const initialAvoidNeighbour = formState.values[Fields.AVOID_NEIGHBOUR];

    return addresses.filter((a) => a.udprn !== initialAvoidNeighbour);
  }, []);

  const [avoidAddresses, setAvoidAddresses] = useState(initialAvoidAddresses);
  const [preferredAddresses, setPrefferedAddresses] = useState(
    initialPrefferedAddresses
  );

  const onChangePreferredNeighbour = useCallback(
    (udprn) => {
      setAvoidAddresses(addresses.filter((a) => a.udprn !== udprn));
    },
    [addresses, setAvoidAddresses]
  );

  const onChangeAvoidNeighbour = useCallback(
    (udprn) => {
      setPrefferedAddresses(addresses.filter((a) => a.udprn !== udprn));
    },
    [addresses, setPrefferedAddresses]
  );

  return (
    <Container fluid={fluid} className={classNames("g-0", className)}>
      {!addresses?.length ? (
        <Alert
          variant="warning"
          title={M.NO_AVAILABLE_NEIGHBOURS}
          message={M.SORRY_NO_NEIGHBOURS_IN_YOUR_AREA}
        />
      ) : (
        <>
          <Row className="g-0">
            <Col xs={12} sm={12}>
              <Field
                name={Fields.PREFERRED_NEIGHBOUR}
                component={Dropdown}
                values={[
                  { value: null, label: M.DELIVER_TO_ANY_OF_MY_NEIGHBOURS },
                  ...preferredAddresses.map(AddressModel.formatAddressOption),
                ]}
                label={M.PLEASE_SELECT_YOUR_PREFERRED_NEIGHBOUR}
                placeholder={M.DELIVER_TO_ANY_OF_MY_NEIGHBOURS}
              />
              <OnChange name={Fields.PREFERRED_NEIGHBOUR}>
                {onChangePreferredNeighbour}
              </OnChange>
            </Col>
            <Col xs={12} sm={12}>
              <Field
                name={Fields.AVOID_NEIGHBOUR}
                component={Dropdown}
                disabled={!addresses.length}
                values={[
                  { value: null, label: PLEASE_SELECT_AVOID_NEIGHBOUR },
                  ...avoidAddresses.map(AddressModel.formatAddressOption),
                ]}
                label={M.PLEASE_SELECT_AVOID_NEIGHBOUR}
                placeholder={M.PLEASE_SELECT_AVOID_NEIGHBOUR}
              />
              <OnChange name={Fields.AVOID_NEIGHBOUR}>
                {onChangeAvoidNeighbour}
              </OnChange>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

PreferredNeighbour.defaultProps = {
  fluid: false,
};

PreferredNeighbour.propTypes = {
  fluid: PropTypes.bool,
  className: PropTypes.string,
  addresses: PropTypes.array,
};

export default PreferredNeighbour;
