import classNames from "classnames";
import { get, startCase } from "lodash";
import PropTypes from "prop-types";
import { Button, Col, Row } from "react-bootstrap";

import {
  Location,
  Neighbour,
  PickupPoint as PickupPointIcon,
  SafePlace,
} from "../../../components/atoms/icons";
import IconWithLabel from "../../../components/atoms/IconWithLabel/IconWithLabel";
import EditableCard from "../../../components/molecules/EditableCard";
import { AddressPropType } from "../../../constants/propType";
import {
  SAFE_PLACE_CODE,
  SAFE_PLACE_UI_BY_CODE,
} from "../../../constants/safePlace";
import * as M from "../../../constants/strings";
import {
  formatAddressOrgPropertyStreet,
  formatAddressTownLocalityPostcode,
} from "../../../models/address";
import styles from "./AddressesCardsList.module.scss";

const LeaveSafe = ({ address }) => {
  const safePlace = get(address, "preferences.safePlace.code");
  const safePlaceCustom = get(address, "preferences.safePlace.custom");

  return (
    <Col
      className={classNames("d-flex justify-content-end", styles.dataText)}
      xs={6}
    >
      <span className={styles.ellipsis}>
        {safePlace
          ? safePlace === SAFE_PLACE_CODE.OTHER
            ? safePlaceCustom
            : SAFE_PLACE_UI_BY_CODE[safePlace].label
          : M.NOT_SET}
      </span>
    </Col>
  );
};

const PreferredNeighbour = ({ address }) => {
  const preferredNeighbour = get(address, "preferences.neighbours.preferred");
  return (
    <Col className={classNames("d-flex justify-content-end", styles.dataText)}>
      <span className={styles.longContent}>
        {preferredNeighbour
          ? formatAddressOrgPropertyStreet(preferredNeighbour)
          : M.ANY}
      </span>
    </Col>
  );
};

const AvoidNeighbour = ({ address }) => {
  const avoidNeighbour = get(address, "preferences.neighbours.avoid");

  return (
    <Col className={classNames("d-flex justify-content-end", styles.dataText)}>
      <span className={styles.longContent}>
        {avoidNeighbour
          ? formatAddressOrgPropertyStreet(avoidNeighbour)
          : M.NOT_SET}
      </span>
    </Col>
  );
};

const PickupPoint = ({ address }) => {
  const pickupLocationCode = get(address, "preferences.pickupLocationCode");
  const pickupName = get(address, "preferences.pickupName");

  return (
    <Col className={classNames("d-flex justify-content-end", styles.dataText)}>
      <span className={styles.longContent}>
        {pickupLocationCode ? pickupName : M.NOT_SET}
      </span>
    </Col>
  );
};

const Pinpoint = ({ address }) => {
  const pinpoint = get(address, "pinpoint");
  const pinpointUnavailable = get(address, "pinpoint.unavailable");
  const isPinpointSet =
    (pinpoint?.latitude && pinpoint?.longitude) || pinpoint?.what3words;

  if (pinpointUnavailable) {
    return (
      <Col className={styles.dataText}>
        <Row className="g-0">
          <Col>{M.UNAVAILABLE}</Col>
        </Row>
      </Col>
    );
  }

  return (
    <Col className={styles.dataText}>
      {isPinpointSet ? (
        <>
          {pinpoint?.latitude && pinpoint?.longitude && (
            <Row className="g-0">
              <Col>{pinpoint?.latitude.toFixed(6)},</Col>
              <Col>{pinpoint?.longitude.toFixed(6)}</Col>
            </Row>
          )}

          {pinpoint?.what3words && (
            <Row className="g-0">
              <Col>
                <span className={styles.longContent}>
                  {"///" + pinpoint?.what3words}
                </span>
              </Col>
            </Row>
          )}
        </>
      ) : (
        <Row className="g-0">
          <Col>{M.NOT_SET}</Col>
        </Row>
      )}
    </Col>
  );
};

const AddressPhoto = ({ address }) => {
  const imageUrl = get(address, "imageUrl");

  return (
    <Col className={classNames("d-flex justify-content-end", styles.dataText)}>
      <span>
        {imageUrl ? (
          <img alt="address" src={imageUrl} width={73} height={71} />
        ) : (
          M.NOT_SET
        )}
      </span>
    </Col>
  );
};

const DeliveryNote = ({ address }) => (
  <Col
    className={classNames("d-flex justify-content-end", styles.dataText)}
    xs={6}
  >
    <span className={styles.ellipsis}>{get(address, "note") || M.NOT_SET}</span>
  </Col>
);

const MoreTimeNeeded = ({ address }) => (
  <Col className={classNames("d-flex justify-content-end", styles.dataText)}>
    <span>{get(address, "needMoreTime") ? M.ON : M.NOT_SET}</span>
  </Col>
);

const AddressesCardsList = ({ addresses, onEdit, onAddNewAddress }) => (
  <Row className="g-0 g-lg-3">
    {addresses.map((address, index) => (
      <Col key={index} lg={6} xxl={4} className="mb-4">
        <EditableCard
          title={formatAddressOrgPropertyStreet(address)}
          subTitle={formatAddressTownLocalityPostcode(address)}
          variant="secondary"
          className={classNames("p-0", styles.container)}
          classes={{
            title: styles.header,
            titleContainer: "p-3",
            contentContainer: "g-0",
          }}
          onEdit={() => onEdit(address)}
        >
          {[
            { icon: SafePlace, title: M.LEAVE_SAFE, component: LeaveSafe },
            {
              icon: Neighbour,
              title: M.PREFERRED_NEIGHBOUR,
              component: PreferredNeighbour,
            },
            {
              icon: Neighbour,
              title: M.AVOID_NEIGHBOUR,
              component: AvoidNeighbour,
            },
            {
              icon: PickupPointIcon,
              title: M.PICKUP_POINT,
              component: PickupPoint,
            },
            {
              icon: Location,
              title: M.PINPOINT_ADDRESS,
              component: Pinpoint,
            },
            {
              title: M.ADDRESS_PHOTO,
              component: AddressPhoto,
            },
            {
              title: M.DELIVERY_NOTE,
              component: DeliveryNote,
            },
            {
              title: startCase(M.MORE_TIME_NEEDED),
              component: MoreTimeNeeded,
            },
          ].map(({ icon, title, component: Component }, index) => (
            <Row className={classNames("g-0 p-3", styles.dataRow)} key={index}>
              <Col>
                <IconWithLabel
                  icon={icon}
                  label={title}
                  inline
                  customStyles={{
                    container: styles.iconWithLabel,
                    label: styles.dataLabel,
                    icon: styles.icon,
                  }}
                />
              </Col>
              <Component address={address} />
            </Row>
          ))}
        </EditableCard>
      </Col>
    ))}
    {addresses.length < 10 && (
      <Col lg={6} xxl={4} className="mb-4">
        <Button
          variant="link"
          onClick={onAddNewAddress}
          className={classNames(
            "w-100 h-100 text-danger text-decoration-none",
            styles.newAddressButton
          )}
        >
          {M.NEW_DELIVERY_ADDRESS}
        </Button>
      </Col>
    )}
  </Row>
);

AddressesCardsList.propTypes = {
  addresses: PropTypes.arrayOf(AddressPropType),
  onEdit: PropTypes.func,
  onAddNewAddress: PropTypes.func,
};

export default AddressesCardsList;
