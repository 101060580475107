import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./Divider.module.scss";

function Divider({ color, className, children }) {
  return (
    <div className={classNames(styles.container, "divider", className)}>
      <div className={classNames(styles.line, `bg-${color}`)} />
      <div className={classNames(styles.text, `text-${color}`)}>{children}</div>
      <div className={classNames(styles.line, `bg-${color}`)} />
    </div>
  );
}

Divider.defaultProps = {
  color: "dark",
};

Divider.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "success",
    "danger",
    "warning",
    "info",
    "light",
    "dark",
  ]),
};

export default Divider;
