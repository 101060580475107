import PropTypes from "prop-types";

export const AddressPropType = PropTypes.shape({
  organisation: PropTypes.string,
  property: PropTypes.string,
  street: PropTypes.string,
  county: PropTypes.string,
  postcode: PropTypes.string,
  town: PropTypes.string,
});
