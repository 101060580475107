import classNames from "classnames";
import PropTypes from "prop-types";

import * as M from "../../../constants/strings";
import styles from "./PickupInfo.module.scss";

const PickupInfo = ({
  type,
  distance,
  isOpen,
  showIsOpen,
  openStatus,
  className,
}) => (
  <span className={classNames(styles.text, className)}>
    <span className="me-2 text-danger">{type}</span>
    <span className="me-2">{`${+distance}${M.MILES}`}</span>
    {showIsOpen && (
      <span className={`me-2 ${isOpen ? "text-success" : "text-warning"}`}>
        {isOpen ? M.OPEN : M.CLOSED}
      </span>
    )}
    <span className="me-2">{openStatus}</span>
  </span>
);

PickupInfo.defaultProps = {
  showIsOpen: false,
};

PickupInfo.propTypes = {
  type: PropTypes.string.isRequired,
  distance: PropTypes.string.isRequired,
  openStatus: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  showIsOpen: PropTypes.bool,
};

export default PickupInfo;
