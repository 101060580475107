import PropTypes from "prop-types";
import { noop } from "lodash";

import * as M from "../../../constants/strings";
import { STEP_NAME } from "../../../constants";
import { SafePlace } from "../../atoms/icons";
import EditableCard from "../../molecules/EditableCard/EditableCard";
import {
  SAFE_PLACE_CODE,
  SAFE_PLACE_UI_BY_CODE,
} from "../../../constants/safePlace";
import Alert from "../../atoms/Alert/Alert";
import SafePlaceCard from "../../molecules/SafePlaceCard";
import { ClassesPropType } from "./propTypes";

const EditSafePlaceCard = ({ classes, data, onEditClick }) => (
  <EditableCard
    icon={SafePlace}
    title={
      <>
        <span className="d-none d-sm-inline">{M.YOUR_SAFE_PLACE}</span>
        <span className="d-sm-none">{M.PREFERRED_SAFE_PLACE}</span>
      </>
    }
    onEdit={() => onEditClick(STEP_NAME.SAFE_PLACE)}
    classes={classes}
  >
    {data.code ? (
      data.code === SAFE_PLACE_CODE.OTHER ? (
        <>
          <p className={classes.dataHeader}>{M.SAFE_PLACE}:</p>
          <p className={classes.text}>{data.custom}</p>
        </>
      ) : (
        <SafePlaceCard
          label={SAFE_PLACE_UI_BY_CODE[data?.code].label}
          icon={SAFE_PLACE_UI_BY_CODE[data?.code].icon}
          inline
          selectable={false}
          className={classes.card}
        />
      )
    ) : (
      <Alert
        variant="info"
        title={M.REMINDER}
        message={M.NO_SAFE_PLACE_SETUP}
      />
    )}
  </EditableCard>
);

EditSafePlaceCard.defaultProps = {
  classes: {},
  data: {},
  onEditClick: noop,
};

EditSafePlaceCard.propTypes = {
  classes: ClassesPropType,
  onEditClick: PropTypes.func,
  data: PropTypes.shape({
    code: PropTypes.oneOf(Object.values(SAFE_PLACE_CODE)),
    custom: PropTypes.string,
  }),
};

export default EditSafePlaceCard;
