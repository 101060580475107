import { useCallback, useState } from "react";
import classNames from "classnames";
import { pick } from "lodash";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate, useOutlet } from "react-router-dom";
import { useBreakpoint } from "use-breakpoint";
import { Trackable, useTracker } from "@dpdgroupuk/react-event-tracker";

import UpdateName from "../../components/organisms/UpdateName";
import NotificationEmails from "../../components/organisms/NotificationEmails";
import ListItemWithIcon from "../../components/molecules/ListItemWithIcon";
import * as M from "../../constants/strings";
import { BREAKPOINTS } from "../../constants/ui";
import { useAuth } from "../../features/Auth";
import { useOverlay } from "../../features/Overlay";
import { ProfileActions, ProfileSelectors } from "../../features/Profile";
import { ConsumerActions } from "../../features/Profile/Consumer";
import { ConsumerEmailsActions } from "../../features/Profile/Emails";
import styles from "./PersonalInformation.module.scss";
import Edit from "../../components/atoms/icons/Edit";
import { Paths } from "../../router";
import ProvidersInfo from "../../components/organisms/ProvidersInfo";
import { useToaster } from "../../features/Toaster";
import Modal from "../../components/atoms/Modal";
import Plus from "../../components/atoms/icons/Plus";
import {
  DELETE_PROFILE_MODAL,
  YOUR_PERSONAL_INFORMATION,
} from "../../constants/analytics";

function PersonalInformation() {
  const auth = useAuth();
  const dispatch = useDispatch();
  const overlay = useOverlay();
  const toaster = useToaster();
  const navigate = useNavigate();
  const tracker = useTracker();
  const profile = useSelector(ProfileSelectors.getProfile);
  const outlet = useOutlet();
  const [showConfirmDeleteProfile, setShowConfirmDeleteProfile] =
    useState(false);
  const { breakpoint } = useBreakpoint(BREAKPOINTS);

  const handleUpdateNameSubmit = useCallback(
    async (values) => {
      try {
        overlay.show();

        tracker.logEvent(YOUR_PERSONAL_INFORMATION.ON_CLICK_SAVE);

        await dispatch(
          ConsumerActions.updateConsumer([auth.currentSession.uid, values])
        );

        toaster.showSuccess({
          body: M.CHANGES_SAVED,
          autoHide: true,
        });
      } catch (e) {
        toaster.showError({
          body: e.message,
        });
      } finally {
        overlay.hide();
      }
    },
    [dispatch, overlay, auth, toaster, tracker]
  );

  const verifyEmail = useCallback(
    async ({ id, email }) =>
      auth
        .sendVerifyEmail({
          email,
          id,
        })
        .unwrap(),
    [auth]
  );

  const onClickAddNewEmail = useCallback(() => {
    tracker.logEvent(YOUR_PERSONAL_INFORMATION.ON_CLICK_ADD_NEW_EMAIL);
    navigate("addEmail");
  }, [navigate, tracker]);

  const deleteEmail = useCallback(
    async ({ id, email }) => {
      try {
        overlay.show();

        await dispatch(
          ConsumerEmailsActions.deleteEmail([id, auth.currentSession.uid])
        );

        toaster.showInfo({
          body: M.EMAIL_$_REMOVED(email),
          autoHide: true,
        });
      } catch (e) {
        toaster.showError({
          body: e.message,
        });
      } finally {
        overlay.hide();
      }
    },
    [dispatch, overlay, auth, toaster]
  );

  const resendEmailVerification = useCallback(
    async (doc) => {
      try {
        overlay.show();
        // absent on insight
        tracker.logEvent(
          YOUR_PERSONAL_INFORMATION.ON_CLICK_RESEND_VERIFICATION_EMAIL
        );

        const remoteEmail = await dispatch(
          ConsumerEmailsActions.getEmail([auth.currentSession.uid, doc.id])
        ).unwrap();

        if (remoteEmail.verified) {
          return toaster.showWarning({
            body: M.EMAIL_HAS_ALREADY_BEEN_VERIFIED,
          });
        }

        await verifyEmail(doc);

        toaster.showInfo({
          body: M.WE_HAVE_SENT_ANOTHER_VERIFICATION_EMAIL_TO_$(doc.email),
          autoHide: true,
        });
      } catch (e) {
        toaster.showError({
          body: e.message,
        });
      } finally {
        overlay.hide();
      }
    },
    [dispatch, overlay, auth, toaster, verifyEmail, tracker]
  );

  const onConfirmDeleteProfile = useCallback(async () => {
    try {
      overlay.show();
      tracker.logEvent(DELETE_PROFILE_MODAL.ON_CLICK_DELETE);

      setShowConfirmDeleteProfile(false);

      const uid = auth.currentSession.uid;

      await dispatch(ProfileActions.logout());

      await dispatch(ProfileActions.deleteProfile([uid])).unwrap();

      toaster.showInfo({
        body: M.REMOVED_PROFILE_SUCCESS,
        autoHide: true,
      });
      navigate(Paths.SIGN_IN);
    } catch (e) {
      toaster.showError({
        body: e.message,
      });
    } finally {
      overlay.hide();
    }
  }, [auth, overlay, dispatch, toaster, tracker, navigate]);

  return (
    <Trackable
      loadId={YOUR_PERSONAL_INFORMATION.LOAD}
      interfaceId={YOUR_PERSONAL_INFORMATION.INTERFACE_ID}
    >
      <Row className="g-0">
        {(breakpoint === "desktop" || !outlet) && (
          <Col
            lg={5}
            className={classNames(
              "h-100 border-right",
              styles.navPanel,
              styles.container
            )}
          >
            <Row className={classNames("g-0 p-4", styles.header)}>
              <Col>
                <h5>{M.PERSONAL_INFORMATION}</h5>
                <span>{M.UPDATE_ACCOUNT_PROFILE}</span>
              </Col>
            </Row>
            <Row className={classNames("g-0 p-4 pb-5", styles.content)}>
              <Col>
                <Row className="g-0">
                  <Col className="pb-0">
                    <UpdateName
                      initialValues={{
                        ...pick(profile, [
                          "consumer.firstName",
                          "consumer.lastName",
                        ]).consumer,
                      }}
                      onSubmit={handleUpdateNameSubmit}
                    />
                    <hr className="mb-0" />
                  </Col>
                </Row>
                <Row className="g-0">
                  <Col className="pt-3">
                    <h6 className="mb-1">{M.MOBILE_NUMBER}</h6>
                    <span className={styles.subtitle}>
                      {M.USED_MOBILE_NOTIFICATIONS}
                    </span>
                    <ListItemWithIcon
                      onIconClick={() => {
                        tracker.logEvent(
                          YOUR_PERSONAL_INFORMATION.ON_CLICK_EDIT_MOBILE
                        );
                        navigate(Paths.PROFILE_PERSONAL_EDIT_PHONE);
                      }}
                      icon={Edit}
                    >
                      <p className="mb-0">{profile.consumer.phoneNumber}</p>
                    </ListItemWithIcon>
                  </Col>
                </Row>
                <Row className="g-0">
                  <Col className="pt-3">
                    <h6 className="mb-1">{M.NOTIFICATION_EMAILS}</h6>
                    <span className={styles.subtitle}>
                      {M.USED_FOR_EMAIL_NOTIFICATIONS}
                    </span>
                    <NotificationEmails
                      emails={profile?.emails}
                      onClickDeleteEmail={deleteEmail}
                      onClickResendVerification={resendEmailVerification}
                    />
                    <Row>
                      <Col className="d-flex justify-content-end">
                        <Button
                          variant="link"
                          className={classNames(
                            "d-flex align-items-center text-danger text-end",
                            styles.addEmailButton
                          )}
                          onClick={onClickAddNewEmail}
                          disabled={profile.emails.length >= 5}
                        >
                          <Plus />
                          {M.ADD_NEW_EMAIL}
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="d-flex justify-content-end">
                        <span className={styles.helperText}>
                          {M.ADD_FIVE_EMAILS}
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="g-0">
                  <Col className="pt-3">
                    <h6 className="mb-1">{M.LOGIN_ACCOUNT}</h6>
                    <span className={styles.subtitle}>{M.HOW_YOU_LOGIN}</span>
                    <ProvidersInfo />
                  </Col>
                </Row>
                <hr className="mt-0" />
                <Row className={classNames("g-0", styles.deleteProfile)}>
                  <Col>
                    <h6 className="mb-1">{M.DELETE_YOUR_PROFILE}</h6>
                    <span className={styles.subtitle}>
                      {M.DELETE_MY_PROFILE_MESSAGE}
                    </span>
                  </Col>
                </Row>
                <Row className="g-0">
                  <Col className="d-flex justify-content-end">
                    <Button
                      variant="danger"
                      onClick={() => {
                        tracker.logEvent(
                          YOUR_PERSONAL_INFORMATION.ON_CLICK_DELETE_PROFILE
                        );
                        setShowConfirmDeleteProfile(true);
                      }}
                    >
                      {M.DELETE_MY_PROFILE}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        )}
        <Modal
          show={showConfirmDeleteProfile}
          title={M.YOUR_DPD_PROFILE}
          acceptButtonText={M.YES_DELETE_PROFILE}
          handleClose={() => {
            setShowConfirmDeleteProfile(false);
            tracker.logEvent(DELETE_PROFILE_MODAL.ON_CLICK_CLOSE);
          }}
          handleAccept={onConfirmDeleteProfile}
          closeButton
          centered
        >
          <Trackable
            loadId={DELETE_PROFILE_MODAL.LOAD}
            interfaceId={DELETE_PROFILE_MODAL.INTERFACE_ID}
            modal
          >
            <h6>{M.SURE_TO_DELETE_PROFILE}</h6>
            <p>{M.DELETE_MY_PROFILE_MESSAGE}</p>
          </Trackable>
        </Modal>
        {outlet && (
          <Col className={classNames("h-100", styles.content)}>
            <Outlet
              context={{ showBackButtonDesktop: breakpoint === "tablet" }}
            />
          </Col>
        )}
      </Row>
    </Trackable>
  );
}

export default PersonalInformation;
