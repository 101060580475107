import { ReduxifyWizard } from "../../features/Wizard";
import { OnboardingSlice } from "./slice";
import PersonalInformationStep from "./PersonalInformationStep";
import DeliveryAddressStep from "./DeliveryAddressStep";
import PreferredNeighbourStep from "./PreferredNeighbourStep";
import SafePlaceStep from "./SafePlaceStep";
import PinpointLocationStep from "./PinpointLocationStep";
import ConfirmDetailsStep from "./ConfirmDetailsStep";
import PickupPointStep from "./PickupPointStep";

import { STEP_NAME } from "../../constants";
import Step from "./Step";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Fields } from "../../constants/forms";
import { useAuth } from "../../features/Auth";
import { getPersonalInformationValues } from "./selectors";

const Onboarding = () => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const personalInfoValues = useSelector(getPersonalInformationValues);

  useEffect(() => {
    const authPhoneNumber = auth.currentSession?.phone_number;
    // check if local storage has data already in cache
    if (personalInfoValues && personalInfoValues[Fields.PHONE_NUMBER]) {
      if (authPhoneNumber !== personalInfoValues[Fields.PHONE_NUMBER]) {
        dispatch(OnboardingSlice.actions.clear());
      }
    }
  }, []);

  return (
    <ReduxifyWizard
      slice={OnboardingSlice}
      className="d-flex flex-column-reverse"
      isLazyMount={true}
      transitions={{}} // Disable transition animation
    >
      <Step
        stepName={STEP_NAME.PERSONAL_INFORMATION}
        component={PersonalInformationStep}
      />
      <Step
        stepName={STEP_NAME.DELIVERY_ADDRESS}
        component={DeliveryAddressStep}
      />
      <Step
        stepName={STEP_NAME.PINPOINT_LOCATION}
        component={PinpointLocationStep}
      />
      <Step
        stepName={STEP_NAME.NEIGHBOURS}
        component={PreferredNeighbourStep}
      />
      <Step stepName={STEP_NAME.SAFE_PLACE} component={SafePlaceStep} />
      <Step stepName={STEP_NAME.PICKUP_POINT} component={PickupPointStep} />
      <Step
        stepName={STEP_NAME.CONFIRM_DETAILS}
        component={ConfirmDetailsStep}
      />
    </ReduxifyWizard>
  );
};

export default Onboarding;
