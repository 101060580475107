import { includes } from "lodash";
import {
  PHONE_NUMBER_REGEXP,
  WHAT_3_WORDS_PATTERN,
  UK_POST_CODE_FULL,
  EMAIL_REGEX,
} from "../constants/regex";
import * as M from "../constants/strings";

export const isNonEmptyString = (value) => /\S/.test(value);

export const required = (value) => (value ? undefined : M.REQUIRED);
export const notEmpty = (value) => {
  if ((value || "").length) {
    return isNonEmptyString(value) ? undefined : M.SHOULD_CONTAIN_ONE_CHARACTER;
  }
  return undefined;
};

export const isWhat3WordsValid = (value) => WHAT_3_WORDS_PATTERN.test(value);

export const mustBeValidWhat3Words = (value) => {
  if (value && !isWhat3WordsValid(value)) {
    return M.WE_CANNOT_FIND_YOUR_WHAT3WORDS;
  }
};

export const mustBeNumber = (value) =>
  isNaN(value) ? M.MUST_BE_NUMBER : undefined;

// we don't want to check if value is exist here, it's only for format validation, so `!val` is present
export const isPostcodeValid = (postcode) => UK_POST_CODE_FULL.test(postcode);

export const isEmailValid = (value) => EMAIL_REGEX.test(value);
// we don't want to check if value is exist here, it's only for format validation, so `!val` is present
export const postcode = (postcode) =>
  isPostcodeValid(postcode) ? undefined : M.INVALID_POSTCODE;

export const minValue = (min) => (value) =>
  isNaN(value) || value >= min ? undefined : M.GREATER_THAN_$(min);

export const phoneNumber = (value) =>
  PHONE_NUMBER_REGEXP.test(value) ? undefined : M.INVALID_MOBILE_NUMBER;

export const differentTo = (valueToCompare, error) => (value) =>
  valueToCompare !== value
    ? undefined
    : error || M.DIFFERENT_THAN_$(valueToCompare);

export const isAlreadyAdded = (item, allItems, entity) =>
  includes(allItems, item) ? M.THIS_$_ADDED(entity) : undefined;

export const email = (email) => {
  return email && !isEmailValid(email) ? M.INVALID_EMAIL : undefined;
};

export const maxLength = (max) => (value) =>
  value && value.length > max ? M.LESS_OR_$_CHARACTERS(max) : undefined;

export const minLength = (min) => (value) =>
  value && value.length < min ? M.GREATER_OR_$_CHARACTERS(min) : undefined;

export const maxLength16 = maxLength(16);

export const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    );

export const customType = (customType, type) =>
  !type && !customType
    ? M.PLEASE_FILL_ADDRESS_LABEL
    : composeValidators(notEmpty, maxLength(35))(customType);
