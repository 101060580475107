import PropTypes from "prop-types";

export const StepPropType = {
  values: PropTypes.object,
  references: PropTypes.object,
  setValues: PropTypes.func,
  setReferences: PropTypes.func,
  currentNamedStep: PropTypes.string,
  currentStep: PropTypes.number,
  totalSteps: PropTypes.number,
  nextStep: PropTypes.func,
  previousStep: PropTypes.func,
  goToStep: PropTypes.func,
  goToNamedStep: PropTypes.func,
  firstStep: PropTypes.func,
  lastStep: PropTypes.func,
};
