import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Marker } from "react-map-gl";
import { Trackable, useTracker } from "@dpdgroupuk/react-event-tracker";

import HomeMarker from "../../atoms/icons/HomeMarker";
import PinLocationsMap from "../../molecules/PinLocationsMap";
import styles from "./ChooseYourLocation.module.scss";

const ChooseYourLocation = ({
  pointLocation,
  pinPointLocation,
  error,
  onChange,
  analytics = {},
}) => {
  const tracker = useTracker();

  const onMarkerChanged = useCallback(
    (boxElem) => {
      tracker.logEvent(analytics.ON_MOVE_PIN);

      onChange({
        lngLat: {
          lng: boxElem.lngLat.lng,
          lat: boxElem.lngLat.lat,
        },
      });
    },
    [onChange, tracker, analytics]
  );

  const onUndoMove = useCallback(() => {
    tracker.logEvent(analytics.ON_UNDO_MOVE);
  }, [tracker, analytics]);

  return (
    <Trackable interfaceId={analytics.INTERFACE_ID} loadId={analytics.LOAD}>
      <PinLocationsMap
        pointLocation={pointLocation}
        pinPointLocation={pinPointLocation}
        onChange={onChange}
        onUndoMove={onUndoMove}
        error={error}
        cursorMap="initial"
      >
        <Marker
          key="home-marker"
          longitude={pointLocation.lngLat.lng}
          latitude={pointLocation.lngLat.lat}
          anchor="bottom"
          style={{ zIndex: 2 }}
          draggable
          onDragEnd={onMarkerChanged}
        >
          <HomeMarker className={styles.pointLocationMarker} />
        </Marker>

        <Marker
          key="pin-home-marker"
          longitude={pinPointLocation.lngLat.lng}
          latitude={pinPointLocation.lngLat.lat}
          anchor="bottom"
          style={{ opacity: 0.6, cursor: "default" }}
        >
          <HomeMarker className={styles.pointLocationMarker} />
        </Marker>
      </PinLocationsMap>
    </Trackable>
  );
};

ChooseYourLocation.propTypes = {
  pointLocation: PropTypes.object.isRequired,
  pinPointLocation: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
};

export default ChooseYourLocation;
