import classNames from "classnames";
import { Col, Container, Image, Navbar, Row } from "react-bootstrap";
import DPDLogo from "../../atoms/icons/DPDLogo";
import LoginBackgroundDekstop from "../../../assets/images/LoginBackgroundDekstop.png";
import styles from "./Login.module.scss";

const Login = ({ children }) => {
  return (
    <Container
      fluid
      className={classNames("g-0 d-flex flex-column", styles.container)}
    >
      <Row className="g-0">
        <Col>
          <Navbar className="p-0">
            <Navbar.Brand href="/" className={styles.header}>
              <DPDLogo className={styles.logo} />
            </Navbar.Brand>
          </Navbar>
        </Col>
      </Row>
      <Row>
        <Col
          xs="auto"
          className={classNames(
            "d-none d-lg-flex justify-content-end",
            styles.imageContainer
          )}
        >
          <Image
            src={LoginBackgroundDekstop}
            className={classNames(styles.imageOffset)}
          />
        </Col>
        <Col>
          <Row className={classNames("g-0", styles.content)}>
            <Col className="p-3">{children}</Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
