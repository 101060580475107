const PickupPoint = (props) => {
  return (
    <svg
      width="4rem"
      height="3.9375rem" // 63px
      viewBox="0 0 64 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_99_5003)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M57.057 42.2363C57.057 42.2291 57.0522 42.2243 57.0451 42.2243H6.95529C6.94813 42.2243 6.94336 42.2291 6.94336 42.2363V52.9773C6.94336 54.9544 8.5458 56.5568 10.5229 56.5568H53.4774C55.4545 56.5568 57.057 54.9544 57.057 52.9773V42.2363ZM20.0683 50.5837H43.932C44.5918 50.5837 45.1252 50.0504 45.1252 49.3906C45.1252 48.7307 44.5918 48.1974 43.932 48.1974H20.0683C19.4097 48.1974 18.8752 48.7307 18.8752 49.3906C18.8752 50.0504 19.4097 50.5837 20.0683 50.5837Z"
          fill="currentColor"
        />
        <path
          d="M57.0568 43.4175H6.94318C6.28455 43.4175 5.75 42.8842 5.75 42.2243C5.75 41.5645 6.28455 41.0311 6.94318 41.0311H57.0568C57.7167 41.0311 58.25 41.5645 58.25 42.2243C58.25 42.8842 57.7167 43.4175 57.0568 43.4175Z"
          fill="currentColor"
        />
        <path
          d="M53.4773 57.75H10.5227C7.89057 57.75 5.75 55.6094 5.75 52.9773V10.0227C5.75 7.39057 7.89057 5.25 10.5227 5.25H53.4773C56.1094 5.25 58.25 7.39057 58.25 10.0227V52.9773C58.25 55.6094 56.1094 57.75 53.4773 57.75ZM10.5227 7.63636C9.20665 7.63636 8.13636 8.70665 8.13636 10.0227V52.9773C8.13636 54.2934 9.20665 55.3636 10.5227 55.3636H53.4773C54.7934 55.3636 55.8636 54.2934 55.8636 52.9773V10.0227C55.8636 8.70665 54.7934 7.63636 53.4773 7.63636H10.5227Z"
          fill="#414042"
        />
        <path
          d="M43.3333 19.2997L36.9057 23.1537C36.8341 23.1955 36.7422 23.2169 36.6479 23.2169C36.5441 23.2169 36.4451 23.1907 36.3711 23.1441L36.1718 23.0201L35.518 22.6144C35.3724 22.5249 35.2591 22.3208 35.2591 22.1502V21.4773V21.1408C35.2591 20.969 35.376 20.7268 35.5251 20.6408L35.6564 20.5621L42.2284 16.621L32.4694 11.2111C32.2415 11.0847 31.868 11.0835 31.6401 11.2099L21.5757 16.6747L33.2462 23.3541C33.3977 23.4365 33.5122 23.6333 33.5122 23.8111V23.8553V24.4936L33.511 25.972L33.5075 33.4473C33.5075 33.6215 33.3882 33.8219 33.2354 33.9031L32.356 34.3899C32.356 34.3899 32.3465 34.3911 32.3429 34.3935C32.2713 34.4281 32.1854 34.4507 32.0935 34.4507H32.0768C31.9718 34.4531 31.8728 34.4328 31.7964 34.3911L31.2416 34.0844L30.9135 33.9031C30.7632 33.8231 30.645 33.6215 30.645 33.4473L30.6426 25.972H30.6403V25.3324C30.6319 25.2418 30.5663 25.1308 30.4935 25.0902L20.6688 19.4071L20.6641 30.5168C20.6641 30.7769 20.8478 31.0991 21.0733 31.2303L31.6616 37.4337C31.8871 37.5661 32.2546 37.5649 32.4789 37.4313L42.9276 31.222C43.152 31.0883 43.3345 30.7662 43.3345 30.5061L43.3333 19.2997Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_99_5003">
          <rect
            width="63"
            height="63"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PickupPoint;
