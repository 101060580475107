import { deleteDoc, getDoc, setDoc, updateDoc } from "firebase/firestore";

/**
 * Firestore Transaction adapter
 * @see: Firestore Transaction
 */
const TransactionAdapter = {
  async get(documentRef) {
    return getDoc(documentRef);
  },

  async set(docRef, data, options) {
    return setDoc(docRef, data, options);
  },

  async update(docRef, data, ...args) {
    return updateDoc(docRef, data, ...args);
  },

  async delete(documentRef) {
    return deleteDoc(documentRef);
  },
};

export default TransactionAdapter;
