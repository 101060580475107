import React, { useState, useCallback, forwardRef } from "react";
import { noop } from "lodash";
import InputGroup from "react-bootstrap/InputGroup";
import Image from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

import What3wordsLogo from "../../../assets/images/What3wordsLogo.png";
import * as S from "../../../constants/strings";
import What3wordsInputLogo from "../../atoms/icons/What3wordsInputLogo";
import FieldContainer from "../../atoms/FieldContainer";
import { isWhat3WordsValid } from "../../../utils/validators";
import styles from "./What3WordsLocation.module.scss";

const MenuItemChildren = ({ option }) => (
  <div key={option.words} className={styles.containerItem}>
    <div className={styles.topContainerContent}>
      <What3wordsInputLogo className={styles.topContentLogo} />
      <span className={styles.topContentText}>{option.words}</span>
    </div>
    <div>
      <small>{option.nearestPlace}</small>
    </div>
  </div>
);

const What3WordsAutosuggest = forwardRef(
  (
    { input, meta = {}, id, clipToBoundBox, onChange, onInputChange, ...props },
    ref
  ) => {
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [selectedValue, setSelectedVal] = useState(null);
    const [options, setOptions] = useState([]);
    const [val, setVal] = useState();
    const handleFocus = useCallback(
      (e) => {
        setSelectedVal(null);
        input.onFocus(e);
      },
      [selectedValue, setSelectedVal]
    );
    const handleSelect = useCallback(
      ([data]) => {
        if (data) {
          input.onChange(data.words);
          onChange && onChange(data.words);
          setSelectedVal(data.words);
          setErrorMessage("");
        }
      },
      [input.onChange, setErrorMessage, setSelectedVal, ref]
    );
    const handleInputChange = useCallback(
      (val) => {
        if (!val) {
          setErrorMessage("");
        } else {
          setErrorMessage(S.WE_CANNOT_FIND_YOUR_WHAT3WORDS);
        }
        setVal(val);
        onInputChange(val);
      },
      [setVal, onInputChange]
    );
    const handleBlur = useCallback(() => {
      if (val !== selectedValue) {
        const option = options.find((option) => option.words === val);

        if (option) {
          handleSelect([option]);
        }
      }
      input.onBlur();
    }, [val, options, input.onBlur, handleSelect, val, selectedValue]);
    const handleSearch = useCallback(
      async (value) => {
        if (!isWhat3WordsValid(value)) {
          return;
        }
        setIsLoading(true);

        const [southLat, westLng, northLat, eastLng] =
          clipToBoundBox.split(",");
        const parseCoordinates = (lat, lng) => ({
          lat: parseFloat(lat),
          lng: parseFloat(lng),
        });

        const { suggestions } = await what3words.api.autosuggest({
          input: value,
          nResults: 10,
          clipToBoundingBox: {
            northeast: parseCoordinates(northLat, eastLng),
            southwest: parseCoordinates(southLat, westLng),
          },
        });

        setIsLoading(false);
        setOptions(suggestions);
      },
      [setIsLoading, setOptions, clipToBoundBox]
    );

    return (
      <FieldContainer
        meta={meta}
        input={input}
        error={meta.touched && !meta.active && (meta.error || errorMessage)}
        {...props}
      >
        <Form.Label>
          <Image src={What3wordsLogo} />
        </Form.Label>
        <InputGroup className="mb-12">
          <InputGroup.Text>{S.WHAT_3_WORDS_PREFFIX}</InputGroup.Text>
          <AsyncTypeahead
            ref={ref}
            {...input}
            {...props}
            isLoading={isLoading}
            inputProps={{ id }}
            id={input?.name || "What3WordsAutosuggest"}
            labelKey="words"
            onSearch={handleSearch}
            onChange={handleSelect}
            onFocus={handleFocus}
            options={options}
            onInputChange={handleInputChange}
            placeholder={S.TAP_FIND_YOUR_WHAT_3_WORDS}
            renderMenuItemChildren={(option) => (
              <MenuItemChildren option={option} />
            )}
            useCache={false}
            multiple={false}
            isInvalid={
              meta.touched && !meta.active && (!!meta.error || !!errorMessage)
            }
          />
        </InputGroup>
      </FieldContainer>
    );
  }
);
What3WordsAutosuggest.defaultProps = {
  onInputChange: noop,
  clipToBoundBox: "",
  input: {
    onChange: noop,
    onBlur: noop,
  },
};

export default What3WordsAutosuggest;
