import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { OnChange, OnFocus } from "react-final-form-listeners";
import { Trackable, useTracker } from "@dpdgroupuk/react-event-tracker";
import PreferredNeighbour from "../../../components/organisms/PreferredNeighbour";
import { getDeliveryAddressValues } from "../selectors";
import { WizardForm } from "../../../features/Wizard";
import styles from "../AddNewAddress.module.scss";
import * as M from "../../../constants/strings";
import Template from "../Template";
import { Fields } from "../../../constants/forms";
import { removeWhiteSpaces } from "../../../utils/string";
import { ADD_ADDRESS_NEIGHBOUR } from "../../../constants/analytics";

const initialState = {
  [Fields.UDPRN]: null,
  [Fields.PREFERRED_NEIGHBOUR]: null,
  [Fields.AVOID_NEIGHBOUR]: null,
};

const PreferredNeighbourStep = ({
  values,
  references,
  nextStep,
  setValues,
}) => {
  const { udprn, postcode } = useSelector(getDeliveryAddressValues);
  const tracker = useTracker();

  const initialValues = useMemo(() => {
    const result = { ...initialState, [Fields.UDPRN]: udprn };
    const currentAddress = (references.addresses || []).find(
      (a) => a.udprn === udprn
    );

    if (
      removeWhiteSpaces(postcode) !==
      removeWhiteSpaces(currentAddress?.postcode)
    ) {
      return result;
    }

    if (values[[Fields.PREFERRED_NEIGHBOUR]] === udprn) {
      return {
        ...values,
        [Fields.PREFERRED_NEIGHBOUR]: null,
      };
    }

    if (values[[Fields.AVOID_NEIGHBOUR]] === udprn) {
      return {
        ...values,
        [Fields.AVOID_NEIGHBOUR]: null,
      };
    }

    return {
      ...result,
      ...values,
    };
  }, []);

  const neighbourAddresses = useMemo(() => {
    return (references.addresses || []).filter((a) => a.udprn !== udprn);
  }, []);

  const onClickSkip = useCallback(() => {
    tracker.logEvent(ADD_ADDRESS_NEIGHBOUR.ON_SETUP_LATER);
    setValues(initialState);
    nextStep();
  }, [nextStep, setValues, tracker]);

  const handleSubmit = useCallback(() => {
    tracker.logEvent(ADD_ADDRESS_NEIGHBOUR.ON_NEXT);
    nextStep();
  }, [nextStep, tracker]);

  const handleClickBack = useCallback(() => {
    tracker.logEvent(ADD_ADDRESS_NEIGHBOUR.ON_BACK);
  }, [tracker]);

  const handleFocusPreferredNeighbour = useCallback(() => {
    tracker.logEvent(ADD_ADDRESS_NEIGHBOUR.ON_PREFERRED_NEIGHBOUR_DROPDOWN);
  }, [tracker]);

  const handleChangePreferredNeighbour = useCallback(
    (value) => {
      tracker.logEvent(
        value
          ? ADD_ADDRESS_NEIGHBOUR.ON_PREFERRED_NEIGHBOUR_SELECT
          : ADD_ADDRESS_NEIGHBOUR.ON_DELIVER_TO_ANY
      );
    },
    [tracker]
  );

  const handleFocusAvoidNeighbour = useCallback(() => {
    tracker.logEvent(ADD_ADDRESS_NEIGHBOUR.ON_AVOID_NEIGHBOUR_DROPDOWN);
  }, [tracker]);

  const handleChangeAvoidNeighbour = useCallback(() => {
    tracker.logEvent(ADD_ADDRESS_NEIGHBOUR.ON_AVOID_NEIGHBOUR_SELECT);
  }, [tracker]);

  return (
    <Trackable
      interfaceId={ADD_ADDRESS_NEIGHBOUR.INTERFACE_ID}
      loadId={ADD_ADDRESS_NEIGHBOUR.LOAD}
    >
      <WizardForm
        initialValues={initialValues}
        onSubmit={handleSubmit}
        formClassName={styles.stepContent}
      >
        <Template
          title={M.SELECT_NEIGHBOUR}
          subtitle={M.LET_US_KNOW_NEIGHBOURS}
          onClickSkip={onClickSkip}
          onClickBack={handleClickBack}
        >
          <PreferredNeighbour fluid addresses={neighbourAddresses} />
          <OnFocus name={Fields.PREFERRED_NEIGHBOUR}>
            {handleFocusPreferredNeighbour}
          </OnFocus>
          <OnFocus name={Fields.AVOID_NEIGHBOUR}>
            {handleFocusAvoidNeighbour}
          </OnFocus>
          {/* Do we need to cover reset separately for avoid neighbour? */}
          <OnChange name={Fields.AVOID_NEIGHBOUR}>
            {handleChangeAvoidNeighbour}
          </OnChange>
          <OnChange name={Fields.PREFERRED_NEIGHBOUR}>
            {handleChangePreferredNeighbour}
          </OnChange>
        </Template>
      </WizardForm>
    </Trackable>
  );
};

PreferredNeighbourStep.propTypes = {
  values: PropTypes.object,
  references: PropTypes.object,
  nextStep: PropTypes.func,
};

export default PreferredNeighbourStep;
