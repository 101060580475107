import * as Service from "./service";
import { createFirebaseAsyncThunk, ENTITY } from "../../ErrorBoundary";

export const getConsumer = createFirebaseAsyncThunk(
  "profile/consumer/get",
  Service.getConsumer,
  ENTITY.ACCOUNT
);

export const createConsumer = createFirebaseAsyncThunk(
  "profile/consumer/create",
  async ([uid, consumer, transaction]) =>
    Service.createConsumer(uid, consumer, { transaction }),
  ENTITY.ACCOUNT
);

export const updateConsumer = createFirebaseAsyncThunk(
  "profile/consumer/update",
  ([uid, consumer]) => Service.updateConsumer(uid, consumer),
  ENTITY.ACCOUNT
);

export const deleteConsumer = createFirebaseAsyncThunk(
  "profile/consumer/delete",
  Service.deleteConsumer,
  ENTITY.ACCOUNT
);
