import { useCallback, useState, useRef } from "react";
import { isEmpty, indexOf, noop } from "lodash";
import PropTypes from "prop-types";
import { useBreakpoint } from "use-breakpoint";

import { PICKUP_POINT_TYPE } from "../../../constants/pickupPoint";
import {
  LocationPropType,
  PickupPointPropType,
  PickupPointsPropType,
} from "../../../constants/propType";
import { BREAKPOINTS } from "../../../constants/ui";
import {
  filterByType,
  getPickupLocationCode,
  sortSelectedPickupToTop,
} from "../../../utils/pickupPoint";
import SetPickupPointDesktop from "../SetPickupPointDesktop";
import SetPickupPointMobile from "../SetPickupPointMobile";
import { ITEM_HEIGHT } from "./constants";

const SetPickupPoint = ({
  onSetupLater,
  currentLocation,
  pickupLocations,
  selectedPickupPoint,
  onSelectedPickupPoint,
  onClickBack,
  onClickNext,
  onPickupDetailsClick,
  onClickPrevious,
  onDirectionsClick,
  onShopsFilterClick,
  onLockersFilterClick,
  onMapPinClick,
}) => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const pickupListRef = useRef();
  const pickupMobileListRef = useRef();
  const [activePickupPoint, setActivePickupPoint] = useState();
  const [filterBy, setFilterBy] = useState();

  const onFilterButtonClick = useCallback(
    (type) => {
      setFilterBy(type === filterBy ? null : type);

      if (type === PICKUP_POINT_TYPE.SHOP) {
        onShopsFilterClick();
      }

      if (type === PICKUP_POINT_TYPE.LOCKER) {
        onLockersFilterClick();
      }
    },
    [filterBy, onShopsFilterClick, onLockersFilterClick]
  );

  const isFiltersVisible =
    !isEmpty(filterByType(pickupLocations, PICKUP_POINT_TYPE.SHOP)) &&
    !isEmpty(filterByType(pickupLocations, PICKUP_POINT_TYPE.LOCKER));
  const filteredPickupPoints = filterByType(pickupLocations, filterBy).map(
    (pickupLocation, index) => ({ ...pickupLocation, number: index + 1 })
  );
  const sortedPickupPoints = sortSelectedPickupToTop(
    filteredPickupPoints,
    selectedPickupPoint
  );

  const onSelectClick = useCallback(
    (pickupPoint) => {
      onSelectedPickupPoint(pickupPoint);
      setActivePickupPoint(
        getPickupLocationCode(selectedPickupPoint) ===
          getPickupLocationCode(pickupPoint)
          ? null
          : pickupPoint
      );
      pickupListRef?.current?.scroll({
        top: 0,
      });
      pickupMobileListRef?.current?.scroll({
        top: 0,
      });
    },
    [selectedPickupPoint, onSelectedPickupPoint, setActivePickupPoint]
  );

  const handleMapPinClick = useCallback(
    (pickupPoint) => {
      const isPickupDetailsActive =
        getPickupLocationCode(activePickupPoint) ===
        getPickupLocationCode(pickupPoint);

      if (isPickupDetailsActive) {
        setActivePickupPoint(null);
      } else {
        const indexOfPickup = indexOf(sortedPickupPoints, pickupPoint);
        pickupListRef?.current?.scroll({
          top: indexOfPickup * ITEM_HEIGHT,
          behavior: "smooth",
        });

        setActivePickupPoint(pickupPoint);
        onMapPinClick();
      }
    },
    [
      sortedPickupPoints,
      activePickupPoint,
      pickupListRef,
      setActivePickupPoint,
      onMapPinClick,
    ]
  );

  const handlePickupPointDetailsClick = useCallback(
    (pickupPoint) => {
      setActivePickupPoint(pickupPoint);
      onPickupDetailsClick();
    },
    [setActivePickupPoint, onPickupDetailsClick]
  );

  return (
    <>
      {breakpoint === "mobile" ? (
        <SetPickupPointMobile
          currentLocation={currentLocation}
          selectedPickupPoint={selectedPickupPoint}
          pickupPoints={filteredPickupPoints}
          onSelectClick={onSelectClick}
          onPickupDetailsClick={handlePickupPointDetailsClick}
          onClickBack={onClickBack}
          filterBy={filterBy}
          isFiltersVisible={isFiltersVisible}
          onFilterButtonClick={onFilterButtonClick}
          onClickNext={onClickNext}
          onSetupLater={onSetupLater}
          pickupListRef={pickupMobileListRef}
          onDirectionsClick={onDirectionsClick}
          onMapPinClick={onMapPinClick}
        />
      ) : (
        <SetPickupPointDesktop
          currentLocation={currentLocation}
          selectedPickupPoint={selectedPickupPoint}
          activePickupPoint={activePickupPoint}
          pickupPoints={sortedPickupPoints}
          onSelectClick={onSelectClick}
          onPickupDetailsClick={handlePickupPointDetailsClick}
          onClickBack={onClickBack}
          onMapPinClick={handleMapPinClick}
          filterBy={filterBy}
          isFiltersVisible={isFiltersVisible}
          onFilterButtonClick={onFilterButtonClick}
          onClickNext={onClickNext}
          onSetupLater={onSetupLater}
          pickupListRef={pickupListRef}
          onClickPrevious={onClickPrevious}
          onDirectionsClick={onDirectionsClick}
        />
      )}
    </>
  );
};

SetPickupPoint.defaultProps = {
  onPickupDetailsClick: noop,
  onClickPrevious: noop,
  onDirectionsClick: noop,
  onShopsFilterClick: noop,
  onLockersFilterClick: noop,
  onMapPinClick: noop,
};

SetPickupPoint.propTypes = {
  onSetupLater: PropTypes.func,
  currentLocation: LocationPropType.isRequired,
  pickupLocations: PickupPointsPropType.isRequired,
  selectedPickupPoint: PickupPointPropType,
  onSelectedPickupPoint: PropTypes.func,
  onClickBack: PropTypes.func,
  onClickNext: PropTypes.func,
  onPickupDetailsClick: PropTypes.func,
  onClickPrevious: PropTypes.func,
  onDirectionsClick: PropTypes.func,
  onShopsFilterClick: PropTypes.func,
  onLockersFilterClick: PropTypes.func,
  onMapPinClick: PropTypes.func,
};

export default SetPickupPoint;
