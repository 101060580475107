import classNames from "classnames";
import PropTypes from "prop-types";
import { Col, Image, Row } from "react-bootstrap";
import { noop } from "lodash";

import PickupPoint from "../../../assets/images/pickup_point.png";
import { PickupPointPropType } from "../../../constants/propType";
import * as M from "../../../constants/strings";
import {
  getPickupAddress,
  getPickupOpenWindowList,
  getPickupKind,
  getPartnerLogo,
  getOpenStatusObject,
} from "../../../utils/pickupPoint";
import ButtonWithIcon from "../../atoms/ButtonWithIcon";
import Direction from "../../atoms/icons/Direction";
import ClosedForLunchStatus from "../../atoms/ClosedForLunchStatus";
import OpenWindowList from "../../atoms/OpenWindowList";
import PickupWindowSticker from "../../atoms/PickupWindowSticker";
import AddressView from "../AddressView";
import styles from "./PickupDetails.module.scss";

const PickupImage = ({ pickupPoint }) => {
  const partnerLogo = getPartnerLogo(pickupPoint);

  return (
    <div
      className={classNames(styles.imageContainer, !partnerLogo && "border-0")}
    >
      <Image src={partnerLogo || PickupPoint} className={styles.img} />
      <PickupWindowSticker type={getPickupKind(pickupPoint)} />
    </div>
  );
};

const PickupDetails = ({ pickupPoint, directionLink, onDirectionsClick }) => {
  const closedForLunch = getOpenStatusObject(pickupPoint).closedForLunch;

  return (
    <Row className="pt-3">
      <Col className="me-3">
        <Row>
          <Col className={styles.inlineImage}>
            <PickupImage pickupPoint={pickupPoint} />
          </Col>
        </Row>
        <Row className={classNames("pt-3 pb-3", styles.divider)}>
          <Col>
            <AddressView address={getPickupAddress(pickupPoint)} inline />
          </Col>
          <Col md="auto" className="g-0 d-flex align-items-center">
            <ButtonWithIcon
              variant="outline-primary"
              label={M.DIRECTIONS}
              icon={() => (
                <Direction width={17.5} height={17.5} className="ms-1" />
              )}
              className="d-flex align-items-center"
              href={directionLink}
              target="_blank"
              onClick={onDirectionsClick}
            />
          </Col>
        </Row>

        {closedForLunch && (
          <Row className={classNames("p-0", styles.closedForLunchContainer)}>
            <Col className="py-3">
              <ClosedForLunchStatus closedForLunch={closedForLunch} />
            </Col>
          </Row>
        )}

        <OpenWindowList
          openWindows={getPickupOpenWindowList(pickupPoint)}
          className="pt-3"
        />
      </Col>
      <Col className={styles.sideImage} xs="auto">
        <PickupImage pickupPoint={pickupPoint} />
      </Col>
    </Row>
  );
};

PickupImage.propTypes = {
  pickupPoint: PickupPointPropType,
};

PickupDetails.propTypes = {
  pickupPoint: PickupPointPropType,
  directionLink: PropTypes.string,
  onDirectionsClick: PropTypes.func,
};

PickupDetails.defaultProps = {
  onDirectionsClick: noop,
};

export default PickupDetails;
