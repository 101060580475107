export const SESSION_TYPE = {
  GUEST: 0,
  ACCOUNT: 1,
  PHONE_ACCOUNT: 2,
  CONSUMER_ACCOUNT: 3,
};

export const PROVIDER_ID = {
  PHONE: "phone",
  GOOGLE: "google.com",
  FACEBOOK: "facebook.com",
  APPLE: "apple.com",
  MICROSOFT: "microsoft.com",
  YAHOO: "yahoo.com",
};

export const EMAIL_PROVIDERS = [
  PROVIDER_ID.GOOGLE,
  PROVIDER_ID.FACEBOOK,
  PROVIDER_ID.APPLE,
  PROVIDER_ID.MICROSOFT,
  PROVIDER_ID.YAHOO,
];
