export default class EventEmitter extends EventTarget {
  constructor() {
    super();
    this._listeners = [];
  }

  emit(name, detail) {
    const event = new CustomEvent(name, {
      detail,
    });
    this.dispatchEvent(event);
  }

  addListener(type, listener) {
    this._listeners.push({ type, listener });
    super.addEventListener(type, listener);
    return () => {
      this.removeEventListener(type, listener);
    };
  }

  removeListeners(type) {
    for (let index = 0; index < this._listeners.length; index++) {
      const item = this._listeners[index];

      const listener = item.listener;

      if (item.type === type) {
        this.removeEventListener(type, listener);
      }
    }
  }
}
