import moment from "moment-timezone";
import { indexOf } from "lodash";

import * as DATE_FORMAT from "../constants/dateFormat";

export const weekdays = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const weekdaysShort = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export const getCurrentMomentDate = () =>
  moment().tz(DATE_FORMAT.EUROPE_LONDON_TIME_ZONE);

export const getCurrentDay = () =>
  getCurrentMomentDate().format(DATE_FORMAT.DAY);

export const getCurrentDayNumber = () => indexOf(weekdays, getCurrentDay()) + 1;

export const getDayNameByShortName = (shortName) =>
  weekdays[indexOf(weekdaysShort, shortName)];

export const getTimeMomentDate = (time) =>
  moment.tz(time, DATE_FORMAT.TIME, DATE_FORMAT.EUROPE_LONDON_TIME_ZONE);

export const getIsCurrentTimeBetween = (startTime, endTime) => {
  const currentDate = getCurrentMomentDate();

  const isCurrentTimeAfterStartTime = currentDate.isSameOrAfter(
    getTimeMomentDate(startTime)
  );

  const isCurrentTimeBeforeEndTime = currentDate.isBefore(
    getTimeMomentDate(endTime)
  );

  return isCurrentTimeAfterStartTime && isCurrentTimeBeforeEndTime;
};

export const isCurrentTimeBefore = (time) =>
  getCurrentMomentDate().isBefore(getTimeMomentDate(time));

export const isCurrentTimeAfter = (time) =>
  getCurrentMomentDate().isAfter(getTimeMomentDate(time));

export const getTimeString = (time) => {
  const hours = moment(time, DATE_FORMAT.TIME).format("h");
  const minutes = moment(time, DATE_FORMAT.TIME).format("mm");

  return `${hours}${minutes !== "00" ? `:${minutes}` : ""}${moment(
    time,
    DATE_FORMAT.TIME
  ).format("a")}`;
};
