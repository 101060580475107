import {
  collection,
  doc,
  getDocs,
  getFirestore,
  serverTimestamp,
} from "firebase/firestore";

import { defaultApp } from "../../../firebase";
import t from "../transaction";
import { IMAGE_NAME } from "../../../constants";
import { deleteImage, uploadImage } from "../Storage";

const db = getFirestore(defaultApp);

export const getAddressesCollectionRef = (uid) =>
  collection(db, "consumers", uid, "addresses");

export const getAddressPhotoPath = (uid, udprn) =>
  `images/${uid}/addresses/${udprn}/${IMAGE_NAME.ADDRESS}`;
export const getAddressSafePlacePhotoPath = (uid, udprn) =>
  `images/${uid}/addresses/${udprn}/${IMAGE_NAME.SAFE_PLACE}`;

export const getAddressDocRef = (uid, udprn) =>
  doc(db, "consumers", uid, "addresses", udprn);

export const getAddresses = async (uid) => {
  const addressesRef = getAddressesCollectionRef(uid);
  const querySnapshot = await getDocs(addressesRef);
  return querySnapshot.docs.map((doc) => doc.data());
};

export const addAddress = async (uid, address, { transaction = t } = {}) => {
  const newAddressDocRef = getAddressDocRef(uid, address.udprn);
  await transaction.set(newAddressDocRef, {
    ...address,
    createdDate: serverTimestamp(),
    modified_at: null,
  });
  return address;
};

export const updateAddress = async (uid, address, { transaction = t } = {}) => {
  const addressDocRef = getAddressDocRef(uid, address.udprn);
  const data = {
    ...address,
    modified_at: serverTimestamp(),
  };
  await transaction.update(addressDocRef, data);

  return data;
};

export const deleteAddress = async (uid, udprn, { transaction = t } = {}) => {
  const addressDocRef = getAddressDocRef(uid, udprn);
  await transaction.delete(addressDocRef);
  return udprn;
};

export const uploadAddressPhoto = async (uid, udprn, image) => {
  return uploadImage(getAddressPhotoPath(uid, udprn), image);
};

export const uploadAddressSafePlacePhoto = async (uid, udprn, image) => {
  return uploadImage(getAddressSafePlacePhotoPath(uid, udprn), image);
};

export const deleteAddressPhoto = async (uid, udprn) => {
  return deleteImage(getAddressPhotoPath(uid, udprn));
};

export const deleteAddressSafePlacePhoto = async (uid, udprn) => {
  return deleteImage(getAddressSafePlacePhotoPath(uid, udprn));
};
