import { forwardRef } from "react";
import PropTypes from "prop-types";
import Map from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";

const { REACT_APP_MAPBOX_API_TOKEN } = process.env;

const MapBoxComponent = forwardRef(
  ({ style, initialViewState, children, ...props }, ref) => {
    const mapPadding =
      window.innerWidth > 768
        ? { top: 106, bottom: 80, left: 60, right: 60 }
        : { top: 86, bottom: 60, left: 40, right: 40 };
    const initialState = {
      zoom: 17.8,
      fitBoundsOptions: {
        padding: mapPadding,
      },
      ...initialViewState,
    };
    const styleMap = {
      width: "100%",
      height: "100%",
      position: "relative",
      background: "rgb(26, 29, 33)",
      ...style,
    };

    return (
      <Map
        ref={ref}
        mapboxAccessToken={REACT_APP_MAPBOX_API_TOKEN}
        initialViewState={initialState}
        style={styleMap}
        mapStyle="mapbox://styles/mapbox/satellite-streets-v11"
        {...props}
      >
        {children}
      </Map>
    );
  }
);

MapBoxComponent.propTypes = {
  children: PropTypes.node,
};

export default MapBoxComponent;
