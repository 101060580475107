import { Badge, Nav } from "react-bootstrap";
import PropTypes from "prop-types";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import IconWithLabel from "../../atoms/IconWithLabel/IconWithLabel";
import styles from "./TabNavItem.module.scss";
import Arrow from "../../atoms/icons/Arrow/Arrow";

const TabNavItem = ({
  title,
  subTitle,
  icon,
  href,
  classes = {},
  badge,
  footer,
  onClick,
}) => {
  return (
    <Nav.Item
      className={classNames(styles.navItem, classes.navItem)}
      onClick={onClick}
    >
      <NavLink
        to={href}
        className={classNames(styles.navLink, classes.navLink)}
      >
        <div className="d-flex flex-column align-items-start mw-100">
          {badge && (
            <Badge
              bg="danger"
              className={classNames("mb-2 overflow-hidden", styles.badge)}
            >
              {badge}
            </Badge>
          )}
          <IconWithLabel
            icon={icon}
            label={title}
            customStyles={{
              container: classNames(
                styles.labelContainer,
                classes.labelContainer
              ),
              icon: classNames(styles.icon, classes.icon),
            }}
          />
          {subTitle && (
            <span
              className={classNames(
                styles.subTitle,
                classes.subtitle,
                icon && styles.margin40
              )}
            >
              {subTitle}
            </span>
          )}
          {footer}
        </div>
        <Arrow className={classNames(styles.arrow, classes.arrow)} />
      </NavLink>
    </Nav.Item>
  );
};

TabNavItem.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.func,
  href: PropTypes.string,
  classes: PropTypes.object,
  subTitle: PropTypes.string,
  badge: PropTypes.string,
  footer: PropTypes.node,
  onClick: PropTypes.func,
};

export default TabNavItem;
