import { getFirestore, runTransaction } from "firebase/firestore";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ConsumerActions, ConsumerService } from "./Consumer";
import { ConsumerAddressesActions } from "./Addresses";
import { ConsumerEmailsActions } from "./Emails";
import { defaultApp } from "../../firebase";
import { createFirebaseAsyncThunk, ENTITY, ERROR_CODE } from "../ErrorBoundary";
import { AuthActions } from "../Auth";
import { EditPhoneSlice } from "../../pages/EditPhone/slice";
import { VerifyEmailSlice } from "../../pages/VerifyEmail/slice";
import { OnboardingSlice } from "../../pages/Onboarding/slice";
import { AddNewAddressSlice } from "../../pages/AddNewAddress/slice";

const db = getFirestore(defaultApp);

export const createProfile = createFirebaseAsyncThunk(
  "profile/create",
  async ([uid, entries], { dispatch }) => {
    await runTransaction(db, async (transaction) => {
      const existingConsumer = await ConsumerService.getConsumer(uid, {
        transaction,
      });

      if (existingConsumer) {
        const error = new Error("Document already exists");
        error.code = ERROR_CODE.ALREADY_EXISTS;

        throw error;
      }

      const result = {};
      result.consumer = await dispatch(
        ConsumerActions.createConsumer([uid, entries.consumer, transaction])
      ).unwrap();
      const childrenPromises = [
        dispatch(
          ConsumerAddressesActions.addAddress([
            uid,
            entries.address,
            transaction,
          ])
        )
          .unwrap()
          .then((address) => {
            result.addresses = [address];
            return address;
          }),
      ];

      if (entries.email) {
        childrenPromises.push(
          dispatch(
            ConsumerEmailsActions.addEmail([uid, entries.email, transaction])
          )
            .unwrap()
            .then((email) => {
              result.emails = [email];
              return email;
            })
        );
      }

      await Promise.all(childrenPromises);

      return result;
    });
  },
  ENTITY.ACCOUNT
);

export const deleteProfile = createAsyncThunk(
  "profile/delete",
  async ([uid], { dispatch }) =>
    dispatch(ConsumerActions.deleteConsumer(uid)).unwrap()
);

export const getProfile = createAsyncThunk(
  "profile/get",
  async (uid, { dispatch }) => {
    const [consumer, addresses, emails] = await Promise.all([
      dispatch(ConsumerActions.getConsumer(uid)).unwrap(),
      dispatch(ConsumerAddressesActions.getAddresses(uid)).unwrap(),
      dispatch(ConsumerEmailsActions.getEmails(uid)).unwrap(),
    ]);
    return {
      consumer,
      addresses,
      emails,
    };
  }
);

export const logout = createAsyncThunk("profile/logout", (_, { dispatch }) => {
  dispatch(EditPhoneSlice.actions.clear());
  dispatch(VerifyEmailSlice.actions.clear());
  dispatch(OnboardingSlice.actions.clear());
  dispatch(AddNewAddressSlice.actions.clear());
  return dispatch(AuthActions.logoutSession()).unwrap();
});
