import { Col, Container, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import PropTypes from "prop-types";
import { noop } from "lodash";
import { OnBlur } from "react-final-form-listeners";

import { Fields } from "../../../constants/forms";
import * as M from "../../../constants/strings";
import * as Formatters from "../../../utils/formatters";
import * as Validators from "../../../utils/validators";
import Input from "../../atoms/Input";

const PersonalInformation = ({
  onEnterFirstName,
  onEnterLastName,
  onEnterEmail,
}) => (
  <Container>
    <Row>
      <Col xs={12} sm={6}>
        <Field
          component={Input}
          name={Fields.FIRSTNAME}
          label={M.FIRST_NAME}
          maxLength={64}
          formatOnBlur={true}
          format={Formatters.trim}
          validate={Validators.composeValidators(
            Validators.required,
            Validators.notEmpty,
            Validators.maxLength(64)
          )}
          required
        />
        <OnBlur name={Fields.FIRSTNAME}>{onEnterFirstName}</OnBlur>
      </Col>
      <Col xs={12} sm={6}>
        <Field
          component={Input}
          name={Fields.LASTNAME}
          label={M.LAST_NAME}
          maxLength={64}
          formatOnBlur={true}
          format={Formatters.trim}
          validate={Validators.composeValidators(
            Validators.required,
            Validators.notEmpty,
            Validators.maxLength(64)
          )}
          required
        />
        <OnBlur name={Fields.LASTNAME}>{onEnterLastName}</OnBlur>
      </Col>
      <Col xs={12}>
        <Field
          component={Input}
          name={Fields.PHONE_NUMBER}
          type="tel"
          label={M.MOBILE_NUMBER}
          keyboardType="numeric"
          disabled
        />
      </Col>
      <Col xs={12}>
        <Field
          component={Input}
          name={Fields.EMAIL}
          label={M.EMAIL_ADDRESS}
          maxLength={255}
          formatOnBlur
          format={Formatters.formatEmail}
          validate={Validators.composeValidators(
            Validators.notEmpty,
            Validators.email,
            Validators.maxLength(255)
          )}
        />
        <OnBlur name={Fields.EMAIL}>{onEnterEmail}</OnBlur>
      </Col>
    </Row>
  </Container>
);

PersonalInformation.defaultProps = {
  onEnterFirstName: noop,
  onEnterLastName: noop,
  onEnterEmail: noop,
};

PersonalInformation.propTypes = {
  onEnterFirstName: PropTypes.func,
  onEnterLastName: PropTypes.func,
  onEnterEmail: PropTypes.func,
};

export default PersonalInformation;
