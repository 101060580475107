import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { TrackerProvider } from "@dpdgroupuk/react-event-tracker";
import "polyfill-object.fromentries";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.scss";
import "./firebase";
import ErrorBoundary from "./features/ErrorBoundary/ErrorBoundary";
import tracker from "./utils/analytics";

TimeAgo.addDefaultLocale(en);

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <TrackerProvider tracker={tracker}>
      <PersistGate loading={null} persistor={persistor}>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </PersistGate>
    </TrackerProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
