const LocationMarker = (props) => (
  <svg
    width="0.8125rem" // 13px
    height="0.9375rem" // 15px
    viewBox="0 0 13 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.50065 7.49984C6.89023 7.49984 7.22386 7.361 7.50153 7.08334C7.77872 6.80614 7.91732 6.47275 7.91732 6.08317C7.91732 5.69359 7.77872 5.35996 7.50153 5.0823C7.22386 4.8051 6.89023 4.6665 6.50065 4.6665C6.11107 4.6665 5.77768 4.8051 5.50048 5.0823C5.22282 5.35996 5.08398 5.69359 5.08398 6.08317C5.08398 6.47275 5.22282 6.80614 5.50048 7.08334C5.77768 7.361 6.11107 7.49984 6.50065 7.49984ZM6.50065 14.3175C6.40621 14.3175 6.31176 14.2998 6.21732 14.2644C6.12287 14.229 6.04023 14.1818 5.9694 14.1228C4.24579 12.5998 2.95898 11.1862 2.10898 9.88196C1.25898 8.57721 0.833984 7.35817 0.833984 6.22484C0.833984 4.454 1.40372 3.04324 2.54319 1.99255C3.68219 0.941851 5.00135 0.416504 6.50065 0.416504C7.99996 0.416504 9.31911 0.941851 10.4581 1.99255C11.5976 3.04324 12.1673 4.454 12.1673 6.22484C12.1673 7.35817 11.7423 8.57721 10.8923 9.88196C10.0423 11.1862 8.75551 12.5998 7.0319 14.1228C6.96107 14.1818 6.87843 14.229 6.78398 14.2644C6.68954 14.2998 6.5951 14.3175 6.50065 14.3175Z"
      fill="currentColor"
    />
  </svg>
);

export default LocationMarker;
