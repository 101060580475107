import { useOutletContext } from "react-router";

import VerifyPhone from "./VerifyPhone";
import VerifyPin from "./VerifyPin";
import ProfilePage from "../../components/templates/ProfilePage";
import { ReduxifyWizard } from "../../features/Wizard";
import { EditPhoneSlice } from "./slice";
import {
  CHANGE_YOUR_MOBILE_NUMBER,
  ENTER_MOBILE_NUMBER_AND_AND_FOLLOW_VERIFICATION_STEPS,
} from "../../constants/strings";

function Page() {
  const { showBackButtonDesktop } = useOutletContext();

  return (
    <ReduxifyWizard slice={EditPhoneSlice} isLazyMount>
      <ProfilePage
        title={CHANGE_YOUR_MOBILE_NUMBER}
        subtitle={ENTER_MOBILE_NUMBER_AND_AND_FOLLOW_VERIFICATION_STEPS}
        stepName="phone"
        component={VerifyPhone}
        bodyClassName="p-4"
        showBackButtonDesktop={showBackButtonDesktop}
      />
      <ProfilePage
        title={CHANGE_YOUR_MOBILE_NUMBER}
        subtitle={ENTER_MOBILE_NUMBER_AND_AND_FOLLOW_VERIFICATION_STEPS}
        stepName="pin"
        component={VerifyPin}
        bodyClassName="p-4"
        showBackButtonDesktop={showBackButtonDesktop}
      />
    </ReduxifyWizard>
  );
}

export default Page;
