import React, { useCallback } from "react";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Trackable, useTracker } from "@dpdgroupuk/react-event-tracker";
import { Paths } from "../../router";
import CreateProfile from "../../components/organisms/CreateProfile";
import { useAuth } from "../../features/Auth";
import { useOverlay } from "../../features/Overlay";
import { STEP_NAME } from "../../constants";
import { useToaster } from "../../features/Toaster";
import { CREATE_PROFILE } from "../../constants/analytics";

const CreateProfileContainer = ({ goToNamedStep }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const auth = useAuth();
  const overlay = useOverlay();
  const toaster = useToaster();
  const tracker = useTracker();

  const onClickBack = useCallback(() => {
    tracker.logEvent(CREATE_PROFILE.ON_BACK);
    goToNamedStep("phone");
  }, [goToNamedStep, tracker]);

  const onClickSetupAccount = useCallback(async () => {
    tracker.logEvent(CREATE_PROFILE.ON_SETUP_ACCOUNT);

    try {
      overlay.show();
      await auth
        .signInWithCustomToken(searchParams.get("customToken"))
        .unwrap();
      navigate({
        pathname: Paths.CREATE_PROFILE,
        search: createSearchParams({
          step: STEP_NAME.PERSONAL_INFORMATION,
        }).toString(),
      });
    } catch (e) {
      toaster.showError({
        body: e.message,
      });
    } finally {
      overlay.hide();
    }
  }, [overlay, auth, navigate, searchParams, toaster, tracker]);

  return (
    <Trackable
      loadId={CREATE_PROFILE.LOAD}
      interfaceId={CREATE_PROFILE.INTERFACE_ID}
    >
      <CreateProfile
        onClickBack={onClickBack}
        onClickSetupAccount={onClickSetupAccount}
      />
    </Trackable>
  );
};

export default CreateProfileContainer;
