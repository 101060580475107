import { get, pick } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { Trackable, useTracker } from "@dpdgroupuk/react-event-tracker";

import ManagePreferences from "../../components/molecules/ManagePreferences";
import { useAuth } from "../../features/Auth";
import { Fields } from "../../constants/forms";
import { locationsApis } from "../../apis";
import { Validators } from "../../utils";
import { useOverlay } from "../../features/Overlay";
import { StepPropType, WizardForm } from "../../features/Wizard";
import { useToaster } from "../../features/Toaster";
import * as M from "../../constants/strings";
import { useAbortController } from "../../hooks/useAbortController";
import { VERIFY_PHONE } from "../../constants/analytics";

const VerifyPhone = ({
  nextStep,
  setValues,
  values,
  references,
  setReferences,
  previousStep,
}) => {
  const auth = useAuth();
  const overlay = useOverlay();
  const toaster = useToaster();
  const [submitError, setSubmitError] = useState(null);
  const tracker = useTracker();

  const onSubmit = useCallback(
    async (formValues) => {
      tracker.logEvent(VERIFY_PHONE.ON_NEXT);

      try {
        overlay.show();
        setValues({
          ...values,
          ...formValues,
        });
        const verificationId = await auth
          .verifyPhoneNumber(formValues)
          .unwrap();
        nextStep({ verificationId, pin: null, resendPinTimer: 60 });
      } catch (e) {
        setSubmitError(e);
      } finally {
        overlay.hide();
      }
    },
    [overlay, auth, values, nextStep, setValues, tracker]
  );

  const signalAbort = useAbortController();

  const onChangePostcode = useCallback(
    async (postcode, form) => {
      try {
        let addresses = [];
        overlay.show();

        if (Validators.isPostcodeValid(postcode)) {
          tracker.logEvent(VERIFY_PHONE.ON_POSTCODE_INPUT);

          const result = await locationsApis.searchAddressesByPostcode(
            postcode,
            {
              signal: signalAbort(),
            }
          );
          addresses = result.data;

          if (!addresses.length) {
            toaster.showError({
              body: M.UNABLE_TO_FIND_ADDRESS_WITH_POSTCODE,
            });
          }
        }

        form.change(Fields.UDPRN, null);
        setReferences("addresses", addresses);
      } catch (e) {
        setReferences("addresses", []);

        if (e.name !== "AbortError") {
          toaster.showError({
            body: get(e, "errors[0].message") || e.message,
          });
        }
      } finally {
        overlay.hide();
      }
    },
    [toaster, overlay, setReferences, signalAbort, tracker]
  );

  const onBlurPhone = useCallback(() => {
    tracker.logEvent(VERIFY_PHONE.ON_MOBILE_INPUT);
  }, [tracker]);

  const onFocusAddress = useCallback(() => {
    tracker.logEvent(VERIFY_PHONE.ON_CLICK_ADDRESS_DROPDOWN);
  }, [tracker]);

  const onSelectAddress = useCallback(
    (value) => {
      if (value) {
        tracker.logEvent(VERIFY_PHONE.ON_ADDRESS_SELECT);
      }
    },
    [tracker]
  );

  const onClickBack = useCallback(() => {
    tracker.logEvent(VERIFY_PHONE.ON_BACK);
    previousStep();
  }, [previousStep, tracker]);

  const onCantFindAddress = useCallback(() => {
    tracker.logEvent(VERIFY_PHONE.ON_CANT_FIND_ADDRESS);
  }, [tracker]);

  const initialValues = useMemo(
    () => pick(values, [Fields.PHONE_NUMBER, Fields.POSTCODE, Fields.UDPRN]),
    []
  );

  return (
    <Trackable
      loadId={VERIFY_PHONE.LOAD}
      interfaceId={VERIFY_PHONE.INTERFACE_ID}
    >
      <WizardForm initialValues={initialValues} onSubmit={onSubmit}>
        {(formProps) => (
          <ManagePreferences
            {...formProps}
            submitError={submitError}
            addresses={references.addresses || []}
            onClickBack={onClickBack}
            onChangePostcode={onChangePostcode}
            onBlurPhone={onBlurPhone}
            onFocusAddress={onFocusAddress}
            onSelectAddress={onSelectAddress}
            onCantFindAddress={onCantFindAddress}
          />
        )}
      </WizardForm>
    </Trackable>
  );
};

VerifyPhone.propTypes = StepPropType;

export default VerifyPhone;
