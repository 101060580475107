import PropTypes from "prop-types";

const What3wordsInputLogo = (props) => (
  <svg
    height={props.size}
    width={props.size}
    viewBox="0 0 16 16"
    {...props}
    fill="none"
  >
    <path
      d="M6.22223 15.4298C6.08144 15.4296 5.94271 15.389 5.81743 15.3115C5.69216 15.234 5.58391 15.1217 5.50159 14.9839C5.41927 14.846 5.36522 14.6866 5.34389 14.5187C5.32256 14.3507 5.33455 14.1791 5.37888 14.0178L8.93464 1.14457C9.00924 0.874642 9.16963 0.651534 9.38054 0.524324C9.59145 0.397113 9.8356 0.376221 10.0593 0.466242C10.2829 0.556264 10.4678 0.749824 10.5732 1.00434C10.6786 1.25886 10.696 1.55349 10.6214 1.82342L7.06561 14.6966C7.00655 14.9101 6.89333 15.0958 6.74197 15.2274C6.59061 15.359 6.40878 15.4298 6.22223 15.4298Z"
      fill="#E11F26"
    />
    <path
      d="M0.88863 15.4302C0.747843 15.4299 0.609112 15.3894 0.483835 15.3119C0.358558 15.2343 0.250312 15.1221 0.167989 14.9842C0.0856654 14.8464 0.0316151 14.687 0.0102788 14.519C-0.0110575 14.3511 0.00092836 14.1794 0.0452528 14.0182L3.60101 1.14495C3.67605 0.875695 3.83651 0.65334 4.04721 0.526655C4.25791 0.39997 4.50164 0.379297 4.72495 0.469171C4.94826 0.559044 5.13292 0.752127 5.23842 1.00607C5.34393 1.26002 5.36168 1.55409 5.28777 1.8238L1.73198 14.697C1.67292 14.9105 1.5597 15.0962 1.40835 15.2278C1.25699 15.3594 1.07517 15.4302 0.88863 15.4302Z"
      fill="#E11F26"
    />
    <path
      d="M11.5559 15.4297C11.4151 15.4294 11.2764 15.3889 11.1511 15.3114C11.0259 15.2338 10.9176 15.1216 10.8353 14.9837C10.753 14.8459 10.6989 14.6865 10.6776 14.5185C10.6562 14.3506 10.6682 14.179 10.7126 14.0177L14.2683 1.14446C14.3433 0.875206 14.5038 0.652852 14.7145 0.526167C14.9252 0.399482 15.1689 0.378809 15.3922 0.468683C15.6156 0.558556 15.8002 0.751639 15.9057 1.00558C16.0112 1.25953 16.029 1.55361 15.9551 1.82332L12.3993 14.6965C12.3402 14.91 12.227 15.0957 12.0757 15.2273C11.9243 15.3589 11.7425 15.4297 11.5559 15.4297Z"
      fill="#E11F26"
    />
  </svg>
);

What3wordsInputLogo.propTypes = {
  size: PropTypes.number,
};

What3wordsInputLogo.defaultProps = {
  size: 16,
};

export default What3wordsInputLogo;
