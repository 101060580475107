import { useMemo } from "react";
import classNames from "classnames";
import { Stack } from "react-bootstrap";
import PropTypes from "prop-types";

import Dropzone, { ErrorCode } from "react-dropzone";
import Upload from "../../atoms/icons/Upload";
import { formatBytes } from "../../../utils/formatters";

function DropBox({
  hint,
  disabled,
  placeholder,
  getRootProps,
  fileRejections,
  getInputProps,
}) {
  const inputProps = getInputProps();
  const rootProps = getRootProps({
    style: { cursor: "pointer" },
    className: classNames(
      "d-flex align-items-center justify-content-center h-100"
    ),
  });
  const error = useMemo(() => {
    if (fileRejections.length) {
      const firstError = fileRejections[0].errors[0];
      switch (firstError.code) {
        case ErrorCode.FileInvalidType:
          return `File type must be one of ${inputProps.accept.replaceAll(
            "image/",
            " "
          )}`;
        case ErrorCode.FileTooLarge:
          const [bytes] = firstError.message.match(/\d+/g);
          return `File is larger than ${formatBytes(bytes)}`;
        default:
          return firstError.message;
      }
    }
    return null;
  }, [fileRejections]);
  return (
    <Stack {...rootProps}>
      <input {...inputProps} />
      <Upload className="mb-2" />
      <h6
        className={classNames({
          "text-center": true,
          "text-black-50": disabled,
          "text-danger": !!error,
        })}
      >
        {error || placeholder}
      </h6>
      {!error && !!hint && <em className={"text-black-50"}>({hint})</em>}
    </Stack>
  );
}

DropBox.propTypes = {
  ...Dropzone.propTypes,
  hint: PropTypes.string,
  disabled: PropTypes.bool,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
};

export default DropBox;
