import api from "./api";

export const searchLocations = (postcode, options) => {
  return api.get({
    options,
    path: "/locations/search",
    query: {
      postcode,
    },
  });
};

export const searchAddressesByPostcode = (postcode, options) => {
  return api.get({
    options,
    path: "/locations/searchByPostcode",
    query: {
      postcode,
    },
  });
};

export const getNeighbourLocations = (postcode, udprn, options) => {
  return api.get({
    options,
    path: "/locations/neighbours",
    query: {
      postcode,
      udprn,
    },
  });
};

export const getPickupLocations = ({ latitude, longitude }, options) => {
  return api.get({
    options,
    path: "/pickupLocations",
    query: {
      latlong: `${latitude},${longitude}`,
    },
  });
};

export const getPickupLocationsByPostcode = (postcode, options) => {
  return api.get({
    options,
    path: "/pickupLocations/address",
    query: {
      searchAddress: postcode,
    },
  });
};

export const getPickupLocationsById = (id, options) => {
  return api.get({
    options,
    path: `/pickupLocations/${id}`,
  });
};

export const getAddressByUdprn = (udprn, options) => {
  return api.get({
    options,
    path: `/locations/${udprn}`,
  });
};
