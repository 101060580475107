import classNames from "classnames";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";

import { Accessibility, Parking, Late, Saturday, Sunday } from "../icons";

const PickupAmenities = ({
  disabledAccess,
  parkingAvailable,
  openLate,
  openSaturday,
  openSunday,
  className,
}) => {
  const items = [
    {
      icon: Accessibility,
      showIcon: disabledAccess,
    },
    {
      icon: Parking,
      showIcon: parkingAvailable,
    },
    {
      icon: Late,
      showIcon: openLate,
    },
    {
      icon: Saturday,
      showIcon: openSaturday,
    },
    {
      icon: Sunday,
      showIcon: openSunday,
    },
  ];

  return (
    <Row className={classNames("g-0", className)}>
      {items
        .filter(({ showIcon }) => showIcon)
        .map(({ icon: Icon, showIcon }, index) => (
          <Col key={index} xs="auto" className="p-0 me-2">
            {showIcon && <Icon />}
          </Col>
        ))}
    </Row>
  );
};

PickupAmenities.propTypes = {
  disabledAccess: PropTypes.bool,
  parkingAvailable: PropTypes.bool,
  openLate: PropTypes.bool,
  openSaturday: PropTypes.bool,
  openSunday: PropTypes.bool,
};

export default PickupAmenities;
