import { useCallback, useMemo, useState } from "react";
import classNames from "classnames";
import { get, isBoolean, noop } from "lodash";
import { Button, Col, Row } from "react-bootstrap";
import { Field, Form } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import { useDispatch, useSelector } from "react-redux";
import {
  Outlet,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import Async from "react-async";

import { Trackable, useTracker } from "@dpdgroupuk/react-event-tracker";

import { locationsApis } from "../../apis";
import { getPickupName } from "../../utils/pickupPoint";
import LocationMarker from "../../components/atoms/icons/LocationMarker";
import PickupPoint from "../../components/atoms/icons/PickupPoint";
import Modal from "../../components/atoms/Modal";
import Toggle from "../../components/atoms/Toggle";
import TabNavItem from "../../components/molecules/TabNavItem/TabNavItem";
import ProfilePage from "../../components/templates/ProfilePage";
import { FIRST_DELIVERY_ATTEMPT_CODE } from "../../constants";
import { ADDRESS, DELETE_ADDRESS_MODAL } from "../../constants/analytics";
import * as M from "../../constants/strings";
import { Fields } from "../../constants/forms";
import { useAuth } from "../../features/Auth";
import { useOverlay } from "../../features/Overlay";
import {
  ConsumerAddressesActions,
  ConsumerAddressesSelector,
  ConsumerAddressModel,
} from "../../features/Profile";
import { useToaster } from "../../features/Toaster";
import { DELIVERY_OPTIONS } from "./constants";
import styles from "./DeliveryAddressOptions.module.scss";
import { Paths } from "../../router";
import { formatAddressOrgPropertyStreet } from "../../models/address";
import { useRemoteConfig } from "../../features/Config";
import { ERROR_CODE } from "../../features/ErrorBoundary/utils";

function DeliveryAddress() {
  const auth = useAuth();
  const { udprn } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const overlay = useOverlay();
  const toaster = useToaster();
  const { needMoreTime } = useRemoteConfig();
  const [showConfirmDeleteAddress, setShowDeleteConfirmAddress] =
    useState(false);
  const [pickupPoint, setPickupPoint] = useState(null);
  const addresses = useSelector(ConsumerAddressesSelector.getConsumerAddresses);
  const tracker = useTracker();
  const targetAddress = addresses.find((a) => a.udprn === udprn);
  const pickupLocationCode = get(
    targetAddress,
    "preferences.pickupLocationCode"
  );
  const showFirstDeliveryAttemptToggle = get(
    targetAddress,
    `preferences.${Fields.PICKUP_LOCATION_CODE}`
  );
  const { showBackButtonDesktop } = useOutletContext();

  const initialValues = useMemo(
    () => ({
      [Fields.FIRST_DELIVERY_ATTEMPT]:
        get(targetAddress, `preferences.${Fields.FIRST_DELIVERY_ATTEMPT}`) ===
        FIRST_DELIVERY_ATTEMPT_CODE.PICKUP,
      needMoreTime: get(targetAddress, Fields.NEED_MORE_TIME),
    }),
    [targetAddress]
  );

  const onConfirmDeleteAddress = useCallback(async () => {
    try {
      overlay.show();
      tracker.logEvent(DELETE_ADDRESS_MODAL.ON_CLICK_DELETE);

      setShowDeleteConfirmAddress(false);

      const remoteAddresses = await dispatch(
        ConsumerAddressesActions.getAddresses(auth.currentSession.uid)
      ).unwrap();

      ConsumerAddressModel.validateAddressOnDelete(udprn, remoteAddresses);

      await dispatch(
        ConsumerAddressesActions.deleteAddress([auth.currentSession.uid, udprn])
      ).unwrap();

      toaster.showSuccess({
        body: M.REMOVED_ADDRESS_FROM_ACCOUNT,
        autoHide: true,
      });
      navigate(Paths.PROFILE_ADDRESSES, {
        replace: true,
      });
    } catch (e) {
      toaster.showError({
        body: e.message,
      });

      if (e.code === ERROR_CODE.NOT_FOUND) {
        navigate(Paths.PROFILE_ADDRESSES, {
          replace: true,
        });
      }
    } finally {
      overlay.hide();
    }
  }, [dispatch, overlay, toaster, auth, udprn, navigate]);

  const onFirstDeliveryAttemptToggle = useCallback(
    async (deliverStraightToPickup, form) => {
      try {
        await dispatch(
          ConsumerAddressesActions.updateAddress([
            auth.currentSession.uid,
            {
              udprn,
              "preferences.firstDeliveryAttempt": deliverStraightToPickup
                ? FIRST_DELIVERY_ATTEMPT_CODE.PICKUP
                : FIRST_DELIVERY_ATTEMPT_CODE.HOME,
            },
          ])
        ).unwrap();
      } catch (e) {
        form.reset();

        toaster.showError({
          body: e.message,
          reload: e.reload,
        });
      }
    },
    [dispatch, toaster, auth, udprn]
  );

  const onNeedMoreTimeToggle = useCallback(
    async (needMoreTime, form) => {
      if (isBoolean(needMoreTime)) {
        try {
          await dispatch(
            ConsumerAddressesActions.updateAddress([
              auth.currentSession.uid,
              {
                udprn,
                needMoreTime,
              },
            ])
          ).unwrap();
        } catch (e) {
          form.reset();

          toaster.showError({
            body: e.message,
            reload: e.reload,
          });
        }
      }
    },
    [dispatch, toaster, auth, udprn]
  );

  const loadPickupPoint = useCallback(async () => {
    const { data } = await locationsApis.getPickupLocationsById(
      pickupLocationCode
    );

    setPickupPoint({ pickupLocation: data });
  }, [pickupLocationCode]);

  return (
    <Trackable loadId={ADDRESS.LOAD} interfaceId={ADDRESS.INTERFACE_ID}>
      <ProfilePage
        title={M.YOUR_DELIVERY_OPTIONS}
        subtitle={
          <div className={styles.subtitle}>
            <p className="m-0">{M.MANAGE_YOUR_OPTIONS}</p>
            <b>{formatAddressOrgPropertyStreet(targetAddress)}</b>
          </div>
        }
        outlet={<Outlet context={[targetAddress]} />}
        showBackButtonDesktop={showBackButtonDesktop}
      >
        {DELIVERY_OPTIONS.map(
          ({ title, subTitle, href, icon, actionId }, index) => (
            <TabNavItem
              key={index}
              title={title}
              subTitle={subTitle}
              href={href}
              icon={icon}
              classes={{
                subtitle: styles.optionSubTitle,
              }}
              onClick={() => tracker.logEvent(actionId)}
            />
          )
        )}
        <TabNavItem
          title={M.PICKUP_POINT}
          subTitle={M.PICKUP_POINT_MESSAGE}
          href="pickupPoint"
          icon={PickupPoint}
          footer={
            pickupLocationCode && (
              <Async promiseFn={loadPickupPoint}>
                <Row
                  className={classNames("mt-2 w-100", styles.selectedPickup)}
                >
                  {pickupPoint && (
                    <>
                      <Col className="d-flex align-items-center" xs="auto">
                        <LocationMarker />
                      </Col>
                      <Col className="p-0">
                        <p className="mb-0">{`${getPickupName(
                          pickupPoint
                        )}, ${get(
                          pickupPoint,
                          "pickupLocation.address.postcode"
                        )}`}</p>
                      </Col>
                    </>
                  )}
                </Row>
              </Async>
            )
          }
          classes={{
            subtitle: styles.optionSubTitle,
          }}
          onClick={() => tracker.logEvent(ADDRESS.ON_CLICK_PICKUP_POINT)}
        />
        <Form initialValues={initialValues} onSubmit={noop}>
          {(fProps) => (
            <form onSubmit={fProps.handleSubmit}>
              {showFirstDeliveryAttemptToggle && (
                <>
                  <Field
                    name={Fields.FIRST_DELIVERY_ATTEMPT}
                    component={Toggle}
                    label={M.DELIVER_TO_PICKUP_POINT}
                    helperText={M.ALWAYS_DELIVER_TO_PICKUP_POINT}
                    classes={{
                      container: styles.actionOption,
                      helperText: styles.optionSubTitle,
                    }}
                    type="switch"
                  />
                  <OnChange name={Fields.FIRST_DELIVERY_ATTEMPT}>
                    {(value) => {
                      onFirstDeliveryAttemptToggle(value, fProps.form);
                      tracker.logEvent(ADDRESS.TOGGLE_PICKUP_POINT);
                    }}
                  </OnChange>
                </>
              )}

              {needMoreTime && (
                <>
                  <Field
                    name={Fields.NEED_MORE_TIME}
                    component={Toggle}
                    label={M.MORE_TIME_NEEDED}
                    helperText={M.MORE_TIME_NEEDED_MESSAGE}
                    classes={{
                      container: styles.actionOption,
                      helperText: styles.optionSubTitle,
                    }}
                    type="switch"
                  />
                  <OnChange name={Fields.NEED_MORE_TIME}>
                    {(value) => {
                      onNeedMoreTimeToggle(value, fProps.form);
                      tracker.logEvent(ADDRESS.TOGGLE_MORE_TIME_NEEDED);
                    }}
                  </OnChange>
                </>
              )}
            </form>
          )}
        </Form>
        {addresses.length > 1 && (
          <>
            <Button
              className={classNames(
                "text-start p-4",
                styles.deleteAddressButton
              )}
              onClick={() => {
                setShowDeleteConfirmAddress(true);
                tracker.logEvent(ADDRESS.ON_CLICK_DELETE_ADDRESS);
              }}
            >
              {M.DELETE_THIS_ADDRESS}
            </Button>
            <Modal
              show={showConfirmDeleteAddress}
              title={M.YOUR_DPD_PROFILE}
              acceptButtonText={M.YES_DELETE_ADDRESS}
              handleClose={() => {
                setShowDeleteConfirmAddress(false);
                tracker.logEvent(DELETE_ADDRESS_MODAL.ON_CLICK_CLOSE);
              }}
              handleAccept={onConfirmDeleteAddress}
              closeButton
              centered
            >
              <Trackable
                loadId={DELETE_ADDRESS_MODAL.LOAD}
                interfaceId={DELETE_ADDRESS_MODAL.INTERFACE_ID}
                modal
              >
                <h6>{M.DELETE_ADDRESS_PROMPT}</h6>
                <p>{M.DELETE_ADDRESS_MESSAGE}</p>
              </Trackable>
            </Modal>
          </>
        )}
      </ProfilePage>
    </Trackable>
  );
}

export default DeliveryAddress;
