import { useCallback, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { OnChange } from "react-final-form-listeners";
import { Trackable, useTracker } from "@dpdgroupuk/react-event-tracker";
import SafePlace from "../../../components/organisms/SafePlace/SafePlace";
import { WizardForm } from "../../../features/Wizard";
import styles from "../AddNewAddress.module.scss";
import * as M from "../../../constants/strings";
import Template from "../Template";
import { Fields } from "../../../constants/forms";
import { SafePlaceModel } from "../../../models";
import { ADD_ADDRESS_SAFE_PLACE } from "../../../constants/analytics";
import { SAFE_PLACE_CODE } from "../../../constants/safePlace";
import { Validators } from "../../../utils";

const initialState = {
  [Fields.SAFE_PLACE_CODE]: null,
  [Fields.SAFE_PLACE_CUSTOM]: null,
  [Fields.IMAGE_URL]: null,
  [Fields.SAFE_PLACE_RESPONSIBILITY]: false,
};

const SafePlaceStep = ({ values, nextStep, setValues }) => {
  const tracker = useTracker();
  const initialValues = useMemo(
    () => ({
      ...initialState,
      ...values,
    }),
    []
  );

  const onClickSkip = useCallback(() => {
    tracker.logEvent(ADD_ADDRESS_SAFE_PLACE.ON_SETUP_LATER);
    setValues(initialState);
    nextStep();
  }, [nextStep, setValues, tracker]);

  const handleSubmit = useCallback(() => {
    tracker.logEvent(ADD_ADDRESS_SAFE_PLACE.ON_NEXT);
    nextStep();
  }, [nextStep, tracker]);

  const handleClickBack = useCallback(() => {
    tracker.logEvent(ADD_ADDRESS_SAFE_PLACE.ON_BACK);
  }, [tracker]);

  const handleSafePlaceSelect = useCallback(
    (value) => {
      if (value && value !== SAFE_PLACE_CODE.OTHER) {
        tracker.logEvent(ADD_ADDRESS_SAFE_PLACE.ON_SELECT);
      }
    },
    [tracker]
  );

  const handleSafePlaceCustomChange = useCallback(
    (value) => {
      if (value && Validators.isNonEmptyString(value)) {
        tracker.logEvent(ADD_ADDRESS_SAFE_PLACE.ON_TEXT_INPUT);
      }
    },
    [tracker]
  );

  const handleTermsChange = useCallback(() => {
    tracker.logEvent(ADD_ADDRESS_SAFE_PLACE.ON_TERMS);
  }, [tracker]);

  const handleUploadClick = useCallback(() => {
    tracker.logEvent(ADD_ADDRESS_SAFE_PLACE.ON_CLICK_UPLOAD);
  }, [tracker]);

  const handleDeleteClick = useCallback(() => {
    tracker.logEvent(ADD_ADDRESS_SAFE_PLACE.ON_CLICK_DELETE);
  }, [tracker]);

  const handleImageClick = useCallback(() => {
    tracker.logEvent(ADD_ADDRESS_SAFE_PLACE.ON_CLICK_IMAGE);
  }, [tracker]);

  return (
    <Trackable
      interfaceId={ADD_ADDRESS_SAFE_PLACE.INTERFACE_ID}
      loadId={ADD_ADDRESS_SAFE_PLACE.LOAD}
    >
      <WizardForm
        initialValues={initialValues}
        onSubmit={handleSubmit}
        formClassName={styles.stepContent}
        validate={SafePlaceModel.validateSafePlaceValues}
      >
        <Template
          title={M.SELECT_SAFE_PLACE}
          subtitle={M.WE_CAN_LEAVE_IN_SAFE_PLACE}
          onClickSkip={onClickSkip}
          onClickBack={handleClickBack}
        >
          <SafePlace
            classes={{ optionCol: "gx-lg-2" }}
            onUploadClick={handleUploadClick}
            onDeleteClick={handleDeleteClick}
            onImagePreviewClick={handleImageClick}
          />
          <OnChange name={Fields.SAFE_PLACE_CODE}>
            {handleSafePlaceSelect}
          </OnChange>
          <OnChange name={Fields.SAFE_PLACE_CUSTOM}>
            {handleSafePlaceCustomChange}
          </OnChange>
          <OnChange name={Fields.SAFE_PLACE_RESPONSIBILITY}>
            {handleTermsChange}
          </OnChange>
        </Template>
      </WizardForm>
    </Trackable>
  );
};

SafePlace.SafePlaceStep = {
  setValues: PropTypes.func,
  values: PropTypes.object,
  nextStep: PropTypes.func,
};

export default SafePlaceStep;
