import React, { forwardRef } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import { WHAT_3_WORDS_PREFFIX } from "../../../constants/strings";
import styles from "./MapBoxWhat3Words.module.scss";

const MapBoxWhat3Words = forwardRef(({ title, className, ...props }, ref) => {
  return (
    <div
      className={classNames(className, styles.boxContainer, "shadow-sm")}
      ref={ref}
      {...props}
    >
      <div className={styles.messageContainer}>
        <span className={styles.messagePrefix}>{WHAT_3_WORDS_PREFFIX}</span>
        {title}
      </div>
    </div>
  );
});

MapBoxWhat3Words.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
};

export default MapBoxWhat3Words;
