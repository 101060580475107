import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";

import * as M from "../../../constants/strings";
import { STEP_NAME } from "../../../constants";
import { Neighbour } from "../../atoms/icons";
import EditableCard from "../../molecules/EditableCard/EditableCard";
import AddressView, { AddressPropType } from "../../molecules/AddressView";
import { ClassesPropType } from "./propTypes";

const NeighboursCard = ({ classes, available, data, onEditClick }) => (
  <EditableCard
    icon={Neighbour}
    title={
      <>
        <span className="d-none d-sm-inline">{M.YOUR_PREFERRED_NEIGHBOUR}</span>
        <span className="d-sm-none">{M.PREFERRED_NEIGHBOUR}</span>
      </>
    }
    onEdit={() => onEditClick(STEP_NAME.NEIGHBOURS)}
    classes={classes}
    editable={available}
  >
    <Row>
      <Col>
        <p className={classes.dataHeader}>{M.PREFERRED_NEIGHBOUR}:</p>
        {(!available && <p className={classes.text}>{M.UNAVAILABLE}</p>) ||
          (data.preferred && (
            <AddressView
              address={data.preferred}
              classes={{ text: classes.text }}
            />
          )) || <p className={classes.text}>{M.ANY}</p>}
      </Col>
      <Col>
        <p className={classes.dataHeader}>{M.AVOID_NEIGHBOUR}:</p>
        {(!available && <p className={classes.text}>{M.UNAVAILABLE}</p>) ||
          (data.avoid && (
            <AddressView
              address={data.avoid}
              classes={{ text: classes.text }}
            />
          )) || <p className={classes.text}>{M.NONE}</p>}
      </Col>
    </Row>
  </EditableCard>
);

NeighboursCard.propTypes = {
  classes: ClassesPropType,
  available: PropTypes.bool,
  onEditClick: PropTypes.func,
  data: PropTypes.shape({
    avoid: AddressPropType,
    preferred: AddressPropType,
  }),
};

export default NeighboursCard;
