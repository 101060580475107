import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { createWizardSlice } from "../../features/Wizard";

export const EditPhoneSlice = createWizardSlice("editPhone");
export const EditPhoneSliceSelectors = EditPhoneSlice.selectors;

export const EditPhoneReducer = persistReducer(
  {
    key: "editPhone",
    storage: storage,
  },
  EditPhoneSlice.reducer
);
