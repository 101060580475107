export const PERSONAL_INFO = "Personal Info";
export const ADDRESS = "Address";
export const LOCATION = "Location";
export const NEIGHBOUR = "Neighbour";
export const LEAVE_SAFE = "Leave Safe";
export const PICKUP_POINT = "Pickup Point";
export const DPD_PREFERENCES = "DPD preferences";
export const LOOKS_LIKE_HAVE_NOT_PARCEL =
  "Looks like you’ve not previously received a DPD parcel with those details";
export const NOT_WORRY =
  "Not to worry, we can quickly create you a new account now so that you can manage your DPD delivery options";
export const MANAGE_DPD_PREFERENCES = "Manage your DPD preferences";
export const BEFORE_START =
  "Before we start let’s see if we've already got any information from your previous DPD deliveries";
export const SIGN_IN_TO_ACCOUNT =
  "Sign in to an existing account or create a new account to update your delivery preferences with DPD and never miss a parcel delivery";
export const HAVE_FOUND_ACCOUNT = "We’ve found an account with those details";
export const IS_YOUR_ADDRESS = "Is this your current delivery address?";
export const VERIFY_MOBILE_NUMBER = "Please verify your mobile number";
export const CHANGE_YOUR_MOBILE_NUMBER = "Change your mobile number";
export const ENTER_MOBILE_NUMBER_AND_AND_FOLLOW_VERIFICATION_STEPS =
  "Enter new mobile number and follow the verification steps";
export const HAVE_SENT_PIN_TO_$ = (phoneNumber) =>
  `We’ve sent you a 6 digit pin to your mobile number ${phoneNumber}`;
export const WE_HAVE_SENT_PIN_TO_$_ENTER_THE_PIN = (phoneNumber) =>
  `${HAVE_SENT_PIN_TO_$(
    phoneNumber
  )}, enter the pin to verify your mobile number`;
export const BASED_UPON_YOUR_LOCATION = "Based upon your current location ";
export const IS_YOUR_CLOSEST_PICKUP = " is your closest Pickup Point";
export const CLOSED_FOR_LUNCH = "Closed for lunch";
export const CLOSED_FOR_LUNCH_BETWEEN = "Closed for lunch between";
export const SHOP = "Shop";
export const LOCKER = "Locker";
export const OPEN = "Open";
export const OPENS = "Opens";
export const CLOSED = "Closed";
export const CLOSES = "Closes";
export const MILES = "mi";
export const EMAIL = "email";
export const SIGN_OUT = "Sign Out";

// Labels
export const SELECT_ADDRESS = "Select your address";
export const PLEASE_SELECT_AVOID_NEIGHBOUR =
  "Please select a neighbour to avoid (if applicable)";
export const PLEASE_SELECT_YOUR_PREFERRED_NEIGHBOUR =
  "Please select your preferred neighbour";
export const DELIVER_TO_ANY_OF_MY_NEIGHBOURS =
  "Deliver to any of my neighbours";
export const MOBILE_NUMBER = "Mobile Number";
export const NOTIFICATION_EMAILS = "Notification Emails";
export const POSTCODE = "Post Code";
export const FIRST_NAME = "First Name";
export const LAST_NAME = "Last Name";
export const CANT_FIND_YOUR_ADDRESS = "Can’t find your address?";
export const WE_ARE_SORRY_THAT_YOU_CANT_FIND_YOUR_ADDRESS =
  "We're sorry, but it appears that your address is not recognised in our system. There could be several reasons for this such as it's a newly built property.";
export const UNABLE_TO_FIND_ADDRESS_WITH_POSTCODE =
  "Sorry, we’re unable to find an address with the postcode you’ve provided";
export const MY_ADDRESS_IS_NOT_SHOWN_IN_THE_POSTCODE_FINDER =
  "My address is not shown in the Postcode Finder?";
export const EMAIL_ADDRESS = "Email Address";

export const ENTER_EMAIL_ADDRESS = "Enter email address";
export const EMAIL_ADDRESSES = "Email Addresses";
export const FRONT_PORCH = "Front Porch";
export const GARAGE = "Garage";
export const OUTBUILDING = "Outbuilding";
export const REAR_PORCH = "Rear Porch";
export const OTHER_SAFE_PLACE = "Other safe place";
export const OTHER_SAFE_PLACE_EXAMPLE = "E.g. ‘Side alley access to garden’";
export const ADD_SAFE_PLACE_PHOTO =
  "You can also add a photo of your safe place";
export const ADD_SAFE_PLACE_PHOTO_AFTER_SELECTION =
  "Once you've made a selection you can also add a photo of your safe place";
export const ACCEPT_RESPONSIBILITY =
  "Yes, I accept full responsibility for parcels that are left in my safe place";
export const NO_AVAILABLE_PICKUP_POINTS = "No available Pickup Points";
export const NO_AVAILABLE_PICKUP_DPD_POINTS =
  "We’re sorry, but there seems to be no available DPD Pickup Points within a 30 mile radius of your location.";
export const WE_ALWAYS_ADDING_PICKUP_LOCATIONS =
  "We're always adding new Pickup locations regularly so please check back soon to set your preferred Pickup Point.";
export const UNAVAILABLE = "Unavailable";
export const ACCOUNT_OVERVIEW = "Account Overview";
export const YOUR_DELIVERY_ADDRESSES = "Your delivery addresses";
export const HOW_WE_CONTACT_YOU = "How we contact you";
export const NEW_DELIVERY_ADDRESS = "+ New Delivery Address";
export const ACCOUNT = "Account";
export const INFORMATION = "Information";
export const NOTIFICATIONS = "Notifications";
export const FULL_NAME = "Full Name";
export const UNVERIFIED = "Unverified";
export const PINPOINT_ADDRESS = "Pinpoint Address";
export const PLEASE_MAKE_CHANGE = "Please make a change to save";
export const EMAIL_LOGIN = "Email Login";
export const GOOGLE_ACCOUNT = "Google Account";
export const MICROSOFT_ACCOUNT = "Microsoft Account";
export const FACEBOOK_ACCOUNT = "Facebook Account";
export const APPLE_ACCOUNT = "Apple Account";
export const YAHOO_ACCOUNT = "Yahoo Account";
export const ADD_FIVE_EMAILS = "You can add up to five email addresses";
export const ENTER_NEW_EMAIL_ADDRESS =
  "Enter new email address and follow the verification steps";
export const ADD_TEN_ADDRESSES = "You can add up to ten addresses";
export const ADDRESS_NAME = "Name of address";
export const SELECT_ADDRESS_TYPE = "Select type of address";
export const PINPOINT_ADDRESS_MESSAGE =
  "Move the map to pinpoint your exact location or enter your what3words to help your driver find your property";
export const ADD_NOTE = "Add note";
export const ADD_EMAIL = "Add email";
export const ADD_EMAIL_ADDRESS = "Add email address";
export const ADD_NOTE_MESSAGE =
  "Add a note to help our driver find your property";
export const ADD_PHOTO = "Add photo";
export const ADD_YOUR_PROPERTY_PHOTO = "Add a photo of your property";
export const PREFERRED_NEIGHBOUR_MESSAGE =
  "If the sender allows we will deliver your parcel to your preferred neighbour when you're not at home";
export const LEAVE_SAFE_MESSAGE =
  "If the sender allows, we will leave your parcel in your chosen safe place when you're not at home";
export const PICKUP_POINT_MESSAGE =
  "Select a Pickup Point nearest to you so you can collect your parcels, if the sender allows";
export const DELIVER_TO_PICKUP_POINT =
  "Deliver my parcels straight to my Pickup Point";
export const ALWAYS_DELIVER_TO_PICKUP_POINT =
  "Always deliver my parcels to my Pickup Point";
export const MORE_TIME_NEEDED = "More time needed";
export const MORE_TIME_NEEDED_MESSAGE =
  "Not everyone can get to the door quickly. If that's you, then turn on 'More time needed'";
export const DELETE_THIS_ADDRESS = "Delete this address";
export const SELECT_SAVE = "Select save to confirm your change";
export const ENTER_NOTE = "Enter your note";
export const CUSTOM_ADDRESS_LABEL = "Custom address label";
export const ADD_LABEL = "Add a label (e.g. ‘University’)";
export const ADDRESS_PHOTO = "Address Photo";
export const DELIVERY_NOTE = "Delivery Note";
export const ON = "On";
export const ADDRESS_NOTE = "Address note";

// Buttons
export const HOME = "Home";
export const ADD = "Add";
export const SAVE = "Save";
export const BACK = "Back";
export const BACK_TO_HOME = "Back to home";
export const NEXT = "Next";
export const SHOPS = "Shops";
export const LOCKERS = "Lockers";
export const SETUP_YOUR_ACCOUNT = "Setup Your Account";
export const NO_SETUP_YOUR_ACCOUNT = "No, Setup Your Account";
export const YES_THAT_IS_ME = "Yes, That’s Me";
export const CANT_FIND_ADDRESS = "Can’t find your address?";
export const SELECT = "Select";
export const CONFIRM = "Confirm";
export const DETAILS = "Details";
export const DIRECTIONS = "Directions";
export const EDIT = "Edit";
export const SELECTED = "Selected";
export const SETUP_LATER = "Setup Later";
export const SIGN_IN_USING_$ = (what) => `Sign in using ${what}`;
export const SIGN_IN_WITH_MICROSOFT = SIGN_IN_USING_$("your Microsoft account");
export const SIGN_IN_WITH_GOOGLE = SIGN_IN_USING_$("your Google account");
export const SIGN_IN_WITH_APPLE_ID = SIGN_IN_USING_$("your Apple ID");
export const SIGN_IN_WITH_FACEBOOK = SIGN_IN_USING_$("your Facebook profile");
export const SIGN_IN_WITH_YAHOO = SIGN_IN_USING_$("your Yahoo account");
export const SIGN_IN_WITH_EMAIL = SIGN_IN_USING_$("your email address");
export const CHOOSE_ANOTHER_LOCATION = "Choose another location";
export const HAPPY_TO_USE_LOCATION = "I’m happy to use this location";
export const GO_BACK_TO_DEFAULT_PICKUP = "Go back to default Pickup Point";
export const RESET = "Reset";
export const LIST_VIEW = "List View";
export const CLOSE = "Close";
export const DONE = "Done";
export const YES_UNLINK_ACCOUNT = "Yes, unlink this account";
export const YES_DELETE_EMAIL = "Yes, Delete This Email";
export const YES_DELETE_ADDRESS = "Yes, Delete This Address";
export const YES_DELETE_PROFILE = "Yes, Delete Profile";
export const ADD_ADDRESS = "Add address";
export const ADD_NEW_EMAIL = "Add new email address";
export const RESEND_VERIFICATION_EMAIL = "Resend Verification Email";
export const DIDNT_RECEIVE_EMAIL = "I didn't receive my email";
export const DELETE = "Delete";
export const UPLOAD = "Upload";

// Titles
export const PERSONAL_INFORMATION = "Your personal Information";
export const YOUR_DELIVERY_ADDRESS = "Your delivery address";
export const PINPOINT_LOCATION = "Pinpoint Location";
export const WHAT_3_WORDS = "What3Words";
export const SELECT_NEIGHBOUR = "Select your preferred neighbour";
export const SELECT_SAFE_PLACE = "Select your preferred safe place";
export const CHOOSE_PICKUP_POINT = "Choose your preferred Pickup Point";
export const SET_PICKUP_POINT = "Set your Pickup Point";
export const CONFIRM_DETAILS = "Confirm your details and you’re all set:";
export const YOUR_SAFE_PLACE = "Your preferred safe place";
export const PREFERRED_SAFE_PLACE = "Preferred safe place";
export const YOUR_PINPOINT_LOCATION = "Your pinpoint location";
export const YOUR_PREFERRED_NEIGHBOUR = "Your preferred neighbour";
export const YOUR_PICKUP_POINT = "Your Pickup point";
export const REMINDER = "Reminder";
export const NO_AVAILABLE_NEIGHBOURS = "No Available Neighbours";
export const YOUR_DPD_PROFILE = "Your DPD Profile";
export const ADDRESSES = "Addresses";
export const PERSONAL_INFORMATION_SHORT = "Personal Information";
export const LOGIN_ACCOUNT = "Login Accounts";
export const YOUR_DELIVERY_OPTIONS = "Your delivery options";
export const ADDRESS_LABEL = "Address label";
export const ADD_DELIVERY_ADDRESS = "Add delivery address";
export const DELIVERY_ADDRESS = "Delivery Address";
export const DELIVERY_ADDRESSES = "Delivery addresses";
export const STRAIGHT_TO_PICKUP_POINT = "Straight to Pickup Point";
export const FIRST_TIME_DELIVERY = "First time delivery";
export const EMAIL_RESENT = "We've sent another email, check your inbox";
export const CHECK_YOUR_EMAIL = "Check your email";

// Subtitles
export const KEEP_UP_TO_DATE =
  "So we can keep you up-to date about all your DPD deliveries";
export const WE_KNOW_YOUR_ADDRESS =
  "So that we know your primary delivery address and we can keep you informed when deliveries are being sent to your address";
export const SET_EXACT_LOCATION =
  "Set your exact location on the map to help your driver find your property. Move the map pin to where your home is or use what3words.";
export const LET_US_KNOW_NEIGHBOURS =
  "Let us know which neighbours you would prefer as a backup";
export const WE_CAN_LEAVE_IN_SAFE_PLACE =
  "If you’re not available to sign for your parcel we can leave it in your safe place, if allowed by the sender.";
export const WE_HAVE_SHOPS =
  "Did you know that we also have hundreds of shops and lockers which you can use to collect your parcels from?";
export const LET_US_KNOW_PICKUP_POINT =
  "Let us know which Pickup Point you would like as a backup";
export const PHONE_NUMBER = "Phone Number";
export const FULL_ADDRESS = "Full Address";
export const PREFERRED_NEIGHBOUR = "Preferred Neighbour";
export const AVOID_NEIGHBOUR = "Avoid Neighbour";
export const SAFE_PLACE = "Safe Place";
export const PROFILE_SUBHEADER =
  "See all of your information held by DPD, manage your delivery options or learn about your profile deactivation options";
export const QUICK_OVERVIEW_OF_YOUR_ACCOUNT =
  "A quick overview of your account information";
export const UPDATE_ACCOUNT_PROFILE = "Update your account profile below";
export const USED_FOR_EMAIL_NOTIFICATIONS =
  "Used for email parcel notifications";
export const HOW_YOU_LOGIN =
  "This is how you login to your account, you must have at least one login method";
export const USED_MOBILE_NOTIFICATIONS =
  "Used for mobile phone parcel notifications";
export const UNLINK_ACCOUNT_PROMPT =
  "Are you sure you would like to unlink this login method?";
export const DELETE_EMAIL_PROMPT =
  "Are you sure you would like to delete this email address?";
export const MANAGE_NOTIFICATIONS = "Manage your notification options";
export const DELETE_ADDRESS_PROMPT =
  "Are you sure you would like to delete this address?";
export const MANAGE_YOUR_OPTIONS = "Manage your delivery options for: ";
export const CREATE_OWN_LABEL = "Select or create your own address label";
export const ADD_ADDITIONAL_ADDRESS =
  "Follow the steps and add your additional delivery address";
export const FIRST_TIME_DELIVERY_DELIVERY_ADDRESS_DESCRIPTION =
  "We have saved your delivery preferences and we will deliver your parcel as instructed, provided and sender allows it";
export const FIRST_TIME_DELIVERY_STRAIGHT_TO_PICKUP_POINT_DESCRIPTION =
  "Always deliver my parcels to my chosen Pickup Point";
export const CLICK_TO_UPDATE_ADDRESS =
  "Click on the address to update your delivery preferences";

// Messages
export const NO_$_SETUP = (what) =>
  `You haven't set your ${what} yet, you can do this now or set it up later`;
export const NO_W3W_SETUP = NO_$_SETUP("what3words location");
export const NO_SAFE_PLACE_SETUP = NO_$_SETUP("preferred safe place");
export const NO_PICKUP_POINT_SETUP = NO_$_SETUP("Pickup point");
export const ANY = "Any";
export const NONE = "None";
export const NOT_SET = "Not Set";
export const SORRY_NO_NEIGHBOURS_IN_YOUR_AREA =
  "We’re sorry, but there seems to be no available neighbours within your postcode area.";
export const UNLINK_ACCOUNT_MESSAGE =
  "If you unlink this login method, you will no longer be able to login via that account";
export const DELETE_EMAIL_MESSAGE =
  "If you delete this email address, you will no longer be able to receive parcel notifications from this account";
export const DELETE_ADDRESS_MESSAGE =
  "If you delete this address, you will no longer be able to manage your delivery preferences at this location";
export const AUTH_PROVIDER_DELETED_MESSAGE = (provider) =>
  `Your ${provider} login method has been removed, you will no longer be able to login via this account`;
export const CHANGES_SAVED = "Your change has been saved";
export const EMAIL_$_REMOVED = (email) => (
  <p>
    <span>Your email </span>
    <b>{email}</b>
    <span> has been removed from your account</span>
  </p>
);
export const YOU_CHOSEN_$_AS_PREFERRED_PICKUP = (pickup) =>
  `You’ve chosen ‘${pickup}’ as your preferred Pickup Point`;
export const STAY_UPDATED_WITH_NOTIFICATIONS =
  "Stay updated on all things DPD with notifications relating to DPD Design Space, Special Offers, DPD News & Updates and more.";
export const WILL_NEVER_SHARE_INFORMATION =
  "We will never share your information with any third parties";
export const EMAIL_VERIFIED = "Your email has been verified";
export const MOBILE_NUMBER_VERIFIED = "Your mobile number has been verified";

// Errors
export const PLEASE_CHECK_CHECKBOX =
  "Please check this box if you would like to proceed";
export const PLEASE_SELECT_SAFE_PLACE =
  "You must select at least one safe place option";
export const WE_CANNOT_FIND_YOUR_WHAT3WORDS = "We cannot find your what3words";
export const PINPOINT_LOCATION_UNAVAILABLE = "Pinpoint Location Unavailable";
export const PINPOINT_LOCATION_UNAVAILABLE_MESSAGE_DETAILS =
  "Sorry, we're unable to pinpoint your location based on your address";
export const ASYNC_REQUEST_ABORTED = "Async request aborted";
export const INVALID_EMAIL = "Please enter a valid email";
export const WHAT_3_WORDS_PREFFIX = "///";
export const TAP_FIND_YOUR_WHAT_3_WORDS = "Tap 'Find your what3words'";
export const PIN_TOO_FAR_FROM_ORIGINAL_LOCATION =
  "Pin too far from original location";
export const EMAIL_ADDRESS_UNVERIFIED =
  "Email address unverified - Please verify via the link we’ve emailed you";
export const THIS_$_ADDED = (entity) => `This ${entity} has already been added`;
export const THIS_EMAIL_ADDED = THIS_$_ADDED(EMAIL);
export const THIS_ADDRESS_DELETED = "This address has already been deleted";

export const ENTER_MOBILE_NUMBER = "Enter mobile number";

export const WE_HAVE_SENT_$_TO_$ = (what, to) =>
  `We’ve sent ${what} to ${to}, please check your inbox and verify your email, be sure to check your spam folder too`;

export const WE_HAVE_SENT_VERIFICATION_EMAIL_TO_$ = (email) =>
  WE_HAVE_SENT_$_TO_$("a verification email", email);

export const WE_HAVE_SENT_ANOTHER_VERIFICATION_EMAIL_TO_$ = (email) =>
  WE_HAVE_SENT_$_TO_$("another verification email", email);
export const THIS_NUMBER_IS_ALREADY_IN_USE =
  "This number is already in use on your account";

export const ACCEPT_SAFE_PLACE_IMAGE_HINT =
  "Only *.jpeg, *.png, *.webp and *.bmp image less than 10MB will be accepted";

export const YOUR_SAFE_PLACE_IMAGE = "Your safe place image";
export const YOUR_PROPERTY_IMAGE = "Your property image";

export const DRAG_AND_DROP_FILE_HERE =
  "Drag and drop a file here or click to upload";

export const THIS_ADDRESS_HAS_ALREADY_BEEN_ADDED =
  "This address has already been added to your profile";

export const CHOOSE_YOUR_NAME_OF_ADDRESS = "Choose your name of address";

export const REMOVED_ADDRESS_FROM_ACCOUNT =
  "Your delivery address has been removed from your account";

export const ADDED_ADDRESS_TO_YOUR_ACCOUNT =
  "Your address has been successfully added to your account";

export const SOMETHING_WENT = "Something went wrong";
export const SOMETHING_WENT_WRONG_TRY_AGAIN =
  "Something went wrong, please try again";

export const DELETE_MY_PROFILE = "Delete My Profile";
export const DELETE_YOUR_PROFILE = "Delete your profile";
export const SURE_TO_DELETE_PROFILE =
  "Are you sure you would like to delete your profile?";
export const DELETE_MY_PROFILE_MESSAGE =
  "If you delete your profile, you will no longer be able to manage your delivery preferences with this account";

export const REMOVED_PROFILE_SUCCESS =
  "Your profile has been removed successfully";
export const PLEASE_FILL_ADDRESS_LABEL =
  "Please fill out a custom address label";
export const REQUIRED = "Required";
export const SHOULD_CONTAIN_ONE_CHARACTER =
  "Should contain at least one non-whitespace character";
export const INVALID_POSTCODE = "Invalid postcode";
export const INVALID_MOBILE_NUMBER = "Invalid mobile number";
export const MUST_BE_NUMBER = "Must be a number";
export const GREATER_THAN_$ = (min) => `Should be greater than ${min}`;
export const DIFFERENT_THAN_$ = (entity) =>
  `Should be different than ${entity}`;
export const LESS_OR_$_CHARACTERS = (max) =>
  `Must be ${max} characters or less`;
export const GREATER_OR_$_CHARACTERS = (min) =>
  `Must be ${min} characters or greater`;

export const EMAIL_HAS_ALREADY_BEEN_VERIFIED =
  "Email has already been verified";

export const UNABLE_TO_LOAD_IMAGE =
  "Sorry, we're unable to load the image. Please try to upload the photo again";
// NOTE: ask in slack about wording
export const WE_CANT_VERIFY_EMAIL =
  "Sorry, we can't verify this email because it is not linked with your profile";
export const LINK_EXPIRED =
  "Sorry, but it looks like the link you’re trying to use has now expired";

export const SORRY_CANT_LOAD_PAGE = "Sorry, we can’t load this page";
export const UNABLE_TO_LOAD_THIS_PAGE = "Sorry, we're unable to load this page";
export const TRY_AGAIN = "Try again";
export const UNABLE_TO_LOAD_THIS_PAGE_TRY_AGAIN =
  "Sorry, we're unable to load this page, please try again.";
export const PAGE_NOT_FOUND = "The page you’re looking for can’t be found";
export const YOU_HAVE_ACCOUNT_REFRESH =
  "It looks like you’ve already got an account. Please refresh the page.";
export const REFRESH = "Refresh";

export const $_NOT_FOUND_REFRESH = (what) =>
  `Sorry, this ${what} no longer exists in your account. Please refresh the page.`;

export const ACCOUNT_NOT_FOUND_REFRESH =
  "Sorry, this account no longer exists. Please refresh the page.";
export const LOST_INTERNET_CONNECTION =
  "Sorry, it looks like you’ve lost connection. Please connect to the internet and try again";
export const MAX_RETRY_TIME_EXCEEDED =
  "Max retry time for operation exceeded, please try again";
export const PLEASE_REVIEW_MANDATORY_FIELDS =
  "Please review your data and populate mandatory fields";
