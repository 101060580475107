import PropTypes from "prop-types";
import { Button, Col, Row } from "react-bootstrap";

import * as M from "../../../constants/strings";
import {
  getPickupAddress,
  getPickupAmenities,
  getPickupInfo,
  getPickupName,
} from "../../../utils/pickupPoint";
import Alert from "../../atoms/Alert";
import PickupPoint from "../../atoms/icons/PickupPoint";
import PreferredPickupCard from "../../molecules/PreferredPickupCard";
import PickupLocationsMap from "../../molecules/PickupLocationsMap";
import Template from "../../templates/OnboardingStepContainer";

const PreferredPickupPoint = ({
  currentLocation,
  pickupLocations,
  preferredPickupPoint,
  isHappyToUseLocation,
  onHappyToUseLocationClick,
  onChooseLocationClick,
  onSetupLater,
  isPickupPointsAvailable,
}) => (
  <Template
    title={M.CHOOSE_PICKUP_POINT}
    subtitle={M.WE_HAVE_SHOPS}
    icon={PickupPoint}
    onSetupLaterClick={onSetupLater}
  >
    {isPickupPointsAvailable ? (
      <>
        <Row>
          <Col className="mb-4">
            <PreferredPickupCard
              name={getPickupName(preferredPickupPoint)}
              address={getPickupAddress(preferredPickupPoint)}
              info={getPickupInfo(preferredPickupPoint)}
              amenities={getPickupAmenities(preferredPickupPoint)}
              selected={isHappyToUseLocation}
            >
              <PickupLocationsMap
                currentLocation={currentLocation}
                pickupLocations={pickupLocations}
                selectedPickupPoint={preferredPickupPoint}
                mapPadding={{ top: 50, bottom: 15, left: 25, right: 25 }}
                interactive={false}
              />
            </PreferredPickupCard>
          </Col>
        </Row>
        <Row>
          <Col className="text-center mb-4">
            <span>
              <span>{M.BASED_UPON_YOUR_LOCATION}</span>
              <b>{getPickupName(preferredPickupPoint)}</b>
              <span>{M.IS_YOUR_CLOSEST_PICKUP}</span>
            </span>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col md={6} className="mb-2 mb-md-0 pe-md-1">
            <Button
              variant="outline-danger"
              className="w-100"
              onClick={onChooseLocationClick}
            >
              {M.CHOOSE_ANOTHER_LOCATION}
            </Button>
          </Col>
          <Col md={6} className="ps-md-1">
            <Button
              variant={isHappyToUseLocation ? "success" : "danger"}
              className="w-100"
              onClick={onHappyToUseLocationClick}
            >
              {isHappyToUseLocation ? M.SELECTED : M.HAPPY_TO_USE_LOCATION}
            </Button>
          </Col>
        </Row>
      </>
    ) : (
      <Alert
        variant="warning"
        title={M.NO_AVAILABLE_PICKUP_POINTS}
        message={
          <span>
            <span>{M.NO_AVAILABLE_PICKUP_DPD_POINTS}</span>
            <br />
            <span>{M.WE_ALWAYS_ADDING_PICKUP_LOCATIONS}</span>
          </span>
        }
      />
    )}
  </Template>
);

PreferredPickupPoint.propTypes = {
  isHappyToUseLocation: PropTypes.bool,
  onHappyToUseLocationClick: PropTypes.func.isRequired,
  onChooseLocationClick: PropTypes.func.isRequired,
  onSetupLater: PropTypes.func,
  isPickupPointsAvailable: PropTypes.bool,
  onClickNext: PropTypes.func,
};

export default PreferredPickupPoint;
