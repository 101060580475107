import React, { useCallback, useState, useMemo } from "react";
import classNames from "classnames";
import { Tab, Tabs } from "react-bootstrap";
import { useField, useForm } from "react-final-form";
import PropTypes from "prop-types";
import { noop } from "lodash";

import * as M from "../../../constants/strings";
import { MAX_POINT_DISTANCE } from "../../../constants/pinPointLocation";
import { PIN_TOO_FAR_FROM_ORIGINAL_LOCATION } from "../../../constants/strings";
import { getDistanceFromLatLonInKm } from "../../../utils/map";
import Alert from "../../../components/atoms/Alert";
import ChooseYourLocation from "../../../components/organisms/ChooseYourLocation";
import What3WordsLocation from "../../../components/organisms/What3WordsLocation";
import { getAddressPoint } from "../../../models/address";
import styles from "./PinPointLocation.module.scss";

const PinPointLocation = ({ deliveryAddress, onTabChange, analytics }) => {
  const addressPoint = useMemo(
    () => getAddressPoint(deliveryAddress),
    [deliveryAddress]
  );
  const pinPointLocation = useMemo(
    () => ({
      udprn: addressPoint ? deliveryAddress.udprn : null,
      lngLat: {
        lat: addressPoint?.latitude || null,
        lng: addressPoint?.longitude || null,
      },
    }),
    [deliveryAddress, addressPoint]
  );
  const lngLatField = useField(
    "lngLat",
    {
      validate: (value) => {
        if (!value || (!value.lng && !value.lat)) {
          return;
        }
        const distance = getDistanceFromLatLonInKm(
          pinPointLocation.lngLat,
          value
        );

        return (
          distance > MAX_POINT_DISTANCE && PIN_TOO_FAR_FROM_ORIGINAL_LOCATION
        );
      },
    },
    [pinPointLocation]
  );

  const form = useForm();

  const pointLocationInit = useMemo(() => {
    const formState = form.getState();

    if (formState.submitting) {
      return;
    }

    if (
      !formState.values.udprn ||
      formState.values.udprn !== deliveryAddress?.udprn
    ) {
      form.change("what3words", pinPointLocation.what3words);
      form.change("udprn", pinPointLocation.udprn);
      form.change("lngLat", pinPointLocation.lngLat);

      return pinPointLocation;
    }

    if (formState.values.what3words) {
      form.change("what3words", null);
    }

    return formState.values;
  }, [pinPointLocation, form, deliveryAddress?.udprn]);

  const [pointLocation, setPointLocation] = useState(pointLocationInit);
  const onPinPointChange = useCallback(
    (selectedPointLocation) => {
      const formState = form.getState();

      if (!formState.submitting) {
        form.change("lngLat", selectedPointLocation.lngLat);
        form.change("what3words", selectedPointLocation.what3words);

        setPointLocation(selectedPointLocation);
      }
    },
    [form, setPointLocation]
  );

  const onSelectChooseYourLocation = useCallback(() => {
    const formState = form.getState();

    if (!formState.submitting) {
      form.change("what3words", null);
    }
  }, [form]);

  return (
    <>
      {!addressPoint && (
        <Alert
          variant="warning"
          title={M.PINPOINT_LOCATION_UNAVAILABLE}
          message={M.PINPOINT_LOCATION_UNAVAILABLE_MESSAGE_DETAILS}
          className="m-0"
        />
      )}

      {addressPoint && (
        <Tabs
          id="controlled-tab-example"
          defaultActiveKey="choose-your-location"
          className={classNames("border-bottom-0", styles.pinPointLocation)}
          name="pinPointLocation1"
        >
          <Tab
            eventKey="choose-your-location"
            title="Choose your location"
            mountOnEnter={true}
            unmountOnExit={true}
            onEntered={onSelectChooseYourLocation}
            onEnter={() => {
              onTabChange("choose-your-location");
            }}
          >
            <ChooseYourLocation
              pointLocation={pointLocation}
              pinPointLocation={pinPointLocation}
              onChange={onPinPointChange}
              error={lngLatField.meta.error}
              analytics={analytics["choose-your-location"]}
            />
          </Tab>
          <Tab
            eventKey="use-what3words"
            title="Use what3words"
            mountOnEnter={true}
            unmountOnExit={true}
            onEnter={() => {
              onTabChange("use-what3words");
            }}
          >
            <What3WordsLocation
              onChange={onPinPointChange}
              pointLocation={pointLocation}
              pinPointLocation={pinPointLocation}
              error={lngLatField.meta.error}
              analytics={analytics["use-what3words"]}
            />
          </Tab>
        </Tabs>
      )}
    </>
  );
};

PinPointLocation.propTypes = {
  deliveryAddress: PropTypes.object,
  onTabChange: PropTypes.func,
  analytics: PropTypes.object,
};

PinPointLocation.defaultProps = {
  onTabChange: noop,
  analytics: {},
};

export default PinPointLocation;
