import { useCallback } from "react";
import { pick } from "lodash";
import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { OnFocus } from "react-final-form-listeners";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Trackable, useTracker } from "@dpdgroupuk/react-event-tracker";

import Input from "../../components/atoms/Input";
import EditProfileForm from "../../components/organisms/EditProfileForm";
import ProfilePage from "../../components/templates/ProfilePage";
import { EDIT_ADDRESS_NOTE } from "../../constants/analytics";
import { Fields } from "../../constants/forms";
import * as M from "../../constants/strings";
import { useAuth } from "../../features/Auth";
import { useOverlay } from "../../features/Overlay";
import {
  ConsumerAddressesActions,
  ConsumerAddressesSelector,
} from "../../features/Profile";
import { useToaster } from "../../features/Toaster";
import { Validators } from "../../utils";

const AddNote = () => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const overlay = useOverlay();
  const toaster = useToaster();
  const { udprn } = useParams();
  const tracker = useTracker();

  const address = useSelector(
    ConsumerAddressesSelector.getConsumerAddressById(udprn)
  );

  const onClickBack = useCallback(() => {
    tracker.logEvent(EDIT_ADDRESS_NOTE.ON_CLICK_BACK);
  }, [tracker]);

  const onFocusNoteTextarea = useCallback(() => {
    tracker.logEvent(EDIT_ADDRESS_NOTE.ON_CLICK_TEXT_FIELD);
  }, [tracker]);

  const handleSaveAddNote = useCallback(
    async (values) => {
      try {
        overlay.show();
        tracker.logEvent(EDIT_ADDRESS_NOTE.ON_CLICK_SAVE);

        await dispatch(
          ConsumerAddressesActions.updateAddress([
            auth.currentSession.uid,
            { udprn, [Fields.NOTE]: values[Fields.NOTE] || "" },
          ])
        ).unwrap();

        toaster.showSuccess({
          body: M.CHANGES_SAVED,
          autoHide: true,
        });
      } catch (e) {
        toaster.showError({
          body: e.message,
          reload: e.reload,
        });
      } finally {
        overlay.hide();
      }
    },
    [auth, dispatch, overlay, toaster, udprn, tracker]
  );

  return (
    <Trackable
      loadId={EDIT_ADDRESS_NOTE.LOAD}
      interfaceId={EDIT_ADDRESS_NOTE.INTERFACE_ID}
    >
      <ProfilePage
        title={M.ADD_NOTE}
        subtitle={M.ADD_NOTE_MESSAGE}
        showBackButtonDesktop
        onClickBack={onClickBack}
      >
        <EditProfileForm
          initialValues={address[Fields.NOTE] && pick(address, [Fields.NOTE])}
          onSubmit={handleSaveAddNote}
        >
          <Row className="g-0 p-4">
            <Col>
              <Field
                label={M.ADD_NOTE}
                name={Fields.NOTE}
                component={Input}
                placeholder={M.ENTER_NOTE}
                as="textarea"
                maxLength={255}
                validate={Validators.composeValidators(
                  Validators.maxLength(255),
                  Validators.notEmpty
                )}
              />
              <OnFocus name={Fields.NOTE}>{onFocusNoteTextarea}</OnFocus>
            </Col>
          </Row>
        </EditProfileForm>
      </ProfilePage>
    </Trackable>
  );
};

export default AddNote;
