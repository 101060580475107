import classNames from "classnames";
import PropTypes from "prop-types";
import { Button, Col, Row } from "react-bootstrap";

import {
  LocationPropType,
  PickupPointPropType,
  PickupPointsPropType,
} from "../../../constants/propType";
import * as M from "../../../constants/strings";
import LinkButton from "../../atoms/LinkButton";
import PickupPointsList from "../../molecules/PickupPointsList";
import styles from "./PickupMobileVertical.module.scss";

const PickupMobileVertical = ({
  currentLocation,
  selectedPickupPoint,
  pickupPoints,
  onSelectClick,
  onPickupDetailsClick,
  onSetupLater,
  onClickNext,
  pickupListRef,
}) => (
  <Row className={classNames("g-0", styles.container)}>
    <Col>
      <Row className="g-0">
        <Col className="position-relative">
          <PickupPointsList
            currentLocation={currentLocation}
            selectedPickupPoint={selectedPickupPoint}
            pickupPoints={pickupPoints}
            onSelectClick={onSelectClick}
            onPickupDetailsClick={onPickupDetailsClick}
            className={classNames(
              styles.list,
              !onClickNext && styles.listWithNextHidden
            )}
            pickupListRef={pickupListRef}
          />
        </Col>
      </Row>
      {onClickNext && (
        <Row className={classNames("g-0 p-4 pb-1", styles.footer)}>
          <Col xs={12}>
            <Button
              variant="danger"
              className="fs-5 w-100 h-100"
              size="md"
              onClick={onClickNext}
            >
              {M.NEXT}
            </Button>
          </Col>
          <Col xs={12}>
            <LinkButton onClick={onSetupLater} className="w-100 h-100">
              {M.SETUP_LATER}
            </LinkButton>
          </Col>
        </Row>
      )}
    </Col>
  </Row>
);

PickupMobileVertical.propTypes = {
  currentLocation: LocationPropType.isRequired,
  selectedPickupPoint: PickupPointPropType,
  pickupPoints: PickupPointsPropType.isRequired,
  onSelectClick: PropTypes.func,
  onPickupDetailsClick: PropTypes.func,
  onSetupLater: PropTypes.func,
  onClickNext: PropTypes.func,
  pickupListRef: PropTypes.any,
};

export default PickupMobileVertical;
