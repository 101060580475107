const Work = (props) => (
  <svg
    width="2rem"
    height="2rem"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_382_29401)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.10604 10.8162H6.04344V6.73089H9.10604V10.8162ZM7.06465 9.79502H8.08483V7.75209H7.06465V9.79502Z"
        fill="#414042"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.10604 15.9223H6.04344V11.8369H9.10604V15.9223ZM7.06465 14.9011H8.08483V12.8581H7.06465V14.9011Z"
        fill="#414042"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1485 15.9223H14.2111V11.8369H11.1485V15.9223ZM13.1899 14.9011H12.1697V12.8581H13.1899V14.9011Z"
        fill="#414042"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2111 10.8162H11.1485V6.73089H14.2111V10.8162ZM12.1697 9.79502H13.1899V7.75209H12.1697V9.79502Z"
        fill="#414042"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2555 15.9223H19.3181V11.8369H16.2555V15.9223ZM18.2969 14.9011H17.2767V12.8581H18.2969V14.9011Z"
        fill="#414042"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.3181 10.8162H16.2555V6.73089H19.3181V10.8162ZM17.2767 9.79502H18.2969V7.75209H17.2767V9.79502Z"
        fill="#414042"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 2.66699V29.2194L21.3606 29.1888V2.66699H4ZM5.02121 28.1962V3.6882H20.3393V28.1696L5.02121 28.1962Z"
        fill="#414042"
      />
      <path
        d="M22.8921 27.1756H27.4875V4.70898H22.8921C22.6102 4.70898 22.3815 4.93722 22.3815 5.21959C22.3815 5.50195 22.6102 5.73019 22.8921 5.73019H26.4663V26.1544H22.8921C22.6102 26.1544 22.3815 26.3826 22.3815 26.665C22.3815 26.9473 22.6102 27.1756 22.8921 27.1756Z"
        fill="currentColor"
      />
      <path
        d="M14.2118 26.665C14.2118 26.9473 14.4405 27.1756 14.7224 27.1756C15.0042 27.1756 15.233 26.9473 15.233 26.665V20.0271H10.127V26.665C10.127 26.9473 10.3557 27.1756 10.6376 27.1756C10.9194 27.1756 11.1482 26.9473 11.1482 26.665V21.0483H14.2118V26.665Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.4451 11.8574H22.3815V7.77261H25.4451V11.8574ZM23.4027 10.8362H24.4239V8.79382H23.4027V10.8362Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.3815 16.9635H25.4451V12.8787H22.3815V16.9635ZM24.4239 15.9423H23.4027V13.8999H24.4239V15.9423Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_382_29401">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Work;
