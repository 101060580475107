import PropTypes from "prop-types";
import { map } from "lodash";
import { Col, Row } from "react-bootstrap";
import classNames from "classnames";

import styles from "./OpenWindowList.module.scss";

const OpenWindowList = ({ openWindows, className }) => (
  <Row className={className}>
    <Col>
      {map(openWindows, ({ day, openWindow }) => (
        <Row key={day} className="g-0">
          <Col>
            <span className={styles.text}>{day}</span>
          </Col>
          <Col xs="auto">
            <span className={classNames(styles.text, styles.openWindowText)}>
              {openWindow}
            </span>
          </Col>
        </Row>
      ))}
    </Col>
  </Row>
);

OpenWindowList.propTypes = {
  openWindows: PropTypes.array,
};

export default OpenWindowList;
