import { Card } from "react-bootstrap";
import classNames from "classnames";
import PropTypes from "prop-types";
import styles from "./ShadowFieldCard.module.scss";

const ShadowFieldCard = ({ children, variant, className }) => {
  return (
    <Card
      bg={variant}
      className={classNames(
        "d-flex flex-row justify-content-between",
        "align-items-center",
        styles.card,
        className
      )}
    >
      {children}
    </Card>
  );
};

ShadowFieldCard.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default ShadowFieldCard;
