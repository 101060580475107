import PropTypes from "prop-types";

import ShopWindowSticker from "../icons/ShopWindowSticker";
import LockerWindowSticker from "../icons/LockerWindowSticker";
import { PICKUP_POINT_TYPE } from "../../../constants/pickupPoint";

import styles from "./PickupWindowSticker.module.scss";

const PickupWindowSticker = ({ type }) =>
  type === PICKUP_POINT_TYPE.LOCKER ? (
    <LockerWindowSticker className={styles.container} />
  ) : (
    <ShopWindowSticker className={styles.container} />
  );

PickupWindowSticker.propTypes = {
  type: PropTypes.string,
};

export default PickupWindowSticker;
