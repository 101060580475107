import { useCallback, useEffect, useMemo } from "react";
import classNames from "classnames";
import { isEmpty, noop } from "lodash";
import PropTypes from "prop-types";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useBreakpoint } from "use-breakpoint";
import { Trackable, useTracker } from "@dpdgroupuk/react-event-tracker";

import * as locationsApis from "../../../apis/locationsApis";
import Alert from "../../../components/atoms/Alert";
import ContentHeader from "../../../components/molecules/ContentHeader";
import { Loader } from "../../../components/molecules/Loader";
import EditPickupPointFormField from "../../../components/organisms/EditPickupPointFormField";
import * as M from "../../../constants/strings";
import { BREAKPOINTS } from "../../../constants/ui";
import { WizardForm } from "../../../features/Wizard";
import { getAddressPoint } from "../../../models/address";
import styles from "../AddNewAddress.module.scss";
import { getActiveDeliveryAddress } from "../selectors";
import Template from "../Template";
import { ADD_ADDRESS_PICKUP_POINT } from "../../../constants/analytics";

const PickupContainer = ({
  children,
  isPickupLocationsAvailable,
  onClickSkip,
  onClickBack,
}) => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);

  return breakpoint !== "mobile" || !isPickupLocationsAvailable ? (
    <Template
      className="pt-0 p-md-0"
      staticContentHeader={
        <ContentHeader
          title={M.PICKUP_POINT}
          subtitle={M.PICKUP_POINT_MESSAGE}
          classes={{
            title: "h6",
            subtitle: "mb-0",
            container: "g-0 p-4",
          }}
        />
      }
      onClickSkip={onClickSkip}
      onClickBack={onClickBack}
    >
      {children}
    </Template>
  ) : (
    children
  );
};

const PickupPointStep = ({
  values,
  references,
  nextStep,
  previousStep,
  setReferences,
  setValues,
}) => {
  const deliveryAddress = useSelector(getActiveDeliveryAddress);
  const isAddressChanged = deliveryAddress?.udprn !== values.udprn;
  const addressPoint = useMemo(
    () => getAddressPoint(deliveryAddress),
    [deliveryAddress]
  );
  const tracker = useTracker();

  const isPickupLocationsAvailable =
    addressPoint && !isEmpty(references.pickupLocations);

  const initialValues = useMemo(
    () => ({
      pickupLocationCode: null,
      ...values,
    }),
    []
  );

  const loadPickupPoints = useCallback(async ({ location }, { signal }) => {
    if (isAddressChanged && addressPoint) {
      const { data } = await locationsApis.getPickupLocations(location, {
        signal,
      });

      setReferences("pickupLocations", data);
      return data;
    }
  }, []);

  const onClickSkip = useCallback(() => {
    tracker.logEvent(ADD_ADDRESS_PICKUP_POINT.ON_SETUP_LATER);
    setValues({ pickupLocationCode: null });
    nextStep();
  }, [nextStep, setValues, tracker]);

  const handleNext = useCallback(() => {
    tracker.logEvent(ADD_ADDRESS_PICKUP_POINT.ON_NEXT);
    nextStep();
  }, [nextStep, tracker]);

  const handleBack = useCallback(() => {
    tracker.logEvent(ADD_ADDRESS_PICKUP_POINT.ON_BACK);
    previousStep();
  }, [tracker, previousStep]);

  useEffect(() => {
    // set new udprn and clear selected pickup if address changed
    if (isAddressChanged) {
      setValues({
        udprn: deliveryAddress?.udprn,
        pickupLocationCode: null,
      });
    }
  }, [isAddressChanged, deliveryAddress, setValues, setReferences]);

  const handlePickupPointSelect = useCallback(
    (pickupPoint) => {
      tracker.logEvent(ADD_ADDRESS_PICKUP_POINT.ON_SELECT);
      setValues({ ...values, pickupLocationCode: pickupPoint });
    },
    [tracker, setValues, values]
  );

  return (
    <Trackable
      interfaceId={ADD_ADDRESS_PICKUP_POINT.INTERFACE_ID}
      loadId={ADD_ADDRESS_PICKUP_POINT.LOAD}
    >
      <Container className={classNames("g-0", styles.container)} fluid>
        <Loader promiseFn={loadPickupPoints} location={addressPoint}>
          <WizardForm
            initialValues={initialValues}
            onSubmit={handleNext}
            formClassName={styles.stepContent}
          >
            <PickupContainer
              isPickupLocationsAvailable={isPickupLocationsAvailable}
              onClickSkip={onClickSkip}
              onClickBack={handleBack}
            >
              {isPickupLocationsAvailable ? (
                <EditPickupPointFormField
                  currentLocation={addressPoint}
                  pickupLocations={references.pickupLocations}
                  onClickBack={handleBack}
                  onClickNext={handleNext}
                  onDirectionsClick={() => {
                    tracker.logEvent(
                      ADD_ADDRESS_PICKUP_POINT.ON_CLICK_DIRECTIONS
                    );
                  }}
                  onPickupDetailsClick={() => {
                    tracker.logEvent(ADD_ADDRESS_PICKUP_POINT.ON_CLICK_DETAILS);
                  }}
                  onShopsFilterClick={() => {
                    tracker.logEvent(ADD_ADDRESS_PICKUP_POINT.ON_CLICK_SHOPS);
                  }}
                  onLockersFilterClick={() => {
                    tracker.logEvent(ADD_ADDRESS_PICKUP_POINT.ON_CLICK_LOCKERS);
                  }}
                  onMapPinClick={() => {
                    tracker.logEvent(ADD_ADDRESS_PICKUP_POINT.ON_MAP_PIN_CLICK);
                  }}
                  onSelectPickupPoint={handlePickupPointSelect}
                  onClickSetupLater={onClickSkip}
                />
              ) : (
                <Alert
                  variant="warning"
                  title={M.NO_AVAILABLE_PICKUP_POINTS}
                  message={
                    <span>
                      <span>{M.NO_AVAILABLE_PICKUP_DPD_POINTS}</span>
                      <br />
                      <span>{M.WE_ALWAYS_ADDING_PICKUP_LOCATIONS}</span>
                    </span>
                  }
                  className="m-0 m-md-4 mt-md-0"
                />
              )}
            </PickupContainer>
          </WizardForm>
        </Loader>
      </Container>
    </Trackable>
  );
};

PickupContainer.propTypes = {
  children: PropTypes.node,
  isPickupLocationsAvailable: PropTypes.bool,
  onClickSkip: PropTypes.func,
  onClickBack: PropTypes.func,
};

PickupContainer.defaultProps = {
  onClickBack: noop,
};

PickupPointStep.propTypes = {
  values: PropTypes.object,
  references: PropTypes.object,
  nextStep: PropTypes.func,
  previousStep: PropTypes.func,
  setReferences: PropTypes.func,
  setValues: PropTypes.func,
};

export default PickupPointStep;
