import classNames from "classnames";
import PropTypes from "prop-types";

import styles from "./PickupPin.module.scss";

const PickupPin = ({ label, className, ...props }) => (
  <svg
    width="1.5625rem" // 25px
    height="1.875rem" // 30px
    viewBox="0 0 25 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className={classNames(className, styles.container)}
  >
    <path
      d="M0 6C0 2.68629 2.68629 0 6 0H19C22.3137 0 25 2.68629 25 6V19C25 22.3137 22.3137 25 19 25H18.75H15.625L12.5 29.5L9.375 25H6.25H6C2.68629 25 0 22.3137 0 19V6Z"
      fill="currentColor"
    />
    <text x="12" y="18" textAnchor="middle" className={styles.textStyle}>
      {label}
    </text>
  </svg>
);

PickupPin.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
};

export default PickupPin;
