import { useCallback } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Field, useForm } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import classNames from "classnames";
import PropTypes from "prop-types";
import { get, noop, trim } from "lodash";

import Checkmark from "../../atoms/icons/Checkmark";
import Home from "../../atoms/icons/Home";
import Work from "../../atoms/icons/Work";
import Input from "../../atoms/Input";
import SafePlaceCard from "../../molecules/SafePlaceCard/SafePlaceCard";
import { Fields } from "../../../constants/forms";
import { ADDRESS_TYPE_NAME } from "../../../constants";
import {
  ADD_LABEL,
  CUSTOM_ADDRESS_LABEL,
  SELECTED,
} from "../../../constants/strings";
import { Validators } from "../../../utils";

import styles from "./AddressLabel.module.scss";

const AddressLabel = ({
  className,
  options,
  onSelectType,
  onCustomTypeChange,
}) => {
  const form = useForm();

  const onCustomAddressLabelChange = useCallback(
    (value, prev) => {
      if (!prev && value) {
        onCustomTypeChange();
        form.change(Fields.TYPE, null);
      }
    },
    [form, onCustomTypeChange]
  );

  const formatCustomAddressLabelOnBlur = useCallback(
    (value) => {
      const trimmedValue = trim(value);
      const type = trimmedValue.toLowerCase();

      if (type === ADDRESS_TYPE_NAME.HOME.toLowerCase()) {
        form.change(Fields.TYPE, ADDRESS_TYPE_NAME.HOME);
        return "";
      }

      if (type === ADDRESS_TYPE_NAME.WORK.toLowerCase()) {
        form.change(Fields.TYPE, ADDRESS_TYPE_NAME.WORK);
        return "";
      }

      return trimmedValue;
    },
    [form]
  );

  return (
    <Container fluid className={classNames("g-0", className)}>
      <Row className="g-0">
        <Col>
          <Field name={Fields.TYPE}>
            {({ input, meta: { error, touched } }) => (
              <Row className="g-0 g-md-3 mb-md-4">
                {options.map((option, index) => {
                  const selected = input?.value === option.value;

                  return (
                    <Col
                      key={index}
                      className="p-0 px-md-2 mb-3 mb-md-0"
                      xs={12}
                      md={6}
                    >
                      <SafePlaceCard
                        label={option.label}
                        icon={option.icon}
                        selected={selected}
                        className={classNames(
                          "w-100",
                          styles.item,
                          error && touched && "border border-danger"
                        )}
                        onSelect={(selected) => {
                          input.onChange(option.value);
                          form.change(Fields.CUSTOM_ADDRESS_TYPE, "");
                          onSelectType(option.value);
                        }}
                        selectable={!selected}
                        showCheckMark={false}
                        footer={
                          selected && (
                            <Row className="d-flex align-items-center justify-content-end">
                              <Col className="text-success p-0 me-1" xs="auto">
                                <span>{SELECTED}</span>
                              </Col>
                              <Col className="p-0 ms-2" xs="auto">
                                <Checkmark />
                              </Col>
                            </Row>
                          )
                        }
                        inline
                      />
                    </Col>
                  );
                })}
              </Row>
            )}
          </Field>
        </Col>
      </Row>

      <Row className="g-0">
        <Col md={6}>
          <Field
            name={Fields.CUSTOM_ADDRESS_TYPE}
            component={Input}
            label={CUSTOM_ADDRESS_LABEL}
            placeholder={ADD_LABEL}
            maxLength={35}
            validate={(value, allValues) =>
              Validators.customType(value, get(allValues, Fields.TYPE))
            }
            formatOnBlur
            format={formatCustomAddressLabelOnBlur}
          />
          <OnChange name={Fields.CUSTOM_ADDRESS_TYPE}>
            {onCustomAddressLabelChange}
          </OnChange>
        </Col>
      </Row>
    </Container>
  );
};

AddressLabel.defaultProps = {
  options: [
    {
      label: ADDRESS_TYPE_NAME.HOME,
      icon: Home,
      value: ADDRESS_TYPE_NAME.HOME,
    },
    {
      label: ADDRESS_TYPE_NAME.WORK,
      icon: Work,
      value: ADDRESS_TYPE_NAME.WORK,
    },
  ],
  onSelectType: noop,
  onCustomTypeChange: noop,
};

AddressLabel.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      icon: PropTypes.elementType,
      value: PropTypes.string,
    })
  ),
  onSelectType: PropTypes.func,
  onCustomTypeChange: PropTypes.func,
};

export default AddressLabel;
