const FrontPorch = (props) => {
  return (
    <svg
      width="4rem" // 64px
      height="4rem"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.3535 17.9478C28.3535 17.4785 28.734 17.0981 29.2032 17.0981H35.7391C36.2084 17.0981 36.5888 17.4785 36.5888 17.9478V24.4837C36.5888 24.9529 36.2084 25.3334 35.7391 25.3334H29.2032C28.734 25.3334 28.3535 24.9529 28.3535 24.4837V17.9478ZM34.8895 18.7974H30.0529V23.634H34.8895V18.7974Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2801 9.37435C15.2801 7.15841 17.1163 5.3333 19.3977 5.3333H45.5415C47.823 5.3333 49.6592 7.15841 49.6592 9.37435V44.2L50.185 44.3835C51.7891 44.9436 52.9272 46.4462 52.9272 48.1918V50.6338L53.453 50.8174C55.0571 51.3774 56.1951 52.88 56.1951 54.6256V57.8425C56.1951 58.286 55.8264 58.6667 55.3455 58.6667H9.59381C9.11289 58.6667 8.74414 58.286 8.74414 57.8425V54.6256C8.74414 52.88 9.88224 51.3774 11.4863 50.8174L12.0121 50.6338V48.1918C12.0121 46.4462 13.1502 44.9436 14.7543 44.3835L15.2801 44.2V9.37435ZM19.3977 6.98158C18.0753 6.98158 16.9794 8.04269 16.9794 9.37435V44.1507H21.816V12.5913C21.816 12.1477 22.1848 11.7671 22.6657 11.7671H42.2736C42.7545 11.7671 43.1232 12.1477 43.1232 12.5913V44.1507H47.9598V9.37435C47.9598 8.04269 46.864 6.98158 45.5415 6.98158H19.3977ZM41.4239 13.4154H23.5154V44.1507H41.4239V32.7169H39.0056C38.5247 32.7169 38.1559 32.3363 38.1559 31.8927C38.1559 31.4492 38.5247 31.0686 39.0056 31.0686H41.4239V13.4154ZM16.1298 45.799C14.8073 45.799 13.7115 46.8601 13.7115 48.1918V50.5845H25.4052L27.8359 45.799H16.1298ZM35.2091 45.799H29.7302L24.0314 57.0184H40.9079L35.2091 45.799ZM48.8095 45.799H37.1033L39.5341 50.5845H51.2278V48.1918C51.2278 46.8601 50.132 45.799 48.8095 45.799ZM12.8618 52.2328C11.5393 52.2328 10.4435 53.2939 10.4435 54.6256V57.0184H22.1372L24.568 52.2328H12.8618ZM52.0775 52.2328H40.3713L42.8021 57.0184H54.4958V54.6256C54.4958 53.2939 53.4 52.2328 52.0775 52.2328Z"
        fill="#414042"
      />
    </svg>
  );
};

export default FrontPorch;
