import classNames from "classnames";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";

import Check from "../icons/Check";
import styles from "./FilterButton.module.scss";

const FilterButton = ({ type, onClick, selected, children }) => (
  <Button
    variant={selected ? "primary" : "outline-dark"}
    size="sm"
    onClick={() => onClick(type)}
    className={classNames("d-flex", !selected && styles.filterButton)}
  >
    {selected && <Check />}
    {children}
  </Button>
);

FilterButton.propTypes = {
  type: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  children: PropTypes.node,
};

export default FilterButton;
