import classNames from "classnames";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";

import Arrow from "../icons/Arrow";
import styles from "./BackButton.module.scss";

const BackButton = ({ onClick, children, classes = {} }) => (
  <Button
    variant="link"
    className={classNames(
      "d-flex text-decoration-none",
      styles.backButton,
      classes.button
    )}
    onClick={onClick}
  >
    <Arrow className={classNames(styles.icon, classes.icon)} />
    <span className={classNames(styles.backButtonText, classes.text)}>
      {children}
    </span>
  </Button>
);

BackButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
  classes: PropTypes.object,
};

export default BackButton;
