import PropTypes from "prop-types";

function Step({ component: Component, ...props }) {
  return <Component {...props} />;
}

Step.propTypes = {
  component: PropTypes.elementType,
};

export default Step;
