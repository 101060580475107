import React from "react";
import PropTypes from "prop-types";
import { Container, Stack, Button } from "react-bootstrap";
import classNames from "classnames";
import FacePensive from "../icons/FacePensive";
import styles from "./Error.module.scss";
import {
  SORRY_CANT_LOAD_PAGE,
  TRY_AGAIN,
  UNABLE_TO_LOAD_THIS_PAGE,
} from "../../../constants/strings";

function Error({ icon: Icon, title, message, onButtonClick, buttonText }) {
  return (
    <Container className={classNames("h-100 d-flex", styles.container)}>
      <Stack gap={2} className="my-auto d-flex text-center">
        <Icon className="m-auto" />
        <h4>{title}</h4>
        <p>{message}</p>
        {onButtonClick && (
          <Button
            onClick={onButtonClick}
            variant="outline-danger"
            className="w-auto m-auto"
          >
            {buttonText}
          </Button>
        )}
      </Stack>
    </Container>
  );
}

Error.defaultProps = {
  icon: FacePensive,
  title: SORRY_CANT_LOAD_PAGE,
  message: UNABLE_TO_LOAD_THIS_PAGE,
  buttonText: TRY_AGAIN,
};

Error.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  onButtonClick: PropTypes.func,
  buttonText: PropTypes.string,
};

export default Error;
