import React from "react";
import PropTypes from "prop-types";
import Spinner from "react-bootstrap/Spinner";

import useBackdropController from "./useBackdropController";
import Backdrop from "../../components/atoms/Backdrop";
import OverlayContext from "./Context";

const Provider = ({ children, position }) => {
  const [open, show, hide, showWhile] = useBackdropController();
  const contextValue = React.useMemo(
    () => ({
      show,
      hide,
      showWhile,
    }),
    [show, hide, showWhile]
  );

  return (
    <OverlayContext.Provider value={contextValue}>
      {children}
      <Backdrop open={open} position={position}>
        <Spinner animation="border" variant="danger" className="m-auto" />
      </Backdrop>
    </OverlayContext.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.node,
  position: Backdrop.propTypes.position,
  spinner: PropTypes.func,
};

export default Provider;
