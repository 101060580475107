import { useCallback, useRef, useState } from "react";

import classNames from "classnames";
import { indexOf, noop } from "lodash";
import PropTypes from "prop-types";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";

import {
  LocationPropType,
  PickupPointPropType,
  PickupPointsPropType,
} from "../../../constants/propType";
import * as M from "../../../constants/strings";
import { getGoogleMapsDirectionLink } from "../../../utils/googleMaps";
import {
  getPickupAddressPoint,
  getPickupLocationCode,
  sortSelectedPickupToTop,
  getPartnerLogo,
} from "../../../utils/pickupPoint";
import BackButton from "../../atoms/BackButton";
import LinkButton from "../../atoms/LinkButton";
import PickupDetailsMobile from "../../molecules/PickupDetailsMobile";
import PickupFilterButtons from "../../molecules/PickupFilterButtons";
import PickupLocationsMap from "../../molecules/PickupLocationsMap";
import PickupMobileHorizontal from "../PickupMobileHorizontal";
import PickupMobileVertical from "../PickupMobileVertical";
import {
  HEADER_HEIGHT,
  HORIZONTAL_LIST_HEIGHT,
  HORIZONTAL_LIST_MAP_PADDING_BOTTOM,
  MAP_HEIGHT,
  MAP_PADDING,
} from "./constants";
import "swiper/scss";
import styles from "./SetPickupPointMobile.module.scss";

const SetPickupPoint = ({
  currentLocation,
  selectedPickupPoint,
  pickupPoints,
  onSelectClick,
  onPickupDetailsClick,
  onSetupLater,
  onClickBack,
  filterBy,
  isFiltersVisible,
  onFilterButtonClick,
  onClickNext,
  pickupListRef,
  onDirectionsClick,
  onMapPinClick,
}) => {
  const swiperRef = useRef();
  const detailsSwiperRef = useRef();
  const windowHeight = window.innerHeight;
  const verticalListHeight = windowHeight - (MAP_HEIGHT + HEADER_HEIGHT);

  const [showDetails, setShowDetails] = useState(false);
  const [activePickupPoint, setActivePickupPoint] = useState(null);
  const [showHorizontalList, setShowHorizontalList] = useState(false);

  const pickupLocationCodes = pickupPoints.map((pickupPoint) =>
    getPickupLocationCode(pickupPoint)
  );

  const getPickupIndex = useCallback(
    (pickupPoint) =>
      indexOf(pickupLocationCodes, getPickupLocationCode(pickupPoint)),
    [pickupLocationCodes]
  );

  const activePickupIndex = getPickupIndex(activePickupPoint);

  const onDetailsClick = useCallback(
    (pickupPoint) => {
      onPickupDetailsClick(pickupPoint);
      setActivePickupPoint(pickupPoint);
      setShowDetails(true);
      setShowHorizontalList(true);
      swiperRef.current?.swiper?.slideTo(getPickupIndex(pickupPoint));
    },
    [onPickupDetailsClick, setActivePickupPoint, getPickupIndex]
  );

  const handleMapPinClick = useCallback(
    (pickupPoint) => {
      onDetailsClick(pickupPoint);
      onMapPinClick();
    },
    [onDetailsClick, onMapPinClick]
  );

  const handleFilterButtonClick = useCallback(
    (type) => {
      onFilterButtonClick(type);
      detailsSwiperRef.current?.swiper?.slidePrev(); // hide details on filter buttons click
    },
    [onFilterButtonClick]
  );

  const onPickupSelectClick = useCallback(
    (pickupPoint) => {
      onSelectClick(pickupPoint);
      swiperRef.current?.swiper?.slideTo(getPickupIndex(pickupPoint), 2000);
    },
    [onSelectClick, getPickupIndex]
  );

  const onListViewClick = useCallback(() => {
    setShowHorizontalList(false);
    setActivePickupPoint(null);
  }, []);

  const onDetailsSlideSwipe = useCallback(() => {
    swiperRef.current?.swiper?.slideTo(activePickupIndex || 0, 0);
    setShowDetails(false);
  }, [activePickupIndex]);

  const horizontalListHeight = onClickNext
    ? HORIZONTAL_LIST_HEIGHT
    : HORIZONTAL_LIST_HEIGHT - 90;

  return (
    <Container className={classNames("g-0", styles.container)}>
      {showDetails && (
        <Row>
          <Col>
            <Swiper
              direction="vertical"
              initialSlide={1}
              className={classNames(
                "w-100 position-absolute bottom-0 zindex-modal",
                styles.detailsSlider
              )}
              allowSlideNext={false}
              onSlidePrevTransitionEnd={onDetailsSlideSwipe}
              autoHeight
              spaceBetween={0}
              slidesPerView={1}
              observeParents
              observeSlideChildren
              ref={detailsSwiperRef}
              observer
            >
              <SwiperSlide></SwiperSlide>
              <SwiperSlide className={styles.detailsSlide}>
                <div className={styles.detailsShadow} />
                <Row>
                  <Col className="d-flex justify-content-center mb-3">
                    <div className={styles.dragIcon} />
                  </Col>
                </Row>
                <PickupDetailsMobile
                  pickupPoint={activePickupPoint}
                  isSelected={
                    getPickupLocationCode(activePickupPoint) ===
                    getPickupLocationCode(selectedPickupPoint)
                  }
                  pickupListNumber={activePickupIndex + 1}
                  directionLink={getGoogleMapsDirectionLink(
                    currentLocation,
                    getPickupAddressPoint(activePickupPoint)
                  )}
                  onSelectClick={() => onPickupSelectClick(activePickupPoint)}
                  partnerLogo={getPartnerLogo(activePickupPoint)}
                  onDirectionsClick={onDirectionsClick}
                />
                {onClickNext && (
                  <Row className={classNames("g-0 px-3 pb-1", styles.footer)}>
                    <Col xs={12}>
                      <Button
                        variant="danger"
                        className="fs-5 w-100 h-100"
                        size="md"
                        onClick={onClickNext}
                      >
                        {M.NEXT}
                      </Button>
                    </Col>
                    <Col xs={12}>
                      <LinkButton
                        onClick={onSetupLater}
                        className="w-100 h-100"
                      >
                        {M.SETUP_LATER}
                      </LinkButton>
                    </Col>
                  </Row>
                )}
              </SwiperSlide>
            </Swiper>
          </Col>
        </Row>
      )}

      <Row className="g-0 p-3 fixed-top">
        <Col>
          <BackButton onClick={onClickBack}>{M.BACK}</BackButton>
        </Col>
        <Col className="d-flex justify-content-end align-items-center">
          {isFiltersVisible && (
            <PickupFilterButtons
              filterBy={filterBy}
              onFilterButtonClick={handleFilterButtonClick}
            />
          )}
        </Col>
      </Row>
      <Row className={classNames("g-0 h-100 w-100", styles.mapContainer)}>
        <Col>
          <div className={styles.mapShadow} />
          <PickupLocationsMap
            selectedPickupPoint={selectedPickupPoint}
            activePickupPoint={activePickupPoint}
            currentLocation={currentLocation}
            pickupLocations={pickupPoints}
            onPickupPinClick={handleMapPinClick}
            mapPadding={{
              top: 50,
              bottom: showHorizontalList
                ? horizontalListHeight + HORIZONTAL_LIST_MAP_PADDING_BOTTOM
                : verticalListHeight + MAP_PADDING,
              left: MAP_PADDING,
              right: MAP_PADDING,
            }}
            showHorizontalList={showHorizontalList}
          />
        </Col>
      </Row>
      <Row className="g-0 fixed-bottom w-100">
        <Col>
          {showHorizontalList ? (
            <PickupMobileHorizontal
              selectedPickupPoint={selectedPickupPoint}
              pickupPoints={pickupPoints}
              onSelectClick={onPickupSelectClick}
              onPickupDetailsClick={onDetailsClick}
              onSetupLater={onSetupLater}
              onClickNext={onClickNext}
              onListViewClick={onListViewClick}
              setActivePickupPoint={setActivePickupPoint}
              initialSlide={activePickupIndex}
              swiperRef={swiperRef}
            />
          ) : (
            <PickupMobileVertical
              currentLocation={currentLocation}
              selectedPickupPoint={selectedPickupPoint}
              pickupPoints={sortSelectedPickupToTop(
                pickupPoints,
                selectedPickupPoint
              )}
              onSelectClick={onPickupSelectClick}
              onPickupDetailsClick={onDetailsClick}
              onSetupLater={onSetupLater}
              onClickNext={onClickNext}
              pickupListRef={pickupListRef}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

SetPickupPoint.propTypes = {
  currentLocation: LocationPropType.isRequired,
  selectedPickupPoint: PickupPointPropType,
  pickupPoints: PickupPointsPropType.isRequired,
  onSelectClick: PropTypes.func,
  onPickupDetailsClick: PropTypes.func,
  onSetupLater: PropTypes.func,
  onClickBack: PropTypes.func,
  filterBy: PropTypes.string,
  isFiltersVisible: PropTypes.bool,
  onFilterButtonClick: PropTypes.func,
  onClickNext: PropTypes.func,
  pickupListRef: PropTypes.any,
  onDirectionsClick: PropTypes.func,
  onMapPinClick: PropTypes.func,
};

SetPickupPoint.defaultProps = {
  onDirectionsClick: noop,
  onMapPinClick: noop,
};

export default SetPickupPoint;
