import classNames from "classnames";
import { Col, Container, Navbar, Row } from "react-bootstrap";
import DPDLogo from "../../atoms/icons/DPDLogo";
import styles from "./Onboarding.module.scss";

const Onboarding = ({ children }) => {
  return (
    <Container
      fluid
      className={classNames("g-0 d-flex flex-column h-100", styles.container)}
    >
      <>
        <Row>
          <Col>
            <Navbar className="p-0">
              <Navbar.Brand href="/" className={styles.header}>
                <DPDLogo className={styles.logo} />
              </Navbar.Brand>
            </Navbar>
          </Col>
        </Row>
        <Row
          className={classNames(
            "flex-fill align-items-md-center",
            styles.content
          )}
        >
          <Col className="px-4 pb-4 p-lg-0">{children}</Col>
        </Row>
      </>
    </Container>
  );
};

export default Onboarding;
