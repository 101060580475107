import { Form } from "react-final-form";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Field } from "react-final-form";

import { Fields } from "../../../constants/forms";
import * as M from "../../../constants/strings";
import Input from "../../atoms/Input";
import * as Validators from "../../../utils/validators";
import * as Formatters from "../../../utils/formatters";

import styles from "./UpdateName.module.scss";

const UpdateName = ({ initialValues, onSubmit }) => (
  <Form onSubmit={onSubmit} initialValues={initialValues}>
    {(formProps) => (
      <form onSubmit={formProps.handleSubmit}>
        <Container fluid className="g-0">
          <Row className="g-0">
            <Col>
              <Field
                component={Input}
                name={Fields.FIRSTNAME}
                label={M.FIRST_NAME}
                maxLength={64}
                formatOnBlur={true}
                format={Formatters.trim}
                validate={Validators.composeValidators(
                  Validators.required,
                  Validators.notEmpty,
                  Validators.maxLength(64)
                )}
                required
              />
            </Col>
          </Row>
          <Row className="g-0">
            <Col>
              <Field
                component={Input}
                name={Fields.LASTNAME}
                label={M.LAST_NAME}
                maxLength={64}
                formatOnBlur={true}
                format={Formatters.trim}
                validate={Validators.composeValidators(
                  Validators.required,
                  Validators.notEmpty,
                  Validators.maxLength(64)
                )}
                required
              />
            </Col>
          </Row>
          <Row className="g-0">
            <Col className="d-flex justify-content-end mb-1">
              <Button
                variant="danger"
                type="submit"
                disabled={!formProps.dirty || formProps.invalid}
              >
                {M.SAVE}
              </Button>
            </Col>
          </Row>
          <Row className="g-0">
            <Col
              className={`d-flex justify-content-end ${
                formProps.dirty && "invisible"
              }`}
            >
              <span className={styles.buttonHelperText}>
                {M.PLEASE_MAKE_CHANGE}
              </span>
            </Col>
          </Row>
        </Container>
      </form>
    )}
  </Form>
);

export default UpdateName;
