import classNames from "classnames";
import PropTypes from "prop-types";
import { Button, Col, Image, Row } from "react-bootstrap";
import { noop } from "lodash";

import PickupPoint from "../../../assets/images/pickup_point.png";
import * as M from "../../../constants/strings";
import {
  getPickupAddress,
  getPickupAmenities,
  getPickupInfo,
  getPickupKind,
  getPickupName,
  getPickupOpenWindowList,
  getOpenStatusObject,
} from "../../../utils/pickupPoint";
import OpenWindowList from "../../atoms/OpenWindowList";
import ClosedForLunchStatus from "../../atoms/ClosedForLunchStatus";
import PickupAmenities from "../../atoms/PickupAmenities";
import PickupInfo from "../../atoms/PickupInfo";
import PickupPointTitle from "../../atoms/PickupPointTitle";
import PickupWindowSticker from "../../atoms/PickupWindowSticker";
import AddressView from "../AddressView";
import styles from "./PickupDetailsMobile.module.scss";

const PickupDetailsMobile = ({
  pickupPoint,
  isSelected,
  pickupListNumber,
  directionLink,
  className,
  onSelectClick,
  partnerLogo,
  onDirectionsClick,
}) => {
  const closedForLunch = getOpenStatusObject(pickupPoint).closedForLunch;

  return (
    <Row className={classNames("d-flex g-0 p-3 pb-4 pt-0", className)}>
      <Col className={styles.container}>
        <Row className="g-0 mb-2">
          <Col>
            <PickupPointTitle
              number={pickupListNumber}
              name={getPickupName(pickupPoint)}
            />
          </Col>
        </Row>

        <Row className="g-0 mb-2">
          <Col>
            <PickupInfo {...getPickupInfo(pickupPoint)} />
          </Col>
        </Row>

        <Row className="g-0 mb-3">
          <Col
            className={classNames(
              "d-flex justify-content-center position-relative",
              styles.partnerLogoContainer
            )}
          >
            <Image
              src={partnerLogo || PickupPoint}
              className={classNames(
                "w-100",
                styles.partnerLogo,
                !partnerLogo && "border-0"
              )}
            />
            <PickupWindowSticker type={getPickupKind(pickupPoint)} />
          </Col>
        </Row>

        <PickupAmenities
          {...getPickupAmenities(pickupPoint)}
          className="g-0 mb-3"
        />

        <Row className={classNames("g-0", styles.divider)}>
          <Col className="mb-2">
            <AddressView address={getPickupAddress(pickupPoint)} inline />
          </Col>
        </Row>

        {closedForLunch && (
          <Row className={classNames("g-0", styles.divider)}>
            <Col className="my-2">
              <ClosedForLunchStatus closedForLunch={closedForLunch} />
            </Col>
          </Row>
        )}

        <OpenWindowList
          openWindows={getPickupOpenWindowList(pickupPoint)}
          className="g-0 mt-2"
        />

        <Row className="g-0 mt-2">
          <Col xs={6} className="pe-2">
            <Button
              variant="outline-danger"
              className={classNames(
                "fs-5 w-100 h-100",
                styles.directionsButton
              )}
              size="md"
              href={directionLink}
              target="_blank"
              onClick={onDirectionsClick}
            >
              {M.DIRECTIONS}
            </Button>
          </Col>
          <Col xs={6} className="ps-2">
            <Button
              variant={isSelected ? "success" : "danger"}
              className="fs-5 w-100 h-100"
              size="md"
              onClick={onSelectClick}
            >
              {isSelected ? M.SELECTED : M.SELECT}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

PickupDetailsMobile.propTypes = {
  pickupPoint: PropTypes.object,
  isSelected: PropTypes.bool,
  pickupListNumber: PropTypes.string,
  directionLink: PropTypes.string,
  className: PropTypes.string,
  onSelectClick: PropTypes.func,
  partnerLogo: PropTypes.string,
  onDirectionsClick: PropTypes.func,
};

PickupDetailsMobile.defaultProps = {
  onDirectionsClick: noop,
};

export default PickupDetailsMobile;
