import { Location, Neighbour, SafePlace } from "../../components/atoms/icons";
import { ADDRESS } from "../../constants/analytics";
import * as M from "../../constants/strings";

export const DELIVERY_OPTIONS = [
  {
    title: M.ADDRESS_NAME,
    subTitle: M.SELECT_ADDRESS_TYPE,
    href: "type",
    actionId: ADDRESS.ON_CLICK_ADDRESS_TYPE,
  },
  {
    title: M.PINPOINT_ADDRESS,
    subTitle: M.PINPOINT_ADDRESS_MESSAGE,
    href: "pinpoint",
    icon: Location,
    actionId: ADDRESS.ON_CLICK_PINPOINT_ADDRESS,
  },
  {
    title: M.ADD_NOTE,
    subTitle: M.ADD_NOTE_MESSAGE,
    href: "note",
    actionId: ADDRESS.ON_CLICK_ADD_NOTE,
  },
  {
    title: M.ADD_PHOTO,
    subTitle: M.ADD_YOUR_PROPERTY_PHOTO,
    href: "photo",
    actionId: ADDRESS.ON_CLICK_ADD_PHOTO,
  },
  {
    title: M.PREFERRED_NEIGHBOUR,
    subTitle: M.PREFERRED_NEIGHBOUR_MESSAGE,
    href: "neighbours",
    icon: Neighbour,
    actionId: ADDRESS.ON_CLICK_PREFERRED_NEIGHBOUR,
  },
  {
    title: M.LEAVE_SAFE,
    subTitle: M.LEAVE_SAFE_MESSAGE,
    href: "safePlace",
    icon: SafePlace,
    actionId: ADDRESS.ON_CLICK_LEAVE_SAFE,
  },
];
