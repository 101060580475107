import { createAsyncThunk } from "@reduxjs/toolkit";
import { getToken } from "firebase/app-check";
import * as Auth from "firebase/auth";

import { appCheck, firebaseAuth } from "../../firebase";
import { authApis } from "../../apis";
import { EMAIL_PROVIDERS } from "./constants";
import {
  credentialFromError,
  credentialFromJSON,
  wrapAuthHandler,
} from "./utils";
import { ENTITY, createFirebaseAsyncThunk } from "../ErrorBoundary/utils";
import { EditPhoneSlice } from "../../pages/EditPhone/slice";
import { VerifyEmailSlice } from "../../pages/VerifyEmail/slice";
import { OnboardingSlice } from "../../pages/Onboarding/slice";
import { AddNewAddressSlice } from "../../pages/AddNewAddress/slice";

const OAuthProvider = Auth.OAuthProvider;
const fetchSignInMethodsForEmail = wrapAuthHandler(
  Auth.fetchSignInMethodsForEmail
);
const getRedirectResultFirebase = wrapAuthHandler(Auth.getRedirectResult);
const linkWithCredentialFirebase = wrapAuthHandler(Auth.linkWithCredential);
const signInWithCustomTokenFirebase = wrapAuthHandler(
  Auth.signInWithCustomToken
);
const signInWithRedirectFirebase = wrapAuthHandler(Auth.signInWithRedirect);

export const verifyPhoneNumber = createAsyncThunk(
  "auth/verifyPhone",
  async ({ phoneNumber, udprn }, { rejectWithValue }) => {
    try {
      const { token } = await getToken(appCheck);
      const { data: verificationId } = await authApis.signInWithPhoneNumber(
        phoneNumber,
        udprn,
        token
      );
      return verificationId;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const confirmPinNumber = createAsyncThunk(
  "auth/confirmPhonePin",
  async ({ verificationId, pin }) => {
    try {
      const result = await authApis.confirmVerificationCode(
        verificationId,
        pin
      );
      return result.data;
    } catch (error) {
      throw error;
    }
  }
);

export const verifyUpdatePhoneNumber = createAsyncThunk(
  "auth/verifyUpdatePhoneNumber",
  async (phoneNumber) => {
    const { token } = await getToken(appCheck);
    const { data: verificationId } = await authApis.verifyUpdatePhoneNumber(
      phoneNumber,
      token
    );
    return verificationId;
  }
);

export const confirmUpdatePhoneNumber = createAsyncThunk(
  "auth/confirmUpdatePhoneNumber",
  async ({ verificationId, pin }) => {
    const result = await authApis.confirmUpdatePhoneNumber(verificationId, pin);
    return result.data;
  }
);

export const sendMagicLink = createAsyncThunk(
  "auth/sendMagicLink",
  async (email) => {
    try {
      const { token } = await getToken(appCheck);
      return await authApis.sendMagicLink(email, token);
    } catch (error) {
      throw error;
    }
  }
);

export const verifyMagicLink = createAsyncThunk(
  "auth/verifyMagicLink",
  async (verificationId, { dispatch }) => {
    try {
      const { data } = await authApis.verifyMagicLink(verificationId);
      return dispatch(signInWithCustomToken(data.customToken)).unwrap();
    } catch (error) {
      throw error;
    }
  }
);

export const sendVerifyEmail = createAsyncThunk(
  "auth/sendVerifyEmail",
  async ({ email, id }) => {
    try {
      return await authApis.sendVerifyEmail(email, id);
    } catch (error) {
      throw error;
    }
  }
);

export const verifyEmail = createAsyncThunk(
  "auth/verifyEmail",
  async (verificationId) => {
    try {
      return await authApis.verifyEmail(verificationId);
    } catch (error) {
      throw error;
    }
  }
);

export const createSession = createAsyncThunk(
  "auth/createSession",
  async (token) => {
    return await authApis.createSession(token);
  }
);

export const reloadSession = createAsyncThunk(
  "auth/reloadSession",
  async (_, { dispatch }) => {
    const { customToken } = await authApis.reloadSession();
    return dispatch(signInWithCustomToken(customToken)).unwrap();
  }
);

export const logoutSession = createAsyncThunk("auth/logoutSession", () =>
  authApis.signOut()
);

export const getCurrentSession = createAsyncThunk(
  "auth/getCurrentSession",
  () => {
    return authApis.getSession().then(
      (session) => session,
      () => null
    );
  }
);

export const getRedirectResult = createAsyncThunk(
  "auth/getRedirectResult",
  async (_, { dispatch }) => {
    return getRedirectResultFirebase(firebaseAuth).then(
      (redirectResult) => {
        if (redirectResult) {
          const savedCredential = sessionStorage.getItem("auth/credential");
          // if there is saved credential, it means that user is linking account
          if (savedCredential) {
            // that condition never be used after migration from "Link multiple account using the same email"
            const credentials = JSON.parse(savedCredential);
            return linkWithCredentialFirebase(
              redirectResult.user,
              credentialFromJSON(credentials)
            ).then((userCredential) => {
              // don't forget to remove the credential
              sessionStorage.removeItem("auth/credential");
              return userCredential;
            });
          }
        }
        return redirectResult;
      },
      async (err) => {
        const email = err.customData?.email;
        // that condition never be used after migration from "Link multiple account using the same email"
        if (email && err.code === Auth.AuthErrorCodes.NEED_CONFIRMATION) {
          const credential = credentialFromError(err);
          const providers = await fetchSignInMethodsForEmail(
            firebaseAuth,
            email
          );
          const firstProviderMethod = providers.find((p) =>
            EMAIL_PROVIDERS.includes(p)
          );

          // Test: Could this happen with email link then trying social provider?
          if (!firstProviderMethod) {
            throw err;
          }

          const linkedProvider = new OAuthProvider(firstProviderMethod);
          linkedProvider.setCustomParameters({ login_hint: err.email });

          sessionStorage.setItem(
            "auth/credential",
            JSON.stringify(credential.toJSON())
          );
          await dispatch(signInWithRedirect(linkedProvider)).unwrap();
        }
        throw err;
      }
    );
  }
);

export const getOrCreateSession = createAsyncThunk(
  "auth/getOrCreateSession",
  async (_, { dispatch }) => {
    const redirectResult = await dispatch(getRedirectResult()).unwrap();
    if (redirectResult) {
      const token = await redirectResult.user.getIdToken();
      // This is the signed-in user
      // await signOut(getAuth());
      return dispatch(createSession(token)).unwrap();
    }
    return dispatch(getCurrentSession()).unwrap();
  }
);

export const signInWithRedirect = createAsyncThunk(
  "auth/signInWithRedirect",
  (provider) => {
    return signInWithRedirectFirebase(firebaseAuth, provider);
  }
);

export const signInWithCustomToken = createAsyncThunk(
  "auth/signInWithCustomToken",
  async (customToken, { dispatch }) => {
    const userCredential = await signInWithCustomTokenFirebase(
      firebaseAuth,
      customToken
    );
    const token = await userCredential.user.getIdToken();
    return dispatch(createSession(token)).unwrap();
  }
);

export const unlinkAccounts = createAsyncThunk(
  "auth/unlinkAccounts",
  async (providers) => {
    try {
      await authApis.unlinkAccounts(providers);
    } catch (error) {
      throw error;
    }
  }
);
