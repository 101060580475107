import React from "react";
import PropTypes from "prop-types";
import { Button, ButtonToolbar, Col, Row } from "react-bootstrap";

import * as M from "../../../constants/strings";
import ContentHeader from "../../molecules/ContentHeader";
import Container from "../../templates/Login/LoginContainer";

const CreateProfile = (props) => {
  return (
    <Container>
      <ContentHeader
        title={M.LOOKS_LIKE_HAVE_NOT_PARCEL}
        subtitle={M.NOT_WORRY}
        classes={{ container: "text-center text-lg-start" }}
      />
      <Row>
        <Col xs={12}>
          <ButtonToolbar className="justify-content-between justify-content-lg-end">
            <Button
              variant="outline-danger"
              className="me-lg-3"
              onClick={props.onClickBack}
            >
              {M.BACK}
            </Button>
            <Button variant="danger" onClick={props.onClickSetupAccount}>
              {M.SETUP_YOUR_ACCOUNT}
            </Button>
          </ButtonToolbar>
        </Col>
      </Row>
    </Container>
  );
};

CreateProfile.propTypes = {
  onClickBack: PropTypes.func,
  onClickSetupAccount: PropTypes.func,
};

export default CreateProfile;
