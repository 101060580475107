import FetchClient from "@dpdgroupuk/fetch-client";

const apiClient = new FetchClient({
  mode: "cors",
  credentials: "include",
  baseUrl:
    process.env.NODE_ENV === "development"
      ? "http://localhost:8090"
      : `${process.env.REACT_APP_CPP_API_URL}`,
});

export default apiClient;
