import classNames from "classnames";
import PropTypes from "prop-types";
import { Alert as BootstrapAlert } from "react-bootstrap";

import styles from "./Alert.module.scss";

const Alert = ({ variant, title, message, className }) => (
  <BootstrapAlert
    variant={variant}
    className={classNames(styles.card, className)}
  >
    {title && (
      <BootstrapAlert.Heading className={styles.title}>
        {title}
      </BootstrapAlert.Heading>
    )}
    <p className={styles.message}>{message}</p>
  </BootstrapAlert>
);

Alert.propTypes = {
  variant: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string,
};

export default Alert;
