import { createContext, useContext } from "react";

export const AuthContext = createContext({});

export const useAuth = () => useContext(AuthContext);

export const withAuth = (Component) => (props) =>
  (
    <AuthContext.Consumer>
      {(context) => <Component {...props} auth={context} />}
    </AuthContext.Consumer>
  );
