import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { createWizardSlice } from "../../features/Wizard";

export const VerifyEmailSlice = createWizardSlice("verifyEmail");
export const VerifyEmailSliceSelectors = VerifyEmailSlice.selectors;

export const VerifyEmailReducer = persistReducer(
  {
    key: "verifyEmail",
    storage: storage,
  },
  VerifyEmailSlice.reducer
);
