import { useCallback, useState } from "react";
import { find, get, isEmpty } from "lodash";
import PropTypes from "prop-types";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { useBreakpoint } from "use-breakpoint";

import { Trackable, useTracker } from "@dpdgroupuk/react-event-tracker";

import { locationsApis } from "../../apis";
import Alert from "../../components/atoms/Alert";
import { Loader } from "../../components/molecules/Loader";
import EditPickupPointComponent from "../../components/organisms/EditPickupPoint";
import ProfilePage from "../../components/templates/ProfilePage";
import { FIRST_DELIVERY_ATTEMPT_CODE } from "../../constants";
import { EDIT_PICKUP_POINT } from "../../constants/analytics";
import { Fields } from "../../constants/forms";
import {
  NO_AVAILABLE_PICKUP_DPD_POINTS,
  NO_AVAILABLE_PICKUP_POINTS,
  PICKUP_POINT,
  PICKUP_POINT_MESSAGE,
  WE_ALWAYS_ADDING_PICKUP_LOCATIONS,
  YOU_CHOSEN_$_AS_PREFERRED_PICKUP,
} from "../../constants/strings";
import { BREAKPOINTS } from "../../constants/ui";
import { useAuth } from "../../features/Auth";
import { useOverlay } from "../../features/Overlay";
import {
  ConsumerAddressesActions,
  ConsumerAddressesSelector,
} from "../../features/Profile";
import { useToaster } from "../../features/Toaster";
import { AddressModel } from "../../models";
import { getPickupLocationCode, getPickupName } from "../../utils/pickupPoint";
import styles from "./EditPickupPoint.module.scss";

const EditPickupContainer = ({ children, isPickupLocationsAvailable }) => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);

  return breakpoint !== "mobile" || !isPickupLocationsAvailable ? (
    <ProfilePage
      title={PICKUP_POINT}
      subtitle={PICKUP_POINT_MESSAGE}
      showBackButtonDesktop
      className={styles.container}
      bodyClassName={styles.content}
    >
      {children}
    </ProfilePage>
  ) : (
    children
  );
};

const EditPickupPoint = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const dispatch = useDispatch();
  const overlay = useOverlay();
  const toaster = useToaster();
  const { udprn } = useParams();
  const tracker = useTracker();
  const [pickupPoints, setPickupPoints] = useState([]);
  const [selectedPickupPoint, setSelectedPickupPoint] = useState();
  const isPickupLocationsAvailable = !isEmpty(pickupPoints);

  const addresses = useSelector(ConsumerAddressesSelector.getConsumerAddresses);
  const address = addresses.find((a) => a.udprn === udprn);
  const addressPoint = AddressModel.getAddressPoint(address);
  const savedPickupLocationCode = get(
    address,
    "preferences.pickupLocationCode"
  );

  const loadPickupPoints = useCallback(async ({ location }, { signal }) => {
    if (location) {
      const { data } = await locationsApis.getPickupLocations(location, {
        signal,
      });

      setPickupPoints(data);
      setSelectedPickupPoint(
        find(data, [
          "pickupLocation.pickupLocationCode",
          savedPickupLocationCode,
        ])
      );
    }
  }, []);

  const saveSelectedPickup = useCallback(
    async (pickupPoint) => {
      const pickupLocationCode = getPickupLocationCode(pickupPoint) || null;
      try {
        overlay.show();
        tracker.logEvent(EDIT_PICKUP_POINT.ON_CLICK_SAVE);

        await dispatch(
          ConsumerAddressesActions.updateAddress([
            auth.currentSession.uid,
            {
              udprn,
              "preferences.pickupLocationCode": pickupLocationCode,
              "preferences.firstDeliveryAttempt": pickupLocationCode
                ? get(address, `preferences.${Fields.FIRST_DELIVERY_ATTEMPT}`)
                : FIRST_DELIVERY_ATTEMPT_CODE.HOME,
            },
          ])
        ).unwrap();

        pickupPoint &&
          toaster.showSuccess({
            body: YOU_CHOSEN_$_AS_PREFERRED_PICKUP(getPickupName(pickupPoint)),
          });

        setSelectedPickupPoint(pickupPoint);
      } catch (e) {
        toaster.showError({
          body: e.message,
          reload: e.reload,
        });
      } finally {
        overlay.hide();
      }
    },
    [udprn, auth, dispatch, overlay, toaster, address, tracker]
  );

  const onSelectedPickupPoint = useCallback(
    (pickupPoint) => {
      const selectedPickupPoint =
        savedPickupLocationCode !== getPickupLocationCode(pickupPoint)
          ? pickupPoint
          : null;

      saveSelectedPickup(selectedPickupPoint);
    },
    [saveSelectedPickup, savedPickupLocationCode]
  );

  return (
    <Container fluid className="g-0 position-relative h-100">
      <Loader promiseFn={loadPickupPoints} location={addressPoint}>
        <Trackable
          loadId={EDIT_PICKUP_POINT.LOAD}
          interfaceId={EDIT_PICKUP_POINT.INTERFACE_ID}
        >
          <EditPickupContainer
            isPickupLocationsAvailable={isPickupLocationsAvailable}
          >
            {isPickupLocationsAvailable ? (
              <EditPickupPointComponent
                currentLocation={addressPoint}
                pickupLocations={pickupPoints}
                selectedPickupPoint={selectedPickupPoint}
                onSelectedPickupPoint={onSelectedPickupPoint}
                onClickBack={() => navigate(-1)}
              />
            ) : (
              <Alert
                variant="warning"
                title={NO_AVAILABLE_PICKUP_POINTS}
                message={
                  <span>
                    <span>{NO_AVAILABLE_PICKUP_DPD_POINTS}</span>
                    <br />
                    <span>{WE_ALWAYS_ADDING_PICKUP_LOCATIONS}</span>
                  </span>
                }
                className="m-4"
              />
            )}
          </EditPickupContainer>
        </Trackable>
      </Loader>
    </Container>
  );
};

EditPickupContainer.propTypes = {
  children: PropTypes.node,
  isPickupLocationsAvailable: PropTypes.bool,
};

export default EditPickupPoint;
