import { Image, Modal } from "react-bootstrap";
import PropTypes from "prop-types";

function ImageView({ show, imageUrl, setShow }) {
  return (
    <Modal
      centered
      dialogClassName="d-flex justify-content-center"
      contentClassName={"bg-transparent border-0 w-auto"}
      show={show}
      onHide={() => setShow(false)}
    >
      <Modal.Body className={"position-relative text-center"}>
        <button
          type="button"
          className={
            "close position-absolute rounded-circle border-0 bg-white text-body shadow-sm"
          }
          aria-label="Close"
          onClick={() => setShow(false)}
          style={{ top: 0, right: 0, width: 48, height: 48 }}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <Image rounded fluid src={imageUrl} />
      </Modal.Body>
    </Modal>
  );
}

ImageView.propTypes = {
  show: PropTypes.bool,
  imageUrl: PropTypes.string.isRequired,
  setShow: PropTypes.func,
};

export default ImageView;
