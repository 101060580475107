const Parking = (props) => (
  <svg
    width="2rem"
    height="2rem"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_510_9546)">
      <path d="M0 0H32V32H0V0Z" fill="#01619E" />
      <path
        d="M17.3333 4H8V28H13.3333V20H17.3333C21.7467 20 25.3333 16.4133 25.3333 12C25.3333 7.58667 21.7467 4 17.3333 4ZM17.6 14.6667H13.3333V9.33333H17.6C19.0667 9.33333 20.2667 10.5333 20.2667 12C20.2667 13.4667 19.0667 14.6667 17.6 14.6667Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_510_9546">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Parking;
