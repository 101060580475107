import React, { useState, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { Col, Dropdown as BootstrapDropdown, Form } from "react-bootstrap";
import classNames from "classnames";
import { noop, omit } from "lodash";

import styles from "./Dropdown.module.scss";
import { InputPropTypes, valuesPropType } from "./propTypes";

const Dropdown = ({
  label,
  placeholder,
  value,
  values,
  disabled,
  readonly,
  classes,
  rounded,
  className,
  textTransform,
  onFocus,
  onChange,
  onBlur,
  onToggle,
  inputRef,
  id,
  meta,
  required,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const options = useMemo(
    () =>
      values.map((value) => {
        if (typeof value === "object" && value !== null) {
          return value;
        }

        return { label: value, value };
      }),
    [values]
  );

  const currentValue = useMemo(() => {
    const selected = options.filter(
      (option) => option?.value?.toString() === value?.toString()
    );
    const current = {
      value: null,
      label: null,
    };

    if (selected.length) {
      current.value = selected[0]?.value;
      current.label = selected[0]?.label;
    }

    return current;
  }, [value, options]);

  const placeholderText = useMemo(
    () => placeholder || label,
    [label, placeholder]
  );

  const placeholderLabel = required ? `${placeholderText} *` : placeholderText;
  const inputLabel = required ? `${label} *` : label;

  const updateRef = useCallback(
    (ref) => {
      if (inputRef?.current) {
        inputRef.current = ref;
      }
    },
    [inputRef]
  );

  const onSelect = useCallback(
    (v, e) => {
      if (v !== value) {
        updateRef(e.target);
        onChange?.(
          {
            ...omit(e, ["target"]),
            target: { value: v, name: props.name },
          },
          v
        );
      }
    },
    [onChange, updateRef, value, props.name]
  );

  const handleBlur = useCallback(
    (e) => {
      if (!e.currentTarget?.contains(e.relatedTarget)) {
        setIsOpen(false);
        onBlur?.(value);
      }
    },
    [onBlur, value]
  );

  const handleFocus = useCallback(
    (e) => {
      if (!e.currentTarget?.contains(e.relatedTarget)) {
        onFocus?.(value);
      }
    },
    [onFocus, value]
  );

  const onKeyDown = useCallback(
    (e, value) => {
      if (e.code === "Enter") {
        e.preventDefault();
        e.stopPropagation();

        setIsOpen(false);
        onSelect(value, e);
      }
    },
    [onSelect]
  );

  const handleToggle = useCallback(
    (show, event) => {
      setIsOpen(show);
      onToggle?.(show);
    },
    [setIsOpen, onToggle]
  );
  return (
    <Form.Group as={Col} controlId={props.name}>
      <Form.Label>{inputLabel}</Form.Label>
      <BootstrapDropdown
        {...props}
        show={isOpen}
        onSelect={onSelect}
        onToggle={handleToggle}
        onBlur={handleBlur}
        onFocus={handleFocus}
        tabIndex="1"
        className={styles.dropdown}
      >
        <BootstrapDropdown.Toggle
          tabIndex="0"
          id={id}
          ref={inputRef}
          disabled={disabled || readonly}
          style={{ textTransform }}
          title={currentValue.label}
          className={classNames(
            "form-select form-control",
            !currentValue.label && "text-muted",
            meta.touched && !!meta.error && "is-invalid",
            classes.input
          )}
        >
          {currentValue.label ?? placeholderLabel}
        </BootstrapDropdown.Toggle>
        <BootstrapDropdown.Menu
          as="ul"
          className={classNames(classes.dropdown)}
        >
          {!!placeholderLabel && (
            <BootstrapDropdown.Item
              as="li"
              className={classNames(styles.option, classes.option)}
              label={placeholderLabel}
              eventKey=""
              selected
              disabled
              hidden
            >
              {placeholderLabel}
            </BootstrapDropdown.Item>
          )}
          {options.map((option, index) => (
            <BootstrapDropdown.Item
              key={index}
              eventKey={option.value}
              as={"li"}
              tabIndex={index}
              value={option.value}
              onKeyDown={(e) => onKeyDown(e, option.value)}
              labelclassname={classes.option}
              className={classNames(
                styles.option,
                option.value === null && styles.placeholder,
                classes.option
              )}
              style={{ textTransform }}
              active={currentValue.value === option.value}
            >
              {option.label}
            </BootstrapDropdown.Item>
          ))}
        </BootstrapDropdown.Menu>
      </BootstrapDropdown>
      <Form.Control.Feedback
        type="invalid"
        className={classNames("d-block", styles.error)}
        style={{ minHeight: 23 }}
      >
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

Dropdown.defaultProps = {
  readonly: false,
  values: [],
  disabled: false,
  classes: {},
  rounded: false,
  onChange: noop,
  meta: {},
};

Dropdown.propTypes = {
  ...InputPropTypes,
  textTransform: PropTypes.string,
  values: valuesPropType,
  classes: PropTypes.object,
  rounded: PropTypes.bool,
};

const ReduxifiedDropdown = ({ input, ...props }) => (
  <Dropdown {...props} {...input} />
);

ReduxifiedDropdown.propTypes = {
  input: PropTypes.object,
};

export default ReduxifiedDropdown;
