const Home = (props) => (
  <svg
    width="2rem" // 32px
    height="2rem"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_382_33804)">
      <path
        d="M28.409 16.5203C28.2577 16.5203 28.1065 16.4624 27.9913 16.347L16 4.34002L4.00873 16.347C3.7777 16.5783 3.40429 16.5783 3.17327 16.347C2.94224 16.1157 2.94224 15.7418 3.17327 15.5105L16 2.66699L28.8267 15.5105C29.0578 15.7418 29.0578 16.1157 28.8267 16.347C28.7115 16.4624 28.5603 16.5203 28.409 16.5203Z"
        fill="#414042"
      />
      <path
        d="M26.6368 29.3332H5.36328V16.908C5.36328 16.5814 5.62798 16.3164 5.95413 16.3164C6.28028 16.3164 6.54498 16.5814 6.54498 16.908V28.1501H25.4551V16.908C25.4551 16.5814 25.7192 16.3164 26.0459 16.3164C26.3727 16.3164 26.6368 16.5814 26.6368 16.908V29.3332Z"
        fill="#414042"
      />
      <path
        d="M18.9664 26.6726C18.6397 26.6726 18.3756 26.4085 18.3756 26.0818V19.5821H13.6485V26.0818C13.6485 26.4085 13.3838 26.6726 13.0577 26.6726C12.7315 26.6726 12.4668 26.4085 12.4668 26.0818V18.4004H19.5573V26.0818C19.5573 26.4085 19.2932 26.6726 18.9664 26.6726Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_382_33804">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Home;
