import React, { useCallback, useMemo, forwardRef } from "react";
import { Button } from "react-bootstrap";
import { NavigationControl } from "react-map-gl";
import classNames from "classnames";
import { noop } from "lodash";

import styles from "./PinLocationsMap.module.scss";
import {
  getBoundBoxByLngLatAndDistance,
  MapBoxComponent,
} from "../../../features/MapBox";
import MapBoxErrorMessage from "../../atoms/MapBoxErrorMessage";
import { RESET } from "../../../constants/strings";
import Pending from "../Loader/Pending";
import { MAX_POINT_DISTANCE } from "../../../constants/pinPointLocation";

const PinLocationsMap = forwardRef(
  (
    {
      isPending,
      pinPointLocation,
      error,
      onChange,
      onClickMap,
      onLoadMap,
      initialViewStateMap,
      cursorMap,
      children,
      onUndoMove,
    },
    ref
  ) => {
    const bounds = useMemo(() => {
      const boundBox = getBoundBoxByLngLatAndDistance(pinPointLocation.lngLat, {
        min: MAX_POINT_DISTANCE,
        max: MAX_POINT_DISTANCE + 0.01,
      });

      return [
        [boundBox.southwest.lng, boundBox.southwest.lat],
        [boundBox.northeast.lng, boundBox.northeast.lat],
      ];
    }, [pinPointLocation.lngLat]);

    const undoMove = useCallback(
      (e) => {
        e.stopPropagation();
        onChange({ lngLat: pinPointLocation.lngLat });
        onUndoMove();
      },
      [onChange, pinPointLocation.lngLat, onUndoMove]
    );

    return (
      <div className={styles.container}>
        <MapBoxComponent
          ref={ref}
          initialViewState={{
            longitude: pinPointLocation.lngLat.lng,
            latitude: pinPointLocation.lngLat.lat,
            ...initialViewStateMap,
          }}
          style={{
            paddingBottom: "68%",
          }}
          minZoom={17.8}
          maxZoom={19.5}
          maxBounds={bounds}
          className="mapBoxPinLocation"
          cursor={cursorMap}
          mapStyle="mapbox://styles/mapbox/satellite-streets-v11"
          attributionControl={false}
          keyboard={false}
          dragRotate={false}
          touchZoomRotate={false}
          touchRotate={false}
          pitchWithRotate={false}
          touchPitch={false}
          onClick={onClickMap}
          onLoad={onLoadMap}
        >
          <MapBoxErrorMessage
            className={classNames(styles.mapBoxErrorMessage, {
              invisible: !error,
            })}
            message={error}
          />
          <Button
            variant={"light"}
            size="sm"
            onClick={undoMove}
            className={styles.undoButtonContainer}
          >
            {RESET}
          </Button>
          {isPending && (
            <div className={styles.mapLoader}>
              <Pending />
            </div>
          )}
          <NavigationControl
            showCompass={false}
            style={{ marginTop: "2.8125rem" }} // 45px
          />
          {children}
        </MapBoxComponent>
      </div>
    );
  }
);

PinLocationsMap.defaultProps = {
  onUndoMove: noop,
};

export default PinLocationsMap;
