import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, ButtonToolbar, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Trackable, useTracker } from "@dpdgroupuk/react-event-tracker";
import { useAuth } from "../../features/Auth";
import { useOverlay } from "../../features/Overlay";
import { getEmailValues } from "./selectors";
import ResendEmail from "../../components/atoms/icons/ResendEmail";
import { Paths } from "../../router";
import ContentHeader from "../../components/molecules/ContentHeader";
import Container from "../../components/templates/Login/LoginContainer";
import styles from "./VerifyEmail.module.scss";
import {
  BACK_TO_HOME,
  CHECK_YOUR_EMAIL,
  DIDNT_RECEIVE_EMAIL,
  EMAIL_RESENT,
} from "../../constants/strings";
import { EMAIL_SENT } from "../../constants/analytics";

const SentEmail = ({ values, setValues }) => {
  const navigate = useNavigate();
  const auth = useAuth();
  const overlay = useOverlay();
  const { email } = useSelector(getEmailValues);
  const [resendError, setResendError] = useState(null);
  const tracker = useTracker();

  useEffect(() => {
    if (!email || auth.currentSession) {
      navigate(Paths.SIGN_IN);
    }
  }, []);

  const onResendEmail = useCallback(async () => {
    tracker.logEvent(EMAIL_SENT.ON_EMAIL_NOT_RECEIVED);

    try {
      overlay.show();
      await auth.sendMagicLink(email).unwrap();
      setValues({
        sentEmails: values.sentEmails + 1,
      });
    } catch (e) {
      setResendError(e.message);
    } finally {
      overlay.hide();
    }
  }, [overlay, auth, values, email, setValues, tracker]);

  const onBackToHome = useCallback(async () => {
    tracker.logEvent(EMAIL_SENT.ON_BACK);
    navigate(Paths.SIGN_IN);
  }, [navigate, tracker]);

  return (
    <Trackable loadId={EMAIL_SENT.LOAD} interfaceId={EMAIL_SENT.INTERFACE_ID}>
      <Container className={styles.container}>
        <ContentHeader
          title={values.sentEmails > 1 ? EMAIL_RESENT : CHECK_YOUR_EMAIL}
          subtitle={
            <>
              <p>
                We’ve sent an email to <b>{email}</b>
                <br />
                This email will contain a magic link that will automatically
                sign you in.
              </p>
              {values.sentEmails > 1 && (
                <p>Please be sure to check your spam folder too.</p>
              )}
            </>
          }
          icon={ResendEmail}
          classes={{ container: "text-start", title: "my-2" }}
        />
        {resendError && (
          <Row>
            <Col>
              <p className="text-danger">{resendError}</p>
            </Col>
          </Row>
        )}
        <Row>
          <Col xs={12}>
            <ButtonToolbar className="justify-content-end">
              <Button
                variant="link"
                onClick={onResendEmail}
                className={styles.linkButton}
              >
                <span className="me-lg-4">{DIDNT_RECEIVE_EMAIL}</span>
              </Button>
              <Button
                variant="outline-danger"
                onClick={onBackToHome}
                className={styles.backButton}
              >
                {BACK_TO_HOME}
              </Button>
            </ButtonToolbar>
          </Col>
        </Row>
      </Container>
    </Trackable>
  );
};

export default SentEmail;
