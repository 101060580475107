import PropTypes from "prop-types";

export const RefPropTypes = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.shape({ current: PropTypes.object }),
]);

export const InputPropTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  multiline: PropTypes.bool,
  required: PropTypes.bool,
  type: PropTypes.oneOf([
    "text",
    "password",
    "search",
    "file",
    "directory",
    "time",
    "tel",
    "dropdown",
  ]),
  meta: PropTypes.object,
  input: PropTypes.object,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  onChange: PropTypes.func,
  rounded: PropTypes.bool,
  active: PropTypes.bool,
  error: PropTypes.string,
  inputRef: RefPropTypes,
};

export const valuesPropType = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.any,
  })
);
