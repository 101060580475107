import classNames from "classnames";
import PropTypes from "prop-types";
import { Card, Col, Row } from "react-bootstrap";
import { noop } from "lodash";

import {
  LocationPropType,
  PickupPointPropType,
  PickupPointsPropType,
} from "../../../constants/propType";
import * as M from "../../../constants/strings";
import BackButton from "../../atoms/BackButton";
import PickupPoint from "../../atoms/icons/PickupPoint";
import LinkButton from "../../atoms/LinkButton";
import PickupFilterButtons from "../../molecules/PickupFilterButtons";
import PickupLocationsMap from "../../molecules/PickupLocationsMap";
import PickupPointsList from "../../molecules/PickupPointsList";
import NavigationBar from "../../molecules/WizardNavigationBar/WizardNavigationBar";
import OnboardingTemplate from "../../templates/Onboarding/Onboarding";
import Template from "../../templates/OnboardingStepContainer";
import styles from "./SetPickupPointDesktop.module.scss";

const SetPickupPointDesktop = ({
  currentLocation,
  selectedPickupPoint,
  activePickupPoint,
  pickupPoints,
  onSelectClick,
  onPickupDetailsClick,
  onSetupLater,
  onClickBack,
  onMapPinClick,
  filterBy,
  isFiltersVisible,
  onFilterButtonClick,
  onClickNext,
  pickupListRef,
  onClickPrevious,
  onDirectionsClick,
}) => (
  <OnboardingTemplate>
    <Template
      title={M.SET_PICKUP_POINT}
      subtitle={M.LET_US_KNOW_PICKUP_POINT}
      icon={PickupPoint}
      className={classNames(styles.container, "p-0 px-lg-4")}
      fluid
    >
      <Row>
        <Col>
          <BackButton onClick={onClickBack}>
            {M.GO_BACK_TO_DEFAULT_PICKUP}
          </BackButton>
        </Col>
      </Row>
      <Card className="g-0 mb-3">
        <Card.Body className="p-0">
          <Row className="g-0">
            <Col className={classNames("p-0", styles.listContainer)} xs={6}>
              <PickupPointsList
                currentLocation={currentLocation}
                selectedPickupPoint={selectedPickupPoint}
                activePickupPoint={activePickupPoint}
                pickupPoints={pickupPoints}
                onSelectClick={onSelectClick}
                onPickupDetailsClick={onPickupDetailsClick}
                pickupListRef={pickupListRef}
                onDirectionsClick={onDirectionsClick}
              />
            </Col>
            <Col className="p-0">
              {isFiltersVisible && (
                <PickupFilterButtons
                  filterBy={filterBy}
                  onFilterButtonClick={onFilterButtonClick}
                  className={styles.filterButtonsContainer}
                />
              )}
              <PickupLocationsMap
                selectedPickupPoint={selectedPickupPoint}
                activePickupPoint={activePickupPoint}
                currentLocation={currentLocation}
                pickupLocations={pickupPoints}
                onPickupPinClick={onMapPinClick}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Row>
        <Col className="d-flex justify-content-center p-0">
          <LinkButton onClick={onSetupLater}>{M.SETUP_LATER}</LinkButton>
        </Col>
      </Row>
      <NavigationBar onClickNext={onClickNext} onClickBack={onClickPrevious} />
    </Template>
  </OnboardingTemplate>
);

SetPickupPointDesktop.defaultProps = {
  onClickPrevious: noop,
  onDirectionsClick: noop,
};

SetPickupPointDesktop.propTypes = {
  currentLocation: LocationPropType.isRequired,
  selectedPickupPoint: PickupPointPropType,
  activePickupPoint: PickupPointPropType,
  pickupPoints: PickupPointsPropType.isRequired,
  onSelectClick: PropTypes.func,
  onPickupDetailsClick: PropTypes.func,
  onSetupLater: PropTypes.func,
  onClickBack: PropTypes.func,
  filterBy: PropTypes.string,
  isFiltersVisible: PropTypes.bool,
  onFilterButtonClick: PropTypes.func,
  onClickNext: PropTypes.func,
  onMapPinClick: PropTypes.func,
  pickupListRef: PropTypes.any,
  onClickPrevious: PropTypes.func,
  onDirectionsClick: PropTypes.func,
};

export default SetPickupPointDesktop;
