const createLineDataSource = (coordinates) => ({
  type: "Feature",
  properties: {},
  geometry: {
    type: "LineString",
    coordinates,
  },
});
const baseLatShiftDistance = 0.00055; // near 60 meters
const baseLngShiftDistance = 0.0009; // near 60 meters
// TODO need to calculate baseLatShiftDistance and baseLngShiftDistance by provided distance.max
//    and distance.min
export const getBoundBoxByLngLatAndDistance = (lngLat, distance) => {
  if (distance.min === distance.max) {
    throw new Error("Bound box cannot be calculated with provided ranges");
  }

  return {
    southwest: {
      lng: lngLat.lng - baseLngShiftDistance,
      lat: lngLat.lat - baseLatShiftDistance,
    },
    northeast: {
      lng: lngLat.lng + baseLngShiftDistance,
      lat: lngLat.lat + baseLatShiftDistance,
    },
  };
};

const prepareCoordinatesLinesSquare = (square) => [
  [square.southwest.lng + 0.000004, square.southwest.lat + 0.000003],
  [square.southwest.lng + 0.000004, square.northeast.lat - 0.000003],

  [square.northeast.lng - 0.000004, square.northeast.lat - 0.000003],
  [square.northeast.lng - 0.000004, square.southwest.lat + 0.000003],
  [square.southwest.lng + 0.000004, square.southwest.lat + 0.000003],
];

const metersToPixelsAtMaxZoom = (meters, latitude, zoom) =>
  meters / (78271.484 / 2 ** zoom) / Math.cos((latitude * Math.PI) / 180);

export const drawCircleArea = (mapBoxInstance = {}, point, radius) => {
  const sourceSquareName = "circle-area";
  const squareSource = mapBoxInstance.getSource(sourceSquareName);

  if (!squareSource) {
    mapBoxInstance.addSource(sourceSquareName, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [point.lng, point.lat],
            },
          },
        ],
      },
    });

    mapBoxInstance.addLayer({
      id: "circle500",
      type: "circle",
      source: sourceSquareName,
      paint: {
        "circle-radius": {
          base: 1.75,
          stops: [
            [0, 0],
            //[20, 805]
            [17, metersToPixelsAtMaxZoom(radius * 1000, point.lat, 17)],
            [18, metersToPixelsAtMaxZoom(radius * 1000, point.lat, 18)],
            [19, metersToPixelsAtMaxZoom(radius * 1000, point.lat, 19)],
            [20, metersToPixelsAtMaxZoom(radius * 1000, point.lat, 20)],
          ],
        },
        "circle-color": "#5b94c6",
        "circle-opacity": 0.25,
      },
    });
  }
};

export const drawSquare = (mapBoxInstance = {}, boxSquare) => {
  const sourceSquareName = "square-lines";
  const squareSource = mapBoxInstance.getSource(sourceSquareName);
  const coordinates = prepareCoordinatesLinesSquare(boxSquare);

  if (!squareSource) {
    mapBoxInstance.addSource(sourceSquareName, {
      type: "geojson",
      data: createLineDataSource(coordinates),
    });
    mapBoxInstance.addLayer({
      id: "square-line",
      type: "line",
      source: sourceSquareName,
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": "#F00",
        "line-width": [
          "interpolate",
          ["exponential", 0.5],
          ["zoom"],
          17,
          2,
          18,
          4,
          20,
          10,
        ],
      },
    });
  } else {
    squareSource.setData(createLineDataSource(coordinates));
  }
};

export const convertGridFromWhat3Words = (lines) => {
  const coordinates = lines.map((line) => [
    [line.start.lng, line.start.lat],
    [line.end.lng, line.end.lat],
  ]);

  return {
    features: [
      {
        type: "Feature",
        properties: {},
        geometry: {
          type: "MultiLineString",
          coordinates: coordinates,
        },
      },
    ],
    type: "FeatureCollection",
  };
};

export const drawGrid = (mapBoxInstance = {}, grid) => {
  const gridSource = "grid";
  const mapSourceGrid = mapBoxInstance.getSource(gridSource);

  if (!mapSourceGrid) {
    mapBoxInstance.addSource("grid", {
      type: "geojson",
      data: grid,
    });
    mapBoxInstance.addLayer({
      id: "grid_layer",
      type: "line",
      source: gridSource,
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": "#EEE",
        "line-width": 0.5,
      },
    });
  } else {
    mapBoxInstance.getSource(gridSource).setData(grid);
  }
};
