import { Col, Row } from "react-bootstrap";
import { noop } from "lodash";
import PropTypes from "prop-types";

import * as M from "../../../constants/strings";
import { STEP_NAME } from "../../../constants";
import { Consumer } from "../../atoms/icons";
import EditableCard from "../../molecules/EditableCard/EditableCard";
import { ClassesPropType } from "./propTypes";

const PersonalInformationCard = ({ classes, data, onEditClick }) => (
  <EditableCard
    icon={Consumer}
    title={
      <>
        <span className="d-none d-sm-inline">{M.PERSONAL_INFORMATION}</span>
        <span className="d-sm-none">{M.PERSONAL_INFORMATION_SHORT}</span>
      </>
    }
    onEdit={() => onEditClick(STEP_NAME.PERSONAL_INFORMATION)}
    classes={classes}
  >
    <Row>
      <Col>
        <p className={classes.dataHeader}>{M.FIRST_NAME}:</p>
        <p className={classes.text}>{data.firstName}</p>
      </Col>
      <Col>
        <p className={classes.dataHeader}>{M.LAST_NAME}:</p>
        <p className={classes.text}>{data.lastName}</p>
      </Col>
    </Row>
    <Row>
      <Col>
        <p className={classes.dataHeader}>{M.PHONE_NUMBER}:</p>
        <p className={classes.text}>{data.phoneNumber}</p>
      </Col>
      <Col>
        <p className={classes.dataHeader}>{M.EMAIL_ADDRESS}:</p>
        <p className={classes.text}>{data.email || M.NOT_SET}</p>
      </Col>
    </Row>
  </EditableCard>
);

PersonalInformationCard.defaultProps = {
  classes: {},
  data: {},
  onEditClick: noop,
};

PersonalInformationCard.propTypes = {
  classes: ClassesPropType,
  onEditClick: PropTypes.func,
  data: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phoneNumber: PropTypes.string,
    email: PropTypes.string,
  }),
};

export default PersonalInformationCard;
