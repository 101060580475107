import { useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Col, Container, Nav, Row } from "react-bootstrap";
import { Outlet, useLocation } from "react-router-dom";
import classNames from "classnames";
import { useBreakpoint } from "use-breakpoint";
import { includes } from "lodash";

import { Paths } from "../../router";
import { Loader } from "../../components/molecules/Loader";
import { ProfileActions } from "../../features/Profile";
import { useAuth } from "../../features/Auth";
import TabNavItem from "../../components/molecules/TabNavItem/TabNavItem";
import {
  Address,
  Chat,
  Consumer,
  DPD,
  DPDLogo,
} from "../../components/atoms/icons";
import styles from "./Profile.module.scss";
import {
  ACCOUNT,
  ACCOUNT_OVERVIEW,
  ADDRESS,
  HOW_WE_CONTACT_YOU,
  INFORMATION,
  NOTIFICATIONS,
  PERSONAL_INFORMATION,
  PROFILE_SUBHEADER,
  YOUR_DELIVERY_ADDRESSES,
  YOUR_DPD_PROFILE,
} from "../../constants/strings";
import { BREAKPOINTS } from "../../constants/ui";

const LABELS = {
  ACCOUNT: {
    mobile: ACCOUNT,
    tablet: ACCOUNT_OVERVIEW,
    desktop: ACCOUNT_OVERVIEW,
  },
  INFO: {
    mobile: INFORMATION,
    tablet: PERSONAL_INFORMATION,
    desktop: PERSONAL_INFORMATION,
  },
  ADDRESSES: {
    mobile: ADDRESS,
    tablet: YOUR_DELIVERY_ADDRESSES,
    desktop: YOUR_DELIVERY_ADDRESSES,
  },
  CONTACT: {
    mobile: NOTIFICATIONS,
    tablet: HOW_WE_CONTACT_YOU,
    desktop: HOW_WE_CONTACT_YOU,
  },
};

export function ProfileLayout() {
  const { pathname, search } = useLocation();
  const contentRef = useRef();
  const dispatch = useDispatch();
  const { currentSession } = useAuth();
  const location = useLocation();
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const profileLoader = useCallback(
    ({ uid }) => {
      return dispatch(ProfileActions.getProfile(uid)).unwrap();
    },
    [dispatch]
  );

  const isPickupMobilePage =
    (includes(location.pathname, "pickupPoint") ||
      includes(location.search, "pickupPoint")) &&
    breakpoint === "mobile";

  const showFooterMenu = !isPickupMobilePage; // hide bottom menu on edit and add address step'Pickup Point' mobile screens

  useEffect(() => {
    contentRef.current?.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [pathname, search]);

  return (
    <Loader promiseFn={profileLoader} uid={currentSession.uid}>
      <Container fluid className={classNames("g-0", styles.container)}>
        <Row className="g-0 vh-100">
          <Col
            lg={9}
            className={classNames(
              "g-0",
              "order-1",
              showFooterMenu && styles.content
            )}
            ref={contentRef}
          >
            <Outlet />
          </Col>
          <Col lg={3} className={classNames("g-0", "order-0", styles.navPanel)}>
            <div className={styles.navHeader}>
              <DPDLogo className={styles.logo} />
              <h5>{YOUR_DPD_PROFILE}</h5>
              <p className={classNames("m-0", styles.menuSubtitle)}>
                {PROFILE_SUBHEADER}
              </p>
            </div>
            {showFooterMenu && (
              <Nav activeKey={location.pathname} className={styles.nav}>
                <TabNavItem
                  href={Paths.PROFILE_ACCOUNT}
                  title={LABELS.ACCOUNT[breakpoint]}
                  icon={DPD}
                  classes={{
                    labelContainer: styles.labelContainer,
                    arrow: styles.arrow,
                    navLink: styles.navLink,
                    navItem: styles.navItem,
                    icon: styles.icon,
                  }}
                />
                <TabNavItem
                  href={Paths.PROFILE_PERSONAL_INFO}
                  title={LABELS.INFO[breakpoint]}
                  icon={Consumer}
                  classes={{
                    labelContainer: styles.labelContainer,
                    arrow: styles.arrow,
                    navLink: styles.navLink,
                    navItem: styles.navItem,
                    icon: styles.icon,
                  }}
                />
                <TabNavItem
                  href={Paths.PROFILE_ADDRESSES}
                  title={LABELS.ADDRESSES[breakpoint]}
                  icon={Address}
                  classes={{
                    labelContainer: styles.labelContainer,
                    arrow: styles.arrow,
                    navLink: styles.navLink,
                    navItem: styles.navItem,
                    icon: styles.icon,
                  }}
                />
                <TabNavItem
                  href={Paths.PROFILE_CONTACT}
                  title={LABELS.CONTACT[breakpoint]}
                  icon={Chat}
                  classes={{
                    labelContainer: styles.labelContainer,
                    arrow: styles.arrow,
                    navLink: styles.navLink,
                    navItem: styles.navItem,
                    icon: styles.icon,
                  }}
                />
              </Nav>
            )}
          </Col>
        </Row>
      </Container>
    </Loader>
  );
}
