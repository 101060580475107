import classNames from "classnames";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";

import styles from "./Checkbox.module.scss";

const Checkbox = ({
  label,
  disabled,
  required,
  input,
  meta,
  classes,
  ...props
}) => {
  const inputLabel = required ? `${label} *` : label;

  return (
    <Form.Group controlId={input?.name}>
      <Form.Check
        className={classNames(styles.checkbox, classes.checkbox)}
        type="checkbox"
        disabled={disabled}
        label={inputLabel}
        isInvalid={meta.touched && meta.error}
        {...input}
        {...props}
      />
      <Form.Control.Feedback
        type="invalid"
        className={classNames("d-block", classes.error)}
        style={{ minHeight: 23 }}
      >
        {meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

Checkbox.propTypes = {
  label: PropTypes.string,
  input: PropTypes.object,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  meta: PropTypes.object,
  classes: PropTypes.object,
};

Checkbox.defaultProps = {
  meta: {},
  classes: {},
};

export default Checkbox;
