const Arrow = (props) => (
  <svg
    width="0.5rem" // 8px
    height="0.75rem" // 12px
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.41 1.41L6 0L0 6L6 12L7.41 10.59L2.83 6L7.41 1.41Z"
      fill="currentColor"
    />
  </svg>
);

export default Arrow;
