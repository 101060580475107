import classNames from "classnames";
import { Button, Card } from "react-bootstrap";
import PropTypes from "prop-types";

import IconWithLabel from "../../atoms/IconWithLabel/IconWithLabel";
import Checkmark from "../../atoms/icons/Checkmark";
import styles from "./SafePlaceCard.module.scss";
import { SELECT, SELECTED } from "../../../constants/strings";

const SafePlaceCard = ({
  icon,
  label,
  onSelect,
  selected,
  disabled = false,
  selectable = true,
  inline,
  className,
  showCheckMark = true,
  showSelectedMark = false,
  footer,
}) => {
  return (
    <Card
      bg={disabled && "light"}
      className={classNames(
        "d-flex flex-row justify-content-between",
        styles.card,
        selected && styles.selected,
        inline && styles.inline,
        "align-items-center",
        !inline &&
          "flex-md-column justify-content-md-center align-items-md-center",
        className
      )}
    >
      {showCheckMark && <Checkmark className={styles.checkmark} />}
      <IconWithLabel
        icon={icon}
        label={label}
        customStyles={{
          label: styles.label,
          icon: styles.icon,
          container: styles.iconWithLabel,
        }}
      />
      {selectable && (
        <Button
          disabled={disabled}
          variant={disabled ? "secondary" : "outline-danger"}
          className={classNames(
            styles.button,
            !selected && styles.selectButton
          )}
          onClick={() => {
            onSelect(selected);
          }}
        >
          {selected ? SELECTED : SELECT}
        </Button>
      )}
      {showSelectedMark && selected && !disabled && (
        <div className="d-flex flex-nowrap ms-4">
          <div className="text-success d-flex align-items-center p-0 me-1 justify-content-end d-inline-flex">
            <span>{SELECTED}</span>
          </div>
          <div className="d-flex align-items-center p-0 ms-2 justify-content-end d-inline-flex">
            <Checkmark />
          </div>
        </div>
      )}
      {footer}
    </Card>
  );
};

SafePlaceCard.propTypes = {
  icon: PropTypes.func,
  label: PropTypes.node,
  onSelect: PropTypes.func,
  selected: PropTypes.bool,
  selectable: PropTypes.bool,
  inline: PropTypes.bool,
  className: PropTypes.string,
};

export default SafePlaceCard;
