import { createContext, useContext } from "react";

export const WizardContext = createContext({});

export const useWizard = () => useContext(WizardContext);

export const withWizard = (Component) => (props) =>
  (
    <WizardContext.Consumer>
      {(context) => <Component {...props} wizard={context} />}
    </WizardContext.Consumer>
  );
