const Yahoo = (props) => {
  return (
    <svg
      width="1.5rem"
      height="1.5rem"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 7.38194H5.81438L8.03548 13.1344L10.2855 7.38194H13.999L8.40709 21H4.66943L6.20023 17.3915L2.00012 7.38194H2ZM18.3235 11.9784H14.164L17.8558 3L22 3.00018L18.3235 11.9784ZM15.2536 12.8377C16.5298 12.8377 17.5644 13.885 17.5644 15.1768C17.5644 16.4686 16.5298 17.516 15.2536 17.516C13.9775 17.516 12.9432 16.4686 12.9432 15.1768C12.9432 13.885 13.9777 12.8377 15.2536 12.8377Z"
        fill="#5F01D1"
      />
    </svg>
  );
};

export default Yahoo;
