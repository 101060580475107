import classNames from "classnames";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./ConfirmDetails.module.scss";
import {
  DeliveryAddressCard,
  NeighboursCard,
  PickupPointCard,
  PinPointLocationCard,
  SafePlaceCard,
} from "../ConfirmDetailsCards";
import FirstDeliveryAttempt from "../FirstDeliveryAttempt";

const ConfirmDetails = ({ data, onEditClick }) => (
  <Container fluid className={classNames(styles.container, "g-0")}>
    <FirstDeliveryAttempt
      data={data}
      classes={{ ...styles, dataColumn: "col-lg-12" }}
      className="mt-4"
    />
    <hr className="mb-4 mt-0" />
    <Row className="g-0">
      <Col className="mb-4">
        <DeliveryAddressCard
          classes={styles}
          data={{ ...data.deliveryAddress, ...data.deliveryAddressValues }}
          onEditClick={onEditClick}
        />
      </Col>
    </Row>
    <Row className="g-0">
      <Col className="mb-4">
        <PinPointLocationCard
          classes={styles}
          available={data.isPinpointLocationAvailable}
          data={data.pinpointLocation}
          onEditClick={onEditClick}
        />
      </Col>
    </Row>
    <Row className="g-0">
      <Col className="mb-4">
        <NeighboursCard
          classes={styles}
          data={data.neighbours}
          available={data.isNeighbourAddressesAvailable}
          onEditClick={onEditClick}
        />
      </Col>
    </Row>
    <Row className="g-0">
      <Col className="mb-4">
        <SafePlaceCard
          classes={styles}
          data={data.safePlace}
          onEditClick={onEditClick}
        />
      </Col>
    </Row>
    <Row className="g-0">
      <Col>
        <PickupPointCard
          classes={styles}
          available={data.isPickupPointsAvailable}
          data={data.pickupPoint}
          onEditClick={onEditClick}
        />
      </Col>
    </Row>
  </Container>
);

export default ConfirmDetails;
