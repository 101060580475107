import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { createWizardSlice } from "../../features/Wizard";

export const AddNewAddressSlice = createWizardSlice("addNewAddress");

export const AddNewAddressSelectors = AddNewAddressSlice.selectors;

export const AddNewAddressReducer = persistReducer(
  {
    key: "addNewAddress",
    storage: storage,
  },
  AddNewAddressSlice.reducer
);
