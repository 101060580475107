import React from "react";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/react";

import Error from "../../components/atoms/Error";

class ErrorBoundary extends React.Component {
  constructor(...args) {
    super(...args);
    this.state = {
      error: null,
    };
  }

  componentDidCatch(error, info) {
    Sentry.withScope((scope) => {
      scope.setExtras(info);
      this.setState({ error });
    });
  }

  onReloadClick = () => {
    window.location.reload();
  };

  render() {
    const { component: Component, children, ...props } = this.props;

    if (this.state.error) {
      return (
        <Sentry.ErrorBoundary
          fallback={
            <Component
              error={this.state.error}
              onButtonClick={this.onReloadClick}
              {...props}
            />
          }
        >
          {children}
        </Sentry.ErrorBoundary>
      );
    }

    return children;
  }
}

ErrorBoundary.defaultProps = {
  component: Error,
};

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  component: PropTypes.elementType.isRequired,
};

export default ErrorBoundary;
