import PropTypes from "prop-types";

const Cross = ({ fill, ...props }) => {
  return (
    <svg
      width="1.5rem" // 24px
      height="1.5rem"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.7638 19.7655C19.4512 20.0782 18.9454 20.0782 18.6324 19.7655L11.9999 13.1307L5.3658 19.7655C5.05319 20.0782 4.54741 20.0782 4.23445 19.7655C3.92185 19.4529 3.92185 18.947 4.23445 18.6341L10.87 12.0008L4.23545 5.36595C3.92285 5.05331 3.92285 4.54747 4.23545 4.23448C4.54806 3.92184 5.05384 3.92184 5.3668 4.23448L11.9999 10.8708L18.6339 4.23598C18.9465 3.92334 19.4523 3.92334 19.7653 4.23598C20.0779 4.54862 20.0779 5.05446 19.7653 5.36745L13.1297 12.0008L19.7638 18.6356C20.0787 18.9455 20.0787 19.4555 19.7638 19.7655Z"
        fill={fill}
      />
    </svg>
  );
};

Cross.defaultProps = {
  fill: "#DC0032",
};

Cross.propTypes = {
  fill: PropTypes.string,
};

export default Cross;
