import { Card } from "react-bootstrap";
import PropTypes from "prop-types";

import styles from "./AddressView.module.scss";
import { AddressPropType } from "./propTypes";
import { formatAddressWithPostcode } from "../../../models/address";

const AddressView = ({ address = {}, inline, classes = {} }) => {
  return (
    <Card.Text className={styles.address}>
      {inline ? (
        <span className={classes.text}>
          {formatAddressWithPostcode(address)}
        </span>
      ) : (
        <>
          <span className={classes.text}>{address.organisation}</span>
          <span className={classes.text}>
            {address.property} {address.street}
          </span>
          <span className={classes.text}>{address.town}</span>
          <span className={classes.text}>{address.county}</span>
          <span className={classes.text}>{address.postcode}</span>
        </>
      )}
    </Card.Text>
  );
};

AddressView.propTypes = {
  address: AddressPropType,
  inline: PropTypes.bool,
  classes: PropTypes.object,
};

export default AddressView;
