import { createReducer } from "@reduxjs/toolkit";
import {
  createConsumer,
  deleteConsumer,
  getConsumer,
  updateConsumer,
} from "./actions";
import { mergeWithDotNotation } from "../../../utils/objects";

const initialState = {};

const consumerReducer = createReducer(initialState, {
  [getConsumer.fulfilled]: (state, { payload }) => payload,
  [createConsumer.fulfilled]: (state, { payload }) => payload,
  [updateConsumer.fulfilled]: (state, { payload }) =>
    mergeWithDotNotation(state, payload),
  [deleteConsumer.fulfilled]: (state) => initialState,
});

export default consumerReducer;
