import { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";

import ImageFullView from "./ImageFullView";
import FallbackImage from "../../atoms/FallbackImage";

function ImagePreview({ imageUrl, onClick, ...props }) {
  const [showInModal, setShownInModal] = useState(false);
  const handleKeyDown = useCallback(
    (e) => {
      if (e.code === "Enter") {
        e.preventDefault();
        e.stopPropagation();

        setShownInModal(true);
      }
    },
    [setShownInModal]
  );

  return (
    <>
      <FallbackImage
        fluid
        thumbnail
        tabIndex={0}
        src={imageUrl}
        style={{ cursor: "zoom-in" }}
        className={"border-0 mh-100"}
        onKeyDown={handleKeyDown}
        onClick={() => {
          setShownInModal(true);
          onClick();
        }}
        {...props}
      />
      <ImageFullView
        show={showInModal}
        setShow={setShownInModal}
        imageUrl={imageUrl}
      />
    </>
  );
}

ImagePreview.defaultProps = {
  onClick: noop,
};

ImagePreview.propTypes = {
  imageUrl: PropTypes.string,
  onClick: PropTypes.func,
};
export default ImagePreview;
