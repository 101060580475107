import { createSelector } from "reselect";
import { EditPhoneSliceSelectors } from "./slice";

export const getVerifyPhoneValues = createSelector(
  EditPhoneSliceSelectors.getValues,
  (values) => values?.phone
);
export const getVerifyPinValues = createSelector(
  EditPhoneSliceSelectors.getValues,
  (values) => values?.pin
);
