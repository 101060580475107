import { useCallback, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Field, useForm, useFormState } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import classNames from "classnames";
import PropTypes from "prop-types";
import { noop } from "lodash";

import {
  ACCEPT_RESPONSIBILITY,
  ACCEPT_SAFE_PLACE_IMAGE_HINT,
  ADD_SAFE_PLACE_PHOTO,
  ADD_SAFE_PLACE_PHOTO_AFTER_SELECTION,
  FRONT_PORCH,
  GARAGE,
  OTHER_SAFE_PLACE,
  OTHER_SAFE_PLACE_EXAMPLE,
  REAR_PORCH,
  OUTBUILDING,
  YOUR_SAFE_PLACE_IMAGE,
} from "../../../constants/strings";
import FrontPorch from "../../atoms/icons/FrontPorch";
import Garage from "../../atoms/icons/Garage";
import Shed from "../../atoms/icons/Shed";
import RearPorch from "../../atoms/icons/RearPorch";
import SafePlaceCard from "../../molecules/SafePlaceCard/SafePlaceCard";
import Input from "../../atoms/Input";
import Checkbox from "../../atoms/Checkbox/";
import { Fields } from "../../../constants/forms";
import {
  ACCEPT_IMAGE_MIME_TYPE,
  MAX_FILE_SIZE,
  SAFE_PLACE_CODE,
} from "../../../constants/safePlace";
import { Validators } from "../../../utils";

import styles from "./SafePlace.module.scss";
import ImageDropzone from "../../molecules/ImageDropzone";

const SafePlace = ({
  classes = {},
  options,
  onLoadImageError,
  onUploadClick,
  onDeleteClick,
  onImagePreviewClick,
}) => {
  const form = useForm();
  const formState = useFormState();
  const formValues = formState.values;

  useEffect(() => {
    form.initialize({
      ...formValues,
      [Fields.SAFE_PLACE_RESPONSIBILITY]: false,
    });
  }, []);

  const handleSafePlaceSelect = useCallback(
    (value) => {
      form.change(Fields.SAFE_PLACE_RESPONSIBILITY, false);

      if (value && value !== SAFE_PLACE_CODE.OTHER) {
        form.change(Fields.SAFE_PLACE_CUSTOM, null);
        form.blur(Fields.SAFE_PLACE_RESPONSIBILITY);
      } else if (!value) {
        form.change(Fields.IMAGE_URL, null);
      }
    },
    [form]
  );

  const handleOtherSafePlaceChange = useCallback(
    (value, prev) => {
      if (value && Validators.isNonEmptyString(value)) {
        form.change(Fields.SAFE_PLACE_CODE, SAFE_PLACE_CODE.OTHER);
        form.blur(Fields.SAFE_PLACE_RESPONSIBILITY);
      } else if (value !== null && prev) {
        form.change(Fields.SAFE_PLACE_CODE, null);
      }

      form.change(Fields.SAFE_PLACE_RESPONSIBILITY, false);
    },
    [form]
  );

  const handleResponsibilityChange = useCallback(
    (value) => {
      if (value) {
        form.blur(Fields.SAFE_PLACE_CODE);
        form.blur(Fields.SAFE_PLACE_CUSTOM);
      }
    },
    [form]
  );

  const handleLoadImageError = useCallback(
    (err) => {
      if (onLoadImageError) {
        onLoadImageError(err, form);
      }
    },
    [form, onLoadImageError]
  );

  return (
    <Container
      fluid
      className={classNames(styles.container, classes.container)}
    >
      <Field name={Fields.SAFE_PLACE_CODE}>
        {({ input, meta: { error, touched } }) => (
          <Row className="g-lg-3 g-0">
            {options.map((option, index) => (
              <Col
                key={index}
                xs={12}
                md={6}
                lg={3}
                className={classNames(
                  "d-flex justify-content-center mb-4 gx-sm-3",
                  classes.optionCol
                )}
              >
                <SafePlaceCard
                  label={option.label}
                  icon={option.icon}
                  selected={input?.value === option.value}
                  className={classNames(
                    "text-md-center p-2",
                    error && touched && "border border-danger"
                  )}
                  onSelect={(selected) => {
                    if (selected) {
                      input.onChange(null);
                    } else {
                      input.onChange(option.value);
                    }
                  }}
                />
              </Col>
            ))}
          </Row>
        )}
      </Field>
      <OnChange name={Fields.SAFE_PLACE_CODE}>
        {(value) => handleSafePlaceSelect(value)}
      </OnChange>
      <Row className="g-0">
        <Col>
          <Field
            name={Fields.SAFE_PLACE_CUSTOM}
            component={Input}
            label={OTHER_SAFE_PLACE}
            placeholder={OTHER_SAFE_PLACE_EXAMPLE}
            maxLength={250}
            formatOnBlur
            format={(value) => (value ? value.trim() : null)}
            validate={Validators.composeValidators(
              Validators.notEmpty,
              Validators.maxLength(250)
            )}
          />
          <OnChange name={Fields.SAFE_PLACE_CUSTOM}>
            {(value, previous) => handleOtherSafePlaceChange(value, previous)}
          </OnChange>
        </Col>
      </Row>
      <Row className="g-0">
        <Col>
          <Field
            name={Fields.IMAGE_URL}
            component={ImageDropzone}
            accept={ACCEPT_IMAGE_MIME_TYPE}
            maxSize={MAX_FILE_SIZE} // 10Mb
            title={YOUR_SAFE_PLACE_IMAGE}
            hint={ACCEPT_SAFE_PLACE_IMAGE_HINT}
            placeholder={
              !!formValues[Fields.SAFE_PLACE_CODE]
                ? ADD_SAFE_PLACE_PHOTO
                : ADD_SAFE_PLACE_PHOTO_AFTER_SELECTION
            }
            disabled={!formValues[Fields.SAFE_PLACE_CODE]}
            onLoadImageError={handleLoadImageError}
            onUploadClick={onUploadClick}
            onDeleteClick={onDeleteClick}
            onImagePreviewClick={onImagePreviewClick}
          />
        </Col>
      </Row>
      <Row className="g-0">
        <Col className="d-flex justify-content-center text-center">
          <Field
            name={Fields.SAFE_PLACE_RESPONSIBILITY}
            component={Checkbox}
            label={ACCEPT_RESPONSIBILITY}
            type="checkbox"
            classes={{ error: styles.termsError }}
          />
          <OnChange name={Fields.SAFE_PLACE_RESPONSIBILITY}>
            {(value) => handleResponsibilityChange(value)}
          </OnChange>
        </Col>
      </Row>
    </Container>
  );
};

SafePlace.defaultProps = {
  options: [
    {
      label: FRONT_PORCH,
      icon: FrontPorch,
      value: SAFE_PLACE_CODE.FRONT_PORCH,
    },
    { label: GARAGE, icon: Garage, value: SAFE_PLACE_CODE.GARAGE },
    { label: OUTBUILDING, icon: Shed, value: SAFE_PLACE_CODE.SHED },
    { label: REAR_PORCH, icon: RearPorch, value: SAFE_PLACE_CODE.REAR_PORCH },
  ],
  onUploadClick: noop,
  onDeleteClick: noop,
  onImagePreviewClick: noop,
};

SafePlace.propTypes = {
  classes: PropTypes.object,
  onLoadImageError: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      icon: PropTypes.func,
      value: PropTypes.string,
    })
  ),
  onUploadClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onImagePreviewClick: PropTypes.func,
};

export default SafePlace;
