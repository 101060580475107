import React, { useCallback, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { EventType } from "@dpdgroupuk/fetch-client";
import { bindActionCreators } from "redux";

import { AuthContext } from "./Context";
import * as Actions from "./actions";
import { useOverlay } from "../Overlay";
import { api } from "../../apis";
import { useToaster } from "../Toaster";
import Sentry, { getSentryUser } from "../../sentry";

const AuthProvider = (props) => {
  const toaster = useToaster();
  const dispatch = useDispatch();
  const overlay = useOverlay();
  const { isLoading, currentSession } = useSelector((state) => state.auth);

  const value = useMemo(() => {
    const actions = bindActionCreators(Actions, dispatch);
    return {
      ...actions,
      currentSession,
    };
  }, [dispatch, currentSession]);

  React.useEffect(() => {
    overlay.show();
    dispatch(Actions.getOrCreateSession())
      .unwrap()
      .then((session) => {
        Sentry.setUser(getSentryUser(session));
      })
      .catch((err) => {
        if (err.code === "auth/account-exists-with-different-credential") {
          toaster.showError({
            body: "Sorry, we couldn't authenticate the account you've tried to sign in with. Please try another account.",
          });
        }
      })
      .finally(() => {
        overlay.hide();
      });
  }, []);

  const handleErrorListener = useCallback(
    (error) => {
      if (error.statusCode === 401) {
        toaster.showError({
          body: error.errors?.[0]?.message || error.message,
          autoHide: true,
          onClose: () => {
            window.location.reload();
          },
        });
      }
    },
    [toaster]
  );

  useEffect(() => {
    api.on(EventType.Error, handleErrorListener);
    return () => api.off(EventType.Error, handleErrorListener);
  }, []);

  return (
    <AuthContext.Provider value={value}>
      {isLoading ? null : props.children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export default AuthProvider;
