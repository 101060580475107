import React, { useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./Backdrop.module.scss";

function Backdrop({ className, position, open, children }) {
  useEffect(() => {
    if (open && position === "fixed") {
      document.body.classList.add("overflow-hidden");
    }
    return () => document.body.classList.remove("overflow-hidden");
  }, [position, open]);
  return (
    <>
      {open && (
        <div
          className={classNames(
            styles.backdrop,
            className,
            `position-${position}`
          )}
        >
          {children}
        </div>
      )}
    </>
  );
}

Backdrop.propTypes = {
  open: PropTypes.bool,
  position: PropTypes.oneOf(["absolute", "fixed"]),
  className: PropTypes.string,
  children: PropTypes.node,
};

Backdrop.defaultProps = {
  open: false,
  position: "absolute",
};

export default Backdrop;
