import PropTypes from "prop-types";

const Step = ({ component: Component, ...wizardProps }) => (
  <Component {...wizardProps} />
);

Step.propTypes = {
  component: PropTypes.elementType,
};

export default Step;
