import * as What3Words from "@what3words/react-components"; /* global what3words */
import { WHAT_3_WORDS_LANGUAGE } from "../../constants/pinPointLocation";

const { REACT_APP_WHAT_3_WORDS_KEY } = process.env;

global.What3Words = What3Words;

export const convertCoordinatesToWord = (lng, lat) => {
  what3words.api.setApiKey(REACT_APP_WHAT_3_WORDS_KEY);

  return what3words.api.convertTo3wa(
    { coordinates: { lat, lng } },
    WHAT_3_WORDS_LANGUAGE
  );
};

export const convertWordsToCoordinates = (words) => {
  what3words.api.setApiKey(REACT_APP_WHAT_3_WORDS_KEY);

  return what3words.api.convertToCoordinates({ words }, WHAT_3_WORDS_LANGUAGE);
};

export const getLngLatCenterInSquare = ({ northeast, southwest }) => ({
  lng: (northeast.lng + southwest.lng) / 2,
  lat: (northeast.lat + southwest.lat) / 2,
});

export const mapBoxBoundToWhat3WordsBoundContext = ({ southwest, northeast }) =>
  [southwest.lat, southwest.lng, northeast.lat, northeast.lng].join(",");
