import { noop } from "lodash";
import { Col } from "react-bootstrap";
import { Field } from "react-final-form";
import PropTypes from "prop-types";
import { OnChange, OnFocus } from "react-final-form-listeners";
import { Fields } from "../../../constants/forms";
import Input from "../../atoms/Input";
import * as M from "../../../constants/strings";
import { Dropdown } from "../../atoms/Dropdown";
import AddressNotFound from "../../molecules/AddressNotFound";
import * as Validators from "../../../utils/validators";
import * as Formatters from "../../../utils/formatters";
import * as AddressModel from "../../../models/address";
import { AddressPropType } from "../../molecules/AddressView";
import { compareStrings } from "../../../utils/string";

const AddressFields = ({
  addresses = [],
  onChangePostcode,
  form,
  onFocusAddress,
  onSelectAddress,
  onCantFindAddress,
}) => {
  return (
    <>
      <Col xs={12} sm={12}>
        <Field
          label={M.POSTCODE}
          name={Fields.POSTCODE}
          component={Input}
          maxLength={8}
          required
          format={Formatters.formatPostcode}
          validate={Validators.composeValidators(
            Validators.required,
            Validators.postcode
          )}
          formatOnBlur
        />
        <OnChange name={Fields.POSTCODE}>
          {(value, prevValue) =>
            (!compareStrings(value, prevValue) || !addresses.length) &&
            onChangePostcode(value, form)
          }
        </OnChange>
      </Col>
      <Col xs={12}>
        <Field
          name={Fields.UDPRN}
          component={Dropdown}
          values={addresses.map(AddressModel.formatAddressOption)}
          label={M.SELECT_ADDRESS}
          placeholder={M.SELECT_ADDRESS}
          disabled={!addresses.length}
          validate={Validators.required}
          required
        />
        <OnFocus name={Fields.UDPRN}>{onFocusAddress}</OnFocus>
        <OnChange name={Fields.UDPRN}>{onSelectAddress}</OnChange>
      </Col>
      <Col className={"d-flex justify-content-end"}>
        <AddressNotFound onCantFindAddress={onCantFindAddress} />
      </Col>
    </>
  );
};

AddressFields.defaultProps = {
  addresses: [],
  onFocusAddress: noop,
  onSelectAddress: noop,
};

AddressFields.propTypes = {
  form: PropTypes.any,
  onChange: PropTypes.func,
  addresses: PropTypes.arrayOf(AddressPropType),
  onChangePostcode: PropTypes.func,
  onCantFindAddress: PropTypes.func,
};

export default AddressFields;
