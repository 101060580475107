import classNames from "classnames";
import PropTypes from "prop-types";

import Checkmark from "../../atoms/icons/Checkmark";
import IconWithLabel from "../../atoms/IconWithLabel/IconWithLabel";
import styles from "./WizardNavItem.module.scss";

const WizardNavItem = ({ icon, valid, label, active, disabled }) => {
  // TODO: Clarify whether this item should be button
  return (
    <div
      className={classNames(
        styles.container,
        active && styles.active,
        valid && styles.valid,
        disabled && styles.disabled
      )}
    >
      {valid && !disabled && <Checkmark className={styles.checkmark} />}
      <IconWithLabel
        icon={icon}
        label={label}
        customStyles={{
          icon: styles.icon,
          label: styles.label,
          container: styles.iconWithLabel,
        }}
      />
    </div>
  );
};

WizardNavItem.propTypes = {
  icon: PropTypes.func,
  valid: PropTypes.bool,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

export default WizardNavItem;
