import { useCallback, useState } from "react";
import Dropzone from "react-dropzone";
import { Col, Container, Row } from "react-bootstrap";
import classNames from "classnames";
import { noop } from "lodash";

import PropTypes from "prop-types";
import FieldContainer from "../../atoms/FieldContainer";
import ImagePreview from "../ImageViewer";
import DropBox from "./DropBox";
import CropperView from "../CropperView";
import styles from "./DropzonePreview.module.scss";
import ToolbarPanel from "./ToolbarPanel";

function ImageDropzone({
  title,
  onSelect,
  input,
  meta,
  Toolbar,
  onLoadImageError,
  onUploadClick,
  onDeleteClick,
  onImagePreviewClick,
  ...props
}) {
  const [isCropperModalOpened, setCropperModalOpened] = useState(false);

  const handleCropComplete = useCallback(
    (cropper) => {
      const base64 = cropper.getCroppedCanvas().toDataURL("image/jpeg", 1);
      input.onChange(base64);
    },
    [input]
  );

  return (
    <Dropzone {...props} onDropAccepted={() => setCropperModalOpened(true)}>
      {(state) => {
        return (
          <>
            <FieldContainer input={input} meta={meta}>
              <Container fluid className="g-0">
                <Row className={"mb-4 g-0"}>
                  <Col
                    className={classNames(
                      "d-flex justify-content-center rounded text-center p-3 px-md-4",
                      styles.dropzone
                    )}
                  >
                    {input.value ? (
                      <ImagePreview
                        onError={onLoadImageError}
                        imageUrl={
                          input.value instanceof Blob
                            ? URL.createObjectURL(input.value)
                            : input.value
                        }
                        className={styles.image}
                        onClick={onImagePreviewClick}
                      />
                    ) : (
                      <DropBox {...props} {...state} />
                    )}
                  </Col>
                </Row>
                {Toolbar && (
                  <Toolbar
                    input={input}
                    dropzoneState={state}
                    onDelete={() => {
                      input.onChange(null);
                      onDeleteClick();
                    }}
                    onUpload={() => {
                      state.open();
                      onUploadClick();
                    }}
                    {...props}
                  />
                )}
              </Container>
            </FieldContainer>
            {isCropperModalOpened && state.acceptedFiles.length && (
              <CropperView
                title={title}
                src={URL.createObjectURL(state.acceptedFiles[0])}
                show={isCropperModalOpened}
                setShow={setCropperModalOpened}
                onCropComplete={handleCropComplete}
              />
            )}
          </>
        );
      }}
    </Dropzone>
  );
}

ImageDropzone.Toolbar = ToolbarPanel;

ImageDropzone.defaultProps = {
  accept: { "image/*": [] },
  multiple: false,
  Toolbar: ToolbarPanel,
  onUploadClick: noop,
  onDeleteClick: noop,
  onImagePreviewClick: noop,
};

ImageDropzone.propTypes = {
  title: PropTypes.string,
  onLoadImageError: PropTypes.func,
  onUploadClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onImagePreviewClick: PropTypes.func,
  ...Dropzone.propTypes,
};
export default ImageDropzone;
