import PropTypes from "prop-types";

import styles from "./Wizard.module.scss";

export const Step = ({ children, isActive, transitions }) => (
  <div
    className={`${styles.step} ${transitions} ${
      isActive ? styles.active : ""
    }`.trim()}
  >
    {children}
  </div>
);

Step.propTypes = {
  children: PropTypes.node,
  isActive: PropTypes.bool,
  transitions: PropTypes.string,
};

Step.defaultProps = {
  children: [],
  isActive: false,
  transitions: "",
};

export default Step;
