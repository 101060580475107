import { Col, Container, Row } from "react-bootstrap";
import { noop } from "lodash";
import { Trackable } from "@dpdgroupuk/react-event-tracker";
import DeliveryAddressCard from "../../molecules/DeliveryAddressCard";
import { AddressPropType } from "../../molecules/AddressView";
import PropTypes from "prop-types";
import AddressFields from "./AddressFields";
import {
  CONFIRM_DELIVERY_ADDRESS_ONBOARDING,
  DELIVERY_ADDRESS_ONBOARDING,
} from "../../../constants/analytics";

const DeliveryAddress = ({
  udprn,
  addresses,
  onChangePostcode,
  form,
  onEdit,
  editMode,
  onFocusAddress,
  onSelectAddress,
  onCantFindAddress,
}) => {
  return (
    <Container>
      {!editMode && (
        <Trackable
          loadId={CONFIRM_DELIVERY_ADDRESS_ONBOARDING.LOAD}
          interfaceId={CONFIRM_DELIVERY_ADDRESS_ONBOARDING.INTERFACE_ID}
        >
          <Row>
            <Col xs={12} sm={12} className={"d-flex justify-content-center"}>
              <DeliveryAddressCard
                onEdit={onEdit}
                address={addresses.find((a) => a.udprn === udprn)}
              />
            </Col>
          </Row>
        </Trackable>
      )}
      {editMode && (
        <Trackable
          loadId={DELIVERY_ADDRESS_ONBOARDING.LOAD}
          interfaceId={DELIVERY_ADDRESS_ONBOARDING.INTERFACE_ID}
        >
          <Row>
            <AddressFields
              form={form}
              addresses={addresses}
              onChangePostcode={onChangePostcode}
              onFocusAddress={onFocusAddress}
              onSelectAddress={onSelectAddress}
              onCantFindAddress={onCantFindAddress}
            />
          </Row>
        </Trackable>
      )}
    </Container>
  );
};

DeliveryAddress.defaultProps = {
  addresses: [],
  onEdit: noop,
};

DeliveryAddress.propTypes = {
  udprn: PropTypes.string,
  onChange: PropTypes.func,
  addresses: PropTypes.arrayOf(AddressPropType),
  onChangePostcode: PropTypes.func,
  onEdit: PropTypes.func,
  editMode: PropTypes.bool,
  onCantFindAddress: PropTypes.func,
};

export default DeliveryAddress;
