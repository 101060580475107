import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Trackable, useTracker } from "@dpdgroupuk/react-event-tracker";
import NavigationBar from "../../../components/molecules/WizardNavigationBar/WizardNavigationBar";
import ConfirmDetails from "../../../components/organisms/OnboardingConfirmDetails";
import { WizardForm } from "../../../features/Wizard";
import * as M from "../../../constants/strings";
import { FIRST_DELIVERY_ATTEMPT_CODE, STEP_NAME } from "../../../constants";
import Template from "../../../components/templates/OnboardingStepContainer";
import { getConfirmDetailsValid, getConfirmDetailsValues } from "../selectors";
import {
  ConsumerAddressModel,
  ConsumerEmailModel,
  ConsumerModel,
  ProfileActions,
} from "../../../features/Profile";
import { AuthModel, useAuth } from "../../../features/Auth";
import { Paths } from "../../../router";
import { useOverlay } from "../../../features/Overlay";
import { useToaster } from "../../../features/Toaster";
import OnboardingTemplate from "../../../components/templates/Onboarding/Onboarding";
import { CONFIRM_DETAILS_ONBOARDING } from "../../../constants/analytics";

const ConfirmDetailsStep = ({ goToNamedStep, setValues, values }) => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const overlay = useOverlay();
  const toaster = useToaster();
  const navigate = useNavigate();
  const data = useSelector(getConfirmDetailsValues);
  const tracker = useTracker();
  const isConfirmDetailsValid = useSelector(getConfirmDetailsValid);

  const handleReloadSession = useCallback(async () => {
    try {
      values.toastId && toaster.removeToast(values.toastId);
      overlay.show();
      await auth.reloadSession().unwrap();
    } finally {
      overlay.hide();
    }
  }, [toaster, values.toastId, auth, overlay]);

  const handleSubmit = useCallback(
    async (formData) => {
      tracker.logEvent(CONFIRM_DETAILS_ONBOARDING.ON_CONFIRM);

      try {
        if (!isConfirmDetailsValid) {
          // eslint-disable-next-line no-throw-literal
          throw {
            message: M.PLEASE_REVIEW_MANDATORY_FIELDS,
          };
        }

        overlay.show();
        const entities = {};

        const personalInformation = data.personalInformation;
        entities.consumer = ConsumerModel.createConsumerModel(
          auth.currentSession,
          personalInformation
        );
        entities.address = ConsumerAddressModel.createAddressModel({
          ...data,
          confirmDetails: formData,
        });

        if (personalInformation.email) {
          entities.email = ConsumerEmailModel.createEmailModel(
            personalInformation.email,
            AuthModel.isEmailAccountVerified(
              auth.currentSession,
              personalInformation.email
            )
          );
        }

        await dispatch(
          ProfileActions.createProfile([auth.currentSession.uid, entities])
        ).unwrap();
        // reload user session to switch from PHONE_ACCOUNT to CONSUMER_ACCOUNT
        await auth.reloadSession().unwrap();

        if (entities.email && !entities.email.verified) {
          await auth
            .sendVerifyEmail({
              email: personalInformation.email,
              id: entities.email.id,
            })
            .unwrap();
          toaster.showInfo({
            body: M.WE_HAVE_SENT_VERIFICATION_EMAIL_TO_$(entities.email.email),
          });
        }

        navigate(Paths.PROFILE_ACCOUNT, {
          replace: true,
        });
      } catch (e) {
        const toastId = toaster.showError({
          body: e.message,
          reload: e.reload,
          onReload: handleReloadSession,
        });
        setValues({ toastId });
      } finally {
        overlay.hide();
      }
    },
    [
      data,
      auth,
      toaster,
      dispatch,
      overlay,
      handleReloadSession,
      navigate,
      setValues,
      tracker,
      isConfirmDetailsValid,
    ]
  );

  const handleGoToNamedStep = useCallback(
    (step) => {
      let actionId;

      switch (step) {
        case STEP_NAME.DELIVERY_ADDRESS: {
          actionId = CONFIRM_DETAILS_ONBOARDING.ON_DELIVERY_ADDRESS;
          break;
        }
        case STEP_NAME.NEIGHBOURS: {
          actionId = CONFIRM_DETAILS_ONBOARDING.ON_PREFERRED_NEIGHBOUR;
          break;
        }
        case STEP_NAME.PERSONAL_INFORMATION: {
          actionId = CONFIRM_DETAILS_ONBOARDING.ON_PERSONAL_INFO;
          break;
        }
        case STEP_NAME.PICKUP_POINT: {
          actionId = CONFIRM_DETAILS_ONBOARDING.ON_PICKUP_POINT;
          break;
        }
        case STEP_NAME.PINPOINT_LOCATION: {
          actionId = CONFIRM_DETAILS_ONBOARDING.ON_YOUR_LOCATION;
          break;
        }
        case STEP_NAME.SAFE_PLACE: {
          actionId = CONFIRM_DETAILS_ONBOARDING.ON_SAFE_PLACE;
          break;
        }
        default: {
          actionId = "";
        }
      }

      tracker.logEvent(actionId);

      goToNamedStep(step);
    },
    [goToNamedStep, tracker]
  );

  return (
    <Trackable
      interfaceId={CONFIRM_DETAILS_ONBOARDING.INTERFACE_ID}
      loadId={CONFIRM_DETAILS_ONBOARDING.LOAD}
    >
      <OnboardingTemplate>
        <WizardForm
          onSubmit={handleSubmit}
          Nav={NavigationBar}
          initialValues={{
            firstDeliveryAttempt: FIRST_DELIVERY_ATTEMPT_CODE.HOME,
            ...values,
          }}
        >
          <Template
            fluid
            title={M.CONFIRM_DETAILS}
            classes={{ title: "mb-3 mb-lg-0" }}
          >
            <ConfirmDetails data={data} onEditClick={handleGoToNamedStep} />
          </Template>
        </WizardForm>
      </OnboardingTemplate>
    </Trackable>
  );
};

export default ConfirmDetailsStep;
