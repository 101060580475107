export * as S from "./strings";
export * as F from "./forms";
export * as REGEX from "./regex";
export * from "./address";
export * from "./errorCodes";
export * from "./stepNames";
export * from "./platform";

export const FIRST_DELIVERY_ATTEMPT_CODE = {
  HOME: "HOME",
  PICKUP: "PICKUP",
};

export const COUNTRY_CODE = {
  UK: "+44",
};
