import ReactTimeAgo from "react-time-ago";
import { Toast } from "react-bootstrap";
import PropTypes from "prop-types";
import DpdIcon from "../../atoms/icons/Dpd";
import * as M from "../../../constants/strings";

const DpdToast = ({ bg, delay, date, autoHide, title, children, onClose }) => {
  return (
    <Toast
      bg={bg}
      onClose={onClose}
      show={true}
      delay={delay}
      autohide={autoHide}
      className="d-block m-1"
    >
      <Toast.Header>
        <DpdIcon className={"rounded me-2"} />
        <strong className="me-auto">{title}</strong>
        <small>
          <ReactTimeAgo date={date} locale="en-US" />
        </small>
      </Toast.Header>
      <Toast.Body>{children}</Toast.Body>
    </Toast>
  );
};

DpdToast.defaultProps = {
  delay: 5000,
  animation: true,
  autoHide: false,
  title: M.DPD_PREFERENCES,
};

DpdToast.propTypes = {
  bg: PropTypes.oneOf([
    "primary",
    "secondary",
    "success",
    "danger",
    "warning",
    "info",
    "light",
  ]),
  delay: PropTypes.number,
  date: PropTypes.number,
  autoHide: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func,
};

export default DpdToast;
