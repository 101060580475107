import * as Service from "./service";
import { createFirebaseAsyncThunk, ENTITY } from "../../ErrorBoundary";

export const getEmails = createFirebaseAsyncThunk(
  "profile/emails/get",
  Service.getEmails,
  ENTITY.EMAIL
);

export const getEmail = createFirebaseAsyncThunk(
  "profile/emails/getOne",
  ([uid, id, transaction]) => Service.getEmail(uid, id, { transaction }),
  ENTITY.EMAIL
);

export const updateEmail = createFirebaseAsyncThunk(
  "profile/emails/update",
  ([uid, email, transaction]) =>
    Service.updateEmail(uid, email, { transaction }),
  ENTITY.EMAIL
);

export const addEmail = createFirebaseAsyncThunk(
  "profile/emails/create",
  ([uid, email, transaction]) => Service.addEmail(uid, email, { transaction }),
  ENTITY.EMAIL
);

export const deleteEmail = createFirebaseAsyncThunk(
  "profile/emails/delete",
  async ([id, uid, transaction]) =>
    Service.deleteEmail(uid, id, { transaction }),
  ENTITY.EMAIL
);
