import FrontPorch from "../components/atoms/icons/FrontPorch";
import Garage from "../components/atoms/icons/Garage";
import RearPorch from "../components/atoms/icons/RearPorch";
import Shed from "../components/atoms/icons/Shed";
import { FRONT_PORCH, GARAGE, REAR_PORCH, OUTBUILDING } from "./strings";

export const SAFE_PLACE_CODE = {
  FRONT_PORCH: "FPC",
  GARAGE: "GAR",
  SHED: "OBL",
  REAR_PORCH: "RPC",
  OTHER: "OTH",
};

export const SAFE_PLACE_UI_BY_CODE = {
  [SAFE_PLACE_CODE.FRONT_PORCH]: {
    icon: FrontPorch,
    label: FRONT_PORCH,
  },
  [SAFE_PLACE_CODE.GARAGE]: {
    icon: Garage,
    label: GARAGE,
  },
  [SAFE_PLACE_CODE.SHED]: {
    icon: Shed,
    label: OUTBUILDING,
  },
  [SAFE_PLACE_CODE.REAR_PORCH]: {
    icon: RearPorch,
    label: REAR_PORCH,
  },
};

export const ACCEPT_IMAGE_MIME_TYPE = {
  "image/png": [],
  "image/jpeg": [],
  "image/webp": [],
  "image/bmp": [],
};

export const MAX_FILE_SIZE = 10485760;
