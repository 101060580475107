const Direction = (props) => (
  <svg
    width="1.1875rem" // 19px
    height="1.125rem" // 18px
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6 11.625H7.75V9H10.8125V11.1875L13.875 8.125L10.8125 5.0625V7.25H6.875C6.62708 7.25 6.41942 7.33371 6.252 7.50112C6.084 7.66912 6 7.87708 6 8.125V11.625ZM9.5 17.75C9.28125 17.75 9.066 17.7063 8.85425 17.6187C8.64308 17.5312 8.45 17.4 8.275 17.225L1.275 10.225C1.1 10.05 0.96875 9.85692 0.88125 9.64575C0.79375 9.434 0.75 9.21875 0.75 9C0.75 8.78125 0.79375 8.566 0.88125 8.35425C0.96875 8.14308 1.1 7.95 1.275 7.775L8.275 0.775C8.45 0.6 8.64308 0.46875 8.85425 0.38125C9.066 0.29375 9.28125 0.25 9.5 0.25C9.71875 0.25 9.934 0.29375 10.1458 0.38125C10.3569 0.46875 10.55 0.6 10.725 0.775L17.725 7.775C17.9 7.95 18.0312 8.14308 18.1187 8.35425C18.2063 8.566 18.25 8.78125 18.25 9C18.25 9.21875 18.2063 9.434 18.1187 9.64575C18.0312 9.85692 17.9 10.05 17.725 10.225L10.725 17.225C10.55 17.4 10.3569 17.5312 10.1458 17.6187C9.934 17.7063 9.71875 17.75 9.5 17.75ZM9.5 16L16.5 9L9.5 2L2.5 9L9.5 16Z"
      fill="currentColor"
    />
  </svg>
);

export default Direction;
