export const SIGN_IN = "/signIn";
export const VERIFY_EMAIL = `${SIGN_IN}/email`;
export const CREATE_PROFILE = "/createProfile";
export const SIGN_UP = "/signUp";
export const PROFILE = "/profile";
export const PROFILE_ACCOUNT = `${PROFILE}/account`;
export const PROFILE_PERSONAL_INFO = `${PROFILE}/personal`;

export const PROFILE_PERSONAL_EDIT_PHONE = `${PROFILE}/personal/editPhone`;
export const PROFILE_ADDRESSES = `${PROFILE}/addresses`;
export const PROFILE_CONTACT = `${PROFILE}/contact`;
export const PROFILE_ADDRESS = `${PROFILE}/addresses/:udprn`;
export const PROFILE_ADDRESS_TYPE = `${PROFILE}/addresses/:udprn/type`;
export const PROFILE_ADDRESS_PINPOINT = `${PROFILE}/addresses/:udprn/pinpoint`;
export const PROFILE_ADDRESS_NOTE = `${PROFILE}/addresses/:udprn/note`;
export const PROFILE_ADDRESS_PHOTO = `${PROFILE}/addresses/:udprn/photo`;
export const PROFILE_ADDRESS_NEIGHBOURS = `${PROFILE}/addresses/:udprn/neighbours`;
export const PROFILE_ADDRESS_SAFE_PLACE = `${PROFILE}/addresses/:udprn/safePlace`;
export const PROFILE_ADDRESS_PICKUP = `${PROFILE}/addresses/:udprn/pickup`;
