import { ASYNC_REQUEST_ABORTED } from "../constants/strings";

export const asyncTimeout = (ms) => new Promise((res) => setTimeout(res, ms));

export const createAbortAsyncController = () => {
  let controller = new AbortController();

  return async (promise) => {
    controller.abort();
    controller = new AbortController();

    const nextController = controller;

    const rs = await promise;

    if (nextController.signal.aborted) {
      throw new Error(ASYNC_REQUEST_ABORTED);
    }

    return rs;
  };
};

export const isAsyncRequestAbort = (error) =>
  error.message === ASYNC_REQUEST_ABORTED;
