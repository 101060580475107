import { findIndex, merge } from "lodash";
import { createReducer } from "@reduxjs/toolkit";
import {
  addEmail,
  deleteEmail,
  getEmails,
  getEmail,
  updateEmail,
} from "./actions";
import { ConsumerActions } from "../Consumer";

const initialState = [];

const emailsReducer = createReducer(initialState, {
  [getEmails.fulfilled]: (state, { payload }) => payload,
  [getEmail.fulfilled]: (state, { payload }) => {
    const index = findIndex(state, { id: payload.id });
    state.splice(index, 1, payload);
    return state;
  },
  [addEmail.fulfilled]: (state, { payload }) => {
    state.push(payload);
    return state;
  },
  [updateEmail.fulfilled]: (state, { payload }) => {
    const index = findIndex(state, { id: payload.id });
    state.splice(index, 1, merge(state[index], payload));
    return state;
  },
  [deleteEmail.fulfilled]: (state, { payload }) => {
    const index = findIndex(state, { id: payload });
    state.splice(index, 1);
    return state;
  },
  [ConsumerActions.deleteConsumer.fulfilled]: (state) => initialState,
});

export default emailsReducer;
