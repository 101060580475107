import classNames from "classnames";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";

import Checkmark from "../../atoms/icons/Checkmark";
import CardLabelDetails from "../../atoms/CardLabelDetails";
import { SELECT, SELECTED } from "../../../constants/strings";
import ShadowFieldCard from "../../atoms/ShadowFieldCard";
import styles from "./SelectableCard.module.scss";

const SelectableCard = ({
  title,
  description,
  selected,
  disabled = false,
  onSelect,
  className,
}) => {
  return (
    <ShadowFieldCard
      variant={(disabled && "light") || (selected && "success")}
      className={classNames(className, styles.card)}
    >
      <CardLabelDetails
        title={title}
        className={"ms-2"}
        description={description}
      />
      {(!selected || disabled) && (
        <Button
          disabled={disabled}
          variant={disabled ? "secondary" : "outline-danger"}
          className={classNames(styles.button, "ms-4")}
          onClick={() => {
            onSelect(selected);
          }}
        >
          {SELECT}
        </Button>
      )}

      {selected && !disabled && (
        <div className="d-flex flex-nowrap ms-4">
          <div className="text-success d-flex align-items-center p-0 me-1 justify-content-end d-inline-flex">
            <span>{SELECTED}</span>
          </div>
          <div className="d-flex align-items-center p-0 ms-2 justify-content-end d-inline-flex">
            <Checkmark />
          </div>
        </div>
      )}
    </ShadowFieldCard>
  );
};

SelectableCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  onSelect: PropTypes.func,
  className: PropTypes.string,
};

export default SelectableCard;
