import React, { useCallback, useState, useRef, useMemo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Trackable, useTracker } from "@dpdgroupuk/react-event-tracker";

import MapBoxWhat3Words from "../../atoms/MapBoxWhat3Words";
import PinLocationsMap from "../../molecules/PinLocationsMap";
import {
  convertGridFromWhat3Words,
  drawCircleArea,
  drawGrid,
  drawSquare,
} from "../../../features/MapBox";
import {
  convertCoordinatesToWord,
  convertWordsToCoordinates,
  getLngLatCenterInSquare,
  mapBoxBoundToWhat3WordsBoundContext,
} from "../../../features/What3Words";
import What3WordsAutosuggest from "./Autosuggest";
import { MAX_POINT_DISTANCE } from "../../../constants/pinPointLocation";
import { Field } from "react-final-form";
import * as Validators from "../../../utils/validators";
import {
  asyncTimeout,
  createAbortAsyncController,
  isAsyncRequestAbort,
} from "../../../utils/async";

const { REACT_APP_WHAT_3_WORDS_KEY } = process.env;

/**
 *
 * @param boundingBox { southwest:{lat: <Number>, lng: <Number>}, northeast: {lat: <Number>, lng: <Number>}}
 * @returns {Promise<{features:[{geometry:{coordinates:*, type:string}, type:string, properties:{}}], type:string}>}
 */
const loadGrid = async (boundingBox) => {
  what3words.api.setApiKey(REACT_APP_WHAT_3_WORDS_KEY);
  const data = await what3words.api.gridSection({ boundingBox });

  if (data) {
    return convertGridFromWhat3Words(data.lines);
  }
};
const asyncAbort = createAbortAsyncController();

const What3WordsLocation = ({
  pinPointLocation,
  pointLocation,
  onChange,
  error,
  analytics = {},
}) => {
  const refMap = useRef();
  const [isPending, setIsPending] = useState(false);
  const [title, setTitle] = useState();
  const [w3wBox, setW3WBox] = useState({ loaded: false });
  const tracker = useTracker();

  useMemo(() => {
    if (w3wBox.words) {
      setTitle(w3wBox.words);
    }
    if (w3wBox.loaded && w3wBox.square) {
      drawSquare(refMap.current.getMap(), w3wBox.square);
    }
  }, [w3wBox]);

  const loadW3wData = useCallback(
    async (lngLat) => {
      setIsPending(true);
      try {
        const [dataBox] = await asyncAbort(
          Promise.all([
            convertCoordinatesToWord(lngLat.lng, lngLat.lat),
            asyncTimeout(500),
          ])
        );

        setW3WBox((values) => ({
          ...values,
          ...dataBox,
        }));

        onChange({ lngLat, what3words: dataBox.words });
      } catch (error) {
        if (!isAsyncRequestAbort(error)) {
          throw error;
        }
      }

      setIsPending(false);
    },
    [onChange]
  );

  const [what3WordsBoundBox, setWhat3WordsBoundBox] = useState("");

  useMemo(async () => {
    loadW3wData(pointLocation.lngLat);
  }, [loadW3wData, pointLocation.lngLat]);

  const onMapLoad = useCallback(
    async (event) => {
      const maxBound = event.target.getMaxBounds();
      const southwest = maxBound.getSouthWest();
      const northeast = maxBound.getNorthEast();
      const boundBox = {
        southwest: {
          lng: southwest.lng,
          lat: southwest.lat,
        },
        northeast: {
          lng: northeast.lng,
          lat: northeast.lat,
        },
      };

      setWhat3WordsBoundBox(mapBoxBoundToWhat3WordsBoundContext(boundBox));

      const grid = await loadGrid({
        northeast: boundBox.northeast,
        southwest: boundBox.southwest,
      });

      drawGrid(event.target, grid);
      drawCircleArea(event.target, pinPointLocation.lngLat, MAX_POINT_DISTANCE);
      setW3WBox((w3wBox) => ({
        ...w3wBox,
        loaded: true,
      }));
    },
    [setW3WBox, setWhat3WordsBoundBox, pinPointLocation.lngLat]
  );

  const onMapClick = useCallback(
    async (event) => {
      tracker.logEvent(analytics.ON_TILE_CLICK);

      onChange({
        lngLat: {
          lng: event.lngLat.lng,
          lat: event.lngLat.lat,
        },
        what3words: null,
      });
    },
    [onChange, tracker, analytics]
  );

  const onWhat3WordsSelected = useCallback(
    async (words) => {
      tracker.logEvent(analytics.ON_INPUT);

      const dataBox = await convertWordsToCoordinates(words);
      const lngLat = getLngLatCenterInSquare(dataBox.square);

      onChange({
        lngLat,
        what3words: words,
      });
    },
    [onChange, tracker, analytics]
  );

  return (
    <Trackable interfaceId={analytics.INTERFACE_ID} loadId={analytics.LOAD}>
      <Container fluid className="g-0">
        <Row>
          <Col xs={12}>
            <PinLocationsMap
              isPending={isPending}
              pointLocation={pointLocation}
              pinPointLocation={pinPointLocation}
              onChange={onChange}
              error={error}
              onClickMap={onMapClick}
              onLoadMap={onMapLoad}
              cursorMap="pointer"
              ref={refMap}
            ></PinLocationsMap>
          </Col>
          <Col xs={12} className="mb-3 mt-3">
            {title && <MapBoxWhat3Words title={title} />}
          </Col>
        </Row>
        <Row className="mt-1">
          <Col xs={12}>
            <div>
              <Field
                name={"what3words"}
                component={What3WordsAutosuggest}
                clipToBoundBox={what3WordsBoundBox}
                onChange={onWhat3WordsSelected}
                validate={Validators.mustBeValidWhat3Words}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </Trackable>
  );
};

export default What3WordsLocation;
