import { find, filter, isEmpty, get, pick, head, last, sortBy } from "lodash";

import * as M from "../constants/strings";
import { PICKUP_POINT_TYPE } from "../constants/pickupPoint";
import {
  getTimeMomentDate,
  isCurrentTimeBefore,
  isCurrentTimeAfter,
  weekdaysShort,
  getDayNameByShortName,
  getCurrentMomentDate,
  getCurrentDayNumber,
  getIsCurrentTimeBetween,
  getTimeString,
} from "./date";
import * as DATE_FORMAT from "../constants/dateFormat";

export const getPickupName = (pickupPoint) =>
  getPickupShortName(pickupPoint) ||
  get(getPickupAddress(pickupPoint), "organisation");

export const getPickupLocationCode = (pickupPoint) =>
  get(pickupPoint, "pickupLocation.pickupLocationCode");

export const getPickupShortName = (pickupPoint) =>
  get(pickupPoint, "pickupLocation.shortName");

export const getPickupAddress = (pickupPoint) =>
  get(pickupPoint, "pickupLocation.address");

export const getPickupAddressPoint = (pickupPoint) =>
  get(pickupPoint, "pickupLocation.addressPoint");

export const getOpenSaturday = (pickupPoint) =>
  get(pickupPoint, "pickupLocation.openSaturday");

export const getOpenSunday = (pickupPoint) =>
  get(pickupPoint, "pickupLocation.openSunday");

const getOpenWindowStart = (openWindow) =>
  get(openWindow, "pickupLocationOpenWindowStartTime");

const getOpenWindowEnd = (openWindow) =>
  get(openWindow, "pickupLocationOpenWindowEndTime");

export const getPickupKind = (pickupPoint) =>
  get(pickupPoint, "pickupLocation.kind");

export const getPartnerLogo = (pickupPoint) =>
  get(pickupPoint, "pickupLocation.partnerImageLogo");

export const getPickupType = (pickupPoint) => {
  const pickupLocationKind = getPickupKind(pickupPoint);

  switch (pickupLocationKind) {
    case PICKUP_POINT_TYPE.SHOP:
      return M.SHOP;
    case PICKUP_POINT_TYPE.LOCKER:
      return M.LOCKER;
    default:
      return M.SHOP;
  }
};

const getDayOpenWindows = (pickupPoint, dayNumber) => {
  const pickupLocationOpenWindow = get(
    pickupPoint,
    "pickupLocation.pickupLocationAvailability.pickupLocationOpenWindow",
    []
  );

  const openWindows = filter(pickupLocationOpenWindow, [
    "pickupLocationOpenWindowDay",
    dayNumber,
  ]);

  if (isEmpty(openWindows)) {
    return {};
  } else if (openWindows.length === 1) {
    const openWindow = head(openWindows);

    return {
      openTime: getOpenWindowStart(openWindow),
      closeTime: getOpenWindowEnd(openWindow),
    };
  } else {
    const beforeLunch = head(openWindows);
    const afterLunch = last(openWindows);

    const beforeLunchStart = getOpenWindowStart(beforeLunch);
    const beforeLunchEnd = getOpenWindowEnd(beforeLunch);
    const afterLunchStart = getOpenWindowStart(afterLunch);
    const afterLunchEnd = getOpenWindowEnd(afterLunch);

    const considerLunchBreak =
      beforeLunchEnd !== afterLunchStart &&
      getTimeMomentDate(beforeLunchEnd).isBefore(
        getTimeMomentDate(afterLunchStart)
      );

    return considerLunchBreak
      ? {
          openTime: beforeLunchStart,
          closeTime: afterLunchEnd,
          lunchStart: beforeLunchEnd,
          lunchEnd: afterLunchStart,
        }
      : { openTime: beforeLunchStart, closeTime: afterLunchEnd };
  }
};

const mergeOpenWindows = (openWindows) => {
  const getMergedOpenWindowObject = (mergedOpenSlots) => ({
    day: `${head(mergedOpenSlots).day} - ${last(mergedOpenSlots).day}`,
    openWindow: head(mergedOpenSlots).openWindow,
  });

  const mergedOpenWindowList = openWindows
    .reduce((groups, window) => {
      const groupDays = last(groups);

      if (!groupDays || last(groupDays).openWindow !== window.openWindow) {
        groups.push([window]);
      } else {
        groupDays.push(window);
      }
      return groups;
    }, [])
    .map((groupDays) => {
      if (groupDays.length > 1) {
        return getMergedOpenWindowObject(groupDays);
      } else {
        return head(groupDays);
      }
    });

  return mergedOpenWindowList;
};

const isPickupOpenAt = (pickupPoint, day) => {
  switch (day) {
    case "Saturday":
      return getOpenSaturday(pickupPoint);
    case "Sunday":
      return getOpenSunday(pickupPoint);
    default:
      return true;
  }
};

export const getPickupOpenWindowList = (pickupPoint) => {
  const openWindows = weekdaysShort.map((day, index) => {
    const dayOpenWindows = getDayOpenWindows(pickupPoint, index + 1);

    const isOpenDay =
      isPickupOpenAt(pickupPoint, getDayNameByShortName(day)) &&
      !isEmpty(dayOpenWindows);

    if (isOpenDay) {
      const { openTime, closeTime } = dayOpenWindows;

      return {
        day,
        openWindow: `${openTime} - ${closeTime}`,
      };
    } else {
      return {
        day,
        openWindow: M.CLOSED,
      };
    }
  });

  return mergeOpenWindows(openWindows);
};

const getNextOpenDayOpensStatus = (currentDayNumber, pickupPoint) => {
  const getDayNumber = (day) => get(day, "pickupLocationOpenWindowDay");
  const allDaysOpenWindows = get(
    pickupPoint,
    "pickupLocation.pickupLocationAvailability.pickupLocationOpenWindow"
  );

  if (isEmpty(allDaysOpenWindows)) {
    return "";
  }

  const nextOpenDay =
    find(allDaysOpenWindows, (day) => getDayNumber(day) > currentDayNumber) ||
    head(allDaysOpenWindows);
  const nextOpenDayNumber = getDayNumber(nextOpenDay);
  const { openTime } = getDayOpenWindows(pickupPoint, nextOpenDayNumber);

  return `${M.OPENS} ${weekdaysShort[nextOpenDayNumber - 1]} ${getTimeString(
    openTime
  )}`;
};

const getOpenStatusString = (status, time) =>
  `${status} ${getTimeString(time)}`;

export const getOpenStatusObject = (pickupPoint) => {
  const currentDate = getCurrentMomentDate();
  const currentDayName = currentDate.format(DATE_FORMAT.DAY);
  const currentDayNumber = getCurrentDayNumber();
  const currentDayOpenWindows = getDayOpenWindows(
    pickupPoint,
    currentDayNumber
  );

  const isOpenDay =
    isPickupOpenAt(pickupPoint, currentDayName) &&
    !isEmpty(currentDayOpenWindows);
  const { openTime, closeTime, lunchStart, lunchEnd } = currentDayOpenWindows;

  let openStatusObject = {};

  if (!isOpenDay || isCurrentTimeAfter(closeTime)) {
    openStatusObject = {
      isOpen: false,
      openStatus: getNextOpenDayOpensStatus(currentDayNumber, pickupPoint),
    };
  }

  if (isCurrentTimeBefore(openTime)) {
    openStatusObject = {
      isOpen: false,
      openStatus: getOpenStatusString(M.OPENS, openTime),
    };
  }

  if (getIsCurrentTimeBetween(openTime, closeTime)) {
    openStatusObject = {
      isOpen: true,
      openStatus: getOpenStatusString(M.CLOSES, closeTime),
    };
  }

  if (getIsCurrentTimeBetween(lunchStart, lunchEnd)) {
    openStatusObject = {
      isOpen: false,
      openStatus: M.CLOSED_FOR_LUNCH,
    };
  }

  if (lunchStart && lunchEnd) {
    openStatusObject = {
      ...openStatusObject,
      closedForLunch: `${lunchStart} - ${lunchEnd}`,
    };
  }

  return openStatusObject;
};

export const filterByType = (pickupPoints, type) =>
  type
    ? filter(pickupPoints, {
        pickupLocation: { kind: type },
      })
    : pickupPoints;

export const getDistanceToPickup = (pickupPoint) => {
  const distance = get(pickupPoint, "distance");

  return distance ? distance.toFixed(2) : "";
};

export const getPickupInfo = (pickupPoint) => {
  const { isOpen, openStatus } = getOpenStatusObject(pickupPoint);
  return {
    type: getPickupType(pickupPoint),
    distance: getDistanceToPickup(pickupPoint),
    isOpen,
    openStatus,
  };
};

export const getPickupAmenities = (pickupPoint) =>
  pick(get(pickupPoint, "pickupLocation"), [
    "disabledAccess",
    "parkingAvailable",
    "openLate",
    "openSaturday",
    "openSunday",
  ]);

export const calculateMapFitBounds = ({
  currentLocation,
  pickupLocations = [],
}) => {
  const calculatePickupShop = pickupLocations.reduce(
    (
      bounds,
      {
        pickupLocation: {
          addressPoint: { latitude, longitude },
        },
      }
    ) => {
      bounds.southWest.longitude = Math.max(
        longitude,
        bounds.southWest.longitude
      );
      bounds.southWest.latitude = Math.min(latitude, bounds.southWest.latitude);

      bounds.northEast.longitude = Math.min(
        longitude,
        bounds.northEast.longitude
      );
      bounds.northEast.latitude = Math.max(latitude, bounds.northEast.latitude);

      return bounds;
    },
    {
      southWest: {
        longitude: currentLocation.longitude,
        latitude: currentLocation.latitude,
      },
      northEast: {
        longitude: currentLocation.longitude,
        latitude: currentLocation.latitude,
      },
    }
  );

  // Bounds format: [[south, west], [north, east]]
  return [
    [
      calculatePickupShop.southWest.longitude,
      calculatePickupShop.southWest.latitude,
    ],
    [
      calculatePickupShop.northEast.longitude,
      calculatePickupShop.northEast.latitude,
    ],
  ];
};

export const sortSelectedPickupToTop = (pickupPoints, selectedPickup) =>
  sortBy(
    pickupPoints,
    (pickupPoint) =>
      getPickupLocationCode(pickupPoint) !==
      getPickupLocationCode(selectedPickup)
  );
