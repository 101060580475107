import * as M from "../../../constants/strings";
import { STEP_NAME } from "../../../constants";
import { Location } from "../../atoms/icons";
import EditableCard from "../../molecules/EditableCard/EditableCard";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import Alert from "../../atoms/Alert/Alert";
import { ClassesPropType } from "./propTypes";

const PinPointLocationCard = ({ classes, available, data, onEditClick }) => (
  <EditableCard
    icon={Location}
    title={
      <>
        <span className="d-none d-sm-inline">{M.YOUR_PINPOINT_LOCATION}</span>
        <span className="d-sm-none">{M.PINPOINT_LOCATION}</span>
      </>
    }
    onEdit={() => onEditClick(STEP_NAME.PINPOINT_LOCATION)}
    classes={classes}
    editable={available}
  >
    <Row>
      <Col xs={12} sm={6}>
        <p className={classes.dataHeader}>{M.PINPOINT_LOCATION}:</p>
        <p className={classes.text}>
          {(!available && M.UNAVAILABLE) ||
            (data?.lngLat?.lat &&
              `${data.lngLat.lat.toFixed(6)}, ${data?.lngLat?.lng.toFixed(
                6
              )}`) ||
            M.NOT_SET}
        </p>
      </Col>
      <Col xs={12} sm={6}>
        <p className={classes.dataHeader}>{M.WHAT_3_WORDS}:</p>
        <p className={classes.text}>
          {(!available && M.UNAVAILABLE) ||
            (data?.what3words && `///${data?.what3words}`) ||
            M.NOT_SET}
        </p>
      </Col>
    </Row>
    {available && !data.what3words && (
      <Alert variant="info" title={M.REMINDER} message={M.NO_W3W_SETUP} />
    )}
  </EditableCard>
);

PinPointLocationCard.propTypes = {
  available: PropTypes.bool,
  classes: ClassesPropType,
  onEditClick: PropTypes.func,
  data: PropTypes.shape({
    what3words: PropTypes.string,
    lngLat: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
  }),
};

export default PinPointLocationCard;
