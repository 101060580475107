import { Container } from "react-bootstrap";
import PropTypes from "prop-types";

function Step({ component: Component, ...props }) {
  return (
    <Container fluid>
      <Component {...props} />
    </Container>
  );
}

Step.propTypes = {
  component: PropTypes.elementType,
};

export default Step;
