import classNames from "classnames";
import PropTypes from "prop-types";

import styles from "./IconWithLabel.module.scss";

const IconWithLabel = ({ icon, label, customStyles, inline }) => {
  const Icon = icon;

  return (
    <div
      className={classNames(
        styles.container,
        inline && styles.inline,
        customStyles?.container
      )}
    >
      {icon && (
        <Icon width="1.5rem" height="1.5rem" className={customStyles?.icon} />
      )}
      <span className={customStyles?.label}>{label}</span>
    </div>
  );
};

IconWithLabel.propTypes = {
  icon: PropTypes.func,
  label: PropTypes.node,
  customStyles: PropTypes.object,
  inline: PropTypes.bool,
};

export default IconWithLabel;
