import * as Service from "./service";
import { createFirebaseAsyncThunk, ENTITY } from "../../ErrorBoundary";

export const getAddresses = createFirebaseAsyncThunk(
  "profile/addresses/get",
  Service.getAddresses,
  ENTITY.ADDRESS
);

export const addAddress = createFirebaseAsyncThunk(
  "profile/addresses/create",
  async ([uid, address, transaction]) => {
    const waitFor = [];
    const waitForRollback = [];

    if (address.imageUrl) {
      waitFor.push(
        Service.uploadAddressPhoto(uid, address.udprn, address.imageUrl).then(
          (downloadUrl) => {
            address.imageUrl = downloadUrl;
            waitForRollback.push(() => {
              Service.deleteAddressPhoto(uid, address.udprn);
            });
          }
        )
      );
    }

    if (address.preferences.safePlace.imageUrl) {
      waitFor.push(
        Service.uploadAddressSafePlacePhoto(
          uid,
          address.udprn,
          address.preferences.safePlace.imageUrl
        ).then((downloadUrl) => {
          address.preferences.safePlace.imageUrl = downloadUrl;
          waitForRollback.push(() => {
            Service.deleteAddressSafePlacePhoto(uid, address.udprn);
          });
        })
      );
    }

    waitFor.length && (await Promise.all(waitFor));

    return Service.addAddress(uid, address, { transaction }).catch(
      async (err) => {
        if (waitForRollback.length) {
          await Promise.all(waitForRollback.map((rollbackFn) => rollbackFn()));
        }

        throw err;
      }
    );
  },
  ENTITY.ADDRESS
);

export const updateAddress = createFirebaseAsyncThunk(
  "profile/addresses/update",
  ([uid, address, transaction]) =>
    Service.updateAddress(uid, address, {
      transaction,
    }),
  ENTITY.ADDRESS
);

export const deleteAddress = createFirebaseAsyncThunk(
  "profile/addresses/delete",
  ([uid, udprn, transaction]) =>
    Service.deleteAddress(uid, udprn, { transaction }),
  ENTITY.ADDRESS
);
