import { Button, Modal } from "react-bootstrap";
import Cropper from "react-cropper";
import React, { createRef, useCallback, useMemo } from "react";
import "cropperjs/dist/cropper.css";
import PropTypes from "prop-types";
import { CLOSE, DONE } from "../../../constants/strings";
import useBreakpoint from "use-breakpoint";
import { BREAKPOINTS } from "../../../constants/ui";

function CropperView({ title, show, src, setShow, onCropComplete, maxZoom }) {
  const cropperRef = createRef();

  const { breakpoint } = useBreakpoint(BREAKPOINTS);

  const isMobileOrTablet = useMemo(
    () => ["mobile", "tablet"].some((bp) => bp === breakpoint),
    [breakpoint]
  );

  const handleCropComplete = useCallback(() => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      onCropComplete(cropperRef.current?.cropper);
    }
    setShow(false);
  }, [cropperRef, onCropComplete, setShow]);
  return (
    <Modal centered show={show} backdrop="static" onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Cropper
          ref={cropperRef}
          style={{ height: 400, width: "100%" }}
          aspectRatio={1}
          src={src}
          viewMode={1}
          minCropBoxHeight={200}
          minCropBoxWidth={200}
          background={true}
          responsive={true}
          dragMode={isMobileOrTablet ? "move" : "crop"}
          cropBoxMovable={!isMobileOrTablet}
          cropBoxResizable={!isMobileOrTablet}
          autoCropArea={1}
          checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
          guides={true}
          zoom={(event) => {
            // Zoom in
            if (event.detail.ratio > event.detail.oldRatio) {
              if (event.detail.ratio > maxZoom) {
                event.preventDefault(); // Prevent zoom in
              }
            }
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={() => setShow(false)}>
          {CLOSE}
        </Button>
        <Button autoFocus variant="danger" onClick={handleCropComplete}>
          {DONE}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

CropperView.defaultProps = {
  maxZoom: 2,
  onCropComplete: () => null,
};

CropperView.propTypes = {
  title: PropTypes.string,
  show: PropTypes.bool,
  setShow: PropTypes.func,
  maxZoom: PropTypes.number,
  onCropComplete: PropTypes.func,
  src: PropTypes.any.isRequired,
};

export default CropperView;
