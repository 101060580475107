import BootstrapModal from "react-bootstrap/Modal";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { CLOSE } from "../../../constants/strings";

const Modal = ({
  show,
  title,
  children,
  handleClose = () => {},
  handleAccept = () => {},
  acceptButtonText,
  closeButton,
  centered,
  acceptButtonDisabled,
}) => {
  return (
    <BootstrapModal show={show} centered={centered} onHide={handleClose}>
      <BootstrapModal.Header closeButton={closeButton}>
        <BootstrapModal.Title>{title}</BootstrapModal.Title>
      </BootstrapModal.Header>
      <BootstrapModal.Body>{children}</BootstrapModal.Body>
      <BootstrapModal.Footer>
        <Button variant="outline-danger" onClick={handleClose}>
          {CLOSE}
        </Button>
        <Button
          variant="danger"
          onClick={handleAccept}
          disabled={acceptButtonDisabled}
        >
          {acceptButtonText}
        </Button>
      </BootstrapModal.Footer>
    </BootstrapModal>
  );
};

Modal.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.node,
  handleClose: PropTypes.func,
  handleAccept: PropTypes.func,
  acceptButtonText: PropTypes.string,
  closeButton: PropTypes.bool,
  centered: PropTypes.bool,
  acceptButtonDisabled: PropTypes.bool,
};

export default Modal;
