import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import styles from "./MapBoxErrorMessage.module.scss";

const MapBoxErrorMessage = ({ message, className, ...props }) => {
  return (
    <div className={classNames(className)} {...props}>
      <div className={styles.message}>{message}</div>
    </div>
  );
};

MapBoxErrorMessage.propTypes = {
  message: PropTypes.string,
  className: PropTypes.string,
};

export default MapBoxErrorMessage;
