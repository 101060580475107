import { AuthErrorCodes, OAuthProvider } from "firebase/auth";

import { LOST_INTERNET_CONNECTION } from "../../constants/strings";

export const credentialFromError = (err) => {
  const credential = OAuthProvider.credentialFromError(err);

  return credential;
};

export const credentialFromJSON = (credential) => {
  return OAuthProvider.credentialFromJSON(credential);
};

export const wrapAuthHandler =
  (fn) =>
  (...args) =>
    fn(...args).catch((err) => {
      switch (err.code) {
        case AuthErrorCodes.NETWORK_REQUEST_FAILED:
          throw {
            ...err,
            message: LOST_INTERNET_CONNECTION,
          };
        default:
          throw err;
      }
    });
