import { createReducer } from "@reduxjs/toolkit";
import { setConfig } from "./actions";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const initialState = {
  schoolRun: false,
  needMoreTime: false,
};

const ConfigReducer = createReducer(initialState, {
  [setConfig]: (state, { payload }) => ({
    ...state,
    ...payload,
  }),
});

export default persistReducer(
  {
    key: "config",
    storage: storage,
  },
  ConfigReducer
);
