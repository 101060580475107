import { Outlet } from "react-router-dom";
import LoginTemplate from "../../components/templates/Login";

function Layout() {
  return (
    <LoginTemplate>
      <Outlet />
    </LoginTemplate>
  );
}

export default Layout;
