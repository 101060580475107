const Microsoft = (props) => {
  return (
    <svg
      width="1.5rem"
      height="1.5rem"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M4 4H11.6191V11.6191H4V4Z" fill="#F35325" />
      <path d="M12.3809 4H20V11.6191H12.3809V4Z" fill="#81BC06" />
      <path d="M4 12.381H11.6191V20H4V12.381Z" fill="#05A6F0" />
      <path d="M12.3809 12.381H20V20H12.3809V12.381Z" fill="#FFBA08" />
    </svg>
  );
};

export default Microsoft;
