export const deg2rad = (deg) => deg * (Math.PI / 180);

export const getDistanceFromLatLonInKm = (coordinates1, coordinates2) => {
  const { lat: latitude1, lng: longitude1 } = coordinates1;
  const { lat: latitude2, lng: longitude2 } = coordinates2;

  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(latitude2 - latitude1);
  const dLon = deg2rad(longitude2 - longitude1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(latitude1)) *
      Math.cos(deg2rad(latitude2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km

  return d;
};
