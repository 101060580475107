import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./ButtonWithIcon.module.scss";

const ButtonWithIcon = ({
  variant = "outline-danger",
  className,
  icon,
  label,
  customStyles,
  ...rest
}) => {
  const Icon = icon;
  return (
    <Button className={className} variant={variant} {...rest}>
      {label}
      {icon && (
        <Icon
          width={16}
          height={16}
          className={classNames(styles.icon, customStyles?.icon)}
        />
      )}
    </Button>
  );
};

ButtonWithIcon.propTypes = {
  icon: PropTypes.element,
  label: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.string,
  customStyles: PropTypes.object,
};

export default ButtonWithIcon;
