const Upload = (props) => {
  return (
    <svg
      width="2rem"
      height="2rem"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_104_1918)">
        <path
          d="M27.6665 28.3066H4.33317C3.41428 28.3066 2.6665 27.5588 2.6665 26.6399V22.1955C2.6665 21.2766 3.41428 20.5288 4.33317 20.5288H7.6665C7.97317 20.5288 8.22206 20.7777 8.22206 21.0844C8.22206 21.391 7.97317 21.6399 7.6665 21.6399H4.33317C4.02706 21.6399 3.77762 21.8894 3.77762 22.1955V26.6399C3.77762 26.946 4.02706 27.1955 4.33317 27.1955H27.6665C27.9726 27.1955 28.2221 26.946 28.2221 26.6399V22.1955C28.2221 21.8894 27.9726 21.6399 27.6665 21.6399H24.3332C24.0265 21.6399 23.7776 21.391 23.7776 21.0844C23.7776 20.7777 24.0265 20.5288 24.3332 20.5288H27.6665C28.5854 20.5288 29.3332 21.2766 29.3332 22.1955V26.6399C29.3332 27.5588 28.5854 28.3066 27.6665 28.3066Z"
          fill="#414042"
        />
        <path
          d="M16 23.8621C15.6933 23.8621 15.4444 23.6132 15.4444 23.3065V7.75094C15.4444 7.44428 15.6933 7.19539 16 7.19539C16.3066 7.19539 16.5555 7.44428 16.5555 7.75094V23.3065C16.5555 23.6132 16.3066 23.8621 16 23.8621Z"
          fill="#DC0032"
        />
        <path
          d="M22.1111 10.5287C21.9777 10.5287 21.8444 10.4809 21.7377 10.3843L16 5.16873L10.2627 10.3843C10.035 10.5915 9.68384 10.5737 9.47773 10.3471C9.27107 10.1198 9.28773 9.76817 9.51495 9.56206L16 3.6665L22.485 9.56206C22.7122 9.76817 22.7283 10.1198 22.5222 10.3471C22.4122 10.4676 22.2616 10.5287 22.1111 10.5287Z"
          fill="#DC0032"
        />
      </g>
      <defs>
        <clipPath id="clip0_104_1918">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Upload;
