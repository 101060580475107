import { useCallback, useState } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import { Badge, Button, Col, Row } from "react-bootstrap";
import * as M from "../../../constants/strings";
import Delete from "../../atoms/icons/Delete";
import Modal from "../../atoms/Modal";
import ListItemWithIcon from "../../molecules/ListItemWithIcon";
import styles from "./UpdateEmails.module.scss";
import { Trackable, useTracker } from "@dpdgroupuk/react-event-tracker";
import {
  DELETE_EMAIL_MODAL,
  YOUR_PERSONAL_INFORMATION as ACTION,
} from "../../../constants/analytics";

const NotificationEmails = ({
  emails = [],
  onClickDeleteEmail,
  onClickResendVerification,
}) => {
  const tracker = useTracker();
  const [confirmDeleteEmail, setConfirmDeleteEmail] = useState(false);

  const onConfirmDeleteEmail = useCallback(() => {
    onClickDeleteEmail(confirmDeleteEmail);
    setConfirmDeleteEmail(null);
    tracker.logEvent(DELETE_EMAIL_MODAL.ON_CLICK_DELETE_EMAIL);
  }, [confirmDeleteEmail, onClickDeleteEmail, tracker]);

  return (
    <>
      {emails.map((doc) => {
        const { id, email, verified } = doc;
        return (
          <ListItemWithIcon
            onIconClick={() => {
              tracker.logEvent(ACTION.ON_CLICK_EMAIL_DELETE);
              setConfirmDeleteEmail(doc);
            }}
            icon={Delete}
            key={id}
            footer={
              !verified && (
                <Row className="g-0 d-flex justify-content-end">
                  <Col
                    xs={12}
                    className={classNames(
                      styles.helperText,
                      "text-danger my-2"
                    )}
                  >
                    {M.EMAIL_ADDRESS_UNVERIFIED}
                  </Col>
                  <Col xs="auto">
                    <Button
                      variant="danger"
                      onClick={() => onClickResendVerification(doc)}
                    >
                      {M.RESEND_VERIFICATION_EMAIL}
                    </Button>
                  </Col>
                </Row>
              )
            }
          >
            <Row>
              <Col className={classNames("mb-0", styles.dataText)}>{email}</Col>
              {!verified && (
                <Col xs="auto">
                  <Badge bg="danger">{M.UNVERIFIED}</Badge>
                </Col>
              )}
            </Row>
          </ListItemWithIcon>
        );
      })}
      <Modal
        show={confirmDeleteEmail}
        title={M.YOUR_DPD_PROFILE}
        acceptButtonText={M.YES_DELETE_EMAIL}
        handleClose={() => {
          setConfirmDeleteEmail(null);
          tracker.logEvent(DELETE_EMAIL_MODAL.ON_CLICK_CLOSE);
        }}
        handleAccept={onConfirmDeleteEmail}
        closeButton
        centered
      >
        <Trackable
          loadId={DELETE_EMAIL_MODAL.LOAD}
          interfaceId={DELETE_EMAIL_MODAL.INTERFACE_ID}
          modal
        >
          <h6>{M.DELETE_EMAIL_PROMPT}</h6>
          <p>{M.DELETE_EMAIL_MESSAGE}</p>
        </Trackable>
      </Modal>
    </>
  );
};

NotificationEmails.propTypes = {
  emails: PropTypes.array,
  onClickDeleteEmail: PropTypes.func,
  onClickResendVerification: PropTypes.func,
};

export default NotificationEmails;
