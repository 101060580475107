import { useCallback, useState } from "react";
import { findIndex } from "lodash";
import { Button } from "react-bootstrap";
import { REFRESH } from "../../constants/strings";

const hashCode = (str) => {
  let hash = 0;
  for (let i = 0, len = str.length; i < len; i++) {
    let chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

const useToasterController = () => {
  const [toasts, setToasts] = useState([]);

  const addToast = useCallback(
    (toast) => {
      const id = hashCode(toast.body + "");
      setToasts((prevToasts) => {
        const index = findIndex(prevToasts, ["id", id]);
        index > -1 && prevToasts.splice(index, 1);
        return [...prevToasts, { id, date: Date.now(), ...toast }];
      });
      // return insertion id for further use
      return id;
    },
    [toasts, setToasts]
  );

  const removeToast = useCallback(
    (id) =>
      setToasts((toasts) => {
        const newToasts = [...toasts];
        const index = findIndex(newToasts, ["id", id]);
        newToasts.splice(index, 1);
        return newToasts;
      }),
    [setToasts]
  );

  const showInfo = useCallback(
    (values) =>
      addToast({
        ...values,
        bg: "info",
      }),
    [addToast]
  );

  const showSuccess = useCallback(
    (values) =>
      addToast({
        ...values,
        bg: "success",
        autoHide: true,
      }),
    [addToast]
  );

  const showError = useCallback(
    (values) =>
      addToast({
        ...values,
        body: values.reload ? (
          <>
            <p>{values.body}</p>
            <p className="mb-0">
              <Button
                variant="link"
                className="text-danger p-0"
                onClick={
                  values.onReload ||
                  (() => {
                    window.location.reload();
                  })
                }
              >
                {REFRESH}
              </Button>
            </p>
          </>
        ) : (
          values.body
        ),
        bg: "danger",
      }),
    [addToast]
  );

  const showWarning = useCallback(
    (values) =>
      addToast({
        ...values,
        bg: "warning",
      }),
    [addToast]
  );

  return {
    toasts,
    removeToast,
    showInfo,
    showSuccess,
    showError,
    showWarning,
  };
};

export default useToasterController;
