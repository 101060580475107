const Dpd = (props) => {
  return (
    <svg
      width="2rem" // 32px
      height="2rem"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_199_13107)">
        <mask
          id="mask0_199_13107"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="4"
          y="2"
          width="23"
          height="28"
        >
          <rect
            x="4"
            y="2.66666"
            width="22.6667"
            height="26.6667"
            fill="#C4C4C4"
          />
        </mask>
        <g mask="url(#mask0_199_13107)">
          <path
            d="M26.0177 8.44035L25.7049 8.63892C23.4358 9.97305 21.1729 11.3134 18.8977 12.6413C18.6276 12.7644 18.4683 13.0513 18.5052 13.3487C18.5481 14.143 18.5052 14.0437 19.1553 14.4533C19.3859 14.6357 19.7076 14.6433 19.9464 14.4719C22.0315 13.2308 24.1288 11.9898 26.2139 10.7487L26.5941 10.5315C26.5987 10.6183 26.5987 10.7053 26.5941 10.7922V22.1541C26.6022 22.5396 26.3862 22.8941 26.0422 23.06L18.9897 27.2858C17.9655 27.9064 16.9353 28.5269 15.9234 29.1474C15.5646 29.3953 15.0924 29.3953 14.7337 29.1474C11.3607 27.1121 7.9878 25.0933 4.61487 23.091C4.21837 22.8877 3.97787 22.4667 4.00161 22.0175C4.00161 18.2944 4.00161 14.5898 4.00161 10.9038C4.00161 10.7922 4.00161 10.6867 4.00161 10.5315L4.3389 10.7177L13.9364 16.3645C14.155 16.4607 14.2866 16.6893 14.2615 16.9292C14.2615 19.6843 14.2615 22.4333 14.2615 25.1884V25.4677C14.2535 25.6516 14.3549 25.8226 14.519 25.902L15.0342 26.1937C15.1963 26.293 15.3995 26.293 15.5616 26.1937L16.0522 25.9207C16.2289 25.844 16.3376 25.6616 16.322 25.4677C16.322 25.3932 16.322 25.3188 16.322 25.2443V15.8867C16.322 15.7998 16.322 15.7192 16.322 15.6323C16.3279 15.462 16.2375 15.3031 16.089 15.2227L15.3101 14.7635L4.93377 8.63892L4.61487 8.44655L4.87244 8.2666L14.6846 2.84317C15.0604 2.60783 15.5354 2.60783 15.9111 2.84317C19.1921 4.66753 22.4607 6.46086 25.7294 8.2666L26.0177 8.44035Z"
            fill="#DC0032"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_199_13107">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Dpd;
