import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from "firebase/app-check";
import { getStorage } from "firebase/storage";
import { getRemoteConfig } from "firebase/remote-config";

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

export const defaultApp = initializeApp(config);

export const firebaseAuth = getAuth();
export const firebaseStorage = getStorage();
firebaseStorage.maxUploadRetryTime = 30000;

export const remoteConfig = getRemoteConfig(defaultApp);
if (process.env.NODE_ENV === "development") {
  remoteConfig.settings = {
    /**
     * Defines the maximum age in milliseconds of an entry in the config cache before
     * it is considered stale. Defaults to 43200000 (Twelve hours).
     */
    minimumFetchIntervalMillis: 3000,
  };
}

window.FIREBASE_APPCHECK_DEBUG_TOKEN =
  process.env.REACT_APP_FIREBASE_APPCHECK_DEBUG_TOKEN;

if (process.env.NODE_ENV !== "production") {
  window.firebaseAuth = firebaseAuth;
}

// Create a ReCaptchaEnterpriseProvider instance using your reCAPTCHA Enterprise
// site key and pass it to initializeAppCheck().
export const appCheck = initializeAppCheck(defaultApp, {
  provider: new ReCaptchaEnterpriseProvider(
    process.env.REACT_APP_RECAPTCHA_SITE_KEY
  ),
  isTokenAutoRefreshEnabled: false, // Set to true to allow auto-refresh.
});
