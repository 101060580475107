export const parse = (config) => {
  const text = config.asString();

  try {
    return JSON.parse(text);
  } catch (e) {
    return text;
  }
};

export const parseAll = (allConfig) => {
  const result = {};

  for (const [key, config] of Object.entries(allConfig)) {
    result[key] = parse(config);
  }

  return result;
};
