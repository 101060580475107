const Menu = ({ ...props }) => (
  <svg
    width="1.5rem" // 24px
    height="1.5rem"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="mask0_1558_8790"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_1558_8790)">
      <path
        d="M4 17C3.71667 17 3.47933 16.904 3.288 16.712C3.096 16.5207 3 16.2833 3 16C3 15.7167 3.096 15.479 3.288 15.287C3.47933 15.0957 3.71667 15 4 15C4.28333 15 4.521 15.0957 4.713 15.287C4.90433 15.479 5 15.7167 5 16C5 16.2833 4.90433 16.5207 4.713 16.712C4.521 16.904 4.28333 17 4 17ZM4 13C3.71667 13 3.47933 12.904 3.288 12.712C3.096 12.5207 3 12.2833 3 12C3 11.7167 3.096 11.479 3.288 11.287C3.47933 11.0957 3.71667 11 4 11C4.28333 11 4.521 11.0957 4.713 11.287C4.90433 11.479 5 11.7167 5 12C5 12.2833 4.90433 12.5207 4.713 12.712C4.521 12.904 4.28333 13 4 13ZM4 9C3.71667 9 3.47933 8.904 3.288 8.712C3.096 8.52067 3 8.28333 3 8C3 7.71667 3.096 7.479 3.288 7.287C3.47933 7.09567 3.71667 7 4 7C4.28333 7 4.521 7.09567 4.713 7.287C4.90433 7.479 5 7.71667 5 8C5 8.28333 4.90433 8.52067 4.713 8.712C4.521 8.904 4.28333 9 4 9ZM7 17V15H21V17H7ZM7 13V11H21V13H7ZM7 9V7H21V9H7Z"
        fill="#414042"
      />
    </g>
  </svg>
);

export default Menu;
