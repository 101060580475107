import { useMemo } from "react";

export const useInitValues = (values) =>
  useMemo(() => values || getNullObject(), [values]);

export const getNullObject = () => ({
  udprn: null,
  what3words: null,
  lngLat: {
    lng: null,
    lat: null,
  },
});
