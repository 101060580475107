import { get, noop } from "lodash";
import classNames from "classnames";
import { Button, Col, Container, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { useCallback } from "react";

import WizardNavItem from "../WizardNavItem";
import * as M from "../../../constants/strings";
import styles from "./WizardNavigationBar.module.scss";
import { useWizard } from "../../../features/Wizard";
import {
  Address,
  Consumer,
  Location,
  Neighbour,
  PickupPoint,
  SafePlace,
} from "../../atoms/icons";

const NavigationBar = ({ steps, disabledNext, onClickNext, onClickBack }) => {
  const { currentStep, previousStep, totalSteps } = useWizard();

  const handleClickBack = useCallback(() => {
    previousStep();
    onClickBack();
  }, [previousStep, onClickBack]);

  return (
    <Container fluid className={classNames("mt-2, mt-md-3", styles.container)}>
      <Row className="d-flex justify-content-between align-items-center">
        <Col
          xs="auto"
          className={classNames(
            "d-flex justify-content-start",
            styles.nextButtonContainer,
            { invisible: !currentStep }
          )}
        >
          <Button variant="outline-danger" onClick={handleClickBack}>
            {M.BACK}
          </Button>
        </Col>
        {/* TODO: Add dots instead of this panel when design will be approved */}
        <Col
          className="d-none d-md-flex justify-content-center " // "order-0 order-lg-1"
        >
          {steps.map((step, index) => (
            <WizardNavItem
              key={index}
              icon={get(step, "icon")}
              label={get(step, "label")}
              active={index === currentStep}
              valid={index < currentStep}
              disabled={index > currentStep}
            />
          ))}
        </Col>
        <Col className="d-flex d-md-none justify-content-center">
          {steps.map((step, index) => (
            <div
              className={classNames(
                styles.dot,
                index === currentStep && styles.dotActive,
                index < currentStep && styles.dotValid
              )}
              key={index}
            />
          ))}
        </Col>
        <Col
          xs="auto"
          className={classNames(
            "d-flex justify-content-end",
            styles.nextButtonContainer
          )}
        >
          <Button
            variant="danger"
            disabled={disabledNext}
            onClick={onClickNext}
          >
            {currentStep + 1 === totalSteps ? M.CONFIRM : M.NEXT}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

NavigationBar.propTypes = {
  formProps: PropTypes.object,
  disabledNext: PropTypes.bool,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.elementType,
      label: PropTypes.string,
    })
  ),
  onClickBack: PropTypes.func,
};

const step = (icon, label) => ({ icon, label });

NavigationBar.defaultProps = {
  disabledNext: false,
  steps: [
    step(Consumer, M.PERSONAL_INFO),
    step(Address, M.ADDRESS),
    step(Location, M.LOCATION),
    step(Neighbour, M.NEIGHBOUR),
    step(SafePlace, M.LEAVE_SAFE),
    step(PickupPoint, M.PICKUP_POINT),
  ],
  onClickBack: noop,
};

export default NavigationBar;
