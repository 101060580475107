import Async from "react-async";
import Error from "../../atoms/Error";
import Pending from "./Pending";

const Loader = ({ children, promiseFn, ...args }) => (
  <Async promiseFn={promiseFn} {...args}>
    <Async.Pending>
      <Pending />
    </Async.Pending>
    <Async.Fulfilled>{children}</Async.Fulfilled>
    <Async.Rejected>
      {(error, state) => (
        <Error message={error.message} onButtonClick={() => state.reload()} />
      )}
    </Async.Rejected>
  </Async>
);

Loader.propTypes = Async.propTypes;

export default Loader;
