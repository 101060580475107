const Edit = (props) => {
  return (
    <svg
      width="1.0625rem" // 17px
      height="1.0625rem"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.375 15.125H3.6L11.1469 7.57812L9.92187 6.35313L2.375 13.9V15.125ZM14.8875 6.30938L11.1687 2.63437L12.3938 1.40938C12.7292 1.07396 13.1413 0.90625 13.6301 0.90625C14.1184 0.90625 14.5302 1.07396 14.8656 1.40938L16.0906 2.63437C16.426 2.96979 16.601 3.37462 16.6156 3.84888C16.6302 4.32254 16.4698 4.72708 16.1344 5.0625L14.8875 6.30938ZM13.6187 7.6L4.34375 16.875H0.625V13.1562L9.9 3.88125L13.6187 7.6Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Edit;
