import classNames from "classnames";
import PropTypes from "prop-types";
import { Button, Col, Image, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";

import PickupPoint from "../../../assets/images/pickup_point.png";
import {
  PickupPointPropType,
  PickupPointsPropType,
} from "../../../constants/propType";
import * as M from "../../../constants/strings";
import {
  getPickupAmenities,
  getPickupInfo,
  getPickupKind,
  getPickupLocationCode,
  getPickupName,
  getPartnerLogo,
} from "../../../utils/pickupPoint";
import Menu from "../../atoms/icons/Menu";
import LinkButton from "../../atoms/LinkButton";
import PickupAmenities from "../../atoms/PickupAmenities";
import PickupInfo from "../../atoms/PickupInfo";
import PickupPointTitle from "../../atoms/PickupPointTitle";
import PickupWindowSticker from "../../atoms/PickupWindowSticker";
import "swiper/scss";
import styles from "./PickupMobileHorizontal.module.scss";

const PickupMobileHorizontal = ({
  selectedPickupPoint,
  pickupPoints,
  onSelectClick,
  onPickupDetailsClick,
  onSetupLater,
  onClickNext,
  onListViewClick,
  setActivePickupPoint,
  initialSlide,
  swiperRef,
}) => (
  <Row className="g-0 w-100 h-100">
    <Col>
      <Button
        variant="light"
        className={classNames(
          "d-flex align-items-center",
          styles.listViewButton
        )}
        size="sm"
        onClick={onListViewClick}
      >
        <Menu className="me-2" />
        {M.LIST_VIEW}
      </Button>

      <Swiper
        slidesPerView="auto"
        centeredSlides={true}
        spaceBetween={16}
        className={styles.swiper}
        onSlideChangeTransitionStart={({ activeIndex }) =>
          setActivePickupPoint(pickupPoints[activeIndex])
        }
        initialSlide={initialSlide}
        ref={swiperRef}
      >
        {pickupPoints.map((pickupPoint, index) => {
          const isSelected =
            getPickupLocationCode(pickupPoint) ===
            getPickupLocationCode(selectedPickupPoint);

          return (
            <SwiperSlide className={styles.slide} key={index}>
              <div className={classNames(styles.shadow, styles.bottomShadow)} />
              <div className={classNames(styles.shadow, styles.rightShadow)} />
              <div className={classNames(styles.shadow, styles.leftShadow)} />
              <Row className={classNames("g-0", styles.slideContent)}>
                <Col>
                  <Row
                    className={classNames(
                      "position-relative g-0",
                      styles.imageContainer
                    )}
                  >
                    <Col>
                      <Image
                        src={getPartnerLogo(pickupPoint) || PickupPoint}
                        className={classNames(
                          "w-100",
                          getPartnerLogo(pickupPoint) && styles.image
                        )}
                      />
                      <PickupWindowSticker type={getPickupKind(pickupPoint)} />
                    </Col>
                  </Row>

                  <Row className="g-0 p-3">
                    <Col>
                      <Row className="g-0 mb-3">
                        <Col className={styles.title}>
                          <PickupPointTitle
                            number={index + 1}
                            name={getPickupName(pickupPoint)}
                          />
                        </Col>
                      </Row>

                      <Row className="g-0 mb-3">
                        <Col>
                          <PickupInfo {...getPickupInfo(pickupPoint)} />
                        </Col>
                      </Row>

                      <PickupAmenities
                        {...getPickupAmenities(pickupPoint)}
                        className="mb-3"
                      />

                      <Row className="g-0 mb-3">
                        <Col xs={6} className="pe-1">
                          <Button
                            variant="outline-danger"
                            className={classNames(
                              "fs-5 w-100 h-100",
                              styles.detailsButton
                            )}
                            size="md"
                            onClick={() => onPickupDetailsClick(pickupPoint)}
                          >
                            {M.DETAILS}
                          </Button>
                        </Col>
                        <Col xs={6} className="ps-1">
                          <Button
                            variant={isSelected ? "success" : "danger"}
                            className="fs-5 w-100 h-100"
                            size="md"
                            onClick={() => onSelectClick(pickupPoint)}
                          >
                            {isSelected ? M.SELECTED : M.SELECT}
                          </Button>
                        </Col>
                      </Row>

                      {onClickNext && (
                        <>
                          <Row className="g-0 mb-3">
                            <Col>
                              <Button
                                variant="danger"
                                className="fs-5 w-100 h-100"
                                size="md"
                                onClick={onClickNext}
                              >
                                {M.NEXT}
                              </Button>
                            </Col>
                          </Row>

                          <Row>
                            <Col>
                              <LinkButton
                                onClick={onSetupLater}
                                className="w-100 h-100"
                              >
                                {M.SETUP_LATER}
                              </LinkButton>
                            </Col>
                          </Row>
                        </>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Col>
  </Row>
);

PickupMobileHorizontal.propTypes = {
  selectedPickupPoint: PickupPointPropType,
  pickupPoints: PickupPointsPropType.isRequired,
  onSelectClick: PropTypes.func,
  onPickupDetailsClick: PropTypes.func,
  onSetupLater: PropTypes.func,
  onClickNext: PropTypes.func,
  onListViewClick: PropTypes.func,
  setActivePickupPoint: PropTypes.func,
  initialSlide: PropTypes.number,
  swiperRef: PropTypes.any,
};

export default PickupMobileHorizontal;
