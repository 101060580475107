import classNames from "classnames";
import { Container } from "react-bootstrap";
import styles from "./Login.module.scss";

const LoginContainer = ({ children, className }) => (
  <Container className={classNames("p-0", styles.loginContainer, className)}>
    {children}
  </Container>
);

export default LoginContainer;
