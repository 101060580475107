const Accessibility = (props) => (
  <svg
    width="2rem" // 32px
    height="2rem"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_510_9542)">
      <path d="M0 0H32V32H0V0Z" fill="#01619E" />
      <path
        d="M16 7.99996C17.4728 7.99996 18.6667 6.80605 18.6667 5.33329C18.6667 3.86053 17.4728 2.66663 16 2.66663C14.5272 2.66663 13.3333 3.86053 13.3333 5.33329C13.3333 6.80605 14.5272 7.99996 16 7.99996Z"
        fill="white"
      />
      <path
        d="M25.3333 17.3333V14.6666C23.28 14.6933 21.2133 13.6666 19.9067 12.2266L18.1867 10.32C17.96 10.0666 17.68 9.86664 17.3733 9.71998C17.36 9.71998 17.36 9.70664 17.3467 9.70664H17.3333C16.8667 9.43998 16.3333 9.30664 15.7467 9.35998C14.3467 9.47998 13.3333 10.72 13.3333 12.12V20C13.3333 21.4666 14.5333 22.6666 16 22.6666H22.6667V29.3333H25.3333V22C25.3333 20.5333 24.1333 19.3333 22.6667 19.3333H18.6667V14.7333C20.3867 16.16 23 17.32 25.3333 17.3333ZM13.3333 26.6666C11.12 26.6666 9.33334 24.88 9.33334 22.6666C9.33334 20.92 10.4533 19.4533 12 18.8933V16.1333C8.96001 16.7466 6.66667 19.44 6.66667 22.6666C6.66667 26.3466 9.65334 29.3333 13.3333 29.3333C16.56 29.3333 19.2533 27.04 19.8667 24H17.1067C16.56 25.5466 15.08 26.6666 13.3333 26.6666Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_510_9542">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Accessibility;
