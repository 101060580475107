const ResendEmail = () => (
  <svg
    width="16.5rem" // 268px
    height="9.25rem" // 148px
    viewBox="0 0 268 148"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_24_11693)">
      <path
        d="M14.6603 60.6882C14.6603 60.6882 11.4826 60.4603 11.7755 67.561C12.0684 74.6618 12.7946 78.448 12.7946 78.448C12.7946 78.448 12.7023 80.0233 11.4786 79.7155C10.2549 79.4076 7.95184 77.8723 6.81639 79.0198C5.6368 80.2072 6.05808 82.0304 7.61883 84.1774C9.18359 86.3244 12.4375 91.1502 12.7304 91.59C13.0193 92.0178 13.1316 92.8254 12.265 92.7495C11.3983 92.6735 5.5405 90.6944 4.76614 93.5211C3.99179 96.3478 9.33606 101.613 9.90981 102.201C10.4836 102.789 10.5157 103.157 10.2829 103.513C10.0502 103.868 8.14443 103.017 6.45529 102.525C4.76614 102.029 2.71991 101.753 2.41097 103.421C2.10203 105.088 4.45319 109.97 13.268 115.095L17.5089 117.206L21.5212 114.655C29.7783 108.674 31.6279 103.584 31.1545 101.957C30.677 100.33 28.6709 100.81 27.042 101.469C25.413 102.129 23.5995 103.165 23.3347 102.837C23.0659 102.505 23.0619 102.133 23.5714 101.493C24.081 100.854 28.8715 95.0804 27.8163 92.3456C26.7611 89.6109 21.132 92.1617 20.2774 92.3257C19.4228 92.4896 19.4549 91.678 19.6996 91.2182C19.9484 90.7544 22.7008 85.6247 24.0408 83.3338C25.3809 81.0428 25.6176 79.1877 24.3217 78.1242C23.0739 77.0967 20.9394 78.8518 19.7518 79.2796C18.5642 79.7075 18.3154 78.1522 18.3154 78.1522C18.3154 78.1522 18.6604 74.3139 18.2392 67.2212C17.8179 60.1245 14.6041 60.6962 14.6041 60.6962"
        fill="#E6E6E6"
      />
      <path
        d="M14.9131 67.0493C14.9131 67.0493 14.9211 67.1013 14.9291 67.1973C14.9372 67.3012 14.9492 67.4451 14.9612 67.6331C14.9893 68.0249 15.0254 68.5886 15.0736 69.3083C15.1699 70.7636 15.2942 72.8746 15.4387 75.4814C15.7276 80.695 16.0686 87.8997 16.4177 95.8561C16.7667 103.816 17.0877 111.021 17.3485 116.235C17.4809 118.838 17.5892 120.945 17.6655 122.408C17.7016 123.132 17.7297 123.695 17.7497 124.087C17.7577 124.275 17.7618 124.419 17.7658 124.523C17.7658 124.623 17.7658 124.671 17.7658 124.671C17.7658 124.671 17.7577 124.619 17.7497 124.523C17.7417 124.419 17.7297 124.275 17.7176 124.087C17.6895 123.695 17.6534 123.132 17.6053 122.412C17.509 120.957 17.3846 118.846 17.2402 116.239C16.9513 111.025 16.6103 103.82 16.2612 95.8601C15.9121 87.9037 15.5912 80.699 15.3304 75.4854C15.198 72.8826 15.0896 70.7756 15.0134 69.3123C14.9773 68.5886 14.9492 68.0249 14.9291 67.6331C14.9211 67.4451 14.9171 67.3012 14.9131 67.1973C14.9131 67.0973 14.9131 67.0493 14.9131 67.0493Z"
        fill="#E6E7E8"
      />
      <path
        d="M9.94977 81.2908C9.94977 81.2908 10.0501 81.3627 10.2146 81.5107C10.3791 81.6586 10.6078 81.8785 10.8886 82.1544C11.4463 82.7061 12.2006 83.4898 13.0151 84.3694C13.8135 85.237 14.5317 86.0246 15.0894 86.6323C15.3382 86.9002 15.5468 87.1281 15.7193 87.316C15.8678 87.4759 15.948 87.5679 15.94 87.5759C15.932 87.5839 15.8397 87.5079 15.6752 87.36C15.5107 87.212 15.282 86.9922 15.0052 86.7123C14.4515 86.1525 13.7092 85.3609 12.8947 84.4813C12.0923 83.6177 11.3661 82.8381 10.8044 82.2343C10.5556 81.9665 10.343 81.7426 10.1704 81.5546C10.022 81.3947 9.94174 81.2988 9.94977 81.2908Z"
        fill="#E6E7E8"
      />
      <path
        d="M16.3579 87.4359C16.3258 87.404 17.8785 85.7847 19.8205 83.8216C21.7664 81.8545 23.3673 80.2912 23.3994 80.3192C23.4314 80.3512 21.8827 81.9705 19.9368 83.9336C17.9909 85.8967 16.39 87.4639 16.3579 87.4359Z"
        fill="#E6E7E8"
      />
      <path
        d="M16.3418 99.6823C16.3418 99.6823 16.4421 99.6063 16.6387 99.4944C16.8634 99.3664 17.1402 99.2145 17.4692 99.0306C18.1674 98.6387 19.1343 98.095 20.1775 97.4553C21.2207 96.8196 22.1475 96.2119 22.8135 95.7641C23.1265 95.5562 23.3872 95.3802 23.6039 95.2363C23.7925 95.1124 23.8968 95.0484 23.9048 95.0604C23.9128 95.0724 23.8166 95.1523 23.64 95.2883C23.4635 95.4282 23.2027 95.6241 22.8777 95.856C22.2277 96.3278 21.3089 96.9515 20.2577 97.5912C19.2105 98.2309 18.2356 98.7627 17.5214 99.1305C17.1643 99.3144 16.8714 99.4544 16.6668 99.5503C16.4622 99.6463 16.3458 99.6903 16.3418 99.6823Z"
        fill="#E6E7E8"
      />
      <path
        d="M9.31556 95.3962C9.31556 95.3962 9.42389 95.4362 9.60845 95.5322C9.79301 95.6281 10.0538 95.7761 10.3788 95.956C11.0248 96.3198 11.9115 96.8356 12.8824 97.4113C13.8574 97.987 14.732 98.5188 15.362 98.9106C15.6789 99.1065 15.9317 99.2665 16.1042 99.3824C16.2768 99.4984 16.369 99.5623 16.365 99.5743C16.361 99.5823 16.2567 99.5343 16.0721 99.4384C15.8876 99.3424 15.6268 99.1945 15.3018 99.0146C14.6558 98.6507 13.7691 98.135 12.7982 97.5592C11.8232 96.9835 10.9485 96.4518 10.3186 96.0599C10.0016 95.864 9.74888 95.7041 9.57635 95.5882C9.40382 95.4722 9.31154 95.4082 9.31556 95.3962Z"
        fill="#E6E7E8"
      />
      <path
        d="M7.92393 106.203C7.92393 106.203 8.06034 106.259 8.29706 106.383C8.55786 106.527 8.89087 106.707 9.28407 106.927C10.1146 107.391 11.2541 108.042 12.5099 108.77C13.7657 109.498 14.8972 110.165 15.7116 110.653C16.0968 110.889 16.4178 111.085 16.6746 111.241C16.8993 111.381 17.0196 111.465 17.0156 111.477C17.0116 111.485 16.8792 111.421 16.6425 111.297C16.3817 111.153 16.0487 110.973 15.6555 110.753C14.8249 110.289 13.6855 109.638 12.4296 108.91C11.1738 108.182 10.0424 107.515 9.2279 107.027C8.84272 106.791 8.52175 106.595 8.26496 106.439C8.04028 106.299 7.91991 106.215 7.92393 106.203Z"
        fill="#E6E7E8"
      />
      <path
        d="M16.8313 111.289C16.8313 111.289 16.9556 111.193 17.2004 111.041C17.4692 110.877 17.8183 110.669 18.2315 110.417C19.1022 109.89 20.3018 109.154 21.6018 108.31C22.9058 107.467 24.0653 106.671 24.8998 106.091C25.297 105.815 25.6301 105.584 25.8909 105.404C26.1276 105.244 26.26 105.16 26.264 105.168C26.272 105.176 26.1516 105.28 25.927 105.456C25.7023 105.632 25.3773 105.883 24.964 106.183C24.1455 106.787 22.994 107.599 21.686 108.446C20.3821 109.294 19.1704 110.014 18.2837 110.517C17.8383 110.769 17.4772 110.965 17.2245 111.097C16.9717 111.229 16.8313 111.297 16.8232 111.289H16.8313Z"
        fill="#E6E7E8"
      />
      <path
        d="M23.2024 118.582H11.4707L15.3024 147.464H19.3748L23.2024 118.582Z"
        fill="#E6E7E8"
      />
      <path
        d="M12.0801 122.132C12.0801 122.132 12.0921 122.172 12.1443 122.236C12.1924 122.296 12.2847 122.376 12.4291 122.436C12.706 122.556 13.1955 122.568 13.6689 122.284C14.1424 122.024 14.5556 121.512 15.1895 121.284C15.8195 121.044 16.5978 121.088 17.3481 121.328C18.1064 121.56 18.7604 121.944 19.3582 122.3C19.9561 122.652 20.5017 122.968 20.9912 123.171C21.4767 123.383 21.902 123.479 22.1949 123.519C22.3433 123.539 22.4557 123.543 22.5359 123.543C22.6121 123.543 22.6563 123.543 22.6563 123.535C22.6643 123.495 21.9943 123.503 21.0474 123.052C20.5739 122.836 20.0403 122.512 19.4505 122.152C18.8607 121.788 18.1867 121.392 17.4083 121.152C16.638 120.905 15.8074 120.861 15.1294 121.128C14.7923 121.256 14.5075 121.456 14.2707 121.656C14.03 121.852 13.8174 122.04 13.6007 122.176C13.1714 122.452 12.722 122.464 12.4572 122.372C12.1884 122.28 12.1001 122.12 12.0841 122.136L12.0801 122.132Z"
        fill="white"
      />
      <path
        d="M12.5095 127.449C12.5095 127.449 12.6018 127.329 12.7864 127.138C12.9749 126.954 13.2799 126.71 13.7252 126.546C14.1666 126.378 14.7323 126.278 15.3582 126.302C15.9881 126.322 16.6702 126.462 17.4004 126.578C18.1266 126.698 18.8488 126.754 19.4908 126.666C20.1327 126.582 20.6904 126.398 21.1358 126.198C21.5811 125.998 21.9101 125.774 22.1228 125.61C22.3395 125.446 22.4438 125.338 22.4357 125.33C22.4157 125.298 21.9623 125.702 21.0796 126.074C20.6383 126.254 20.0926 126.422 19.4667 126.49C18.8408 126.566 18.1507 126.51 17.4285 126.39C16.7063 126.274 16.0122 126.138 15.3622 126.126C14.7122 126.11 14.1265 126.23 13.6731 126.422C13.2157 126.61 12.9067 126.882 12.7302 127.09C12.6459 127.198 12.5858 127.285 12.5537 127.349C12.5176 127.413 12.5055 127.445 12.5095 127.449Z"
        fill="white"
      />
      <path
        d="M21.8741 130.296C21.8741 130.296 21.7698 130.216 21.5491 130.128C21.3324 130.04 20.9994 129.952 20.5781 129.92C20.1569 129.888 19.6553 129.912 19.1097 130.004C18.564 130.092 17.9742 130.244 17.3603 130.372C16.7465 130.5 16.1527 130.588 15.615 130.568C15.0774 130.548 14.5999 130.432 14.2268 130.268C13.4725 129.936 13.2037 129.457 13.1756 129.489C13.1676 129.493 13.2197 129.613 13.3722 129.792C13.5247 129.972 13.7855 130.2 14.1706 130.388C14.5518 130.576 15.0453 130.712 15.607 130.74C16.1687 130.772 16.7786 130.684 17.4005 130.556C18.0224 130.424 18.6041 130.268 19.1418 130.172C19.6754 130.076 20.1649 130.036 20.5741 130.048C21.3966 130.076 21.8661 130.324 21.8781 130.288L21.8741 130.296Z"
        fill="white"
      />
      <path
        d="M13.5451 134.302C13.5491 134.334 13.9623 134.042 14.6845 134.186C15.0376 134.25 15.4308 134.426 15.8762 134.63C16.3175 134.834 16.8511 135.01 17.4249 135.054C18.5764 135.166 19.6035 134.794 20.2294 134.406C20.5504 134.214 20.7751 134.01 20.9275 133.87C21.072 133.719 21.1442 133.631 21.1322 133.623C21.1081 133.595 20.7991 133.938 20.1612 134.29C19.5313 134.638 18.5443 134.97 17.4409 134.866C16.3295 134.802 15.4789 134.142 14.7086 134.054C14.3315 133.998 14.0185 134.054 13.8219 134.13C13.6253 134.206 13.541 134.294 13.5451 134.302Z"
        fill="white"
      />
      <path
        d="M14.1992 139.848C14.1992 139.868 14.5924 139.868 15.1782 139.656C15.768 139.46 16.4982 139.008 17.3488 138.672C17.7781 138.508 18.2034 138.408 18.5966 138.412C18.9858 138.412 19.3349 138.508 19.5997 138.64C20.1373 138.908 20.2938 139.288 20.3259 139.264C20.3339 139.264 20.3098 139.164 20.2135 139.016C20.1172 138.868 19.9407 138.676 19.6639 138.52C19.391 138.364 19.0219 138.248 18.6006 138.236C18.1793 138.224 17.726 138.324 17.2846 138.492C16.4019 138.844 15.6958 139.308 15.1301 139.528C14.5724 139.768 14.1952 139.816 14.2032 139.848H14.1992Z"
        fill="white"
      />
      <path
        d="M14.7726 142.427C14.7645 142.451 15.0454 142.551 15.5148 142.666C15.9843 142.782 16.6382 142.906 17.3725 142.978C18.1067 143.05 18.7727 143.054 19.2542 143.034C19.7357 143.01 20.0326 142.966 20.0286 142.942C20.0286 142.886 18.841 142.934 17.3885 142.79C15.9361 142.651 14.7846 142.371 14.7686 142.427H14.7726Z"
        fill="white"
      />
      <path
        d="M233.408 69.7768L197.731 77.8995L203.226 101.863L238.902 93.74L233.408 69.7768Z"
        fill="white"
      />
      <path
        d="M233.591 70.0279C233.591 70.0279 233.511 70.1719 233.33 70.4237C233.129 70.6996 232.865 71.0635 232.536 71.5192C231.813 72.4908 230.802 73.8462 229.563 75.5094C227.007 78.8839 223.508 83.5057 219.628 88.6234L219.548 88.7313L219.424 88.6714C219.331 88.6274 219.227 88.5794 219.127 88.5274C213.269 85.6927 207.969 83.1299 204.093 81.2548C202.199 80.3232 200.659 79.5596 199.555 79.0158C199.046 78.7559 198.636 78.548 198.328 78.3921C198.051 78.2482 197.906 78.1642 197.91 78.1562C197.914 78.1442 198.071 78.2082 198.356 78.3321C198.673 78.476 199.09 78.664 199.607 78.8999C200.723 79.4196 202.28 80.1513 204.193 81.0429C208.081 82.894 213.393 85.4289 219.267 88.2276C219.368 88.2755 219.472 88.3235 219.564 88.3715L219.36 88.4195C223.267 83.3258 226.794 78.7239 229.37 75.3655C230.646 73.7302 231.685 72.3948 232.427 71.4393C232.776 70.9995 233.057 70.6476 233.27 70.3838C233.467 70.1439 233.571 70.0239 233.583 70.0279H233.591Z"
        fill="#E6E7E8"
      />
      <path
        d="M203.408 102.113C203.327 102.073 205.257 98.175 207.717 93.4132C210.18 88.6474 212.239 84.8211 212.323 84.8651C212.403 84.9051 210.473 88.8033 208.014 93.5651C205.554 98.3269 203.492 102.157 203.408 102.113Z"
        fill="#E6E7E8"
      />
      <path
        d="M224.114 81.8625C224.17 81.7905 227.573 84.4493 231.705 87.7958C235.842 91.1462 239.144 93.917 239.088 93.9889C239.031 94.0609 235.633 91.4021 231.496 88.0557C227.364 84.7092 224.058 81.9345 224.114 81.8665V81.8625Z"
        fill="#E6E7E8"
      />
      <path
        d="M74.4788 4.61982L71.207 18.7417L92.2317 23.5786L95.5034 9.45675L74.4788 4.61982Z"
        fill="white"
      />
      <path
        d="M95.5379 9.64766C95.5379 9.64766 95.4576 9.70363 95.2972 9.79159C95.1206 9.88755 94.8839 10.0115 94.595 10.1674C93.9611 10.4953 93.0744 10.9551 91.9871 11.5228C89.7603 12.6583 86.711 14.2136 83.3327 15.9328L83.2605 15.9688L83.2124 15.9048C83.1762 15.8568 83.1321 15.8048 83.092 15.7529C80.7168 12.7462 78.5662 10.0275 76.9934 8.0404C76.2311 7.06085 75.6092 6.26121 75.1638 5.68948C74.9592 5.4216 74.7987 5.2057 74.6744 5.04577C74.566 4.89784 74.5099 4.81788 74.5139 4.81388C74.5179 4.80988 74.5861 4.88185 74.7065 5.02178C74.8389 5.17771 75.0114 5.38562 75.224 5.6455C75.6814 6.20924 76.3194 6.99288 77.1018 7.95643C78.6866 9.93552 80.8492 12.6423 83.2445 15.6329C83.2846 15.6849 83.3287 15.7369 83.3648 15.7848L83.2445 15.7569C86.6308 14.0576 89.6921 12.5183 91.9269 11.3989C93.0262 10.8591 93.921 10.4153 94.5629 10.0994C94.8638 9.95552 95.1006 9.84357 95.2851 9.75561C95.4496 9.67964 95.5379 9.63966 95.5419 9.64766H95.5379Z"
        fill="#E6E7E8"
      />
      <path
        d="M71.2406 18.9273C71.2085 18.8833 73.2387 17.3121 75.7744 15.4169C78.3101 13.5218 80.3925 12.0185 80.4246 12.0625C80.4567 12.1064 78.4265 13.6777 75.8908 15.5729C73.355 17.468 71.2727 18.9713 71.2406 18.9273Z"
        fill="#E6E7E8"
      />
      <path
        d="M87.4541 13.4938C87.5022 13.4699 88.6217 15.7528 89.9497 18.5875C91.2777 21.4262 92.3169 23.7411 92.2688 23.7651C92.2206 23.7891 91.1012 21.5062 89.7732 18.6715C88.4451 15.8368 87.406 13.5178 87.4581 13.4938H87.4541Z"
        fill="#E6E7E8"
      />
      <path
        d="M207.761 8.16172L192.399 14.7383L196.848 25.0571L212.209 18.4805L207.761 8.16172Z"
        fill="white"
      />
      <path
        d="M207.856 8.24817C207.856 8.24817 207.832 8.32014 207.772 8.44808C207.703 8.58801 207.615 8.77193 207.507 9.00382C207.262 9.49959 206.921 10.1913 206.5 11.0389C205.629 12.7661 204.437 15.129 203.121 17.7478L203.093 17.8038L203.033 17.7878C202.989 17.7758 202.937 17.7598 202.889 17.7478C200.024 16.9562 197.428 16.2365 195.53 15.7127C194.603 15.4489 193.845 15.233 193.308 15.081C193.059 15.0051 192.858 14.9451 192.706 14.9011C192.569 14.8571 192.497 14.8331 192.497 14.8291C192.497 14.8251 192.573 14.8411 192.714 14.8731C192.866 14.9131 193.071 14.9611 193.324 15.0251C193.869 15.169 194.628 15.3689 195.562 15.6128C197.46 16.1246 200.06 16.8282 202.929 17.6039C202.977 17.6159 203.029 17.6319 203.073 17.6439L202.985 17.6838C204.321 15.073 205.525 12.7181 206.403 10.9949C206.841 10.1553 207.198 9.47161 207.455 8.97983C207.575 8.75194 207.671 8.57202 207.747 8.43608C207.816 8.31214 207.852 8.24817 207.86 8.25217L207.856 8.24817Z"
        fill="#E6E7E8"
      />
      <path
        d="M196.942 25.1445C196.902 25.1325 197.448 23.2214 198.158 20.8825C198.872 18.5395 199.482 16.6484 199.522 16.6644C199.562 16.6764 199.017 18.5835 198.303 20.9264C197.588 23.2694 196.979 25.1565 196.938 25.1445H196.942Z"
        fill="#E6E7E8"
      />
      <path
        d="M204.574 14.3454C204.594 14.3094 206.34 15.225 208.474 16.3884C210.609 17.5559 212.322 18.5275 212.302 18.5634C212.282 18.5994 210.537 17.6839 208.402 16.5204C206.268 15.3569 204.554 14.3814 204.574 14.3454Z"
        fill="#E6E7E8"
      />
      <path
        d="M228.094 112.524C226.457 111.501 224.583 110.857 222.658 110.661C221.771 110.569 220.8 110.597 220.086 111.133C219.933 111.245 219.789 111.417 219.821 111.601C219.845 111.737 219.961 111.837 220.074 111.921C222.284 113.604 225.631 113.816 228.094 112.524Z"
        fill="#E5444B"
      />
      <path
        d="M227.955 112.372C226.723 111.417 225.487 110.453 224.444 109.294C223.401 108.134 222.554 106.763 222.241 105.236C222.145 104.772 222.117 104.244 222.414 103.88C225.21 106.059 227.409 108.874 227.951 112.372"
        fill="#E5444B"
      />
      <path
        d="M228.066 112.504C227.854 110.977 227.781 109.41 228.13 107.91C228.484 106.411 229.302 104.976 230.586 104.12C231.071 105.248 230.863 106.543 230.522 107.722C229.996 109.542 229.17 111.273 228.086 112.832"
        fill="#E5444B"
      />
      <path
        d="M230.811 117.402C229.146 117.602 227.693 118.478 226.453 119.601C225.21 120.725 224.327 122.236 223.962 123.867C225.595 123.767 226.979 122.62 227.986 121.332C228.989 120.045 229.687 118.634 230.791 117.43"
        fill="#E5444B"
      />
      <path
        d="M231.405 118.186C230.065 114.895 230.551 110.925 232.645 108.05C233.592 111.413 233.247 115.211 231.405 118.186Z"
        fill="#E5444B"
      />
      <path
        d="M235.028 122.488C233.515 122.396 231.979 122.752 230.667 123.507C229.355 124.259 228.271 125.402 227.593 126.754C227.501 126.938 227.421 127.17 227.545 127.334C227.654 127.477 227.858 127.489 228.039 127.477C229.503 127.381 230.803 126.506 231.874 125.506C232.946 124.507 233.881 123.351 235.064 122.488"
        fill="#E5444B"
      />
      <path
        d="M234.915 122.252C234.137 119.765 234.088 117.058 234.779 114.547C235.051 113.556 235.509 112.512 236.428 112.045C236.508 112.005 236.596 111.969 236.68 112.001C236.757 112.029 236.805 112.109 236.841 112.184C237.17 112.868 237.262 113.64 237.23 114.395C237.198 115.151 237.037 115.895 236.881 116.634C236.592 118.002 236.436 119.473 235.685 120.653C235.505 120.932 235.348 121.236 235.208 121.536C235.063 121.836 235.063 121.94 234.935 122.18"
        fill="#E5444B"
      />
      <path
        d="M244.508 123.679C243.798 120.984 244.552 117.718 246.258 115.511C246.382 115.347 246.551 115.175 246.755 115.191C247.068 115.219 247.148 115.635 247.136 115.951C247.012 118.705 246.093 121.416 244.512 123.679"
        fill="#E5444B"
      />
      <path
        d="M245.375 124.267C248.661 125.118 252.428 124.291 255.161 122.284C253.54 121.464 251.634 121.232 249.865 121.636C248.095 122.04 246.478 122.828 245.375 124.267Z"
        fill="#E5444B"
      />
      <path
        d="M246.102 122.472C247.943 119.481 251.47 117.53 254.985 117.354C254.579 118.777 253.404 119.885 252.08 120.553C250.756 121.22 249.275 121.512 247.815 121.796C247.249 121.904 246.471 122.028 246.102 122.472Z"
        fill="#E5444B"
      />
      <path
        d="M244.693 113.768C245.19 112.068 245.692 110.349 245.828 108.586C245.965 106.823 245.712 104.976 244.793 103.46C243.076 106.775 242.899 110.645 244.641 113.948"
        fill="#E5444B"
      />
      <path
        d="M250.479 110.853C252.489 107.215 256.589 104.304 260.738 103.896C259.137 107.866 254.772 110.937 250.479 110.853Z"
        fill="#E5444B"
      />
      <path
        d="M249.211 111.565C252.208 112.021 254.764 113.928 257.564 115.083C258.162 115.331 258.808 115.527 259.454 115.443C260.096 115.359 260.73 114.931 260.874 114.3C260.995 113.776 260.754 113.22 260.377 112.836C260 112.452 259.502 112.212 259.005 112.009C255.955 110.741 252.682 110.713 249.468 111.481"
        fill="#E5444B"
      />
      <path
        d="M247.478 113.66C248.156 113.852 248.388 114.203 248.926 114.659C250.747 116.207 252.934 117.326 255.261 117.902C255.502 117.962 255.819 117.986 255.939 117.766C256.044 117.574 255.911 117.346 255.775 117.174C254.8 115.959 253.412 115.131 251.951 114.587C250.491 114.044 249.223 113.392 247.478 113.532"
        fill="#E5444B"
      />
      <path
        d="M245.52 101.01C247.955 103.277 251.39 104.44 254.708 104.124C254.86 104.108 255.025 104.084 255.125 103.976C255.358 103.724 255.077 103.337 254.808 103.129C252.108 101.03 248.834 100.158 245.516 101.01"
        fill="#E5444B"
      />
      <path
        d="M247.843 100.162C251.065 97.7551 255.482 97.0274 259.31 98.2749C257.865 99.6942 255.863 100.458 253.845 100.666C251.827 100.874 249.809 100.654 247.843 100.162Z"
        fill="#E5444B"
      />
      <path
        d="M246.92 94.3487C246.655 91.6619 246.828 89.5389 246.639 86.8401C246.623 86.6242 246.579 86.3643 246.374 86.2884C246.093 86.1804 245.857 86.5243 245.764 86.8121C244.962 89.299 245.399 92.1537 246.916 94.2887"
        fill="#E5444B"
      />
      <path
        d="M246.728 94.6445C248.557 93.5171 249.925 92.0457 251.193 90.3185C252.076 89.1151 252.818 87.6757 252.617 86.1964C251.378 86.1724 250.202 86.8521 249.396 87.7917C248.589 88.7313 248.112 89.9027 247.755 91.0862C247.398 92.2696 247.145 93.4851 246.728 94.6445Z"
        fill="#E5444B"
      />
      <path
        d="M251.113 93.429C255.125 91.9497 258.868 89.7507 262.106 86.968C260.794 86.4402 259.29 86.7561 257.978 87.2838C254.977 88.4913 252.529 90.5264 251.057 93.397"
        fill="#E5444B"
      />
      <path
        d="M247.959 96.1158C249.989 96.3357 252.052 96.4797 254.062 96.1438C256.04 95.816 257.909 95.0323 259.759 94.2567C259.912 94.1927 260.08 94.1167 260.144 93.9648C260.217 93.8009 260.14 93.601 260.004 93.477C259.871 93.3571 259.691 93.2971 259.518 93.2491C257.508 92.6894 255.338 92.9493 253.36 93.617C251.382 94.2807 249.761 95.0723 247.951 96.1118"
        fill="#E5444B"
      />
      <path
        d="M236.372 101.102C234.988 98.3148 235.076 95.0683 236.272 92.1976C237.114 93.477 237.215 95.1043 237.062 96.6236C236.91 98.1469 236.444 99.7102 236.412 101.241"
        fill="#E5444B"
      />
      <path
        d="M230.839 98.3188C227.381 98.2548 223.926 99.7221 221.579 102.249C223.304 102.521 225.082 102.077 226.651 101.313C228.219 100.55 229.616 99.4782 230.996 98.4147"
        fill="#E5444B"
      />
      <path
        d="M227.124 97.0794C225.434 96.9434 223.806 96.7355 222.181 97.2313C220.556 97.7271 219.075 98.8545 218.413 100.414C219.994 100.59 221.599 100.198 223.047 99.5422C224.496 98.8865 225.816 97.9789 227.124 97.0794Z"
        fill="#E5444B"
      />
      <path
        d="M232.628 97.1394C231.107 96.1479 229.543 95.1363 227.773 94.7245C226.004 94.3127 223.95 94.6166 222.714 95.9479C224.146 96.7876 225.888 96.8835 227.549 96.8835C229.21 96.8835 231.248 96.8635 232.817 97.4153"
        fill="#E5444B"
      />
      <path
        d="M232.636 97.2193C230.036 94.6724 229.049 90.1025 230.096 86.6241C232.143 89.7187 233.057 93.537 232.636 97.2193Z"
        fill="#E5444B"
      />
      <path
        d="M239.79 90.0066C240.28 88.8711 240.625 87.6757 241.066 86.5202C241.507 85.3647 242.053 84.2253 242.883 83.3057C243.714 82.3861 244.865 81.7024 246.105 81.6425C245.331 85.1448 242.996 88.2714 239.854 90.0226"
        fill="#E5444B"
      />
      <path
        d="M238.494 91.51C237.684 90.3985 236.745 88.8872 235.645 88.0556C234.546 87.2239 233.11 86.7202 231.77 87.04C233.138 89.4749 235.718 91.1901 238.498 91.51"
        fill="#E5444B"
      />
      <path
        d="M238.755 90.0986C238.222 88.7512 237.688 87.3919 237.471 85.9565C237.255 84.5252 237.387 82.9939 238.121 81.7465C239.55 84.4372 240.007 87.3239 238.827 90.1266"
        fill="#E5444B"
      />
      <path
        d="M239.802 81.7504C240.612 79.0837 243.06 77.1206 245.704 76.221C246.037 76.109 246.39 76.0131 246.739 76.065C247.088 76.117 247.429 76.3569 247.501 76.7008C247.598 77.1525 247.22 77.5604 246.867 77.8602C244.809 79.6154 242.386 80.9468 239.798 81.7504"
        fill="#E5444B"
      />
      <path
        d="M239.83 79.5674C239.935 77.8482 240.039 76.105 240.601 74.4778C241.162 72.8505 242.242 71.3232 243.814 70.6075C244.083 72.3427 243.313 74.07 242.386 75.5653C241.459 77.0606 240.34 78.4679 239.786 80.1352"
        fill="#E5444B"
      />
      <path
        d="M239.791 80.7509C239.121 78.8078 239.052 76.3409 237.885 74.6457C237.07 73.4623 235.762 72.6546 235.06 71.4032C234.595 72.5747 234.735 73.922 235.225 75.0855C235.714 76.249 236.521 77.2485 237.383 78.1681C238.246 79.0877 238.988 79.7794 239.791 80.7509Z"
        fill="#E5444B"
      />
      <path
        d="M239.802 137.969C239.75 137.969 239.706 125.007 239.702 109.022C239.702 93.0332 239.738 80.0752 239.79 80.0752C239.842 80.0752 239.886 93.0332 239.89 109.022C239.89 125.007 239.854 137.969 239.802 137.969Z"
        fill="#414042"
      />
      <path
        d="M251.169 99.1465C251.169 99.1465 250.997 99.2225 250.68 99.3304C250.327 99.4503 249.877 99.6023 249.336 99.7822C248.2 100.166 246.643 100.726 244.95 101.417C243.273 102.133 241.696 102.717 240.794 103.492C240.344 103.876 240.087 104.292 239.959 104.592C239.831 104.896 239.807 105.084 239.791 105.08C239.782 105.08 239.786 105.032 239.795 104.944C239.811 104.856 239.831 104.728 239.891 104.568C239.999 104.248 240.252 103.804 240.705 103.397C241.159 102.985 241.789 102.617 242.507 102.281C243.229 101.945 244.027 101.597 244.874 101.25C246.571 100.558 248.14 100.018 249.291 99.6623C249.865 99.4823 250.335 99.3544 250.656 99.2664C250.981 99.1825 251.161 99.1425 251.165 99.1545L251.169 99.1465Z"
        fill="#414042"
      />
      <path
        d="M254.892 90.0267C254.892 90.0267 254.848 90.0826 254.756 90.1706C254.644 90.2745 254.507 90.4025 254.339 90.5624C253.949 90.9142 253.424 91.39 252.782 91.9738C251.438 93.1692 249.612 94.7845 247.594 96.5756C245.568 98.3708 243.819 100.086 242.607 101.377C242.001 102.021 241.524 102.557 241.195 102.929C241.042 103.101 240.918 103.245 240.818 103.357C240.729 103.452 240.681 103.504 240.677 103.5C240.673 103.496 240.709 103.44 240.789 103.333C240.87 103.229 240.982 103.073 241.138 102.881C241.447 102.493 241.913 101.945 242.507 101.285C243.698 99.9661 245.436 98.2309 247.466 96.4317C249.496 94.6405 251.346 93.0293 252.69 91.8698C253.348 91.3061 253.889 90.8423 254.287 90.5024C254.463 90.3545 254.611 90.2346 254.728 90.1386C254.828 90.0586 254.884 90.0147 254.888 90.0227L254.892 90.0267Z"
        fill="#414042"
      />
      <path
        d="M245.412 98.3988C245.359 98.3748 245.781 97.4033 246.202 96.1718C246.627 94.9404 246.828 93.9049 246.88 93.9129C246.928 93.9169 246.808 94.9884 246.379 96.2318C246.166 96.8555 245.933 97.3993 245.749 97.7911C245.564 98.1789 245.432 98.4108 245.408 98.3988H245.412Z"
        fill="#414042"
      />
      <path
        d="M239.814 106.243C239.814 106.243 239.694 106.123 239.501 105.883C239.289 105.611 239.02 105.272 238.703 104.864C238.033 104 237.102 102.805 236.063 101.501C235.024 100.194 234.065 99.0224 233.375 98.1748C233.05 97.771 232.773 97.4352 232.556 97.1673C232.364 96.9274 232.263 96.7915 232.275 96.7835C232.283 96.7755 232.404 96.8954 232.612 97.1193C232.821 97.3432 233.114 97.675 233.475 98.0869C234.193 98.9145 235.168 100.074 236.211 101.381C237.255 102.689 238.165 103.896 238.811 104.784C239.132 105.228 239.389 105.587 239.562 105.839C239.734 106.091 239.826 106.235 239.814 106.243Z"
        fill="#414042"
      />
      <path
        d="M237.323 103.057C237.291 103.089 236.629 102.305 236.28 101.11C236.023 100.266 236.011 99.4983 236.043 99.1465L236.127 99.1545C236.091 99.3064 236.063 99.3823 236.047 99.3823C236.031 99.3823 236.031 99.2944 236.047 99.1425L236.131 99.1505C236.163 99.4983 236.216 100.242 236.464 101.062C236.801 102.217 237.379 103.033 237.327 103.065L237.323 103.057Z"
        fill="#414042"
      />
      <path
        d="M234.105 99.0224C234.081 99.0504 233.868 98.7786 233.375 98.5986C232.889 98.4107 232.155 98.3707 231.352 98.4387C230.546 98.4987 229.828 98.6386 229.314 98.7666C228.801 98.8945 228.484 98.9945 228.476 98.9665C228.468 98.9425 228.765 98.7985 229.278 98.6386C229.788 98.4787 230.518 98.3108 231.336 98.2508C232.151 98.1828 232.921 98.2428 233.423 98.4747C233.932 98.6986 234.117 99.0144 234.101 99.0184L234.105 99.0224Z"
        fill="#414042"
      />
      <path
        d="M233.343 98.047C233.326 98.075 232.917 97.8791 232.227 97.6432C231.537 97.4073 230.55 97.1594 229.443 97.0714C228.331 96.9875 227.32 97.0874 226.602 97.2154C225.884 97.3433 225.447 97.4792 225.438 97.4473C225.438 97.4353 225.539 97.3913 225.735 97.3233C225.928 97.2513 226.217 97.1674 226.578 97.0874C227.3 96.9235 228.327 96.8036 229.459 96.8875C230.59 96.9755 231.589 97.2513 232.275 97.5232C232.62 97.6592 232.893 97.7831 233.074 97.8831C233.254 97.983 233.355 98.039 233.347 98.051L233.343 98.047Z"
        fill="#414042"
      />
      <path
        d="M239.79 93.9129C239.738 93.9249 239.376 92.6615 238.975 91.0942C238.574 89.5229 238.293 88.2395 238.341 88.2275C238.393 88.2155 238.755 89.4749 239.156 91.0462C239.557 92.6175 239.838 93.8969 239.79 93.9129Z"
        fill="#414042"
      />
      <path
        d="M239.842 93.9128C239.786 93.9328 239.553 93.0892 238.939 92.2256C238.341 91.346 237.583 90.9022 237.611 90.8582C237.619 90.8382 237.832 90.9142 238.125 91.1221C238.418 91.326 238.783 91.6698 239.096 92.1176C239.73 93.0132 239.89 93.9088 239.846 93.9128H239.842Z"
        fill="#414042"
      />
      <path
        d="M239.802 131.06C239.782 131.06 239.742 130.748 239.597 130.196C239.453 129.644 239.18 128.865 238.727 127.953C237.828 126.122 236.191 123.803 234.117 121.48C233.085 120.309 232.086 119.201 231.24 118.142C230.389 117.086 229.691 116.079 229.181 115.187C228.672 114.295 228.339 113.532 228.142 112.992C228.046 112.72 227.978 112.512 227.938 112.364C227.898 112.22 227.878 112.14 227.886 112.14C227.902 112.136 227.998 112.436 228.211 112.964C228.423 113.492 228.772 114.243 229.294 115.115C229.815 115.991 230.518 116.982 231.372 118.03C232.223 119.077 233.222 120.177 234.257 121.352C236.339 123.683 237.972 126.03 238.847 127.893C239.288 128.821 239.545 129.616 239.665 130.176C239.726 130.456 239.766 130.676 239.782 130.828C239.802 130.976 239.806 131.056 239.802 131.056V131.06Z"
        fill="#414042"
      />
      <path
        d="M250.074 119.501C250.074 119.501 249.885 119.613 249.536 119.801C249.191 119.993 248.701 120.293 248.14 120.721C247.578 121.148 246.952 121.712 246.306 122.388C245.664 123.063 244.998 123.851 244.324 124.695C242.972 126.386 241.704 127.881 240.95 129.077C240.565 129.668 240.284 130.168 240.103 130.52C239.919 130.868 239.822 131.068 239.806 131.06C239.802 131.06 239.818 131.004 239.854 130.908C239.895 130.812 239.951 130.668 240.035 130.488C240.199 130.124 240.464 129.612 240.837 129.009C241.572 127.785 242.827 126.27 244.18 124.579C244.858 123.735 245.528 122.947 246.186 122.268C246.844 121.592 247.486 121.032 248.063 120.617C248.641 120.197 249.147 119.913 249.504 119.737C249.68 119.649 249.829 119.593 249.925 119.553C250.021 119.513 250.074 119.497 250.078 119.501H250.074Z"
        fill="#414042"
      />
      <path
        d="M250.274 123.251C250.274 123.279 249.841 123.331 249.135 123.415C248.433 123.507 247.458 123.623 246.435 123.943C245.416 124.275 244.569 124.779 244.019 125.206C243.462 125.634 243.201 125.99 243.173 125.97C243.165 125.962 243.217 125.866 243.341 125.71C243.462 125.55 243.662 125.338 243.935 125.106C244.477 124.639 245.331 124.107 246.378 123.767C247.426 123.435 248.413 123.339 249.123 123.283C249.833 123.231 250.27 123.227 250.274 123.251Z"
        fill="#414042"
      />
      <path
        d="M243.51 125.558C243.45 125.526 243.963 124.743 244.36 123.695C244.733 122.636 244.822 121.708 244.886 121.716C244.91 121.716 244.91 121.952 244.866 122.324C244.822 122.696 244.733 123.211 244.533 123.755C244.332 124.299 244.071 124.755 243.875 125.071C243.67 125.386 243.53 125.57 243.506 125.554L243.51 125.558Z"
        fill="#414042"
      />
      <path
        d="M253.4 109.43C253.4 109.43 253.34 109.47 253.219 109.526C253.079 109.59 252.902 109.67 252.686 109.77C252.453 109.869 252.168 109.997 251.847 110.165C251.526 110.329 251.149 110.501 250.76 110.733C250.563 110.845 250.355 110.961 250.138 111.085C249.921 111.209 249.709 111.353 249.484 111.497C249.026 111.773 248.569 112.112 248.08 112.46C247.121 113.184 246.114 114.04 245.147 115.031C244.188 116.031 243.361 117.062 242.667 118.042C242.334 118.541 242.009 119.009 241.748 119.473C241.616 119.705 241.475 119.921 241.359 120.141C241.243 120.361 241.134 120.569 241.026 120.772C240.805 121.168 240.649 121.548 240.493 121.876C240.336 122.2 240.216 122.488 240.123 122.724C240.031 122.943 239.959 123.123 239.899 123.263C239.847 123.387 239.814 123.451 239.806 123.447C239.798 123.447 239.818 123.379 239.863 123.251C239.915 123.103 239.975 122.923 240.055 122.696C240.135 122.456 240.244 122.16 240.396 121.832C240.541 121.5 240.693 121.116 240.906 120.713C241.01 120.509 241.118 120.293 241.227 120.073C241.339 119.853 241.48 119.629 241.608 119.397C241.865 118.925 242.19 118.45 242.519 117.946C243.209 116.954 244.039 115.911 245.006 114.903C245.981 113.908 247 113.044 247.971 112.324C248.469 111.977 248.93 111.641 249.396 111.369C249.624 111.229 249.841 111.085 250.062 110.965C250.282 110.845 250.491 110.733 250.692 110.621C251.089 110.393 251.47 110.229 251.799 110.073C252.124 109.913 252.417 109.798 252.654 109.706C252.874 109.622 253.059 109.55 253.203 109.494C253.328 109.446 253.396 109.426 253.4 109.434V109.43Z"
        fill="#414042"
      />
      <path
        d="M245.271 114.859C245.271 114.859 245.343 114.543 245.716 114.196C246.081 113.852 246.743 113.532 247.517 113.508C248.288 113.488 248.958 113.704 249.411 113.876C249.869 114.056 250.137 114.2 250.129 114.224C250.117 114.252 249.832 114.148 249.371 114.004C248.91 113.864 248.256 113.676 247.525 113.696C246.799 113.72 246.169 113.996 245.804 114.296C245.431 114.595 245.299 114.875 245.271 114.859Z"
        fill="#414042"
      />
      <path
        d="M244.055 116.107C244.003 116.079 244.46 115.407 244.564 114.447C244.685 113.488 244.392 112.732 244.448 112.716C244.468 112.708 244.572 112.884 244.661 113.2C244.749 113.512 244.809 113.972 244.749 114.471C244.689 114.971 244.528 115.403 244.368 115.687C244.207 115.975 244.067 116.123 244.051 116.107H244.055Z"
        fill="#414042"
      />
      <path
        d="M230.819 132.915C231.1 137.869 232.597 142.906 234.9 148H244.918C247.117 142.958 248.509 137.929 248.758 132.915H230.819Z"
        fill="#808080"
      />
      <path
        d="M247.136 140.931C247.136 140.983 243.838 141.023 239.77 141.023C235.701 141.023 232.403 140.983 232.403 140.931C232.403 140.879 235.701 140.839 239.77 140.839C243.838 140.839 247.136 140.879 247.136 140.931Z"
        fill="white"
      />
      <path
        d="M246.912 142.726C246.912 142.778 243.818 142.818 240.007 142.818C236.195 142.818 233.102 142.778 233.102 142.726C233.102 142.674 236.195 142.634 240.007 142.634C243.818 142.634 246.912 142.674 246.912 142.726Z"
        fill="white"
      />
      <path
        d="M202.544 43.9758H59.9581C56.7296 43.9758 54.1123 46.5839 54.1123 49.8012V139.816C54.1123 143.033 56.7296 145.641 59.9581 145.641H202.544C205.773 145.641 208.39 143.033 208.39 139.816V49.8012C208.39 46.5839 205.773 43.9758 202.544 43.9758Z"
        fill="#808080"
      />
      <path
        d="M48.082 139.072H215.227V140.851C215.227 144.778 212.029 147.968 208.085 147.968H55.2238C51.2838 147.968 48.082 144.782 48.082 140.851V139.072Z"
        fill="#808080"
      />
      <path
        d="M203.816 48.0139H58.2529V133.866H203.816V48.0139Z"
        fill="#414042"
      />
      <path
        d="M116.041 138.121L118.115 141.407H147.304L149.23 138.121H116.041Z"
        fill="#414042"
      />
      <path
        d="M62.5659 50.9207H199.804C200.53 50.9207 201.116 51.5084 201.116 52.2281V132.283C201.116 133.007 200.526 133.591 199.804 133.591H62.5659C61.8397 133.591 61.2539 133.003 61.2539 132.283V52.2281C61.2539 51.5044 61.8437 50.9207 62.5659 50.9207Z"
        fill="#E6E6E6"
      />
      <path
        d="M200.967 133.715H61.1016L61.254 57.0978H200.967V133.715Z"
        fill="white"
      />
      <path
        d="M72.7249 54.1152C72.7249 54.7309 72.2234 55.2266 71.6095 55.2266C70.9957 55.2266 70.4941 54.7269 70.4941 54.1152C70.4941 53.5034 70.9957 53.0037 71.6095 53.0037C72.2234 53.0037 72.7249 53.5034 72.7249 54.1152Z"
        fill="#414042"
      />
      <path
        d="M65.9525 54.1152C65.9525 54.7309 65.4509 55.2266 64.8371 55.2266C64.2232 55.2266 63.7217 54.7269 63.7217 54.1152C63.7217 53.5034 64.2232 53.0037 64.8371 53.0037C65.4509 53.0037 65.9525 53.5034 65.9525 54.1152Z"
        fill="#414042"
      />
      <path
        d="M69.2025 54.1152C69.2025 54.7309 68.7009 55.2266 68.0871 55.2266C67.4732 55.2266 66.9717 54.7269 66.9717 54.1152C66.9717 53.5034 67.4732 53.0037 68.0871 53.0037C68.7009 53.0037 69.2025 53.5034 69.2025 54.1152Z"
        fill="#414042"
      />
      <path
        d="M207.062 138.121C207.062 138.177 172.822 138.221 130.589 138.221C88.3567 138.221 54.1084 138.177 54.1084 138.121C54.1084 138.065 88.3446 138.021 130.589 138.021C172.834 138.021 207.062 138.065 207.062 138.121Z"
        fill="#414042"
      />
      <path
        d="M268 147.628C268 147.684 208.001 147.732 134.008 147.732C60.0147 147.732 0 147.684 0 147.628C0 147.572 59.9866 147.524 134.008 147.524C208.029 147.524 268 147.572 268 147.628Z"
        fill="#414042"
      />
      <path
        d="M144.736 70.5715C144.792 70.5795 144.816 70.8034 144.792 71.0792C144.764 71.3551 144.696 71.571 144.64 71.567C144.584 71.563 144.56 71.3351 144.584 71.0592C144.612 70.7834 144.68 70.5675 144.736 70.5715Z"
        fill="#414042"
      />
      <path
        d="M145.27 66.6772C145.326 66.6852 145.306 67.129 145.226 67.6648C145.146 68.2005 145.029 68.6283 144.973 68.6203C144.917 68.6123 144.937 68.1725 145.017 67.6328C145.098 67.097 145.214 66.6692 145.27 66.6772Z"
        fill="#414042"
      />
      <path
        d="M146.057 62.8271C146.113 62.8391 146.065 63.2789 145.945 63.8107C145.828 64.3384 145.684 64.7582 145.628 64.7462C145.572 64.7342 145.62 64.2944 145.74 63.7627C145.857 63.2309 146.001 62.8111 146.057 62.8271Z"
        fill="#414042"
      />
      <path
        d="M147.128 59.0448C147.184 59.0608 147.1 59.4966 146.944 60.0163C146.787 60.5361 146.615 60.9439 146.558 60.9279C146.502 60.9119 146.587 60.4761 146.743 59.9564C146.899 59.4366 147.072 59.0288 147.128 59.0448Z"
        fill="#414042"
      />
      <path
        d="M148.504 55.3624C148.557 55.3864 148.436 55.8102 148.236 56.314C148.035 56.8178 147.826 57.2096 147.774 57.1896C147.722 57.1696 147.838 56.7418 148.043 56.238C148.244 55.7303 148.452 55.3424 148.504 55.3624Z"
        fill="#414042"
      />
      <path
        d="M150.213 51.8241C150.265 51.8521 150.105 52.2639 149.856 52.7437C149.607 53.2235 149.367 53.5953 149.315 53.5713C149.262 53.5473 149.423 53.1355 149.672 52.6517C149.92 52.168 150.161 51.7961 150.213 51.8241Z"
        fill="#414042"
      />
      <path
        d="M152.276 48.4736C152.324 48.5056 152.115 48.8974 151.814 49.3452C151.526 49.805 151.249 50.1489 151.201 50.1209C151.152 50.0929 151.349 49.6931 151.642 49.2333C151.947 48.7815 152.232 48.4417 152.28 48.4736H152.276Z"
        fill="#414042"
      />
      <path
        d="M154.707 45.3791C154.751 45.4191 154.503 45.7869 154.158 46.2027C153.813 46.6185 153.492 46.9264 153.448 46.8904C153.403 46.8544 153.648 46.4866 153.997 46.0668C154.346 45.647 154.663 45.3391 154.707 45.3791Z"
        fill="#414042"
      />
      <path
        d="M157.484 42.5924C157.52 42.6364 157.235 42.9722 156.838 43.344C156.445 43.7159 156.092 43.9837 156.052 43.9438C156.011 43.9038 156.3 43.5679 156.698 43.1921C157.095 42.8163 157.448 42.5484 157.484 42.5924Z"
        fill="#414042"
      />
      <path
        d="M160.59 40.1694C160.622 40.2174 160.293 40.5132 159.855 40.8331C159.418 41.153 159.033 41.3769 158.997 41.3289C158.961 41.2849 159.29 40.985 159.731 40.6652C160.172 40.3413 160.558 40.1214 160.59 40.1694Z"
        fill="#414042"
      />
      <path
        d="M163.992 38.1784C164.016 38.2304 163.651 38.4822 163.173 38.7381C162.696 38.994 162.287 39.1659 162.254 39.114C162.226 39.066 162.591 38.8141 163.073 38.5542C163.554 38.2943 163.964 38.1264 163.992 38.1784Z"
        fill="#414042"
      />
      <path
        d="M167.643 36.6991C167.659 36.7551 167.262 36.947 166.752 37.1309C166.242 37.3148 165.813 37.4228 165.789 37.3668C165.765 37.3148 166.166 37.1189 166.68 36.935C167.193 36.7471 167.627 36.6431 167.643 36.6991Z"
        fill="#414042"
      />
      <path
        d="M171.478 35.8114C171.486 35.8674 171.061 35.9833 170.527 36.0753C169.998 36.1872 169.556 36.2312 169.544 36.1753C169.532 36.1193 169.953 35.9833 170.491 35.8714C171.029 35.7794 171.474 35.7554 171.482 35.8114H171.478Z"
        fill="#414042"
      />
      <path
        d="M175.414 35.5916C175.414 35.6475 174.973 35.6995 174.431 35.7075C173.89 35.7155 173.448 35.6715 173.444 35.6155C173.444 35.5596 173.882 35.5076 174.427 35.4996C174.973 35.4916 175.418 35.5356 175.414 35.5916Z"
        fill="#414042"
      />
      <path
        d="M179.33 36.0514C179.318 36.1074 178.872 36.0834 178.339 35.9994C177.805 35.9115 177.376 35.7955 177.38 35.7395C177.388 35.6836 177.829 35.7075 178.371 35.7915C178.913 35.8795 179.338 35.9954 179.326 36.0514H179.33Z"
        fill="#414042"
      />
      <path
        d="M183.105 37.1749C183.085 37.2268 182.652 37.1269 182.138 36.951C181.625 36.7751 181.224 36.5871 181.24 36.5312C181.256 36.4752 181.689 36.5751 182.207 36.7551C182.724 36.935 183.125 37.1229 183.105 37.1749Z"
        fill="#414042"
      />
      <path
        d="M186.617 38.958C186.585 39.0059 186.179 38.83 185.71 38.5621C185.24 38.2943 184.879 38.0344 184.903 37.9864C184.928 37.9384 185.337 38.1143 185.81 38.3822C186.288 38.6541 186.645 38.91 186.617 38.958Z"
        fill="#414042"
      />
      <path
        d="M189.706 41.3929C189.665 41.4329 189.3 41.177 188.895 40.8211C188.49 40.4653 188.189 40.1415 188.221 40.0935C188.257 40.0495 188.618 40.3014 189.032 40.6652C189.445 41.0251 189.746 41.3489 189.706 41.3929Z"
        fill="#414042"
      />
      <path
        d="M191.656 43.6319C191.607 43.6638 191.435 43.5159 191.266 43.3C191.098 43.0841 190.998 42.8762 191.042 42.8402C191.086 42.8042 191.262 42.9522 191.431 43.1721C191.603 43.392 191.704 43.5959 191.656 43.6319Z"
        fill="#414042"
      />
      <path
        d="M210.208 67.7208C210.184 67.7728 209.759 67.6289 209.253 67.393C208.748 67.1611 208.363 66.9292 208.387 66.8772C208.411 66.8253 208.84 66.9732 209.342 67.2051C209.843 67.437 210.228 67.6689 210.208 67.7208Z"
        fill="#414042"
      />
      <path
        d="M213.996 69.0323C213.984 69.0882 213.538 69.0003 213.005 68.8404C212.471 68.6764 212.058 68.5045 212.078 68.4485C212.098 68.3926 212.539 68.4805 213.069 68.6404C213.594 68.8004 214.012 68.9763 213.996 69.0323Z"
        fill="#414042"
      />
      <path
        d="M217.956 69.66C217.956 69.716 217.503 69.716 216.949 69.656C216.395 69.596 215.954 69.5001 215.962 69.4441C215.97 69.3881 216.423 69.3881 216.969 69.4481C217.515 69.5081 217.956 69.604 217.956 69.66Z"
        fill="#414042"
      />
      <path
        d="M221.96 69.536C221.968 69.592 221.523 69.672 220.969 69.7159C220.415 69.7559 219.962 69.7439 219.962 69.688C219.962 69.632 220.403 69.552 220.953 69.508C221.503 69.4681 221.952 69.4801 221.96 69.536Z"
        fill="#414042"
      />
      <path
        d="M225.901 68.8004C225.917 68.8563 225.491 69.0083 224.95 69.1402C224.404 69.2561 223.955 69.2921 223.943 69.2361C223.931 69.1762 224.364 69.0522 224.902 68.9363C225.435 68.8043 225.881 68.7444 225.897 68.8004H225.901Z"
        fill="#414042"
      />
      <path
        d="M229.623 67.329C229.651 67.381 229.262 67.6089 228.756 67.8408C228.251 68.0727 227.822 68.2166 227.802 68.1647C227.781 68.1127 228.171 67.8808 228.668 67.6529C229.17 67.425 229.595 67.2811 229.623 67.329Z"
        fill="#414042"
      />
      <path
        d="M232.95 65.102C232.986 65.146 232.673 65.4739 232.227 65.8097C231.782 66.1456 231.377 66.3535 231.349 66.3055C231.317 66.2575 231.662 65.9736 232.103 65.6418C232.544 65.3099 232.909 65.0581 232.95 65.102Z"
        fill="#414042"
      />
      <path
        d="M235.678 62.1834C235.726 62.2154 235.501 62.6112 235.144 63.039C234.787 63.4668 234.442 63.7627 234.402 63.7227C234.358 63.6827 234.635 63.3269 234.984 62.9071C235.333 62.4833 235.63 62.1474 235.678 62.1834Z"
        fill="#414042"
      />
      <path
        d="M237.555 58.665C237.607 58.685 237.503 59.1248 237.274 59.6326C237.046 60.1403 236.789 60.5162 236.737 60.4882C236.685 60.4602 236.861 60.0484 237.082 59.5486C237.307 59.0488 237.495 58.645 237.551 58.665H237.555Z"
        fill="#414042"
      />
      <path
        d="M238.298 54.7548C238.354 54.7548 238.402 55.2066 238.342 55.7623C238.286 56.318 238.146 56.7538 238.094 56.7418C238.037 56.7299 238.082 56.2861 238.134 55.7423C238.19 55.1986 238.238 54.7548 238.294 54.7548H238.298Z"
        fill="#414042"
      />
      <path
        d="M237.72 50.8126C237.772 50.7966 237.957 51.2124 238.081 51.7561C238.205 52.2999 238.222 52.7557 238.165 52.7597C238.109 52.7677 238.001 52.3319 237.876 51.8001C237.756 51.2684 237.664 50.8286 237.72 50.8126Z"
        fill="#414042"
      />
      <path
        d="M235.974 47.2263C236.019 47.1943 236.324 47.5342 236.604 48.018C236.885 48.4977 237.038 48.9295 236.986 48.9535C236.933 48.9775 236.701 48.5977 236.424 48.1219C236.147 47.6501 235.93 47.2583 235.974 47.2263Z"
        fill="#414042"
      />
      <path
        d="M233.191 44.3756C233.223 44.3276 233.624 44.5515 234.045 44.9153C234.47 45.2792 234.747 45.643 234.703 45.683C234.659 45.723 234.322 45.4311 233.909 45.0753C233.496 44.7154 233.158 44.4276 233.191 44.3756Z"
        fill="#414042"
      />
      <path
        d="M229.551 42.7523C229.563 42.6964 230.02 42.7403 230.554 42.9163C231.087 43.0922 231.481 43.3281 231.457 43.3761C231.433 43.428 231.011 43.2841 230.49 43.1122C229.968 42.9403 229.543 42.8083 229.555 42.7523H229.551Z"
        fill="#414042"
      />
      <path
        d="M225.575 42.8162C225.559 42.7603 225.988 42.5963 226.546 42.5284C227.103 42.4564 227.561 42.5004 227.561 42.5564C227.561 42.6163 227.115 42.6603 226.574 42.7323C226.032 42.8002 225.591 42.8722 225.575 42.8162Z"
        fill="#414042"
      />
      <path
        d="M222.116 44.7474C222.076 44.7114 222.349 44.3396 222.802 43.9997C223.252 43.6599 223.681 43.4919 223.709 43.5399C223.737 43.5919 223.364 43.8358 222.931 44.1636C222.493 44.4915 222.164 44.7833 222.12 44.7434L222.116 44.7474Z"
        fill="#414042"
      />
      <path
        d="M220.528 48.3337C220.471 48.3337 220.451 47.8699 220.584 47.3222C220.716 46.7744 220.953 46.3746 221.005 46.3986C221.061 46.4226 220.913 46.8424 220.788 47.3702C220.66 47.8979 220.588 48.3337 220.528 48.3297V48.3337Z"
        fill="#414042"
      />
      <path
        d="M221.311 52.212C221.262 52.2399 221.006 51.8561 220.821 51.3244C220.637 50.7926 220.6 50.3368 220.653 50.3248C220.713 50.3128 220.837 50.7406 221.018 51.2564C221.194 51.7722 221.363 52.184 221.307 52.212H221.311Z"
        fill="#414042"
      />
      <path
        d="M223.934 55.1866C223.902 55.2346 223.497 55.0187 223.079 54.6469C222.658 54.2751 222.393 53.8992 222.437 53.8632C222.482 53.8233 222.807 54.1311 223.22 54.491C223.629 54.8508 223.97 55.1387 223.938 55.1866H223.934Z"
        fill="#414042"
      />
      <path
        d="M227.577 56.8098C227.565 56.8658 227.112 56.8058 226.582 56.6259C226.053 56.446 225.66 56.2141 225.684 56.1621C225.708 56.1101 226.133 56.2501 226.651 56.426C227.168 56.6019 227.593 56.7498 227.577 56.8058V56.8098Z"
        fill="#414042"
      />
      <path
        d="M231.557 57.1897C231.557 57.2456 231.112 57.3216 230.554 57.3056C229.996 57.2936 229.547 57.2016 229.555 57.1457C229.559 57.0857 230.008 57.0857 230.558 57.0977C231.108 57.1097 231.557 57.1297 231.557 57.1897Z"
        fill="#414042"
      />
      <path
        d="M235.489 56.4621C235.509 56.5141 235.092 56.702 234.55 56.8419C234.009 56.9818 233.555 57.0138 233.547 56.9579C233.535 56.9019 233.968 56.7779 234.502 56.642C235.036 56.5061 235.473 56.4101 235.489 56.4661V56.4621Z"
        fill="#414042"
      />
      <path
        d="M239.128 54.8027C239.16 54.8507 238.783 55.1026 238.289 55.3625C237.796 55.6224 237.379 55.7903 237.355 55.7383C237.331 55.6863 237.708 55.4344 238.193 55.1786C238.679 54.9227 239.1 54.7548 239.128 54.8027Z"
        fill="#414042"
      />
      <path
        d="M242.342 52.4199C242.382 52.4638 242.065 52.7837 241.632 53.1355C241.203 53.4874 240.825 53.7352 240.789 53.6873C240.757 53.6393 241.074 53.3194 241.499 52.9716C241.925 52.6238 242.302 52.3759 242.342 52.4199Z"
        fill="#414042"
      />
      <path
        d="M245.079 49.4972C245.123 49.5332 244.87 49.901 244.505 50.3248C244.144 50.7486 243.815 51.0565 243.775 51.0165C243.735 50.9765 243.987 50.6047 244.348 50.1889C244.706 49.7731 245.035 49.4612 245.079 49.4972Z"
        fill="#414042"
      />
      <path
        d="M247.282 46.1628C247.334 46.1908 247.141 46.5986 246.856 47.0744C246.568 47.5501 246.299 47.91 246.251 47.878C246.202 47.846 246.395 47.4382 246.676 46.9664C246.961 46.4946 247.23 46.1348 247.282 46.1628Z"
        fill="#414042"
      />
      <path
        d="M248.911 42.5084C248.967 42.5284 248.846 42.9602 248.642 43.476C248.441 43.9957 248.225 44.3915 248.168 44.3636C248.12 44.3436 248.249 43.9118 248.445 43.4C248.646 42.8882 248.854 42.4884 248.907 42.5084H248.911Z"
        fill="#414042"
      />
      <path
        d="M250.029 38.6741C250.085 38.6861 250.017 39.1299 249.877 39.6697C249.736 40.2054 249.58 40.6292 249.524 40.6093C249.467 40.5933 249.536 40.1495 249.676 39.6137C249.816 39.082 249.973 38.6582 250.029 38.6741Z"
        fill="#414042"
      />
      <path
        d="M250.732 34.7359C250.788 34.7439 250.768 35.1917 250.684 35.7395C250.6 36.2872 250.487 36.723 250.427 36.711C250.371 36.699 250.391 36.2513 250.475 35.7075C250.56 35.1638 250.672 34.728 250.732 34.7359Z"
        fill="#414042"
      />
      <path
        d="M251.1 30.7578C251.156 30.7578 251.172 31.2096 251.128 31.7614C251.088 32.3131 251.008 32.7569 250.948 32.7489C250.892 32.7449 250.876 32.2931 250.92 31.7454C250.96 31.1976 251.04 30.7538 251.1 30.7578Z"
        fill="#414042"
      />
      <path
        d="M251.229 26.7637C251.285 26.7637 251.325 27.2115 251.313 27.7632C251.301 28.315 251.249 28.7628 251.189 28.7588C251.133 28.7588 251.093 28.307 251.105 27.7592C251.117 27.2075 251.169 26.7597 251.229 26.7637Z"
        fill="#414042"
      />
      <path
        d="M251.201 22.7655C251.257 22.7655 251.313 23.2093 251.321 23.765C251.329 24.3168 251.289 24.7646 251.233 24.7646C251.177 24.7646 251.121 24.3168 251.113 23.765C251.105 23.2133 251.145 22.7655 251.201 22.7655Z"
        fill="#414042"
      />
      <path
        d="M251.141 20.3066C251.197 20.3066 251.249 20.4066 251.253 20.5345C251.253 20.6625 251.213 20.7664 251.157 20.7664C251.101 20.7664 251.049 20.6665 251.045 20.5385C251.045 20.4106 251.085 20.3066 251.141 20.3066Z"
        fill="#414042"
      />
      <path
        d="M259.931 8.43524L232.66 11.3875L234.657 29.7072L261.928 26.755L259.931 8.43524Z"
        fill="#E5444B"
      />
      <path
        d="M234.647 29.7266V29.7186L232.648 11.391H232.656L259.94 8.4364V8.4444L261.938 26.772H261.93L234.647 29.7266ZM232.669 11.407L234.663 29.7066L261.914 26.756L259.919 8.45639L232.669 11.407Z"
        fill="#414042"
      />
      <path
        d="M260.044 8.65601C260.044 8.65601 259.972 8.75596 259.815 8.92788C259.643 9.11579 259.414 9.36368 259.129 9.67154C258.507 10.3312 257.637 11.2508 256.565 12.3823C254.367 14.6732 251.357 17.8118 248.023 21.2862L247.951 21.3582L247.867 21.3022C247.803 21.2622 247.73 21.2142 247.658 21.1703C243.546 18.5515 239.822 16.1805 237.102 14.4493C235.774 13.5897 234.695 12.8901 233.92 12.3903C233.563 12.1544 233.278 11.9625 233.062 11.8186C232.865 11.6866 232.765 11.6106 232.773 11.6067C232.777 11.5987 232.885 11.6586 233.09 11.7786C233.311 11.9105 233.607 12.0904 233.973 12.3103C234.759 12.7941 235.854 13.4738 237.198 14.3054C239.931 16.0206 243.666 18.3715 247.795 20.9663C247.867 21.0103 247.939 21.0583 248.003 21.0983L247.847 21.1183C251.201 17.6639 254.23 14.5453 256.441 12.2663C257.532 11.1589 258.423 10.2553 259.061 9.60757C259.362 9.3117 259.599 9.07581 259.783 8.8959C259.952 8.73597 260.04 8.65201 260.048 8.66L260.044 8.65601Z"
        fill="#414042"
      />
      <path
        d="M234.767 29.9262C234.711 29.8863 236.488 27.1555 238.735 23.821C240.986 20.4866 242.856 17.8158 242.912 17.8558C242.968 17.8958 241.19 20.6265 238.944 23.961C236.693 27.2915 234.823 29.9662 234.767 29.9262Z"
        fill="#414042"
      />
      <path
        d="M251.951 16.6483C251.999 16.6004 254.298 18.8753 257.083 21.726C259.867 24.5767 262.086 26.9276 262.038 26.9756C261.99 27.0236 259.691 24.7486 256.906 21.8979C254.122 19.0472 251.903 16.6963 251.951 16.6483Z"
        fill="#414042"
      />
      <path
        d="M135.392 63.9825C135.448 63.9745 135.532 64.1864 135.58 64.4583C135.629 64.7302 135.617 64.9581 135.56 64.9701C135.504 64.9821 135.42 64.7662 135.372 64.4943C135.324 64.2224 135.336 63.9945 135.392 63.9825Z"
        fill="#414042"
      />
      <path
        d="M134.75 60.0843C134.806 60.0763 134.922 60.5041 135.011 61.0439C135.099 61.5836 135.123 62.0274 135.067 62.0354C135.011 62.0434 134.894 61.6156 134.806 61.0759C134.718 60.5361 134.694 60.0923 134.75 60.0843Z"
        fill="#414042"
      />
      <path
        d="M134.176 56.1742C134.233 56.1662 134.341 56.598 134.417 57.1377C134.493 57.6775 134.509 58.1213 134.449 58.1293C134.389 58.1373 134.285 57.7055 134.208 57.1657C134.132 56.626 134.116 56.1782 134.176 56.1742Z"
        fill="#414042"
      />
      <path
        d="M133.727 52.2439C133.783 52.2399 133.875 52.6757 133.935 53.2155C133.992 53.7592 133.992 54.203 133.935 54.211C133.879 54.219 133.787 53.7832 133.727 53.2395C133.671 52.6957 133.671 52.2519 133.727 52.2439Z"
        fill="#414042"
      />
      <path
        d="M133.45 48.3018C133.506 48.3018 133.578 48.7376 133.61 49.2813C133.642 49.8251 133.618 50.2689 133.562 50.2729C133.506 50.2769 133.434 49.8371 133.402 49.2933C133.37 48.7496 133.394 48.3018 133.45 48.3018Z"
        fill="#414042"
      />
      <path
        d="M133.41 44.3476C133.466 44.3476 133.51 44.7914 133.51 45.3391C133.51 45.8829 133.458 46.3267 133.402 46.3267C133.346 46.3267 133.302 45.8829 133.302 45.3351C133.302 44.7874 133.354 44.3436 133.41 44.3476Z"
        fill="#414042"
      />
      <path
        d="M133.658 40.4054C133.714 40.4134 133.726 40.8572 133.678 41.3969C133.634 41.9407 133.55 42.3765 133.494 42.3725C133.438 42.3725 133.426 41.9247 133.474 41.3809C133.518 40.8372 133.602 40.3974 133.658 40.4054Z"
        fill="#414042"
      />
      <path
        d="M134.253 36.4991C134.309 36.5111 134.277 36.9549 134.185 37.4907C134.088 38.0264 133.968 38.4543 133.908 38.4463C133.852 38.4383 133.884 37.9945 133.976 37.4547C134.072 36.915 134.197 36.4872 134.253 36.4991Z"
        fill="#414042"
      />
      <path
        d="M135.268 32.677C135.324 32.697 135.24 33.1328 135.088 33.6525C134.931 34.1723 134.759 34.5841 134.706 34.5681C134.65 34.5521 134.73 34.1163 134.891 33.5925C135.047 33.0688 135.22 32.657 135.272 32.677H135.268Z"
        fill="#414042"
      />
      <path
        d="M136.748 29.0146C136.8 29.0386 136.664 29.4624 136.443 29.9622C136.222 30.4579 136.002 30.8458 135.95 30.8258C135.897 30.8058 136.034 30.382 136.255 29.8782C136.475 29.3784 136.696 28.9906 136.748 29.0146Z"
        fill="#414042"
      />
      <path
        d="M138.682 25.5643C138.73 25.5962 138.542 26.0001 138.261 26.4638C137.98 26.9276 137.711 27.2835 137.659 27.2555C137.607 27.2275 137.795 26.8237 138.08 26.3559C138.365 25.8881 138.634 25.5323 138.682 25.5643Z"
        fill="#414042"
      />
      <path
        d="M141.061 22.4056C141.105 22.4456 140.861 22.8174 140.52 23.2413C140.179 23.6651 139.862 23.9809 139.818 23.9449C139.769 23.9089 140.014 23.5371 140.359 23.1093C140.704 22.6815 141.021 22.3657 141.061 22.4056Z"
        fill="#414042"
      />
      <path
        d="M143.882 19.627C143.918 19.671 143.621 20.0028 143.22 20.3706C142.819 20.7385 142.458 21.0023 142.418 20.9624C142.378 20.9224 142.674 20.5905 143.08 20.2187C143.485 19.8469 143.846 19.583 143.882 19.627Z"
        fill="#414042"
      />
      <path
        d="M147.124 17.356C147.152 17.408 146.803 17.6839 146.346 17.9757C145.888 18.2676 145.487 18.4675 145.455 18.4195C145.423 18.3715 145.772 18.0957 146.234 17.7998C146.699 17.5039 147.096 17.304 147.124 17.356Z"
        fill="#414042"
      />
      <path
        d="M150.734 15.7368C150.751 15.7927 150.357 15.9966 149.848 16.1926C149.342 16.3885 148.909 16.5084 148.885 16.4564C148.861 16.4045 149.258 16.2005 149.772 16.0006C150.285 15.8007 150.714 15.6848 150.734 15.7368Z"
        fill="#414042"
      />
      <path
        d="M153.632 15.0251C153.64 15.081 153.431 15.169 153.158 15.221C152.89 15.273 152.661 15.269 152.649 15.213C152.637 15.157 152.85 15.0691 153.122 15.0171C153.395 14.9651 153.624 14.9691 153.632 15.0251Z"
        fill="#414042"
      />
      <path
        d="M132.29 0.0123532L125.039 18.6989L152.859 29.4182L160.11 10.7317L132.29 0.0123532Z"
        fill="#E5444B"
      />
      <path
        d="M152.865 29.4345H152.857L125.028 18.7074V18.6994L132.286 0H132.294L160.123 10.7271V10.7351L152.865 29.4305V29.4345ZM125.052 18.6954L152.853 29.4065L160.095 10.7391L132.294 0.023989L125.052 18.6914V18.6954Z"
        fill="#414042"
      />
      <path
        d="M160.111 10.991C160.111 10.991 159.991 11.0509 159.754 11.1389C159.493 11.2349 159.148 11.3588 158.719 11.5147C157.788 11.8426 156.484 12.2984 154.887 12.8581C151.617 13.9776 147.136 15.5089 142.177 17.2041L142.072 17.2401L142.016 17.1441C141.972 17.0722 141.928 16.9922 141.88 16.9122C139.22 12.3384 136.812 8.19625 135.051 5.16964C134.2 3.67833 133.506 2.46289 133.009 1.59529C132.784 1.18747 132.604 0.863622 132.467 0.615736C132.347 0.391839 132.287 0.271894 132.295 0.263897C132.303 0.259899 132.379 0.371848 132.515 0.587749C132.664 0.827638 132.86 1.14349 133.101 1.53931C133.615 2.39892 134.337 3.59837 135.22 5.06969C136.997 8.0883 139.428 12.2144 142.117 16.7723C142.165 16.8523 142.209 16.9322 142.253 17.0042L142.092 16.9442C147.064 15.277 151.549 13.7737 154.831 12.6742C156.44 12.1464 157.752 11.7186 158.691 11.4108C159.128 11.2748 159.477 11.1629 159.746 11.0789C159.987 11.007 160.115 10.971 160.119 10.983L160.111 10.991Z"
        fill="#414042"
      />
      <path
        d="M125.04 18.9593C125.004 18.8953 128.09 17.1402 131.933 15.0371C135.777 12.9381 138.919 11.2868 138.955 11.3548C138.991 11.4188 135.905 13.174 132.062 15.277C128.218 17.376 125.076 19.0273 125.04 18.9593Z"
        fill="#414042"
      />
      <path
        d="M148.295 14.6853C148.367 14.6653 149.446 18.0037 150.706 22.1418C151.966 26.2839 152.933 29.6584 152.861 29.6784C152.788 29.6984 151.709 26.3599 150.449 22.2218C149.19 18.0837 148.223 14.7093 148.295 14.6853Z"
        fill="#414042"
      />
      <path
        d="M123.708 68.3605C123.764 68.3605 123.792 68.5924 123.772 68.8683C123.748 69.1442 123.684 69.3641 123.628 69.3601C123.572 69.3561 123.544 69.1282 123.564 68.8523C123.588 68.5764 123.652 68.3565 123.708 68.3605Z"
        fill="#414042"
      />
      <path
        d="M123.808 64.2944C123.864 64.2944 123.912 64.7502 123.908 65.31C123.908 65.8737 123.86 66.3295 123.8 66.3255C123.744 66.3255 123.696 65.8697 123.7 65.31C123.7 64.7502 123.748 64.2944 123.808 64.2944Z"
        fill="#414042"
      />
      <path
        d="M123.532 60.2363C123.588 60.2283 123.676 60.6801 123.728 61.2398C123.781 61.7996 123.777 62.2594 123.716 62.2634C123.66 62.2634 123.572 61.8196 123.52 61.2598C123.468 60.7041 123.472 60.2443 123.532 60.2363Z"
        fill="#414042"
      />
      <path
        d="M122.853 56.2262C122.91 56.2142 123.042 56.65 123.15 57.2057C123.259 57.7575 123.303 58.2133 123.243 58.2253C123.186 58.2333 123.05 57.7975 122.946 57.2457C122.837 56.698 122.797 56.2422 122.853 56.2262Z"
        fill="#414042"
      />
      <path
        d="M121.742 52.312C121.798 52.292 121.979 52.7118 122.147 53.2515C122.316 53.7913 122.408 54.2391 122.352 54.2551C122.296 54.2711 122.115 53.8513 121.947 53.3155C121.778 52.7798 121.686 52.332 121.742 52.312Z"
        fill="#414042"
      />
      <path
        d="M120.181 48.5577C120.234 48.5337 120.462 48.9295 120.695 49.4413C120.928 49.957 121.072 50.3888 121.02 50.4128C120.968 50.4328 120.735 50.037 120.506 49.5292C120.278 49.0175 120.129 48.5857 120.181 48.5577Z"
        fill="#414042"
      />
      <path
        d="M118.155 45.0272C118.203 44.9953 118.492 45.3551 118.793 45.8309C119.082 46.3147 119.275 46.7305 119.227 46.7624C119.174 46.7904 118.902 46.4226 118.617 45.9428C118.316 45.471 118.107 45.0632 118.155 45.0312V45.0272Z"
        fill="#414042"
      />
      <path
        d="M115.656 41.8128C115.7 41.7728 116.029 42.0927 116.391 42.5285C116.752 42.9603 117.008 43.3401 116.964 43.3761C116.92 43.4121 116.591 43.0922 116.23 42.6604C115.869 42.2326 115.612 41.8528 115.656 41.8128Z"
        fill="#414042"
      />
      <path
        d="M112.727 38.9861C112.763 38.9421 113.136 39.214 113.554 39.5898C113.975 39.9697 114.284 40.3055 114.24 40.3495C114.2 40.3895 113.831 40.1216 113.413 39.7458C112.996 39.3699 112.687 39.0301 112.727 38.9861Z"
        fill="#414042"
      />
      <path
        d="M109.417 36.6071C109.445 36.5591 109.854 36.771 110.324 37.0829C110.793 37.3948 111.15 37.6866 111.114 37.7346C111.082 37.7826 110.673 37.5667 110.207 37.2588C109.742 36.947 109.385 36.6591 109.417 36.6071Z"
        fill="#414042"
      />
      <path
        d="M105.782 34.7641C105.802 34.7121 106.24 34.86 106.753 35.0959C107.267 35.3318 107.664 35.5637 107.636 35.6157C107.608 35.6676 107.175 35.5197 106.665 35.2838C106.155 35.0479 105.758 34.816 105.782 34.7641Z"
        fill="#414042"
      />
      <path
        d="M101.89 33.5365C101.902 33.4806 102.355 33.5525 102.901 33.7005C103.447 33.8484 103.876 34.0123 103.86 34.0643C103.844 34.1203 103.39 34.0443 102.849 33.9004C102.307 33.7564 101.878 33.5925 101.89 33.5365Z"
        fill="#414042"
      />
      <path
        d="M97.8457 33.0208C97.8457 32.9648 98.3071 32.9408 98.8728 32.9728C99.4386 33.0328 99.8879 33.1248 99.8799 33.1807C99.8719 33.2367 99.4145 33.2367 98.8568 33.1807C98.2991 33.1487 97.8457 33.0768 97.8457 33.0208Z"
        fill="#414042"
      />
      <path
        d="M93.7775 33.3006C93.7694 33.2447 94.2148 33.1447 94.7805 33.0768C95.3422 33.0088 95.8036 33.0048 95.8076 33.0608C95.8076 33.1167 95.3623 33.2167 94.8046 33.2847C94.2469 33.3486 93.7895 33.3566 93.7775 33.3006Z"
        fill="#414042"
      />
      <path
        d="M89.8376 34.3443C89.8175 34.2923 90.2388 34.1084 90.7805 33.9364C91.3221 33.7645 91.7715 33.6726 91.7835 33.7285C91.7996 33.7845 91.3783 33.9644 90.8407 34.1363C90.307 34.3043 89.8577 34.4002 89.8376 34.3443Z"
        fill="#414042"
      />
      <path
        d="M86.162 36.1074C86.1299 36.0594 86.5111 35.7995 87.0086 35.5317C87.5061 35.2638 87.9314 35.0879 87.9555 35.1398C87.9796 35.1918 87.6024 35.4477 87.1089 35.7156C86.6154 35.9795 86.1901 36.1554 86.162 36.1074Z"
        fill="#414042"
      />
      <path
        d="M82.9048 38.5503C82.8646 38.5103 83.1856 38.1824 83.6229 37.8186C84.0603 37.4548 84.4414 37.1989 84.4735 37.2469C84.5096 37.2948 84.1887 37.6187 83.7553 37.9785C83.326 38.3344 82.9449 38.5903 82.9048 38.5503Z"
        fill="#414042"
      />
      <path
        d="M80.2361 41.6169C80.1879 41.5849 80.4086 41.1771 80.7536 40.7293C81.1027 40.2815 81.4397 39.9696 81.4839 40.0056C81.528 40.0456 81.2632 40.4134 80.9181 40.8572C80.5771 41.297 80.2842 41.6489 80.2361 41.6169Z"
        fill="#414042"
      />
      <path
        d="M78.7476 44.2596C78.6955 44.2356 78.7476 44.0157 78.872 43.7639C78.9924 43.512 79.1328 43.3321 79.185 43.36C79.2371 43.388 79.181 43.6079 79.0606 43.8558C78.9402 44.1037 78.7998 44.2836 78.7476 44.2596Z"
        fill="#414042"
      />
      <path
        d="M53.2539 67.8528C53.2539 67.7968 53.4666 67.7208 53.7394 67.6888C54.0122 67.6569 54.2409 67.6769 54.2489 67.7328C54.261 67.7888 54.0403 67.8608 53.7635 67.8968C53.4866 67.9287 53.2579 67.9127 53.2539 67.8528Z"
        fill="#414042"
      />
      <path
        d="M49.281 67.4049C49.297 67.349 49.7384 67.4409 50.276 67.5529C50.8136 67.6648 51.255 67.7568 51.2469 67.8168C51.2429 67.8727 50.7815 67.8727 50.2319 67.7568C49.6822 67.6408 49.2609 67.4569 49.281 67.4049Z"
        fill="#414042"
      />
      <path
        d="M45.6906 65.6338C45.7227 65.5858 46.1159 65.8057 46.5893 66.0896C47.0627 66.3735 47.4439 66.6093 47.4158 66.6613C47.3917 66.7133 46.9624 66.5574 46.481 66.2695C45.9995 65.9856 45.6585 65.6818 45.6906 65.6378V65.6338Z"
        fill="#414042"
      />
      <path
        d="M42.7011 62.963C42.7452 62.923 43.0742 63.2309 43.4634 63.6227C43.8526 64.0145 44.1535 64.3463 44.1174 64.3863C44.0813 64.4303 43.7082 64.1624 43.315 63.7666C42.9218 63.3708 42.657 62.999 42.7011 62.963Z"
        fill="#414042"
      />
      <path
        d="M40.3419 59.7245C40.39 59.6965 40.6789 60.0443 40.9798 60.5041C41.2807 60.9639 41.4894 61.3637 41.4452 61.3997C41.3971 61.4357 41.1122 61.0838 40.8073 60.6201C40.5024 60.1563 40.2937 59.7525 40.3419 59.7245Z"
        fill="#414042"
      />
      <path
        d="M38.6086 56.1141C38.6608 56.0941 38.8814 56.49 39.0981 56.9937C39.3148 57.5015 39.4472 57.9293 39.395 57.9573C39.3428 57.9813 39.1222 57.5894 38.9055 57.0777C38.6848 56.5659 38.5524 56.1341 38.6086 56.1141Z"
        fill="#414042"
      />
      <path
        d="M37.4572 52.2759C37.5133 52.2639 37.6738 52.6837 37.8143 53.2195C37.9547 53.7512 38.0229 54.199 37.9667 54.215C37.9106 54.231 37.7501 53.8112 37.6096 53.2714C37.4692 52.7357 37.401 52.2879 37.4572 52.2759Z"
        fill="#414042"
      />
      <path
        d="M36.7592 48.3297C36.8154 48.3217 36.9317 48.7575 37.012 49.3053C37.0962 49.853 37.1163 50.3008 37.0601 50.3128C37.004 50.3248 36.8916 49.885 36.8074 49.3372C36.7231 48.7895 36.703 48.3377 36.7592 48.3297Z"
        fill="#414042"
      />
      <path
        d="M36.3742 44.3396C36.4301 44.3396 36.5141 44.7794 36.558 45.3311C36.602 45.8829 36.594 46.3347 36.534 46.3387C36.4781 46.3427 36.3941 45.9029 36.3502 45.3471C36.3062 44.7954 36.3142 44.3436 36.3742 44.3396Z"
        fill="#414042"
      />
      <path
        d="M36.2053 41.3409C36.2613 41.3409 36.3212 41.5608 36.3332 41.8367C36.3452 42.1126 36.3092 42.3365 36.2533 42.3405C36.1973 42.3405 36.1373 42.1206 36.1254 41.8447C36.1134 41.5688 36.1493 41.3449 36.2053 41.3409Z"
        fill="#414042"
      />
      <path
        d="M51.3125 17.0949L17.9521 18.6685L19.0087 41.0774L52.369 39.5038L51.3125 17.0949Z"
        fill="#E5444B"
      />
      <path
        d="M19.0016 41.0851V41.0731L17.9424 18.6554H17.9544L51.328 17.0881V17.1001L52.3873 39.5178H52.3752L19.0016 41.0851ZM17.9624 18.6754L19.0217 41.0651L52.3632 39.4978L51.304 17.1081L17.9624 18.6754Z"
        fill="#414042"
      />
      <path
        d="M51.4323 17.364C51.4323 17.364 51.336 17.48 51.1354 17.6759C50.9107 17.8918 50.6138 18.1756 50.2447 18.5315C49.4382 19.2871 48.3108 20.3426 46.9306 21.638C44.0859 24.2608 40.1981 27.8472 35.8849 31.8254L35.7927 31.9093L35.6924 31.8374C35.6161 31.7814 35.5319 31.7214 35.4476 31.6614C30.645 28.175 26.2998 25.0205 23.1221 22.7136C21.5734 21.5701 20.3095 20.6385 19.4068 19.9748C18.9895 19.659 18.6565 19.4071 18.4077 19.2192C18.179 19.0432 18.0627 18.9473 18.0707 18.9393C18.0787 18.9313 18.2031 19.0113 18.4438 19.1712C18.7046 19.3511 19.0497 19.587 19.479 19.8829C20.3978 20.5306 21.6777 21.4341 23.2505 22.5456C26.4402 24.8326 30.8055 27.9631 35.6282 31.4216C35.7124 31.4815 35.7967 31.5415 35.8729 31.5975L35.6803 31.6095C40.0135 27.6593 43.9254 24.0969 46.7861 21.4901C48.1944 20.2227 49.3459 19.1952 50.1684 18.4555C50.5536 18.1157 50.8625 17.8478 51.0953 17.6439C51.3079 17.46 51.4243 17.368 51.4323 17.376V17.364Z"
        fill="#414042"
      />
      <path
        d="M19.1303 41.337C19.0621 41.285 21.4293 38.0984 24.4104 34.2122C27.3915 30.33 29.863 27.2234 29.9312 27.2714C29.9994 27.3234 27.6362 30.5099 24.6512 34.3961C21.6701 38.2784 19.1986 41.3849 19.1303 41.337Z"
        fill="#414042"
      />
      <path
        d="M41.0041 26.4758C41.0683 26.4198 43.6923 29.3544 46.862 33.0248C50.0356 36.6991 52.5553 39.7177 52.4911 39.7737C52.4269 39.8296 49.8069 36.895 46.6333 33.2247C43.4636 29.5543 40.9399 26.5317 41.0041 26.4798V26.4758Z"
        fill="#414042"
      />
      <path
        d="M106.331 88.993L159.518 88.4998L161.074 124.527L106.001 124.135L106.331 88.993Z"
        fill="#E5444B"
      />
      <path
        d="M106.331 88.9929L131.46 68.6454C132.525 67.7822 134.048 67.7859 135.113 68.6492L159.518 88.4997"
        fill="#E5444B"
      />
      <g opacity="0.3">
        <path
          d="M106.331 88.9929L131.46 68.6454C132.525 67.7822 134.048 67.7859 135.113 68.6492L159.518 88.4997"
          fill="black"
        />
      </g>
      <path
        d="M106.001 124.135C105.933 124.071 110.182 119.43 115.488 113.769C120.795 108.103 125.152 103.563 125.216 103.627C125.284 103.69 121.035 108.328 115.728 113.993C110.422 119.654 106.068 124.195 106.001 124.135Z"
        fill="#414042"
      />
      <path
        d="M161.074 124.527C161.006 124.587 156.851 120.058 151.792 114.408C146.734 108.758 142.687 104.131 142.755 104.072C142.822 104.012 146.977 108.541 152.036 114.191C157.095 119.838 161.141 124.468 161.074 124.527Z"
        fill="#414042"
      />
      <g opacity="0.5">
        <path
          d="M159.518 88.4998L132.855 109.815L106.331 88.993"
          fill="black"
        />
      </g>
      <path
        d="M109.016 90.9549L108.896 74.4041L155.467 73.7576L156.03 91.1753L132.851 109.535L109.016 90.9549Z"
        fill="white"
      />
      <g clipPath="url(#clip1_24_11693)">
        <mask
          id="mask0_24_11693"
          maskUnits="userSpaceOnUse"
          x="116"
          y="80"
          width="33"
          height="14"
        >
          <rect
            x="116.575"
            y="80.5549"
            width="31.7784"
            height="13.0852"
            fill="#C4C4C4"
          />
        </mask>
        <g mask="url(#mask0_24_11693)">
          <path
            d="M124.596 86.3948C124.566 86.4097 124.533 86.4172 124.499 86.4166C124.466 86.4159 124.433 86.4073 124.403 86.3913L124.091 86.2107C124.063 86.1931 124.041 86.1693 124.025 86.1413L124.022 86.1362C124.003 86.1075 123.991 86.0745 123.989 86.0405L123.981 85.6866C123.982 85.6539 123.99 85.6219 124.006 85.5934C124.023 85.5648 124.046 85.5406 124.074 85.5228L127.829 83.3949L122.559 80.6011C122.493 80.5704 122.421 80.5544 122.348 80.5544C122.275 80.5544 122.203 80.5704 122.137 80.6011L116.867 83.3949L122.771 86.7375C122.799 86.7545 122.823 86.778 122.84 86.806C122.856 86.8341 122.866 86.8657 122.867 86.8982V91.7744C122.865 91.8071 122.855 91.8388 122.837 91.8667C122.82 91.8947 122.796 91.918 122.767 91.9346L122.449 92.1062C122.42 92.1204 122.387 92.1276 122.354 92.1271H122.348C122.313 92.1286 122.278 92.1214 122.247 92.1062L121.928 91.9346C121.899 91.9178 121.875 91.8944 121.858 91.8665C121.841 91.8385 121.831 91.8069 121.829 91.7744V87.4275C121.827 87.4104 121.821 87.394 121.812 87.3793C121.803 87.3646 121.791 87.3519 121.776 87.3421L116.575 84.3974V90.1654C116.575 90.2945 116.669 90.4536 116.783 90.5193L122.14 93.5915C122.205 93.6234 122.276 93.64 122.348 93.64C122.42 93.64 122.492 93.6234 122.556 93.5915L127.914 90.5188C128.028 90.4529 128.121 90.2945 128.121 90.1649V84.3974L124.596 86.3948Z"
            fill="#DC0032"
          />
          <path
            d="M136.337 90.2489C135.757 90.3983 135.001 90.4722 134.343 90.4722C132.657 90.4722 131.54 89.5992 131.54 88.0016C131.54 86.4895 132.58 85.5101 134.103 85.5101C134.442 85.5101 134.803 85.5521 135.023 85.6589V83.4645H136.336L136.337 90.2489ZM135.024 86.7877C134.816 86.6918 134.542 86.6386 134.213 86.6386C133.414 86.6386 132.877 87.118 132.877 87.9593C132.877 88.8647 133.457 89.376 134.388 89.376C134.553 89.376 134.804 89.3651 135.024 89.3227V86.7877ZM147.119 90.2489C146.538 90.3983 145.783 90.4722 145.126 90.4722C143.44 90.4722 142.322 89.5992 142.322 88.0016C142.322 86.4895 143.363 85.5101 144.885 85.5101C145.224 85.5101 145.586 85.5521 145.805 85.6589V83.4645H147.119L147.119 90.2489ZM145.805 86.7877C145.597 86.6918 145.323 86.6386 144.995 86.6386C144.195 86.6386 143.659 87.118 143.659 87.9593C143.659 88.8647 144.239 89.376 145.17 89.376C145.334 89.376 145.586 89.3651 145.805 89.3227V86.7877ZM138.403 86.7772C138.622 86.6918 138.928 86.6601 139.191 86.6601C140.001 86.6601 140.56 87.118 140.56 87.9373C140.56 88.9042 139.941 89.3417 139.114 89.3529V90.4709C139.136 90.4709 139.158 90.4709 139.18 90.4709C140.877 90.4709 141.896 89.5448 141.896 87.8942C141.896 86.3924 140.812 85.5091 139.213 85.5091C138.403 85.5091 137.603 85.6903 137.077 85.9029V92.284H138.403L138.403 86.7772Z"
            fill="#414042"
          />
        </g>
      </g>
      <path
        d="M109.016 90.9549C109.016 90.9549 109.17 91.067 109.458 91.2837C109.747 91.5079 110.175 91.8331 110.73 92.2591C111.843 93.1186 113.471 94.3779 115.549 95.9885C119.707 99.2172 125.666 103.847 132.904 109.468H132.806C139.481 104.172 147.401 97.8906 155.974 91.0931L155.937 91.1716C155.828 87.8495 155.715 84.363 155.603 80.8166C155.528 78.4287 155.449 76.0707 155.374 73.7576L155.472 73.851C136.706 74.0976 120.154 74.3144 108.9 74.4601L108.96 74.3966C108.982 79.6021 109.001 83.7352 109.016 86.5715C109.016 87.9878 109.02 89.0789 109.023 89.8226C109.023 90.1888 109.023 90.4691 109.023 90.6634C109.023 90.854 109.016 90.9511 109.016 90.9511C109.016 90.9511 109.008 90.8577 109.005 90.6709C109.005 90.4803 108.997 90.2 108.99 89.8375C108.982 89.0976 108.967 88.0065 108.952 86.5939C108.926 83.7501 108.885 79.6096 108.836 74.3966V74.3331H108.896C120.146 74.1687 136.699 73.9257 155.464 73.653H155.558V73.7464C155.633 76.0595 155.708 78.4175 155.787 80.8054C155.899 84.3517 156.012 87.8383 156.12 91.1604V91.209L156.083 91.2389C147.506 98.0251 139.579 104.299 132.896 109.587L132.848 109.628L132.799 109.591C125.572 103.941 119.617 99.2807 115.466 96.0333C113.4 94.4115 111.783 93.141 110.681 92.274C110.137 91.8443 109.717 91.5117 109.432 91.2875C109.151 91.0632 109.012 90.9474 109.012 90.9474L109.016 90.9549Z"
        fill="#414042"
      />
      <circle
        cx="158"
        cy="73"
        r="10.5"
        fill="#E5444B"
        stroke="white"
        strokeWidth="3"
      />
      <path
        d="M159.306 68.095V77H157.548V70.1213L155.437 70.7927V69.4072L159.117 68.095H159.306Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_24_11693">
        <rect width="268" height="148" fill="white" />
      </clipPath>
      <clipPath id="clip1_24_11693">
        <rect
          width="31.7784"
          height="13.0852"
          fill="white"
          transform="translate(116.575 80.5549)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default ResendEmail;
