import classNames from "classnames";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";

import styles from "./ContentHeader.module.scss";

const ContentHeader = ({ title, subtitle, classes = {}, icon: Icon }) => {
  return (
    <Row className={classes.container}>
      <Col>
        {Icon && (
          <Row>
            <Col className="mb-2 d-flex justify-content-center">
              <Icon
                className={classNames("text-danger", styles.icon, classes.icon)}
              />
            </Col>
          </Row>
        )}
        {title && (
          <Row>
            <Col>
              <p className={classNames(styles.title, classes.title || "h4")}>
                {title}
              </p>
            </Col>
          </Row>
        )}
        {subtitle && (
          <Row>
            <Col>
              <p className={classes.subtitle || "mb-4"}>{subtitle}</p>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};

ContentHeader.defaultProps = {
  className: "text-center text-lg-start",
};

ContentHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  classes: PropTypes.shape({
    icon: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    container: PropTypes.string,
  }),
  icon: PropTypes.func,
};

export default ContentHeader;
