import React, { useMemo } from "react";
import classNames from "classnames";
import { Col, Row } from "react-bootstrap";
import { useField } from "react-final-form";

import * as M from "../../../constants/strings";
import { FIRST_DELIVERY_ATTEMPT_CODE } from "../../../constants";
import { Home } from "../../atoms/icons";
import SelectableCard from "../../molecules/SelectableCard/SelectableCard";

const FirstDeliveryAttempt = ({ classes, data, className }) => {
  const field = useField("firstDeliveryAttempt");

  useMemo(() => {
    if (
      !data.pickupPoint &&
      field.input.value !== FIRST_DELIVERY_ATTEMPT_CODE.HOME
    ) {
      field.input.onChange(FIRST_DELIVERY_ATTEMPT_CODE.HOME);
    }
  }, [data, field]);

  return (
    <div className={className}>
      <Row className="gx-0 gx-lg-3">
        <Col className="d-flex align-items-center mb-4">
          <Home className="text-danger" />
          <h6 className="mb-0 ms-2">{M.FIRST_TIME_DELIVERY}</h6>
        </Col>
      </Row>

      <Row className="align-items-stretch gx-0 gx-lg-3">
        <Col
          lg={6}
          className={classNames(
            "d-flex justify-content-lg-start align-items-stretch mb-4",
            classes.dataColumn
          )}
        >
          <SelectableCard
            title={M.DELIVERY_ADDRESS}
            description={M.FIRST_TIME_DELIVERY_DELIVERY_ADDRESS_DESCRIPTION}
            selected={field.input.value === FIRST_DELIVERY_ATTEMPT_CODE.HOME}
            className={classNames("w-100", classes.dataHeader)}
            onSelect={() => {
              field.input.onChange(FIRST_DELIVERY_ATTEMPT_CODE.HOME);
            }}
          />
        </Col>
        <Col
          lg={6}
          className={classNames(
            "d-flex justify-content-lg-start align-items-stretch mb-4",
            classes.dataColumn
          )}
        >
          <SelectableCard
            title={M.STRAIGHT_TO_PICKUP_POINT}
            description={
              M.FIRST_TIME_DELIVERY_STRAIGHT_TO_PICKUP_POINT_DESCRIPTION
            }
            selected={field.input.value === FIRST_DELIVERY_ATTEMPT_CODE.PICKUP}
            disabled={!data.pickupPoint}
            className={classNames("w-100", classes.dataHeader)}
            onSelect={() => {
              field.input.onChange(FIRST_DELIVERY_ATTEMPT_CODE.PICKUP);
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default FirstDeliveryAttempt;
