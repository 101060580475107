const Chat = (props) => (
  <svg
    width="2rem" // 32px
    height="2rem"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_36_10310)">
      <path
        d="M26.1026 22.2801C25.9656 22.2801 25.8294 22.221 25.7357 22.1078L24.1601 20.2033C23.9921 20.0006 24.0212 19.7002 24.2234 19.5331C24.4272 19.3646 24.727 19.3951 24.8936 19.5965L26.4691 21.5009C26.6372 21.7036 26.6081 22.004 26.4058 22.1711C26.3168 22.2444 26.2088 22.2801 26.1026 22.2801Z"
        fill="#414042"
      />
      <path
        d="M28.8572 22.2796C28.594 22.2796 28.3812 22.0668 28.3812 21.8036V9.42796C28.3812 9.16569 28.1679 8.95197 27.9052 8.95197H11.7002C11.438 8.95197 11.2243 9.16569 11.2243 9.42796V18.9477C11.2243 19.2104 11.438 19.4237 11.7002 19.4237H24.5267C24.7899 19.4237 25.0026 19.6364 25.0026 19.8997C25.0026 20.1629 24.7899 20.3756 24.5267 20.3756H11.7002C10.913 20.3756 10.2723 19.735 10.2723 18.9477V9.42796C10.2723 8.64068 10.913 8 11.7002 8H27.9052C28.6925 8 29.3332 8.64068 29.3332 9.42796V21.8036C29.3332 22.0668 29.1199 22.2796 28.8572 22.2796Z"
        fill="#414042"
      />
      <path
        d="M5.8975 26.0875C5.7904 26.0875 5.68331 26.0518 5.5943 25.9785C5.39153 25.8109 5.36344 25.5111 5.53099 25.3083L7.10603 23.4039C7.27405 23.202 7.57392 23.1735 7.77622 23.3406C7.97899 23.5081 8.00707 23.808 7.83952 24.0107L6.26448 25.9152C6.17024 26.0285 6.03411 26.0875 5.8975 26.0875Z"
        fill="#414042"
      />
      <path
        d="M20.2994 24.1831H7.47254C7.20979 24.1831 6.99655 23.9703 6.99655 23.7071C6.99655 23.4439 7.20979 23.2311 7.47254 23.2311H20.2994C20.5622 23.2311 20.7754 23.0178 20.7754 22.7551V19.8997C20.7754 19.6364 20.9882 19.4237 21.2514 19.4237C21.5146 19.4237 21.7274 19.6364 21.7274 19.8997V22.7551C21.7274 23.5424 21.0872 24.1831 20.2994 24.1831Z"
        fill="#414042"
      />
      <path
        d="M3.14249 26.087C2.87975 26.087 2.6665 25.8742 2.6665 25.611V13.2354C2.6665 12.4481 3.30718 11.8074 4.09446 11.8074H10.7692C11.032 11.8074 11.2452 12.0207 11.2452 12.2834C11.2452 12.5461 11.032 12.7594 10.7692 12.7594H4.09446C3.83219 12.7594 3.61848 12.9731 3.61848 13.2354V25.611C3.61848 25.8742 3.40523 26.087 3.14249 26.087Z"
        fill="#414042"
      />
      <path
        d="M21.7714 15.6154C22.2974 15.6154 22.7234 15.1894 22.7234 14.6634C22.7234 14.1374 22.2974 13.7114 21.7714 13.7114C21.2455 13.7114 20.8195 14.1374 20.8195 14.6634C20.8195 15.1894 21.2455 15.6154 21.7714 15.6154Z"
        fill="currentColor"
      />
      <path
        d="M25.5251 15.6158C26.051 15.6158 26.477 15.1898 26.477 14.6639C26.477 14.1379 26.051 13.7119 25.5251 13.7119C24.9991 13.7119 24.5731 14.1379 24.5731 14.6639C24.5731 15.1894 24.9991 15.6158 25.5251 15.6158Z"
        fill="currentColor"
      />
      <path
        d="M14.1014 15.6154C14.6274 15.6154 15.0534 15.1894 15.0534 14.6634C15.0534 14.1374 14.6274 13.7114 14.1014 13.7114C13.5754 13.7114 13.1494 14.1374 13.1494 14.6634C13.1494 15.1894 13.5754 15.6154 14.1014 15.6154Z"
        fill="currentColor"
      />
      <path
        d="M17.9093 15.6158C18.4352 15.6158 18.8613 15.1898 18.8613 14.6639C18.8613 14.1379 18.4352 13.7119 17.9093 13.7119C17.3833 13.7119 16.9573 14.1379 16.9573 14.6639C16.9573 15.1894 17.3833 15.6158 17.9093 15.6158Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default Chat;
