const Plus = ({ ...props }) => (
  <svg
    width="0.875rem" // 14px
    height="0.875rem"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" fill="currentColor" />
  </svg>
);

export default Plus;
