import { BrowserRouter } from "react-router-dom";

import { Routes, ScrollToTop } from "./router";
import { AuthProvider } from "./features/Auth";
import { OverlayProvider } from "./features/Overlay";
import { ToasterProvider } from "./features/Toaster";
import { ConfigProvider } from "./features/Config";
import "./sentry";
import "./App.scss";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <OverlayProvider position="fixed">
          <ToasterProvider>
            <AuthProvider>
              <ConfigProvider>
                <ScrollToTop />
                <Routes />
              </ConfigProvider>
            </AuthProvider>
          </ToasterProvider>
        </OverlayProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
