const Consumer = (props) => {
  return (
    <svg
      width="4rem" // 64px
      height="3.9375rem" // 63px
      viewBox="0 0 64 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_605_2151)">
        <path
          d="M35.4455 31.6622C34.9827 31.6622 34.5451 31.3786 34.3705 30.9203C34.1466 30.3278 34.444 29.664 35.0378 29.44C38.4245 28.1572 40.6123 25.0048 40.6123 21.409C40.6123 16.6591 36.749 12.7957 31.999 12.7957C27.2491 12.7957 23.3857 16.6591 23.3857 21.409C23.3857 25.0048 25.5735 28.1561 28.9603 29.44C29.5529 29.6651 29.8514 30.3277 29.6275 30.9215C29.4036 31.5152 28.7386 31.8127 28.146 31.5887C23.9255 29.989 21.0889 25.8994 21.0889 21.4101C21.0889 15.3946 25.9835 10.5 31.999 10.5C38.0145 10.5 42.9092 15.3946 42.9092 21.4101C42.9092 25.8994 40.0725 29.9901 35.8509 31.5887C35.7177 31.6381 35.581 31.6622 35.4455 31.6622Z"
          fill="#414042"
        />
        <path
          d="M49.2266 53.4756C48.5915 53.4756 48.0781 52.9622 48.0781 52.3271C48.0781 43.4612 40.8659 36.249 32 36.249C28.2216 36.249 24.5501 37.5858 21.6629 40.0124C18.0143 43.0788 15.9219 47.5669 15.9219 52.3271C15.9219 52.9622 15.4074 53.4756 14.7734 53.4756C14.1395 53.4756 13.625 52.9622 13.625 52.3271C13.625 46.8881 16.016 41.7581 20.1837 38.2542C23.4855 35.4796 27.6807 33.9521 32 33.9521C42.1315 33.9521 50.375 42.1956 50.375 52.3271C50.375 52.9622 49.8616 53.4756 49.2266 53.4756Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_605_2151">
          <rect
            width="63"
            height="63"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Consumer;
