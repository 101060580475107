import React from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import { Col } from "react-bootstrap";

const FieldContainer = ({ error, children, meta, input }) => {
  return (
    <Form.Group as={Col} controlId={input?.name}>
      {children}
      <Form.Control.Feedback
        type="invalid"
        className={"d-block"}
        style={{ minHeight: 23 }}
      >
        {meta.touched && !meta.active && (meta.error || error)}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

FieldContainer.propTypes = {
  error: PropTypes.string,
  meta: PropTypes.object,
  input: PropTypes.object,
};

export default FieldContainer;
