import { createSelector } from "reselect";
import { createSlice } from "@reduxjs/toolkit";

const initState = {
  activeStep: null,
  activeNamedStep: null,
  values: {},
  references: {},
};

const emptyObject = {};

export const createWizardSelectors = (name) => {
  const exports = {};
  exports.getWizardState = (state) => state[name];

  exports.getActiveNamedStep = createSelector(
    exports.getWizardState,
    (state) => state.activeNamedStep
  );
  exports.getValues = createSelector(
    exports.getWizardState,
    (state) => state.values || emptyObject
  );
  exports.getReferences = createSelector(
    exports.getWizardState,
    (state) => state.references || emptyObject
  );
  exports.getReferencesByName = createSelector(
    exports.getValues,
    (state, name) => name,
    (references, name) => references[name] || emptyObject
  );
  exports.getValuesByStepName = createSelector(
    exports.getValues,
    (state, stepName) => stepName,
    (values, stepName) => values[stepName] || emptyObject
  );
  exports.getActiveStepValues = createSelector(
    exports.getValues,
    exports.getActiveNamedStep,
    (values, activeStep) => values[activeStep] || emptyObject
  );
  return exports;
};

export const createWizardSlice = (name, initialState = initState) => {
  const slice = createSlice({
    name,
    initialState,
    reducers: {
      setActiveStep(state, { payload }) {
        const { activeStep, activeNamedStep } = payload;
        state.activeStep = activeStep;
        state.activeNamedStep = activeNamedStep;
      },

      setStepValues(state, { payload }) {
        state.values[payload.stepName] = payload.values;
      },

      setActiveStepValues(state, { payload }) {
        state.values[state.activeNamedStep] = payload;
      },

      setReferences(state, { payload: [name, value] }) {
        state.references[name] = value;
      },

      clear: () => initialState,
    },
  });
  return {
    reducer: slice.reducer,
    actions: slice.actions,
    selectors: createWizardSelectors(name),
  };
};
