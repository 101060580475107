import { Stack } from "react-bootstrap";
import classNames from "classnames";
import PropTypes from "prop-types";

import ContentHeader from "../ContentHeader";

function FormSection({ direction, classes = {}, title, subtitle, children }) {
  return (
    <Stack className="g-0 p-4" direction={direction}>
      <ContentHeader
        title={title}
        subtitle={subtitle}
        classes={{
          title: classNames("fw-bold mb-2 lh-sm", !subtitle && "mb-4"),
          subtitle: classes.subtitle || "mb-4",
        }}
      />
      <div
        className={classNames({
          "ms-auto": direction === "horizontal",
        })}
      >
        {children}
      </div>
    </Stack>
  );
}

FormSection.defaultProps = {
  direction: "vertical",
};

FormSection.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node,
  direction: PropTypes.oneOf(["vertical", "horizontal"]),
};

export default FormSection;
