import { useCallback } from "react";
import { find, noop } from "lodash";
import PropTypes from "prop-types";
import { Field, useForm, useFormState } from "react-final-form";

import { Fields } from "../../../constants/forms";
import {
  LocationPropType,
  PickupPointsPropType,
} from "../../../constants/propType";
import { getPickupLocationCode } from "../../../utils/pickupPoint";
import EditPickupPoint from "../EditPickupPoint";

const EditPickupPointFormField = ({
  currentLocation,
  pickupLocations,
  onClickBack,
  onClickNext,
  onDirectionsClick,
  onMapPinClick,
  onPickupDetailsClick,
  onShopsFilterClick,
  onLockersFilterClick,
  onSelectPickupPoint,
  onClickSetupLater,
}) => {
  const form = useForm();
  const formState = useFormState();
  const formValues = formState.values;

  const onSelectedPickupPoint = useCallback(
    (pickupPoint) => {
      const pickupLocationCode = getPickupLocationCode(pickupPoint);
      const newValue =
        formValues.pickupLocationCode === pickupLocationCode
          ? null
          : pickupLocationCode;

      form.change(Fields.PICKUP_LOCATION_CODE, newValue);

      onSelectPickupPoint(newValue);
    },
    [form, formValues, onSelectPickupPoint]
  );

  return (
    <Field
      name={Fields.PICKUP_LOCATION_CODE}
      component={EditPickupPoint}
      selectedPickupPoint={find(pickupLocations, [
        `pickupLocation.${Fields.PICKUP_LOCATION_CODE}`,
        formValues[Fields.PICKUP_LOCATION_CODE],
      ])}
      currentLocation={currentLocation}
      pickupLocations={pickupLocations}
      onSelectedPickupPoint={onSelectedPickupPoint}
      onClickBack={onClickBack}
      onClickNext={onClickNext}
      onSetupLater={onClickSetupLater}
      onDirectionsClick={onDirectionsClick}
      onMapPinClick={onMapPinClick}
      onPickupDetailsClick={onPickupDetailsClick}
      onShopsFilterClick={onShopsFilterClick}
      onLockersFilterClick={onLockersFilterClick}
    />
  );
};

EditPickupPointFormField.defaultProps = {
  onPickupDetailsClick: noop,
  onShopsFilterClick: noop,
  onLockersFilterClick: noop,
  onSelectPickupPoint: noop,
  onDirectionsClick: noop,
  onMapPinClick: noop,
  onClickSetupLater: noop,
};

EditPickupPointFormField.propTypes = {
  currentLocation: LocationPropType.isRequired,
  pickupLocations: PickupPointsPropType.isRequired,
  onClickBack: PropTypes.func,
  onClickNext: PropTypes.func,
  onPickupDetailsClick: PropTypes.func,
  onShopsFilterClick: PropTypes.func,
  onLockersFilterClick: PropTypes.func,
  onSelectPickupPoint: PropTypes.func,
  onDirectionsClick: PropTypes.func,
  onMapPinClick: PropTypes.func,
  onClickSetupLater: PropTypes.func,
};

export default EditPickupPointFormField;
