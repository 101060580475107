import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Trackable, useTracker } from "@dpdgroupuk/react-event-tracker";

import Form from "../../components/organisms/EditProfileForm";
import SafePlace from "../../components/organisms/SafePlace";
import Page from "../../components/templates/ProfilePage";
import { EDIT_SAFE_PLACE } from "../../constants/analytics";
import { Fields } from "../../constants/forms";
import {
  CHANGES_SAVED,
  LEAVE_SAFE,
  LEAVE_SAFE_MESSAGE,
  UNABLE_TO_LOAD_IMAGE,
} from "../../constants/strings";
import { useAuth } from "../../features/Auth";
import { useOverlay } from "../../features/Overlay";
import {
  ConsumerAddressesActions,
  ConsumerAddressesSelector,
} from "../../features/Profile";
import { uploadAddressSafePlacePhoto } from "../../features/Profile/Addresses/service";
import { useToaster } from "../../features/Toaster";
import { SafePlaceModel } from "../../models";
import { isImageInBase64 } from "../../utils/image";

const EditSafePlace = () => {
  const { udprn } = useParams();

  const auth = useAuth();
  const toaster = useToaster();
  const overlay = useOverlay();
  const dispatch = useDispatch();
  const address = useSelector(
    ConsumerAddressesSelector.getConsumerAddressById(udprn)
  );
  const tracker = useTracker();
  const initialValues = useMemo(
    () => ({
      [Fields.SAFE_PLACE_CODE]: address.preferences.safePlace.code,
      [Fields.SAFE_PLACE_CUSTOM]: address.preferences.safePlace.custom,
      [Fields.IMAGE_URL]: address.preferences.safePlace.imageUrl,
      [Fields.SAFE_PLACE_RESPONSIBILITY]: false,
    }),
    [address]
  );

  const handleLoadImageError = useCallback(
    (err, form) => {
      form.change(Fields.IMAGE_URL, null);
      toaster.showError({
        body: UNABLE_TO_LOAD_IMAGE,
      });
    },
    [toaster]
  );

  const handleSubmit = useCallback(
    async ({ safePlaceResponsibility, ...formValues }, formProps) => {
      try {
        overlay.show();
        tracker.logEvent(EDIT_SAFE_PLACE.ON_CLICK_SAVE);

        const values = { ...formValues };

        if (formValues.imageUrl && isImageInBase64(formValues.imageUrl)) {
          values.imageUrl = await uploadAddressSafePlacePhoto(
            auth.currentSession.uid,
            udprn,
            formValues.imageUrl
          );
        }
        await dispatch(
          ConsumerAddressesActions.updateAddress([
            auth.currentSession.uid,
            {
              udprn,
              "preferences.safePlace": values,
            },
          ])
        ).unwrap();

        toaster.showSuccess({
          body: CHANGES_SAVED,
          autoHide: true,
        });

        if (formValues[Fields.SAFE_PLACE_CODE]) {
          formProps.initialize({
            ...formValues,
            [Fields.SAFE_PLACE_RESPONSIBILITY]: true,
          });
        }
      } catch (e) {
        toaster.showError({
          body: e.message,
          reload: e.reload,
        });
      } finally {
        overlay.hide();
      }
    },
    [udprn, auth, dispatch, overlay, toaster, tracker]
  );

  return (
    <Trackable
      loadId={EDIT_SAFE_PLACE.LOAD}
      interfaceId={EDIT_SAFE_PLACE.INTERFACE_ID}
    >
      <Page
        title={LEAVE_SAFE}
        subtitle={LEAVE_SAFE_MESSAGE}
        showBackButtonDesktop
      >
        <Form
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validate={SafePlaceModel.validateSafePlaceValues}
        >
          <SafePlace
            classes={{ container: "p-4", optionCol: "gx-lg-2" }}
            onLoadImageError={handleLoadImageError}
          />
        </Form>
      </Page>
    </Trackable>
  );
};

export default EditSafePlace;
