import { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import Wizard from "./RouterWizard";

export default function Reduxify({ slice, children, ...props }) {
  const dispatch = useDispatch();
  const { actions, selectors } = slice;

  const { activeStep, values, references } = useSelector(
    selectors.getWizardState
  );

  useEffect(() => {
    return () => {
      dispatch(actions.clear());
    };
  }, []);

  const handleStepChange = (payload) => {
    dispatch(actions.setActiveStep(payload));
  };

  const handleStepValuesChange = (stepName, values) => {
    dispatch(actions.setStepValues({ stepName, values }));
  };

  const handleReferencesChange = (name, value) => {
    dispatch(actions.setReferences([name, value]));
  };

  return (
    <Wizard
      initialStep={activeStep}
      initialValues={values}
      initialReferences={references}
      onStepChange={handleStepChange}
      onStepValuesChange={handleStepValuesChange}
      onReferencesChange={handleReferencesChange}
      {...props}
    >
      {children}
    </Wizard>
  );
}

Reduxify.propTypes = {
  ...Wizard.propTypes,
  slice: PropTypes.shape({
    actions: PropTypes.shape({
      setActiveStep: PropTypes.func,
      setStepValues: PropTypes.func,
      setReferences: PropTypes.func,
      clear: PropTypes.func,
    }).isRequired,
    selectors: PropTypes.shape({
      getWizardState: PropTypes.func,
    }).isRequired,
  }),
};
