import classNames from "classnames";
import PropTypes from "prop-types";
import { Button, Col, Row } from "react-bootstrap";
import { noop } from "lodash";

import * as M from "../../../constants/strings";
import {
  getPickupInfo,
  getPickupName,
  getPickupAmenities,
} from "../../../utils/pickupPoint";
import PickupInfo from "../../atoms/PickupInfo";
import PickupPointTitle from "../../atoms/PickupPointTitle";
import PickupAmenities from "../../atoms/PickupAmenities";
import PickupDetails from "../PickupDetails";
import styles from "./PickupPointItem.module.scss";

const PickupPointItem = ({
  number,
  pickupPoint,
  isSelected,
  isActive,
  onSelectClick,
  onPickupDetailsClick,
  directionLink,
  onDirectionsClick,
}) => (
  <Col className="p-3">
    <Row className="g-0 d-flex justify-content-between align-items-end">
      <Col>
        <Row className="g-0 mb-3">
          <Col className={styles.titleContainer}>
            <PickupPointTitle
              number={number}
              name={getPickupName(pickupPoint)}
            />
          </Col>
        </Row>

        <Row className="g-0 mb-md-3">
          <Col>
            <PickupInfo
              {...getPickupInfo(pickupPoint)}
              className={styles.pickupInfoText}
            />
          </Col>
        </Row>

        <Row className="g-0 d-flex justify-content-between">
          <Col className="my-3 my-md-0">
            <PickupAmenities {...getPickupAmenities(pickupPoint)} />
          </Col>
          <Col xs={12} md="auto">
            <Row className="g-0">
              <Col xs={6} md="auto" className="pe-1">
                <Button
                  variant="outline-danger"
                  className={classNames("w-100", styles.detailsButton)}
                  onClick={onPickupDetailsClick}
                  size="sm"
                >
                  {M.DETAILS}
                </Button>
              </Col>
              <Col xs={6} md="auto" className="ps-1">
                <Button
                  variant={isSelected ? "success" : "danger"}
                  onClick={onSelectClick}
                  className="w-100"
                  size="sm"
                >
                  {isSelected ? M.SELECTED : M.SELECT}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
    {isActive && (
      <PickupDetails
        pickupPoint={pickupPoint}
        directionLink={directionLink}
        onDirectionsClick={onDirectionsClick}
      />
    )}
  </Col>
);

PickupPointItem.propTypes = {
  number: PropTypes.number,
  pickupPoint: PropTypes.object,
  isSelected: PropTypes.bool,
  isActive: PropTypes.bool,
  onSelectClick: PropTypes.func,
  onPickupDetailsClick: PropTypes.func,
  directionLink: PropTypes.string,
  onDirectionsClick: PropTypes.func,
};

PickupPointItem.defaultProps = {
  onDirectionsClick: noop,
};

export default PickupPointItem;
