import {
  collection,
  doc,
  getDocs,
  getFirestore,
  serverTimestamp,
} from "firebase/firestore";

import { defaultApp } from "../../../firebase";
import t from "../transaction";

const db = getFirestore(defaultApp);

export const getEmailsCollectionRef = (uid) =>
  collection(db, "consumers", uid, "emails");

export const getEmailDocRef = (uid, id) =>
  doc(db, "consumers", uid, "emails", id);

export const getEmails = async (uid) => {
  const emailsRef = getEmailsCollectionRef(uid);
  const querySnapshot = await getDocs(emailsRef);
  return querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
};

export const getEmail = async (uid, id, { transaction = t } = {}) => {
  const emailRef = getEmailDocRef(uid, id);
  const snapshot = await transaction.get(emailRef);
  return {
    id,
    ...snapshot.data(),
  };
};

export const addEmail = async (uid, email, { transaction = t } = {}) => {
  const emailDocRef = getEmailDocRef(uid, email.id);
  await transaction.set(emailDocRef, {
    ...email,
    createdDate: serverTimestamp(),
    modified_at: null,
  });
  return email;
};

export const updateEmail = async (uid, email, { transaction = t } = {}) => {
  const emailDocRef = getEmailDocRef(uid, email.id);
  const data = {
    ...email,
    modified_at: serverTimestamp(),
  };
  await transaction.update(emailDocRef, data);

  return data;
};

export const deleteEmail = async (uid, id, { transaction = t } = {}) => {
  const emailRef = getEmailDocRef(uid, id);
  await transaction.delete(emailRef);
  return id;
};
