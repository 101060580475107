import React, { useRef, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { pick } from "lodash";
import { useNavigate, useLocation, createSearchParams } from "react-router-dom";
import { Trackable, useTracker } from "@dpdgroupuk/react-event-tracker";

import * as M from "../../constants/strings";
import Container from "../../components/templates/Login/LoginContainer";
import VerifyPin from "../../components/molecules/VerifyPin";
import { useAuth } from "../../features/Auth";
import { getVerifyPhoneValues } from "./selectors";
import { Fields } from "../../constants/forms";
import { useOverlay } from "../../features/Overlay";
import { Paths } from "../../router";
import { VERIFY_PIN } from "../../constants/analytics";

const VerifyPinContainer = ({ setValues, values, nextStep, previousStep }) => {
  const auth = useAuth();
  const overlay = useOverlay();
  const navigate = useNavigate();
  const location = useLocation();
  const phoneNumberValues = useSelector(getVerifyPhoneValues);
  const [submitError, setSubmitError] = useState(null);
  const [pinInputDisabled, setPinInputsDisabled] = useState(false);
  const pinInputRef = useRef(null);
  const tracker = useTracker();

  useEffect(() => {
    const interval = setInterval(() => {
      if (values.resendPinTimer > 0) {
        setValues({
          ...values,
          resendPinTimer: values.resendPinTimer - 1,
        });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [values, setValues]);

  const handleSubmit = useCallback(
    async (pin) => {
      if (pin.length === 6 && values.verificationId) {
        tracker.logEvent(VERIFY_PIN.ON_PIN_ENTERED);

        try {
          overlay.show();
          setPinInputsDisabled(true);
          setValues({ ...values, pin });
          const { consumerId, customToken } = await auth
            .confirmPinNumber({
              pin,
              verificationId: values.verificationId,
            })
            .unwrap();

          if (consumerId) {
            // relogin with custom token to get new session with updated claims
            await auth.signInWithCustomToken(customToken).unwrap();
            return navigate(location?.state?.referer || Paths.PROFILE, {
              replace: true,
            });
          }
          navigate(
            {
              pathname: location.pathname,
              search: createSearchParams({
                step: "profile",
                customToken,
              }).toString(),
            },
            {
              replace: true,
            }
          );
        } catch (e) {
          setSubmitError(e.message);
          pinInputRef.current?.clear();
        } finally {
          setPinInputsDisabled(false);
          overlay.hide();
        }
      }
    },
    [
      overlay,
      auth,
      values,
      setValues,
      navigate,
      location,
      setPinInputsDisabled,
      tracker,
    ]
  );

  const handleResendPin = useCallback(async () => {
    tracker.logEvent(VERIFY_PIN.ON_RESEND_PIN);

    const verificationId = await auth
      .verifyPhoneNumber(
        pick(phoneNumberValues, [Fields.PHONE_NUMBER, Fields.UDPRN])
      )
      .unwrap();

    pinInputRef.current?.clear();
    pinInputRef.current?.focus();
    setSubmitError(null);

    setValues({
      verificationId,
      pin: null,
      resendPinTimer: 60,
    });
  }, [auth, setValues, phoneNumberValues, pinInputRef, tracker]);

  const onBackClick = useCallback(() => {
    tracker.logEvent(VERIFY_PIN.ON_BACK);
    previousStep();
  }, [tracker, previousStep]);

  return (
    <Trackable loadId={VERIFY_PIN.LOAD} interfaceId={VERIFY_PIN.INTERFACE_ID}>
      <Container>
        <VerifyPin
          title={M.VERIFY_MOBILE_NUMBER}
          subtitle={M.HAVE_SENT_PIN_TO_$(phoneNumberValues?.phoneNumber)}
          inputRef={pinInputRef}
          inputDisabled={pinInputDisabled}
          submitError={submitError}
          resendPinIn={values.resendPinTimer}
          phoneNumber={phoneNumberValues?.phoneNumber}
          onResendPinClick={handleResendPin}
          onChange={handleSubmit}
          onBackClick={onBackClick}
        />
      </Container>
    </Trackable>
  );
};

export default VerifyPinContainer;
