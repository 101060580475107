export const Fields = {
  PHONE_NUMBER: "phoneNumber",
  ONBOARDING_STARTED: "started",
  POSTCODE: "postcode",
  ADDRESS: "address",
  FIRSTNAME: "firstName",
  LASTNAME: "lastName",
  EMAIL: "email",
  UDPRN: "udprn",
  PREFERRED_NEIGHBOUR: "preferred",
  AVOID_NEIGHBOUR: "avoid",
  SAFE_PLACE_CODE: "code",
  SAFE_PLACE_CUSTOM: "custom",
  SAFE_PLACE_PHOTO: "safePlacePhoto",
  IMAGE_URL: "imageUrl",
  SAFE_PLACE_RESPONSIBILITY: "safePlaceResponsibility",
  NOTE: "note",
  TYPE: "type",
  CUSTOM_ADDRESS_TYPE: "customAddressType",
  FIRST_DELIVERY_ATTEMPT: "firstDeliveryAttempt",
  NEED_MORE_TIME: "needMoreTime",
  PICKUP_LOCATION_CODE: "pickupLocationCode",
  NOTIFICATIONS: "notifications",
};
