import { uniq } from "lodash";
import { Formatters } from "../utils";

export const formatAddressLine = (address) =>
  Formatters.joinStringsWithComma(
    uniq([
      address?.organisation,
      Formatters.joinStringsWith(
        uniq([address?.property, address?.street]),
        " "
      ),
      address?.town,
      address?.locality,
    ])
  );

export const formatAddressOrgPropertyStreet = (address) =>
  Formatters.joinStringsWithComma(
    uniq([
      address?.organisation,
      Formatters.joinStringsWith(
        uniq([address?.property, address?.street]),
        " "
      ),
    ])
  );

export const formatAddressTownLocalityPostcode = (address) =>
  Formatters.joinStringsWithComma(
    uniq([address?.town, address?.locality, address?.postcode])
  );

export const formatAddressWithPostcode = (address) =>
  Formatters.joinStringsWithComma(
    uniq([
      address?.organisation,
      Formatters.joinStringsWith(
        uniq([address?.property, address?.street]),
        " "
      ),
      address?.town,
      address?.county,
      address?.postcode,
    ])
  );

export const formatAddressOption = (address) => ({
  value: address?.udprn,
  label: formatAddressLine(address),
});

export const getAddressPoint = (deliveryAddress) => {
  if (deliveryAddress?.udprnLatitude && deliveryAddress?.udprnLongitude) {
    return {
      latitude: deliveryAddress.udprnLatitude,
      longitude: deliveryAddress.udprnLongitude,
    };
  }

  if (deliveryAddress?.postcodeLatitude && deliveryAddress?.postcodeLongitude) {
    return {
      latitude: deliveryAddress.postcodeLatitude,
      longitude: deliveryAddress.postcodeLongitude,
    };
  }

  return null;
};
