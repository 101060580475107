import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Image } from "react-bootstrap";

import ImageNotFound from "../../../assets/images/image-not.jpg";
import styles from "./FallbackImage.module.scss";

function FallbackImage({ src, fallBackSrc, className, tabIndex, ...props }) {
  const [config, setConfig] = React.useState({
    loaded: false,
    error: false,
  });
  return (
    <>
      {!config.error ? (
        <Image
          src={src}
          tabIndex={tabIndex}
          {...props}
          className={classNames(styles.fallbackImage, className)}
          onLoad={(event) => {
            setConfig({
              loaded: true,
              error: false,
            });
            props.onLoad && props.onLoad(event);
          }}
          onError={(err) => {
            setConfig({
              error: true,
              loaded: true,
            });
            props.onError && props.onError(err);
          }}
        />
      ) : (
        <Image
          src={fallBackSrc}
          className={classNames(styles.fallbackImage, styles.error, className)}
          {...props}
        />
      )}
    </>
  );
}

FallbackImage.defaultProps = {
  fallBackSrc: ImageNotFound,
};

FallbackImage.propTypes = {
  src: PropTypes.string,
  fallBackSrc: PropTypes.string,
  className: PropTypes.string,
  ...Image.propTypes,
};

export default FallbackImage;
