import FetchClient from "@dpdgroupuk/fetch-client";

class AuthClient extends FetchClient {
  constructor(params = {}) {
    super({
      mode: "cors",
      credentials: "include",
      baseUrl:
        process.env.NODE_ENV === "development"
          ? "http://localhost:8091"
          : `${process.env.REACT_APP_CPP_API_URL}`,
      ...params,
    });
  }

  signInWithPhoneNumber(phoneNumber, udprn, recaptchaToken) {
    return this.post({
      path: "/auth/verifyPhoneNumber",
      headers: {
        "X-Firebase-AppCheck": recaptchaToken,
      },
      body: {
        phoneNumber,
        udprn,
      },
    });
  }

  confirmVerificationCode(verificationId, pin) {
    return this.post({
      path: "/auth/confirmVerificationCode",
      body: {
        verificationId,
        code: pin,
      },
    });
  }

  verifyUpdatePhoneNumber(phoneNumber, recaptchaToken) {
    return this.post({
      path: "/auth/verifyUpdatePhoneNumber",
      headers: {
        "X-Firebase-AppCheck": recaptchaToken,
      },
      body: {
        phoneNumber,
      },
    });
  }
  confirmUpdatePhoneNumber(verificationId, pin) {
    return this.post({
      path: "/auth/confirmUpdatePhoneNumber",
      body: {
        verificationId,
        code: pin,
      },
    });
  }

  async setCustomUserClaims(customClaims) {
    return this.post({
      path: "/auth/setCustomUserClaims",
      body: customClaims,
    });
  }

  async createSession(token) {
    const { data } = await this.post({
      path: "/auth/session/login",
      body: {
        idToken: token,
      },
    });
    return data;
  }

  async reloadSession() {
    const { data } = await this.post({
      path: "/auth/session/reload",
    });
    return data;
  }

  async getSession() {
    const result = await this.get({
      path: "/auth/session",
    });

    return result.data;
  }

  async signOut() {
    return this.post({
      path: "/auth/session/logout",
    });
  }

  async sendMagicLink(email, recaptchaToken) {
    return this.post({
      path: "/auth/sendMagicLink",
      headers: {
        "X-Firebase-AppCheck": recaptchaToken,
      },
      body: {
        email,
      },
    });
  }

  async sendVerifyEmail(email, id) {
    return this.post({
      path: "/auth/sendVerifyEmail",
      body: {
        email,
        id,
      },
    });
  }

  async verifyMagicLink(verificationId) {
    return this.get({
      path: "/auth/verifyMagicLink",
      query: {
        verificationId,
      },
    });
  }

  async verifyEmail(verificationId) {
    return this.get({
      path: "/auth/verifyEmail",
      query: {
        verificationId,
      },
    });
  }

  async unlinkAccounts(providers) {
    return this.post({
      path: "/auth/unlinkAccounts",
      body: {
        providers,
      },
    });
  }
}

const authClient = new AuthClient();

export default authClient;
