import { useCallback, useEffect, useRef } from "react";

export const useAbortController = () => {
  const abortControllerRef = useRef();

  useEffect(() => {
    return () => {
      abortControllerRef.current?.abort();
    };
  }, []);

  return useCallback(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current?.abort();
    }
    abortControllerRef.current = new AbortController();
    return abortControllerRef.current.signal;
  }, []);
};
