import { forwardRef } from "react";
import { Form } from "react-bootstrap";
import classNames from "classnames";
import AuthCode from "react-auth-code-input";
import styles from "./PinInput.module.scss";

const PinInput = forwardRef(
  ({ inputClassName, containerClassName, meta, ...props }, ref) => (
    <div className={styles.wrapper}>
      <AuthCode
        ref={ref}
        containerClassName={classNames(
          "d-flex justify-content-center",
          containerClassName
        )}
        inputClassName={classNames(
          "form-control",
          styles.pinInput,
          inputClassName
        )}
        allowedCharacters="numeric"
        {...props}
      />
      <Form.Control.Feedback
        type="invalid"
        className={"d-block"}
        style={{ minHeight: 23 }}
      >
        {meta?.error}
      </Form.Control.Feedback>
    </div>
  )
);

PinInput.propTypes = AuthCode.propTypes;

export default PinInput;
