import { serverTimestamp, Timestamp } from "firebase/firestore";
import { PLATFORM } from "../../../constants";
import { AuthModel } from "../../Auth";

export const createConsumerModel = (
  session,
  { phoneNumber, firstName, lastName, started }
) => ({
  uid: session.uid,
  consumerId: session.uid,
  deviceId: session.uid, // might be something else
  phoneNumber,
  restApiVersion: "1.5",
  firstName,
  lastName,
  liteProfile: false,
  appVersion: null,
  consumerImageUrl: null,
  modified_at: null,
  configuration: {
    designSpace: {
      authToken: null,
      username: null,
    },
    notifications: {
      push: false,
    },
  },
  metrics: {
    createdDate: serverTimestamp(),
    onBoarding: {
      started: started ? Timestamp.fromMillis(started) : null,
      completed: serverTimestamp(),
      platform: PLATFORM.WEB,
      provider: AuthModel.getSignUpInitialProviderId(session),
    },
  },
});
