import { useCallback } from "react";
import { get } from "lodash";
import { Navigate, useSearchParams } from "react-router-dom";

import { Loader } from "../../components/molecules/Loader";
import * as M from "../../constants/strings";
import { SOMETHING_WENT_WRONG_TRY_AGAIN } from "../../constants/strings";
import { useAuth } from "../../features/Auth";
import { useToaster } from "../../features/Toaster";
import { Paths } from "../../router";
import { PROVIDERS_UI_BY_CODE } from "../../constants/providers";

function DeleteLoginAccountStatus() {
  const [searchParams] = useSearchParams();
  const auth = useAuth();
  const toaster = useToaster();

  const deleteLoginAccountConfirmation = useCallback(async ({ providerId }) => {
    try {
      const currentSession = await auth.reloadSession().unwrap();

      const identities = get(currentSession, "firebase.identities");

      if (!identities.hasOwnProperty(providerId)) {
        toaster.showSuccess({
          body: M.AUTH_PROVIDER_DELETED_MESSAGE(
            PROVIDERS_UI_BY_CODE[providerId]?.label
          ),
        });
      }
    } catch (e) {
      toaster.showError({
        body: SOMETHING_WENT_WRONG_TRY_AGAIN,
      });
    }
  }, []);

  return (
    <Loader
      promiseFn={deleteLoginAccountConfirmation}
      code={searchParams.get("code")}
      providerId={searchParams.get("providerId")}
    >
      <Navigate to={Paths.PROFILE_PERSONAL_INFO} replace />;
    </Loader>
  );
}

export default DeleteLoginAccountStatus;
