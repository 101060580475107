const ShopWindowSticker = (props) => (
  <svg
    width="3.375rem" // 54px
    height="3.375rem"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_48_990)">
      <path d="M200 0H0V117.625H200V0Z" fill="white" />
      <path
        d="M121.017 75.0319C118.368 75.7325 114.92 76.0791 111.919 76.0791C104.22 76.0791 99.1224 71.9819 99.1224 64.4832C99.1224 57.388 103.871 52.7907 110.82 52.7907C112.37 52.7907 114.018 52.9888 115.019 53.4888V43.1877H121.017V75.0319ZM115.019 58.7892C114.068 58.3386 112.82 58.0886 111.317 58.0886C107.671 58.0886 105.22 60.339 105.22 64.2876C105.22 68.5358 107.869 70.9372 112.117 70.9372C112.867 70.9372 114.016 70.8852 115.016 70.6872V58.7892H115.019ZM170.235 75.0319C167.584 75.7325 164.135 76.0791 161.137 76.0791C153.441 76.0791 148.341 71.9819 148.341 64.4832C148.341 57.388 153.092 52.7907 160.041 52.7907C161.59 52.7907 163.239 52.9888 164.239 53.4888V43.1877H170.238V75.0319H170.235ZM164.237 58.7892C163.286 58.3386 162.036 58.0886 160.536 58.0886C156.887 58.0886 154.438 60.339 154.438 64.2876C154.438 68.5358 157.087 70.9372 161.335 70.9372C162.086 70.9372 163.234 70.8852 164.234 70.6872V58.7892H164.237ZM130.449 58.7397C131.447 58.3386 132.846 58.1901 134.046 58.1901C137.747 58.1901 140.295 60.339 140.295 64.1861C140.295 68.724 137.47 70.7763 133.695 70.8307V76.0791C133.794 76.0791 133.895 76.0816 133.997 76.0816C141.743 76.0816 146.392 71.7344 146.392 63.9881C146.392 56.9399 141.446 52.7932 134.145 52.7932C130.447 52.7932 126.798 53.6423 124.399 54.6425V84.583H130.449V58.7397V58.7397Z"
        fill="#414042"
      />
      <path
        d="M56.3086 91.2722C56.6527 91.2697 56.9968 91.1979 57.2568 91.0444L81.7112 76.6238C82.2336 76.3143 82.6594 75.5716 82.6594 74.9651V47.8939L66.5702 57.2692C66.3226 57.4128 65.9339 57.4029 65.6913 57.2518L64.2653 56.4052C64.149 56.3334 64.0425 56.217 63.9633 56.0784C63.9584 56.071 63.9534 56.0611 63.9485 56.0536C63.8569 55.9051 63.8049 55.7491 63.7999 55.6055L63.7628 53.9419C63.7529 53.6622 63.9361 53.3205 64.1861 53.1745L81.3275 43.1877L57.2716 30.0767C57.0067 29.9306 56.6577 29.8588 56.3086 29.8588H56.3037C55.9546 29.8588 55.608 29.9306 55.3431 30.0767L31.2873 43.1877L58.2347 58.8759C58.4847 59.012 58.6753 59.3363 58.6753 59.6285V82.5133C58.6753 82.8005 58.4698 83.1298 58.2173 83.2659L56.7666 84.0705C56.6453 84.1349 56.4918 84.1695 56.3334 84.1695H56.3061C56.1328 84.1745 55.9694 84.1398 55.8432 84.0705L54.3875 83.2659C54.1399 83.1347 53.9369 82.803 53.9369 82.5133V62.1115C53.9246 61.963 53.8156 61.7798 53.6943 61.7105L29.9529 47.889V74.9601C29.9529 75.5667 30.3787 76.3143 30.9035 76.6188L55.3555 91.0395C55.6204 91.1979 55.9645 91.2746 56.3086 91.2722Z"
        fill="#DC0032"
      />
      <path d="M200 117.625H0V200H200V117.625Z" fill="#DC0032" />
      <path
        d="M60.2473 137.584C61.1361 137.584 61.7451 137.998 61.7451 139.149C61.7451 140.3 61.1361 140.758 60.2473 140.758C59.3115 140.758 58.6555 140.297 58.6555 139.149C58.6555 138 59.3091 137.584 60.2473 137.584ZM59.1704 146.046H61.2302V169.3V170.099H59.1704V146.046ZM80.0995 145.402C83.7511 145.402 86.8878 146.642 89.1356 148.668L88.0587 150.232C86.0931 148.44 83.4243 147.336 80.1466 147.336C73.6851 147.336 69.2364 151.935 69.2364 158.05C69.2364 164.168 73.6851 168.812 80.1466 168.812C83.4243 168.812 86.0931 167.708 88.0587 165.915L89.1356 167.48C86.8878 169.503 83.7511 170.745 80.0995 170.745C72.4671 170.745 67.1296 165.274 67.1296 158.053C67.1296 150.829 72.4671 145.402 80.0995 145.402ZM113.295 146.368L99.0605 157.313L114.09 169.775V170.097H111.468L96.8597 157.773V170.097H94.7999V137.448H96.8597V156.81L110.812 146.048H113.293V146.368H113.295ZM137.782 170.099V165.086C136.002 168.49 132.586 170.513 128.043 170.513C121.393 170.513 118.163 165.913 118.163 159.89V146.048H120.222V159.659C120.222 164.717 122.844 168.582 128.511 168.582C133.38 168.582 136.799 165.638 137.782 161.868V146.048H139.842V170.099H137.782ZM149.487 151.245C151.032 149.405 154.074 147.474 157.775 147.474C164.096 147.474 168.171 151.888 168.171 158.144C168.171 164.353 164.051 168.723 157.775 168.723C156.278 168.723 154.867 168.403 153.616 167.903V169.696C154.961 170.23 156.471 170.562 158.102 170.562C165.452 170.562 170.228 165.366 170.228 158.144C170.228 150.831 165.452 145.635 158.102 145.635C154.404 145.635 151.361 147.383 149.487 149.267V146.048H147.427V178.747H149.487V151.245V151.245ZM153.616 169.693V167.901V169.693ZM43.1084 138.827H29.9504V170.099H32.0101V160.259H32.0151V158.419H32.0101V140.669H42.8732C47.8369 140.669 51.9564 143.521 51.9564 149.636C51.9564 155.201 47.8369 158.419 42.8732 158.419H36.1444V160.259H43.1084C49.0079 160.259 54.0656 156.302 54.0656 149.636C54.0656 142.231 49.0079 138.827 43.1084 138.827Z"
        fill="white"
      />
    </g>
  </svg>
);

export default ShopWindowSticker;
