import { useCallback, useRef, useState } from "react";
import { isEmpty, noop } from "lodash";
import PropTypes from "prop-types";
import { useBreakpoint } from "use-breakpoint";

import { PICKUP_POINT_TYPE } from "../../../constants/pickupPoint";
import {
  LocationPropType,
  PickupPointPropType,
  PickupPointsPropType,
} from "../../../constants/propType";
import { BREAKPOINTS } from "../../../constants/ui";
import {
  filterByType,
  sortSelectedPickupToTop,
  getPickupLocationCode,
} from "../../../utils/pickupPoint";
import EditPickupPointDesktop from "../EditPickupPointDesktop";
import SetPickupPointMobile from "../SetPickupPointMobile";

const EditPickupPoint = ({
  currentLocation,
  pickupLocations,
  selectedPickupPoint,
  onSelectedPickupPoint,
  onClickBack,
  onClickNext,
  onSetupLater,
  onDirectionsClick,
  onMapPinClick,
  onPickupDetailsClick,
  onShopsFilterClick,
  onLockersFilterClick,
}) => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const pickupListRef = useRef();
  const pickupMobileListRef = useRef();
  const [activePickupPoint, setActivePickupPoint] = useState();
  const [filterBy, setFilterBy] = useState();

  const onFilterButtonClick = useCallback(
    (type) => {
      setFilterBy(type === filterBy ? null : type);

      if (type === PICKUP_POINT_TYPE.SHOP) {
        onShopsFilterClick();
      }

      if (type === PICKUP_POINT_TYPE.LOCKER) {
        onLockersFilterClick();
      }
    },
    [filterBy, onShopsFilterClick, onLockersFilterClick]
  );
  const isFiltersVisible =
    !isEmpty(filterByType(pickupLocations, PICKUP_POINT_TYPE.SHOP)) &&
    !isEmpty(filterByType(pickupLocations, PICKUP_POINT_TYPE.LOCKER));
  const filteredPickupPoints = filterByType(pickupLocations, filterBy).map(
    (pickupLocation, index) => ({ ...pickupLocation, number: index + 1 })
  );

  const onSelectClick = useCallback(
    (pickupPoint) => {
      onSelectedPickupPoint(pickupPoint);
      setActivePickupPoint(
        getPickupLocationCode(selectedPickupPoint) ===
          getPickupLocationCode(pickupPoint)
          ? null
          : pickupPoint
      );
      pickupListRef?.current?.scroll({
        top: 0,
      });
      pickupMobileListRef?.current?.scroll({
        top: 0,
      });
    },
    [selectedPickupPoint, onSelectedPickupPoint, setActivePickupPoint]
  );

  const handlePickupDetailsClick = useCallback(
    (pickupPoint) => {
      setActivePickupPoint(pickupPoint);
      onPickupDetailsClick(pickupPoint);
    },
    [setActivePickupPoint, onPickupDetailsClick]
  );

  return breakpoint === "mobile" ? (
    <SetPickupPointMobile
      currentLocation={currentLocation}
      selectedPickupPoint={selectedPickupPoint}
      activePickupPoint={activePickupPoint}
      pickupPoints={filteredPickupPoints}
      onSelectClick={onSelectClick}
      onPickupDetailsClick={handlePickupDetailsClick}
      filterBy={filterBy}
      isFiltersVisible={isFiltersVisible}
      onFilterButtonClick={onFilterButtonClick}
      pickupListRef={pickupMobileListRef}
      onClickBack={onClickBack}
      onClickNext={onClickNext}
      onSetupLater={onSetupLater}
      onDirectionsClick={onDirectionsClick}
      onMapPinClick={onMapPinClick}
    />
  ) : (
    <EditPickupPointDesktop
      currentLocation={currentLocation}
      selectedPickupPoint={selectedPickupPoint}
      activePickupPoint={activePickupPoint}
      pickupPoints={sortSelectedPickupToTop(
        filteredPickupPoints,
        selectedPickupPoint
      )}
      onSelectClick={onSelectClick}
      onPickupDetailsClick={handlePickupDetailsClick}
      filterBy={filterBy}
      isFiltersVisible={isFiltersVisible}
      onFilterButtonClick={onFilterButtonClick}
      pickupListRef={pickupListRef}
      onDirectionsClick={onDirectionsClick}
      onMapPinClick={onMapPinClick}
    />
  );
};

EditPickupPoint.defaultProps = {
  currentLocation: {},
  pickupLocations: [],
  selectedPickupPoint: {},
  onDirectionsClick: noop,
  onMapPinClick: noop,
  onShopsFilterClick: noop,
  onLockersFilterClick: noop,
  onPickupDetailsClick: noop,
};

EditPickupPoint.propTypes = {
  currentLocation: LocationPropType.isRequired,
  pickupLocations: PickupPointsPropType,
  selectedPickupPoint: PickupPointPropType,
  onSelectedPickupPoint: PropTypes.func,
  onClickBack: PropTypes.func,
  onClickNext: PropTypes.func,
  onSetupLater: PropTypes.func,
  onDirectionsClick: PropTypes.func,
  onMapPinClick: PropTypes.func,
  onShopsFilterClick: PropTypes.func,
  onLockersFilterClick: PropTypes.func,
  onPickupDetailsClick: PropTypes.func,
};

export default EditPickupPoint;
