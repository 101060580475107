import { useCallback } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Trackable } from "@dpdgroupuk/react-event-tracker";

import { ReduxifyWizard } from "../../features/Wizard";
import EnterEmail from "./EnterEmail";
import SentEmail from "./SentEmail";
import { Step } from "./components";
import { VerifyEmailSlice } from "./slice";
import { Loader } from "../../components/molecules/Loader";
import { useAuth } from "../../features/Auth";
import { Paths } from "../../router";
import { useToaster } from "../../features/Toaster";
import { DPD_PREFERENCES, EMAIL_VERIFIED } from "../../constants/strings";
import { EXPIRED_AUTH_CODES } from "../../constants";
import { AUTH_VERIFICATION } from "../../constants/analytics";

function Page() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const auth = useAuth();
  const dispatch = useDispatch();
  const toaster = useToaster();

  const validateAuthToken = useCallback(async ({ verificationId }) => {
    if (verificationId) {
      try {
        await auth.verifyMagicLink(verificationId).unwrap();

        // ProtectedRoute will manage proper page if session is profile is not completed
        navigate(Paths.PROFILE, {
          replace: true,
        });

        toaster.showSuccess({
          title: DPD_PREFERENCES,
          body: EMAIL_VERIFIED,
        });
      } catch (e) {
        if (EXPIRED_AUTH_CODES.indexOf(e.code) >= 0) {
          dispatch(VerifyEmailSlice.actions.clear());
          return true;
        }
        throw e;
      }
    }
  }, []);

  return (
    <Trackable
      loadId={AUTH_VERIFICATION.LOAD}
      interfaceId={AUTH_VERIFICATION.INTERFACE_ID}
    >
      <Loader
        promiseFn={validateAuthToken}
        verificationId={searchParams.get("verificationId")}
      >
        {(expired) => (
          <ReduxifyWizard slice={VerifyEmailSlice} isLazyMount={true}>
            <Step expired={expired} stepName="enter" component={EnterEmail} />
            <Step stepName="sent" component={SentEmail} />
          </ReduxifyWizard>
        )}
      </Loader>
    </Trackable>
  );
}

export default Page;
