import { find, get, isEmpty } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Trackable, useTracker } from "@dpdgroupuk/react-event-tracker";

import { getActiveDeliveryAddress } from "../selectors";
import { StepPropType } from "../../../features/Wizard";
import { locationsApis } from "../../../apis";
import { getPickupLocationCode } from "../../../utils/pickupPoint";
import NavigationBar from "../../../components/molecules/WizardNavigationBar/WizardNavigationBar";
import PreferredPickupPoint from "../../../components/organisms/PreferredPickupPoint";
import SetPickupPoint from "../../../components/organisms/SetPickupPoint";
import { Loader } from "../../../components/molecules/Loader";
import OnboardingTemplate from "../../../components/templates/Onboarding/Onboarding";
import {
  PICKUP_POINT_ONBOARDING,
  PREFERRED_PICKUP_POINT_ONBOARDING,
} from "../../../constants/analytics";

const PickupPointStep = ({
  values,
  setValues,
  references,
  setReferences,
  nextStep,
}) => {
  const tracker = useTracker();
  const deliveryAddress = useSelector(getActiveDeliveryAddress);
  const isAddressChanged = deliveryAddress?.udprn !== values.udprn;
  const { pickupLocations } = references;

  const { pickupLocationCode, isPickupPointSelected } = values;

  const isPickupPointsAvailable = !isEmpty(pickupLocations);

  const selectedPickupPoint = isPickupPointSelected
    ? find(pickupLocations, [
        "pickupLocation.pickupLocationCode",
        pickupLocationCode,
      ])
    : null;
  const preferredPickupPoint = get(pickupLocations, "[0]");
  const [chooseAnotherLocation, setChooseAnotherLocation] = useState(
    isPickupPointSelected && !isAddressChanged
  );
  const isHappyToUseLocation = pickupLocationCode && !isPickupPointSelected;

  const isSelectPickupPointActive =
    chooseAnotherLocation || isPickupPointSelected;
  const currentLocation = {
    latitude:
      deliveryAddress?.udprnLatitude || deliveryAddress?.postcodeLatitude,
    longitude:
      deliveryAddress?.udprnLongitude || deliveryAddress?.postcodeLongitude,
  };

  const loadReferences = useCallback(async ({ location }, { signal }) => {
    // reload pickup locations if address changed
    if (isAddressChanged) {
      const { data } = await locationsApis.getPickupLocations(location, {
        signal,
      });

      setReferences("pickupLocations", data);
    }
  }, []);

  const onSelectPickupPoint = useCallback(
    (pickupPoint) => {
      tracker.logEvent(PICKUP_POINT_ONBOARDING.ON_SELECT);
      const isSamePickupSelected =
        getPickupLocationCode(pickupPoint) === pickupLocationCode;

      setValues({
        udprn: values.udprn,
        pickupLocationCode: isSamePickupSelected
          ? null
          : getPickupLocationCode(pickupPoint),
        isPickupPointSelected: !isSamePickupSelected,
      });
    },
    [pickupLocationCode, values, setValues, tracker]
  );

  const onHappyToUseLocationClick = useCallback(() => {
    tracker.logEvent(PREFERRED_PICKUP_POINT_ONBOARDING.ON_SELECT);
    setValues({
      udprn: values.udprn,
      pickupLocationCode: isHappyToUseLocation
        ? null
        : getPickupLocationCode(preferredPickupPoint),
    });
  }, [isHappyToUseLocation, preferredPickupPoint, values, setValues, tracker]);

  const onBackToDefaultPickupClick = useCallback(() => {
    tracker.logEvent(PICKUP_POINT_ONBOARDING.ON_BACK);
    setValues({
      udprn: values.udprn,
      pickupLocationCode: null,
    });
    setChooseAnotherLocation(false);
  }, [values, setValues, tracker]);

  const handleClickSetupLater = useCallback(() => {
    tracker.logEvent(
      isSelectPickupPointActive && isPickupPointsAvailable
        ? PICKUP_POINT_ONBOARDING.ON_SETUP_LATER
        : PREFERRED_PICKUP_POINT_ONBOARDING.ON_SETUP_LATER
    );
    setValues({
      udprn: values.udprn,
      pickupLocationCode: null,
    });
    nextStep();
  }, [
    values,
    setValues,
    nextStep,
    tracker,
    isSelectPickupPointActive,
    isPickupPointsAvailable,
  ]);

  useEffect(() => {
    // set new udprn and clear selected pickup if address changed
    if (isAddressChanged) {
      setValues({
        udprn: deliveryAddress.udprn,
        pickupLocationCode: null,
        isPickupPointSelected: false,
      });
    }
  }, [isAddressChanged, deliveryAddress, setValues]);

  return isSelectPickupPointActive && isPickupPointsAvailable ? (
    <Trackable
      interfaceId={PICKUP_POINT_ONBOARDING.INTERFACE_ID}
      loadId={PICKUP_POINT_ONBOARDING.LOAD}
    >
      <SetPickupPoint
        onSetupLater={handleClickSetupLater}
        currentLocation={currentLocation}
        pickupLocations={pickupLocations}
        selectedPickupPoint={selectedPickupPoint}
        onSelectedPickupPoint={onSelectPickupPoint}
        onClickBack={onBackToDefaultPickupClick}
        onClickNext={() => {
          tracker.logEvent(PICKUP_POINT_ONBOARDING.ON_NEXT);
          nextStep();
        }}
        onPickupDetailsClick={() => {
          tracker.logEvent(PICKUP_POINT_ONBOARDING.ON_CLICK_DETAILS);
        }}
        onClickPrevious={() => {
          tracker.logEvent(PICKUP_POINT_ONBOARDING.ON_BACK);
        }}
        onDirectionsClick={() => {
          tracker.logEvent(PICKUP_POINT_ONBOARDING.ON_CLICK_DIRECTIONS);
        }}
        onShopsFilterClick={() => {
          tracker.logEvent(PICKUP_POINT_ONBOARDING.ON_CLICK_SHOPS);
        }}
        onLockersFilterClick={() => {
          tracker.logEvent(PICKUP_POINT_ONBOARDING.ON_CLICK_LOCKERS);
        }}
        onMapPinClick={() => {
          tracker.logEvent(PICKUP_POINT_ONBOARDING.ON_MAP_PIN_CLICK);
        }}
      />
    </Trackable>
  ) : (
    <OnboardingTemplate>
      <Loader promiseFn={loadReferences} location={currentLocation}>
        <Trackable
          interfaceId={PREFERRED_PICKUP_POINT_ONBOARDING.INTERFACE_ID}
          loadId={PREFERRED_PICKUP_POINT_ONBOARDING.LOAD}
        >
          <PreferredPickupPoint
            currentLocation={currentLocation}
            pickupLocations={pickupLocations}
            preferredPickupPoint={preferredPickupPoint}
            onChooseLocationClick={() => {
              tracker.logEvent(
                PREFERRED_PICKUP_POINT_ONBOARDING.ON_CHOOSE_ANOTHER
              );
              setChooseAnotherLocation(true);
            }}
            onHappyToUseLocationClick={onHappyToUseLocationClick}
            onSetupLater={handleClickSetupLater}
            isHappyToUseLocation={isHappyToUseLocation}
            isPickupPointsAvailable={isPickupPointsAvailable}
          />
        </Trackable>
        <NavigationBar
          onClickNext={() => {
            tracker.logEvent(PREFERRED_PICKUP_POINT_ONBOARDING.ON_NEXT);
            nextStep();
          }}
          onClickBack={() => {
            tracker.logEvent(PREFERRED_PICKUP_POINT_ONBOARDING.ON_BACK);
          }}
        />
      </Loader>
    </OnboardingTemplate>
  );
};

PickupPointStep.propTypes = StepPropType;

export default PickupPointStep;
