import classNames from "classnames";
import { noop } from "lodash";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router";

import BackButton from "../../atoms/BackButton";
import styles from "./ProfilePage.module.scss";

const Page = ({
  title,
  subtitle,
  component: Component,
  children,
  className,
  bodyClassName,
  showBackButtonDesktop,
  outlet,
  onClickBack,
  ...props
}) => {
  const navigate = useNavigate();

  return (
    <Row className={classNames("g-0", className)}>
      <Col lg={5} className={classNames("w-100", styles.container)}>
        <Row className={classNames("g-0 p-4", styles.header)}>
          <Row
            className={classNames(
              "g-0 d-none",
              !showBackButtonDesktop && "d-lg-block" // show on desktop if showBackButtonDesktop false
            )}
          >
            <Col>
              <h5>{title}</h5>
              <span>{subtitle}</span>
            </Col>
          </Row>

          <Row
            className={`g-0 d-lg-${showBackButtonDesktop ? "block" : "none"}`} // show if mobile or desktop and showBackButtonDesktop true
          >
            <Col xs={12}>
              <BackButton
                classes={{
                  button: classNames(
                    "p-0 align-items-start",
                    styles.backButton
                  ),
                }}
                onClick={() => {
                  onClickBack();
                  navigate(-1);
                }}
              >
                <h5>{title}</h5>
              </BackButton>
            </Col>
            <Col className="ps-4">
              <span>{subtitle}</span>
            </Col>
          </Row>
        </Row>

        <Row className={classNames("g-0 mb-4 mb-md-0", styles.content)}>
          <Col className={classNames(styles.content, bodyClassName)}>
            {Component ? <Component {...props} /> : children}
          </Col>
        </Row>
      </Col>
      {outlet && (
        <Col lg={7} className={classNames("g-0")}>
          {outlet}
        </Col>
      )}
    </Row>
  );
};

Page.defaultProps = {
  onClickBack: noop,
};

Page.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  className: PropTypes.string,
  bodyClassName: PropTypes.string,
  component: PropTypes.elementType,
  outlet: PropTypes.node,
  children: PropTypes.node,
  showBackButtonDesktop: PropTypes.bool,
  onClickBack: PropTypes.func,
};

export default Page;
