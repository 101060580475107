const SafePlace = (props) => {
  return (
    <svg
      width="4rem"
      height="3.9375rem" // 63px
      viewBox="0 0 64 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_99_4996)">
        <path
          d="M27.3444 52.4087L17.2161 42.2665C16.7613 41.8111 16.7613 41.075 17.2161 40.6196C17.6709 40.1642 18.4061 40.1642 18.8609 40.6196L27.3444 49.1149L45.1372 31.3008C45.592 30.8454 46.3272 30.8454 46.782 31.3008C47.2368 31.7562 47.2368 32.4923 46.782 32.9477L27.3444 52.4087Z"
          fill="currentColor"
        />
        <path
          d="M56.4302 32.5238C56.1324 32.5238 55.8347 32.4096 55.6078 32.1825L32 8.54377L8.39218 32.1825C7.93736 32.6379 7.20219 32.6379 6.74737 32.1825C6.29254 31.7271 6.29254 30.991 6.74737 30.5356L32 5.25L57.2526 30.5356C57.7075 30.991 57.7075 31.7271 57.2526 32.1825C57.0258 32.4096 56.728 32.5238 56.4302 32.5238Z"
          fill="#414042"
        />
        <path
          d="M52.9408 57.75H11.0586V33.2878C11.0586 32.6449 11.5797 32.1231 12.2218 32.1231C12.8639 32.1231 13.3851 32.6449 13.3851 33.2878V55.4206H50.6143V33.2878C50.6143 32.6449 51.1343 32.1231 51.7776 32.1231C52.4208 32.1231 52.9408 32.6449 52.9408 33.2878V57.75Z"
          fill="#414042"
        />
      </g>
      <defs>
        <clipPath id="clip0_99_4996">
          <rect
            width="63"
            height="63"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SafePlace;
