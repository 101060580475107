export const LOGIN = {
  INTERFACE_ID: "7QjkZGGGAqCIbQcC",
  LOAD: "n52o5GGGAqCIbcJX",
  ON_APPLE_LOGIN: "j24uyWGGAqCIbWPn",
  ON_EMAIL_LOGIN: "JpXY5GGGAqCIbcjq",
  ON_FACEBOOK_LOGIN: "Zo9eyWGGAqCIbWRJ",
  ON_GOOGLE_LOGIN: "f8J5SWGGAqCIbeRv",
  ON_MICROSOFT_LOGIN: "00JOyWGGAqCIbWIe",
  ON_YAHOO_LOGIN: "jbm.yWGGAqCIbWTo",
};

export const ENTER_EMAIL = {
  INTERFACE_ID: "BojkZGGGAqCIbQdo",
  LOAD: "3_pM5GGGAqCIbcHF",
  ON_INPUT: "R9hs5GGGAqCIbcJW",
  ON_NEXT: "fAZM5GGGAqCIbcHQ",
  ON_BACK: "T3Ps5GGGAqCIbcKj",
};

export const EMAIL_SENT = {
  INTERFACE_ID: "LP2k5GGGAqCIbcpe",
  LOAD: "C5ACNGGGAqCIbZ3J",
  ON_BACK: "IVACNGGGAqCIbZ4L",
  ON_EMAIL_NOT_RECEIVED: "hFACNGGGAqCIbZ3f",
};

export const AUTH_VERIFICATION = {
  INTERFACE_ID: "RQW9bGGGAqCIbVy.",
  LOAD: "sp3rbGGGAqCIbVvj",
};

export const TERMS_AND_CONDITIONS = {
  INTERFACE_ID: "D8kaNGGGAqCIbanl",
  LOAD: "QiA2NGGGAqCIbavj",
  ON_NEXT: "hSA2NGGGAqCIbav8",
  ON_BACK: "8yA2NGGGAqCIbawL",
};

export const VERIFY_PHONE = {
  INTERFACE_ID: "jqyBaaGFYFxgt5wa",
  LOAD: "8i8haaGFYFxgt6hW",
  ON_CLICK_ADDRESS_DROPDOWN: "9b8_T6GGAqCIbXK1",
  ON_ADDRESS_SELECT: "fZ0_T6GGAqCIbXKO",
  ON_BACK: "6E2daaGFYFxgt8UM",
  ON_CANT_FIND_ADDRESS: "X1f_T6GGAqCIbXNb",
  ON_NEXT: "_Wg9aaGFYFxgt8hd",
  ON_MOBILE_INPUT: "ndtdaaGFYFxgt8VE",
  ON_POSTCODE_INPUT: "jHTdaaGFYFxgt8VU",
};

export const VERIFY_PIN = {
  INTERFACE_ID: "ILF02aGFYFxgt7V.",
  LOAD: "XJ_X2aGFYFxgt9gc",
  ON_PIN_ENTERED: "L2c32aGFYFxgt9s1",
  ON_BACK: "qSE32aGFYFxgt9si",
  ON_RESEND_PIN: "_LHsRaGFYFxgtxjj",
};

export const CREATE_PROFILE = {
  INTERFACE_ID: "ilEGRaGGAqAAwDel",
  LOAD: "kdNWRaGGAqAAwFeI",
  ON_BACK: "Tfu7ZaGGAqAAwDyW",
  ON_SETUP_ACCOUNT: "9j8eRaGGAqAAwN0g",
};

export const PERSONAL_INFO_ONBOARDING = {
  INTERFACE_ID: "oJRSpaGGAqAAwLw_",
  LOAD: "v7nDpaGGAqAAwMd1",
  ON_NEXT: "LcMEZaGGAqAAwKQk",
  ON_EMAIL_ENTERED: "TkIEZaGGAqAAwKQP",
  ON_FIRST_NAME_ENTERED: "vQv4ZaGGAqAAwKPp",
  ON_LAST_NAME_ENTERED: "_CAEZaGGAqAAwKP8",
};

export const CONFIRM_DELIVERY_ADDRESS_ONBOARDING = {
  INTERFACE_ID: "u05MPGGGAqCIbQYr",
  LOAD: "ey7sPGGGAqCIbQuJ",
  ON_BACK: "B27sPGGGAqCIbQv8",
  ON_EDIT: "vIc8PGGGAqCIbQ68",
  ON_NEXT: "vq7sPGGGAqCIbQup",
};

export const DELIVERY_ADDRESS_ONBOARDING = {
  INTERFACE_ID: "EKMke6GGAqCIbeQO",
  LOAD: "mzfke6GGAqCIbeZJ",
  ON_BACK: "2TLPe6GGAqCIbcox",
  ON_NEXT: "r3fke6GGAqCIbebl",
  ON_POSTCODE_INPUT: "d1tse6GGAqCIbQsB",
  ON_CLICK_ADDRESS_DROPDOWN: "nV2XT6GGAqCIbWQb",
  ON_ADDRESS_SELECT: "wAIne6GGAqCIbcYM",
  ON_CANT_FIND_ADDRESS: "JcabT6GGAqCIbVZr",
};

export const PINPOINT_LOCATION_ONBOARDING = {
  INTERFACE_ID: "49OFMGGGAqCIbcCA",
  LOAD: "EhANMGGGAqCIbcgd",
  ON_BACK: "KJANMGGGAqCIbchp",
  ON_NEXT: "uRANMGGGAqCIbcg6",
  ON_WHAT3WORDS: "M6jZiGGGAqCIbajW",
  ON_UNDO_MOVE: "Cq0biGGGAqCIbeo1",
  ON_SETUP_LATER: "_JANMGGGAqCIbch4",
  ON_MOVE_PIN: "1pANMGGGAqCIbciH",
};

export const WHAT3WORDS_ONBOARDING = {
  INTERFACE_ID: "rkR1iGGGAqCIbUB6",
  LOAD: "5GZNiGGGAqCIbUpJ",
  ON_BACK: "4OZNiGGGAqCIbUqW",
  ON_NEXT: "WWZNiGGGAqCIbUpn",
  ON_PINPOINT_LOCATION: "u.ZNiGGGAqCIbUrg",
  ON_TILE_CLICK: "VGAdiGGGAqCIbUya",
  ON_SETUP_LATER: "7OZNiGGGAqCIbUqm",
  ON_INPUT: "reZNiGGGAqCIbUrQ",
};

export const PREFERRED_NEIGHBOUR_ONBOARDING = {
  INTERFACE_ID: "04cQ.6GGAqCIbUpi",
  LOAD: "kDzQ.6GGAqCIbUx5",
  ON_BACK: "6rzQ.6GGAqCIbUzq",
  ON_NEXT: "ETzQ.6GGAqCIbUyZ",
  ON_SETUP_LATER: "2Xlk.6GGAqCIbU.r",
  ON_AVOID_NEIGHBOUR_SELECT: "CDsi.6GGAqCIbVoC",
  ON_AVOID_NEIGHBOUR_DROPDOWN: "DOOC.6GGAqCIbVky",
  ON_DELIVER_TO_ANY: "ner0.6GGAqCIbVeZ",
  ON_PREFERRED_NEIGHBOUR_SELECT: "yTLC.6GGAqCIbVmM",
  ON_PREFERRED_NEIGHBOUR_DROPDOWN: "7Qv8.6GGAqCIbVjD",
};

export const SAFE_PLACE_ONBOARDING = {
  INTERFACE_ID: "_Y_cx6GGAqCIbZ.Y",
  LOAD: "lMgCx6GGAqCIbaEx",
  ON_BACK: "sCgCx6GGAqCIbaGt",
  ON_NEXT: "LsgCx6GGAqCIbaFX",
  ON_SETUP_LATER: "VCgCx6GGAqCIbaG5",
  ON_CLICK_DELETE: "nJqRW2GGAqCIbYLD",
  ON_CLICK_IMAGE: "nfHRW2GGAqCIbYNC",
  ON_CLICK_UPLOAD: ".nIhW2GGAqCIbYIE",
  ON_SELECT: "j8gCx6GGAqCIbaGg",
  ON_TERMS: "4EBYJ6GGAqCIbat7",
  ON_TEXT_INPUT: "88gCx6GGAqCIbaGM",
};

export const PREFERRED_PICKUP_POINT_ONBOARDING = {
  INTERFACE_ID: "IoqdT6GGAqCIbUYn",
  LOAD: "F7pjT6GGAqCIbUg.",
  ON_BACK: "SvpjT6GGAqCIbUiO",
  ON_NEXT: "9npjT6GGAqCIbUhc",
  ON_SETUP_LATER: "kfpjT6GGAqCIbUif",
  ON_CHOOSE_ANOTHER: "gr9zT6GGAqCIbUwU",
  ON_SELECT: "8PpjT6GGAqCIbUh_",
};

export const PICKUP_POINT_ONBOARDING = {
  INTERFACE_ID: "hXVkJ6GGAqCIbbKT",
  LOAD: "kbcUJ6GGAqCIbbNC",
  ON_BACK: "s3cUJ6GGAqCIbbOr",
  ON_NEXT: "97cUJ6GGAqCIbbNi",
  ON_SETUP_LATER: "j3cUJ6GGAqCIbbO3",
  ON_CLICK_DETAILS: "PFS1t6GGAqCIbTEA",
  ON_CLICK_DIRECTIONS: "Y9KlW2GGAqCIbX93",
  ON_CLICK_LOCKERS: "k4LXd6GGAqCIbQm7",
  ON_MAP_PIN_CLICK: "tJ5Dt6GGAqCIbUn.",
  ON_SELECT: "lXcUJ6GGAqCIbbOg",
  ON_CLICK_SHOPS: "P3Wjt6GGAqCIbU2x",
};

export const CONFIRM_DETAILS_ONBOARDING = {
  // TODO: Part of the IDs is missing, needs to be updated later
  INTERFACE_ID: "LaNAz6GGAqCIbXce",
  LOAD: "4EcFz6GGAqCIbTlJ",
  ON_DELIVERY_ADDRESS: "kPNXYuGGAqCIbSH_",
  ON_PICKUP_POINT: "VBi3YuGGAqCIbSXd",
  ON_CONFIRM: "CUcFz6GGAqCIbTlq",
  ON_PERSONAL_INFO: "",
  ON_YOUR_LOCATION: "",
  ON_PREFERRED_NEIGHBOUR: "",
  ON_SAFE_PLACE: "",
  ON_BACK: "",
};

export const ACCOUNT_OVERVIEW = {
  INTERFACE_ID: "eIar.2GGAqCIbQvy",
  LOAD: "wycvp2GGAqCIbVfw",
  ON_CLICK_NEW_DELIVERY_ADDRESS: "FNYw7OGGAqCIbQsW",
  ON_CLICK_PERSONAL_INFORMATION_EDIT: "wKcvp2GGAqCIbVf8",
  ON_CLICK_SIGN_OUT: "StDw7OGGAqCIbQv1",
  ON_CLICK_YOUR_DELIVERY_ADDRESSES_EDIT: "jZ_XcOGGAqCIbXZx",
};

export const YOUR_PERSONAL_INFORMATION = {
  INTERFACE_ID: "w5UnV2GGAqCIbejl",
  LOAD: "_h2XV2GGAqCIbQMT",
  ON_CLICK_ADD: "eHjb92GGAqCIbYc9", // separate Add Notification Email Page -> On Click Next
  ON_CLICK_RESEND_VERIFICATION_EMAIL: "", // absent
  ON_CLICK_ADD_NEW_EMAIL: "9mHz92GGAqCIbXx8",
  ON_CLICK_AUTH_DELETE: "qWhQD2GGAqCIba1i",
  ON_CLICK_DELETE_PROFILE: "aeCA7OGGAqCIbQYZ",
  ON_CLICK_EDIT_MOBILE: "ytqE92GGAqCIbYnO",
  ON_CLICK_EMAIL_DELETE: "HBcr92GGAqCIbX9x",
  ON_CLICK_FIRST_NAME: "25BI92GGAqCIbX5R",
  ON_CLICK_LAST_NAME: "B9HI92GGAqCIbX6o",
  ON_CLICK_MOBILE_DELETE: "DgLs92GGAqCIbZMM", // design absent
  ON_CLICK_SAVE: "qG2o92GGAqCIbX7k",
  ON_CLICK_VERIFY_MOBILE: "cesk92GGAqCIbYpw", // clarify
  ON_EMAIL_INPUT: "p42L92GGAqCIbX4y", // clarify
};

// absent
export const ADD_NOTIFICATION_EMAIL = {
  INTERFACE_ID: "",
  LOAD: "",
  ON_CLICK_NEXT: "",
};

export const DELETE_AUTH_MODAL = {
  INTERFACE_ID: "3N4ID2GGAqCIbbSV",
  LOAD: "KiLID2GGAqCIbbVa",
  ON_CLICK_CLOSE: "sSLID2GGAqCIbbVn",
  ON_CLICK_DELETE_AUTH: "IyLID2GGAqCIbbV3",
};

export const DELETE_EMAIL_MODAL = {
  INTERFACE_ID: "XHnH92GGAqCIbZxy",
  LOAD: "5rfn92GGAqCIbZ3b",
  ON_CLICK_CLOSE: "5MXX92GGAqCIbZ71",
  ON_CLICK_DELETE_EMAIL: "jyC392GGAqCIbZ91",
};

export const DELETE_PROFILE_MODAL = {
  INTERFACE_ID: "VlyrbOGGAqCIbdpG",
  LOAD: "tJ9nbOGGAqCIbd5N",
  ON_CLICK_CLOSE: "3p9nbOGGAqCIbd6M",
  ON_CLICK_DELETE: "zZ9nbOGGAqCIbd6p",
};

export const HOW_WE_CONTACT_YOU = {
  INTERFACE_ID: "MF3mYuGGAqCIbTex",
  LOAD: "rDc2YuGGAqCIbTqd",
  ON_TOGGLE: "8mFRYuGGAqCIbUH_",
};

export const YOUR_DELIVERY_ADDRESSES = {
  INTERFACE_ID: "_M2LcOGGAqCIbXSH",
  LOAD: "c5ufcOGGAqCIbXuH",
  ON_CLICK_ADD_ADDRESS: "s2Ug8OGGAqCIbX_W",
  ON_CLICK_ADDRESS: ".krg8OGGAqCIbYA9",
};

export const ADD_ADDRESS = {
  // NOTE: Add note ids are missing
  INTERFACE_ID: "Ytu47OGGAqCIbRgL",
  LOAD: "KdAk7OGGAqCIbRyW",
  ON_NEXT: "0wxW7OGGAqCIbS90",
  ON_MORE_TIME_TOGGLE: "4pam7OGGAqCIbSwb",
  ON_POSTCODE_INPUT: "W2f07OGGAqCIbSM.",
  ON_CLICK_ADDRESS_DROPDOWN: "l.o67OGGAqCIbShB",
  ON_ADDRESS_SELECT: "iv2a7OGGAqCIbSeW",
  ON_CANT_FIND_ADDRESS: "SGh67OGGAqCIbSlN",
  ON_CHANGE_CUSTOM_TYPE: "e4R88OGGAqCIbaaL",
  ON_SELECT_HOME_TYPE: "fl108OGGAqCIbZ3H",
  ON_SELECT_WORK_TYPE: "659s8OGGAqCIbZ7Y",
  ON_CLICK_CUSTOM_TYPE: "nogc8OGGAqCIbZ8j",
  ON_DELETE_IMAGE: "38S55OGGAqCIbbmj",
  ON_IMAGE_CLICK: "h3555OGGAqCIbboi",
  ON_IMAGE_UPLOAD: "VeNp5OGGAqCIbbX5",
};

export const ADDRESS = {
  INTERFACE_ID: "f9Bw8OGGAqCIbYe",
  ON_CLICK_ADD_NOTE: "wWtsTOGGAqCIbVzJ",
  ON_CLICK_ADD_PHOTO: "3cUcTOGGAqCIbV0f",
  ON_CLICK_ADDRESS_TYPE: "C.uI8OGGAqCIbYkX",
  ON_CLICK_DELETE_ADDRESS: "QQeiTOGGAqCIbV4X",
  ON_CLICK_LEAVE_SAFE: "o..cTOGGAqCIbV1Z",
  ON_CLICK_PICKUP_POINT: "QtzcTOGGAqCIbV2U",
  ON_CLICK_PINPOINT_ADDRESS: "8L2oyOGGAqCIbY.I",
  ON_CLICK_PREFERRED_NEIGHBOUR: "PxnoyOGGAqCIbY_Z",
  LOAD: "g2uI8OGGAqCIbYj.",
  TOGGLE_MORE_TIME_NEEDED: "1So8TOGGAqCIbV3P",
  TOGGLE_PICKUP_POINT: "GxjPbOGGAqCIbQC1",
};

export const ADD_ADDRESS_PINPOINT = {
  INTERFACE_ID: "uAx27OGGAqCIbTAp",
  LOAD: "n7KO7OGGAqCIbTF7",
  ON_BACK: "o9NkyOGGAqCIbZnm",
  ON_NEXT: "uunx7OGGAqCIbS5G",
  ON_WHAT3WORDS: "YTNkyOGGAqCIbZpQ",
  ON_UNDO_MOVE: "PTNkyOGGAqCIbZpo",
  ON_SETUP_LATER: "ZZex7OGGAqCIbS3J",
  ON_MOVE_PIN: "bDNkyOGGAqCIbZoj",
};

export const ADD_ADDRESS_WHAT3WORDS = {
  INTERFACE_ID: "3EFJ7OGGAqCIbS86",
  LOAD: "hHVp7OGGAqCIbTB9",
  ON_BACK: "UpZmROGGAqCIbclO",
  ON_NEXT: "FnVp7OGGAqCIbTCT",
  ON_PINPOINT_LOCATION: "15ZmROGGAqCIbcmT",
  ON_TILE_CLICK: ".FZmROGGAqCIbcms",
  ON_SETUP_LATER: "wnVp7OGGAqCIbTCH",
  ON_INPUT: "Q5ZmROGGAqCIbcl9",
};

export const DELETE_ADDRESS_MODAL = {
  INTERFACE_ID: ".zyKTOGGAqCIbWYa",
  ON_CLICK_CLOSE: "epFqTOGGAqCIbWda",
  ON_CLICK_DELETE: "_5FqTOGGAqCIbWd_",
  LOAD: "wRFqTOGGAqCIbWc8",
};

export const ADD_ADDRESS_NEIGHBOUR = {
  INTERFACE_ID: "UB2F7OGGAqCIbTv_",
  LOAD: "ht0l7OGGAqCIbTy_",
  ON_BACK: "fV6eROGGAqCIbeb4",
  ON_NEXT: "Fj0l7OGGAqCIbTzV",
  ON_SETUP_LATER: "8d0l7OGGAqCIbTzJ",
  ON_AVOID_NEIGHBOUR_SELECT: "2b6eROGGAqCIbeeO",
  ON_AVOID_NEIGHBOUR_DROPDOWN: "Mz6eROGGAqCIbedU",
  ON_DELIVER_TO_ANY: "", // missing
  ON_PREFERRED_NEIGHBOUR_SELECT: "kr6eROGGAqCIbedx",
  ON_PREFERRED_NEIGHBOUR_DROPDOWN: "h96eROGGAqCIbec6",
};

export const ADD_ADDRESS_SAFE_PLACE = {
  INTERFACE_ID: "YHi97OGGAqCIbUZi",
  LOAD: "pe1T7OGGAqCIbUdl",
  ON_BACK: "pJebjOGGAqCIbTmS",
  ON_NEXT: "1B1T7OGGAqCIbUd9",
  ON_SETUP_LATER: "D.1T7OGGAqCIbUdw",
  ON_CLICK_DELETE: "N5ebjOGGAqCIbTnp",
  ON_CLICK_IMAGE: "WFebjOGGAqCIbTn7",
  ON_CLICK_UPLOAD: "E5ebjOGGAqCIbTna",
  ON_SELECT: "_xebjOGGAqCIbTmD",
  ON_TERMS: "AZebjOGGAqCIbTm9",
  ON_TEXT_INPUT: "bhebjOGGAqCIbTk5",
};

export const ADD_ADDRESS_PICKUP_POINT = {
  INTERFACE_ID: "zT0b7OGGAqCIbU1O",
  LOAD: "rAfb7OGGAqCIbU4a",
  ON_BACK: "91cdROGGAqCIbdUM",
  ON_NEXT: "JQfb7OGGAqCIbU40",
  ON_SETUP_LATER: "jgfb7OGGAqCIbU4m",
  ON_CLICK_DETAILS: "6dcdROGGAqCIbdVL",
  ON_CLICK_DIRECTIONS: ".LcdROGGAqCIbdX1",
  ON_CLICK_LOCKERS: "hzcdROGGAqCIbdXc",
  ON_MAP_PIN_CLICK: "v9cdROGGAqCIbdV5",
  ON_SELECT: "mlcdROGGAqCIbdT3",
  ON_CLICK_SHOPS: "rjcdROGGAqCIbdWr",
};

export const ADD_ADDRESS_CONFIRM_DETAILS = {
  // TODO: Part of the IDs is missing, needs to be updated later
  INTERFACE_ID: "RmrX7OGGAqCIbVjf",
  LOAD: "d2yQHOGGAqCIbVsB",
  ON_DELIVERY_ADDRESS: "4WEx_2GGAqCIbdw.",
  ON_PICKUP_POINT: "Mcip_2GGAqCIbeA7",
  ON_CONFIRM: "WuyQHOGGAqCIbVsd",
  ON_YOUR_LOCATION: "BWVx_2GGAqCIbd9i",
  ON_PREFERRED_NEIGHBOUR: "D1MJ_2GGAqCIbd.e",
  ON_SAFE_PLACE: "Ku9J_2GGAqCIbd_a",
  ON_BACK: "FFsYHOGGAqCIbWDE",
  // TODO: no ids for first time delivery
};

export const EDIT_ADDRESS_TYPE = {
  INTERFACE_ID: "qi7wyOGGAqCIbZVz",
  ON_CLICK_BACK: "0IXE8OGGAqCIbZgl",
  ON_CLICK_SAVE: "w.PS8OGGAqCIbaeQ",
  LOAD: "0wXE8OGGAqCIbZgU",
};

export const EDIT_ADDRESS_NOTE = {
  INTERFACE_ID: "H63rROGGAqCIbQLT",
  ON_CLICK_BACK: "EEbbROGGAqCIbQUd",
  ON_CLICK_SAVE: "EsbbROGGAqCIbQWP",
  ON_CLICK_TEXT_FIELD: "y0bbROGGAqCIbQVq",
  LOAD: "U4bbROGGAqCIbQUK",
};

export const EDIT_SAFE_PLACE = {
  INTERFACE_ID: "24HLjOGGAqCIbTHS",
  // no onClickBack action ID
  ON_CLICK_SAVE: "GBebjOGGAqCIbTkL",
  LOAD: "iuebjOGGAqCIbTi0",
};

export const EDIT_ADD_PHOTO = {
  INTERFACE_ID: "1xfB5OGGAqCIbX7D",
  ON_CLICK_BACK: "r7Lh5OGGAqCIbYHc",
  ON_CLICK_SAVE: "v3Lh5OGGAqCIbYIJ",
  LOAD: "Q7Lh5OGGAqCIbYHL",
};

export const EDIT_PREFERRED_NEIGHBOUR = {
  INTERFACE_ID: "fwIuROGGAqCIbeRo",
  // no onClickBack action ID
  ON_CLICK_SAVE: "Mx6eROGGAqCIbeaa",
  LOAD: "0m6eROGGAqCIbeZM",
};

export const EDIT_PICKUP_POINT = {
  INTERFACE_ID: "E8YpROGGAqCIbQ99",
  // no onClickBack action ID
  ON_CLICK_SAVE: "15cdROGGAqCIbdTJ",
  LOAD: "EpcdROGGAqCIbdSZ",
};

export const EDIT_PINPOINT_ADDRESS = {
  INTERFACE_ID: "mmpYyOGGAqCIbZMj",
  // no onClickBack action ID
  ON_CLICK_SAVE: "vNNkyOGGAqCIbZmy",
  LOAD: "YNNkyOGGAqCIbZmR",
};

export const VERIFY_EMAIL_LINK = {
  INTERFACE_ID: "Qcmv92GGAqCIbZ63",
  LOAD: "M4Tv92GGAqCIbZ8q",
};
