import PropTypes from "prop-types";
import { Button, Col, Row } from "react-bootstrap";

import * as M from "../../../constants/strings";
import PinInput from "../../atoms/PinInput";
import ContentHeader from "../ContentHeader";
import styles from "./VerifyPin.module.scss";

const VerifyPin = ({
  title,
  subtitle,
  inputRef,
  inputDisabled,
  submitError,
  resendPinIn,
  onResendPinClick,
  onBackClick,
  ...props
}) => {
  const resendBtnDisabled = resendPinIn > 0;
  let resendBtnText = "Resend Pin";

  if (resendBtnDisabled) {
    resendBtnText += ` - ${resendPinIn}s`;
  }

  return (
    <>
      <ContentHeader
        title={title}
        subtitle={subtitle}
        classes={{ container: "text-start" }}
      />
      <Row className="g-0">
        <Col className="d-flex justify-content-center">
          <PinInput
            ref={inputRef}
            disabled={inputDisabled}
            {...props}
            inputClassName={submitError && "border border-danger"}
            allowedCharacters="numeric"
            meta={submitError && { error: submitError }}
          />
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-between justify-content-lg-end">
          <Button
            variant="link"
            onClick={onResendPinClick}
            disabled={resendBtnDisabled}
            className={styles.resendButton}
          >
            <span className="me-4">{resendBtnText}</span>
          </Button>
          <Button variant="outline-danger" onClick={onBackClick}>
            {M.BACK}
          </Button>
        </Col>
      </Row>
    </>
  );
};

VerifyPin.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  inputRef: PropTypes.any,
  inputDisabled: PropTypes.bool,
  submitError: PropTypes.string,
  resendPinIn: PropTypes.number.isRequired,
  onResendPinClick: PropTypes.func.isRequired,
  onBackClick: PropTypes.func.isRequired,
};

export default VerifyPin;
