const Apple = (props) => {
  return (
    <svg
      width="1.5rem"
      height="1.5rem"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.9032 2C15.9906 3.16113 15.6295 4.31119 14.8966 5.20594C14.5457 5.64985 14.1003 6.00698 13.5939 6.25045C13.0876 6.49391 12.5335 6.61736 11.9735 6.6115C11.9377 6.05313 12.0106 5.49307 12.1878 4.96334C12.365 4.43361 12.6432 3.94459 13.0064 3.52425C13.7492 2.66383 14.7835 2.1196 15.9032 2Z"
        fill="black"
      />
      <path
        d="M17.9456 10.451C17.5735 11.1177 17.3733 11.8694 17.3636 12.6359C17.3645 13.4982 17.615 14.3412 18.0838 15.0597C18.5526 15.7782 19.2191 16.3404 20 16.6763C19.693 17.6907 19.2301 18.6493 18.6287 19.5166C17.821 20.7454 16.9741 21.9459 15.6299 21.9681C14.9906 21.9831 14.5591 21.7962 14.1094 21.6013C13.6404 21.3981 13.1516 21.1864 12.3868 21.1864C11.5757 21.1864 11.0651 21.405 10.5726 21.6157C10.147 21.7979 9.73493 21.9743 9.15423 21.9988C7.87411 22.047 6.8958 20.6873 6.05871 19.47C4.3856 16.9841 3.08281 12.4644 4.82939 9.38909C5.2326 8.65223 5.81662 8.03416 6.52455 7.59509C7.23247 7.15602 8.04017 6.91093 8.86893 6.8837C9.59496 6.86851 10.2916 7.15309 10.9024 7.40259C11.3695 7.5934 11.7864 7.7637 12.1278 7.7637C12.4279 7.7637 12.8332 7.60013 13.3054 7.4095C14.0494 7.10921 14.9597 6.74179 15.8874 6.84082C16.5983 6.86344 17.2943 7.05433 17.92 7.39834C18.5457 7.74234 19.0839 8.22998 19.4918 8.82252C18.8496 9.22404 18.3177 9.78423 17.9456 10.451Z"
        fill="black"
      />
    </svg>
  );
};

export default Apple;
