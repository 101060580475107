import PropTypes from "prop-types";
import { Offcanvas } from "react-bootstrap";

const OffCanvas = ({
  name,
  title,
  handleClose,
  show,
  children,
  placement,
  ...props
}) => {
  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement={placement}
        {...props}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{title}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>{children}</Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

OffCanvas.propTypes = {
  placement: PropTypes.string,
  handleClose: PropTypes.func,
  show: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.node,
};

export default OffCanvas;
