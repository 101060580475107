import { Button, Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import classNames from "classnames";
import { noop } from "lodash";

import Delete from "../../atoms/icons/Delete";
import Plus from "../../atoms/icons/Plus";
import styles from "./DropzonePreview.module.scss";
import { DELETE, UPLOAD } from "../../../constants/strings";

function ToolbarPanel({ size, onDelete, input, onUpload, ...props }) {
  return (
    <>
      <Row className="mx-0 d-flex justify-content-sm-between justify-content-lg-end">
        <Col xs={6} lg="auto" className="ps-0 ">
          <Button
            variant="outline-danger"
            className={classNames(
              "d-flex align-items-center justify-content-center w-100",
              styles.button
            )}
            onClick={onDelete}
            disabled={!input.value}
          >
            <span>{DELETE}</span>
            <Delete className="ms-2" />
          </Button>
        </Col>
        <Col xs={6} lg="auto" className="pe-0">
          <Button
            variant="danger"
            className={classNames(
              "d-flex align-items-center justify-content-center w-100",
              styles.button
            )}
            onClick={onUpload}
            disabled={!!input.value || props.disabled}
          >
            <span>{UPLOAD}</span>
            <Plus className="ms-2" />
          </Button>
        </Col>
      </Row>
    </>
  );
}

ToolbarPanel.defaultProps = {
  size: "sm",
  disabled: false,
  onDelete: () => null,
  onUpload: noop,
};

ToolbarPanel.propTypes = {
  size: PropTypes.oneOf(["sm", "lg"]),
  disabled: PropTypes.bool,
  onUpload: PropTypes.func.isRequired,
  input: PropTypes.object.isRequired,
  onDelete: PropTypes.func,
};
export default ToolbarPanel;
