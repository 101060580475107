import { useNavigate } from "react-router-dom";
import Error from "../../components/atoms/Error";
import FaceThinking from "../../components/atoms/icons/FaceThinking/FaceThinking";
import { BACK_TO_HOME, PAGE_NOT_FOUND } from "../../constants/strings";

function NoMatch() {
  const navigate = useNavigate();

  return (
    <Error
      icon={FaceThinking}
      title={PAGE_NOT_FOUND}
      buttonText={BACK_TO_HOME}
      onButtonClick={() => navigate("/")}
    />
  );
}

export default NoMatch;
