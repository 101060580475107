import { doc, getFirestore, serverTimestamp } from "firebase/firestore";

import { defaultApp } from "../../../firebase";
import t from "../transaction";

const db = getFirestore(defaultApp);

export const getConsumerDocRef = (uid) => doc(db, "consumers", uid);

export const getConsumer = async (uid, { transaction = t } = {}) => {
  const docRef = getConsumerDocRef(uid);
  const snapshot = await transaction.get(docRef);
  return snapshot.data();
};

export const createConsumer = async (
  uid,
  consumer,
  { transaction = t } = {}
) => {
  const newConsumerDocRef = getConsumerDocRef(uid);
  await transaction.set(newConsumerDocRef, consumer);
  return consumer;
};

export const updateConsumer = async (
  uid,
  consumer,
  { transaction = t } = {}
) => {
  const consumerDocRef = getConsumerDocRef(uid);
  const data = {
    ...consumer,
    modified_at: serverTimestamp(),
  };
  await transaction.update(consumerDocRef, data);
  return data;
};

export const deleteConsumer = async (uid, { transaction = t } = {}) => {
  const consumerDocRef = getConsumerDocRef(uid);
  await transaction.update(consumerDocRef, {
    delete: true,
    modified_at: serverTimestamp(),
  });
};
