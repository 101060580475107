import { createSelector } from "reselect";
import { SignUpSliceSelectors } from "./slice";

export const getVerifyPhoneValues = createSelector(
  SignUpSliceSelectors.getValues,
  (values) => values?.phone
);

export const getVerifyPhoneAddress = createSelector(
  getVerifyPhoneValues,
  SignUpSliceSelectors.getReferences,
  (phoneValues, references) =>
    (references.addresses || []).find((a) => a.udprn === phoneValues.udprn)
);

export const getVerifyPinValues = createSelector(
  SignUpSliceSelectors.getValues,
  (values) => values?.pin
);
