import { useCallback, useMemo } from "react";
import { pick } from "lodash";
import { Trackable, useTracker } from "@dpdgroupuk/react-event-tracker";

import NavigationBar from "../../../components/molecules/WizardNavigationBar/WizardNavigationBar";
import PersonalInformation from "../../../components/organisms/PersonalInformation";
import { Fields } from "../../../constants/forms";
import { useAuth } from "../../../features/Auth";
import { WizardForm } from "../../../features/Wizard";
import styles from "../Onboarding.module.scss";
import { Consumer } from "../../../components/atoms/icons";
import * as M from "../../../constants/strings";
import Template from "../../../components/templates/OnboardingStepContainer";
import OnboardingTemplate from "../../../components/templates/Onboarding/Onboarding";
import { PERSONAL_INFO_ONBOARDING } from "../../../constants/analytics";

const PersonalInformationStep = ({ values, nextStep }) => {
  const auth = useAuth();
  const tracker = useTracker();

  const initialValues = useMemo(() => {
    const [firstName, lastName] = (auth.currentSession?.name || "").split(" ");
    const profile = {
      [Fields.FIRSTNAME]: firstName,
      [Fields.LASTNAME]: lastName,
      [Fields.PHONE_NUMBER]: auth.currentSession?.phone_number,
      [Fields.ONBOARDING_STARTED]: Date.now(),
    };

    if (auth.currentSession?.email_verified) {
      profile[Fields.EMAIL] = auth.currentSession?.email;
    }

    return {
      ...profile,
      ...pick(values, [
        Fields.FIRSTNAME,
        Fields.LASTNAME,
        Fields.PHONE_NUMBER,
        Fields.EMAIL,
      ]),
    };
  }, []);

  const onSubmit = useCallback(() => {
    tracker.logEvent(PERSONAL_INFO_ONBOARDING.ON_NEXT);
    nextStep();
  }, [nextStep, tracker]);

  const onEnterFirstName = useCallback(() => {
    tracker.logEvent(PERSONAL_INFO_ONBOARDING.ON_FIRST_NAME_ENTERED);
  }, [tracker]);

  const onEnterLastName = useCallback(() => {
    tracker.logEvent(PERSONAL_INFO_ONBOARDING.ON_LAST_NAME_ENTERED);
  }, [tracker]);

  const onEnterEmail = useCallback(() => {
    tracker.logEvent(PERSONAL_INFO_ONBOARDING.ON_EMAIL_ENTERED);
  }, [tracker]);

  return (
    <Trackable
      loadId={PERSONAL_INFO_ONBOARDING.LOAD}
      interfaceId={PERSONAL_INFO_ONBOARDING.INTERFACE_ID}
    >
      <OnboardingTemplate>
        <WizardForm
          initialValues={initialValues}
          onSubmit={onSubmit}
          Nav={NavigationBar}
          formClassName={styles.stepContent}
        >
          <Template
            title={M.PERSONAL_INFORMATION}
            subtitle={M.KEEP_UP_TO_DATE}
            icon={Consumer}
          >
            <PersonalInformation
              onEnterFirstName={onEnterFirstName}
              onEnterLastName={onEnterLastName}
              onEnterEmail={onEnterEmail}
            />
          </Template>
        </WizardForm>
      </OnboardingTemplate>
    </Trackable>
  );
};

export default PersonalInformationStep;
