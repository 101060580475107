import { Container } from "react-bootstrap";
import classNames from "classnames";
import PropTypes from "prop-types";
import { noop } from "lodash";

import {
  LocationPropType,
  PickupPointPropType,
  PickupPointsPropType,
} from "../../../constants/propType";
import { getGoogleMapsDirectionLink } from "../../../utils/googleMaps";
import {
  getPickupAddressPoint,
  getPickupLocationCode,
} from "../../../utils/pickupPoint";
import PickupPointItem from "../PickupPointItem";
import styles from "./PickupPointsList.module.scss";

const PickupPointsList = ({
  currentLocation,
  pickupPoints,
  selectedPickupPoint,
  activePickupPoint,
  onSelectClick,
  onPickupDetailsClick,
  className,
  pickupListRef,
  onDirectionsClick,
}) => (
  <Container
    fluid
    className={classNames("g-0", styles.container, className)}
    ref={pickupListRef}
  >
    {pickupPoints.map((pickupPoint) => {
      const pickupCode = getPickupLocationCode(pickupPoint);
      const isSelected =
        pickupCode === getPickupLocationCode(selectedPickupPoint);
      const isActive = pickupCode === getPickupLocationCode(activePickupPoint);

      return (
        <PickupPointItem
          key={pickupCode}
          number={pickupPoint.number}
          pickupPoint={pickupPoint}
          onSelectClick={() => onSelectClick(pickupPoint)}
          onPickupDetailsClick={() =>
            onPickupDetailsClick(isActive ? null : pickupPoint)
          }
          directionLink={getGoogleMapsDirectionLink(
            currentLocation,
            getPickupAddressPoint(pickupPoint)
          )}
          isSelected={isSelected}
          isActive={isActive}
          onDirectionsClick={onDirectionsClick}
        />
      );
    })}
  </Container>
);

PickupPointsList.propTypes = {
  currentLocation: LocationPropType.isRequired,
  pickupPoints: PickupPointsPropType.isRequired,
  selectedPickupPoint: PickupPointPropType,
  activePickupPoint: PickupPointPropType,
  onSelectClick: PropTypes.func,
  onPickupDetailsClick: PropTypes.func,
  pickupListRef: PropTypes.any,
  className: PropTypes.string,
  onDirectionsClick: PropTypes.func,
};

PickupPointsList.defaultProps = { onDirectionsClick: noop };

export default PickupPointsList;
