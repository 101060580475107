import { COUNTRY_CODE, REGEX } from "../constants";

export const formatUKPhoneNumber = (phoneNumberString) => {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  // formats phone number and returns in format +447123456789
  const result =
    cleaned.match(REGEX.SHORTEST_VALID_UK_PHONE_NUMBER_REGEXP) || [];

  return result.length > 1
    ? `${COUNTRY_CODE.UK}${result[1]}`
    : phoneNumberString;
};

export const trim = (value) => value && value.trim();

const upperPostcodeWithoutSpace = (postcode) =>
  postcode.toUpperCase().replace(/\s/g, "");

export const formatPostcode = (postcode) => {
  if (!REGEX.UK_POST_CODE_FULL.test(trim(postcode))) {
    return (postcode || "").toUpperCase();
  }

  const withoutSpace = upperPostcodeWithoutSpace(postcode);
  const endPartOfPostcode = withoutSpace.slice(withoutSpace.length - 3);

  return `${withoutSpace.replace(endPartOfPostcode, "")} ${endPartOfPostcode}`;
};

export const onlyStrings = (items) => items.filter((value) => !!value);

export const joinStringsWith = (items, separator) =>
  onlyStrings(items).join(separator);

export const joinStringsWithComma = (items) => joinStringsWith(items, ", ");

export const formatAddressLine = ({
  organisation,
  street,
  property,
  locality,
  town,
}) =>
  joinStringsWithComma([
    organisation,
    joinStringsWith([property, street], " "),
    town,
    locality,
  ]);

export function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export const formatEmail = (value) => (value || "").trim().toLowerCase();
