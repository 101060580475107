import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Trackable, useTracker } from "@dpdgroupuk/react-event-tracker";

import ConfirmDetails from "../../../components/organisms/AddAddressConfirmDetails";
import { WizardForm } from "../../../features/Wizard";
import { FIRST_DELIVERY_ATTEMPT_CODE, STEP_NAME } from "../../../constants";
import * as M from "../../../constants/strings";
import Template from "../Template";
import { getConfirmDetailsValid, getConfirmDetailsValues } from "../selectors";
import {
  ConsumerAddressesActions,
  ConsumerAddressModel,
} from "../../../features/Profile";
import { useCallback } from "react";
import { useAuth } from "../../../features/Auth";
import { Paths } from "../../../router";
import { useOverlay } from "../../../features/Overlay";
import { useToaster } from "../../../features/Toaster";
import { getAddresses } from "../../../features/Profile/Addresses/actions";
import { ADD_ADDRESS_CONFIRM_DETAILS } from "../../../constants/analytics";

const ConfirmDetailsStep = ({ goToNamedStep, values }) => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const overlay = useOverlay();
  const toaster = useToaster();
  const navigate = useNavigate();
  const data = useSelector(getConfirmDetailsValues);
  const tracker = useTracker();
  const isConfirmDetailsValid = useSelector(getConfirmDetailsValid);

  const handleSubmit = useCallback(
    async (formData) => {
      tracker.logEvent(ADD_ADDRESS_CONFIRM_DETAILS.ON_CONFIRM);

      try {
        if (!isConfirmDetailsValid) {
          // eslint-disable-next-line no-throw-literal
          throw {
            message: M.PLEASE_REVIEW_MANDATORY_FIELDS,
          };
        }

        overlay.show();

        const address = ConsumerAddressModel.createAddressModel({
          ...data,
          confirmDetails: formData,
        });

        // refresh addresses
        const remoteAddresses = await dispatch(
          getAddresses(auth.currentSession.uid)
        ).unwrap();

        ConsumerAddressModel.validateAddressOnAdd(
          address.udprn,
          remoteAddresses
        );

        await dispatch(
          ConsumerAddressesActions.addAddress([
            auth.currentSession.uid,
            address,
          ])
        ).unwrap();

        toaster.showSuccess({
          body: M.ADDED_ADDRESS_TO_YOUR_ACCOUNT,
        });
        // reload user session to switch from PHONE_ACCOUNT to CONSUMER_ACCOUNT
        navigate(Paths.PROFILE_ADDRESSES, {
          replace: true,
        });
      } catch (e) {
        toaster.showError({
          body: e.message,
        });
      } finally {
        overlay.hide();
      }
    },
    [
      data,
      auth,
      toaster,
      dispatch,
      overlay,
      navigate,
      tracker,
      isConfirmDetailsValid,
    ]
  );

  const handleGoToNamedStep = useCallback(
    (step) => {
      let actionId;

      switch (step) {
        case STEP_NAME.DELIVERY_ADDRESS: {
          actionId = ADD_ADDRESS_CONFIRM_DETAILS.ON_DELIVERY_ADDRESS;
          break;
        }
        case STEP_NAME.NEIGHBOURS: {
          actionId = ADD_ADDRESS_CONFIRM_DETAILS.ON_PREFERRED_NEIGHBOUR;
          break;
        }
        case STEP_NAME.PERSONAL_INFORMATION: {
          actionId = ADD_ADDRESS_CONFIRM_DETAILS.ON_PERSONAL_INFO;
          break;
        }
        case STEP_NAME.PICKUP_POINT: {
          actionId = ADD_ADDRESS_CONFIRM_DETAILS.ON_PICKUP_POINT;
          break;
        }
        case STEP_NAME.PINPOINT_LOCATION: {
          actionId = ADD_ADDRESS_CONFIRM_DETAILS.ON_YOUR_LOCATION;
          break;
        }
        case STEP_NAME.SAFE_PLACE: {
          actionId = ADD_ADDRESS_CONFIRM_DETAILS.ON_SAFE_PLACE;
          break;
        }
        default: {
          actionId = "";
        }
      }

      tracker.logEvent(actionId);

      goToNamedStep(step);
    },
    [goToNamedStep, tracker]
  );

  return (
    <Trackable
      interfaceId={ADD_ADDRESS_CONFIRM_DETAILS.INTERFACE_ID}
      loadId={ADD_ADDRESS_CONFIRM_DETAILS.LOAD}
    >
      <WizardForm
        onSubmit={handleSubmit}
        initialValues={{
          firstDeliveryAttempt: FIRST_DELIVERY_ATTEMPT_CODE.HOME,
          ...values,
        }}
      >
        <Template title={M.CONFIRM_DETAILS} required>
          <ConfirmDetails data={data} onEditClick={handleGoToNamedStep} />
        </Template>
      </WizardForm>
    </Trackable>
  );
};

export default ConfirmDetailsStep;
