import * as Sentry from "@sentry/react";

const { REACT_APP_SENTRY_DSN, REACT_APP_SENTRY_RELEASE, REACT_APP_SENTRY_ENV } =
  process.env;

Sentry.init({
  debug: process.env.NODE_ENV === "development",
  enabled: process.env.NODE_ENV === "production",
  release: REACT_APP_SENTRY_RELEASE,
  dsn: REACT_APP_SENTRY_DSN,
  environment: REACT_APP_SENTRY_ENV,
  tracesSampleRate: 0.0,
  ignoreErrors: [
    // contextmenu error for electron (v.0.37.8)
    "Cannot read property 'nodeType' of undefined",
    "SecurityError",
    // Random plugins/extensions
    "top.GLOBALS",
    "Can't find variable: ga",
    /(ResizeObserver loop limit exceeded)/,
  ],
  denyUrls: [
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
  ],
});

export const setSentryUser = (user) => Sentry.setUser(user);

export const getSentryUser = (user) => {
  if (user) {
    const { uid, email } = user;

    return {
      id: uid,
      email,
    };
  }

  return {};
};

export const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: (action) => {
    return action;
  },
});

export default Sentry;
