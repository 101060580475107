import { get } from "lodash";
import { Fields } from "../constants/forms";
import { SAFE_PLACE_CODE } from "../constants/safePlace";
import * as M from "../constants/strings";

export const validateSafePlaceValues = (values) => {
  const errors = {};
  if (get(values, Fields.SAFE_PLACE_RESPONSIBILITY)) {
    if (
      !get(values, Fields.SAFE_PLACE_CODE) ||
      (get(values, Fields.SAFE_PLACE_CODE) === SAFE_PLACE_CODE.OTHER &&
        !get(values, Fields.SAFE_PLACE_CUSTOM))
    ) {
      errors[Fields.SAFE_PLACE_CODE] = M.PLEASE_SELECT_SAFE_PLACE;
      errors[Fields.SAFE_PLACE_CUSTOM] = M.PLEASE_SELECT_SAFE_PLACE;
    }
  }

  if (
    !get(values, Fields.SAFE_PLACE_RESPONSIBILITY) &&
    get(values, Fields.SAFE_PLACE_CODE)
  ) {
    errors[Fields.SAFE_PLACE_RESPONSIBILITY] = M.PLEASE_CHECK_CHECKBOX;
  }

  return errors;
};
