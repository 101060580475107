import { createSearchParams, Navigate, useLocation } from "react-router-dom";
import { useAuth } from "./Context";
import PropTypes from "prop-types";
import { SESSION_TYPE } from "./constants";
import { Paths } from "../../router";
import * as Model from "./model";
import { getRedirectPathBySessionType, getSessionType } from "./model";

const ProtectedRoute = ({ type, children }) => {
  const { currentSession } = useAuth();
  const location = useLocation();
  const currentSessionType = getSessionType(currentSession);

  // the same as ACCOUNT_ONLY role match
  if (!currentSession) {
    return (
      <Navigate
        to={{
          pathname: Paths.SIGN_IN,
          search: createSearchParams({
            returnUrl: location.pathname + location.search,
          }).toString(),
        }}
        replace
      />
    );
  }

  if (type >= SESSION_TYPE.PHONE_ACCOUNT) {
    if (!Model.isPhoneLinkedAccount(currentSession)) {
      return <Navigate to={Paths.SIGN_UP} replace />;
    }
  }

  if (type >= SESSION_TYPE.CONSUMER_ACCOUNT) {
    if (!Model.isConsumerAccount(currentSession)) {
      return <Navigate to={Paths.CREATE_PROFILE} replace />;
    }
  }

  if (type < currentSessionType) {
    return (
      <Navigate to={getRedirectPathBySessionType(currentSession)} replace />
    );
  }

  return children;
};

ProtectedRoute.propTypes = {
  children: PropTypes.node,
  type: PropTypes.number,
};

export default ProtectedRoute;
