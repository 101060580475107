import classNames from "classnames";
import { Col, Container, Row } from "react-bootstrap";

import ContentHeader from "../../molecules/ContentHeader";
import styles from "./OnboardingStepContainer.module.scss";
import PropTypes from "prop-types";
import LinkButton from "../../atoms/LinkButton";
import { SETUP_LATER } from "../../../constants/strings";

const OnboardingStepContainer = ({
  fluid,
  icon,
  title,
  subtitle,
  children,
  className,
  onSetupLaterClick,
  classes = {},
}) => (
  <Container fluid={fluid} className={classNames(styles.container, className)}>
    <ContentHeader
      title={title}
      subtitle={subtitle}
      classes={{
        container: classNames("text-center text-lg-center", classes.title),
      }}
      icon={icon}
    />
    {children}
    {onSetupLaterClick && (
      <Row>
        <Col className="d-flex justify-content-center">
          <LinkButton onClick={onSetupLaterClick}>{SETUP_LATER}</LinkButton>
        </Col>
      </Row>
    )}
  </Container>
);

OnboardingStepContainer.defaultProps = {
  fluid: false,
};

OnboardingStepContainer.propTypes = {
  fluid: PropTypes.bool,
  children: PropTypes.node,
  onSetupLaterClick: PropTypes.func,
};

export default OnboardingStepContainer;
