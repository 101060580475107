import classNames from "classnames";
import { Col, Container, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { noop } from "lodash";

import * as M from "../../constants/strings";
import ContentHeader from "../../components/molecules/ContentHeader";
import NavigationBar from "./NavBar";
import styles from "./AddNewAddress.module.scss";

const Template = ({
  required,
  title,
  subtitle,
  children,
  staticContentHeader,
  className,
  onClickSkip,
  onClickBack,
}) => (
  <Container fluid className={classNames("g-0", styles.container)}>
    <Row className="g-0">
      <Col>
        <ContentHeader
          title={M.ADD_DELIVERY_ADDRESS}
          subtitle={M.ADD_ADDITIONAL_ADDRESS}
          classes={{ container: "g-0 p-4 pb-0" }}
        />
        <hr className="m-0" />
      </Col>
    </Row>
    {staticContentHeader}
    <Container
      fluid
      className={classNames("p-4 flex-grow-1", styles.content, className)}
    >
      <ContentHeader
        title={title}
        subtitle={subtitle}
        classes={{ title: subtitle ? "h6" : "h6 mb-0", subtitle: "mb-4" }}
      />
      {children}
    </Container>
    <hr className="m-0" />
    <Row className="g-0 p-4">
      <Col>
        <NavigationBar
          required={required}
          onClickSkip={onClickSkip}
          onClickBack={onClickBack}
        />
      </Col>
    </Row>
  </Container>
);

Template.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  required: PropTypes.bool,
  staticContentHeader: PropTypes.node,
  onClickSkip: PropTypes.func,
  onClickBack: PropTypes.func,
};

Template.defaultProps = {
  onClickBack: noop,
};

export default Template;
