import { useCallback, useMemo } from "react";

import { get, noop } from "lodash";
import { Col, Row } from "react-bootstrap";
import { Field, Form } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import { useDispatch, useSelector } from "react-redux";

import Toggle from "../../components/atoms/Toggle";
import { Fields } from "../../constants/forms";
import * as M from "../../constants/strings";
import { useAuth } from "../../features/Auth";
import { ConsumerActions, ConsumerSelector } from "../../features/Profile";
import { useToaster } from "../../features/Toaster";
import styles from "./ContactYou.module.scss";
import { Trackable, useTracker } from "@dpdgroupuk/react-event-tracker";
import { HOW_WE_CONTACT_YOU } from "../../constants/analytics";

function ContactYou() {
  const auth = useAuth();
  const dispatch = useDispatch();
  const toaster = useToaster();
  const consumer = useSelector(ConsumerSelector.getConsumer);
  const tracker = useTracker();

  const initialValues = useMemo(
    () => ({
      [Fields.NOTIFICATIONS]: get(
        consumer,
        `configuration.${Fields.NOTIFICATIONS}.push`
      ),
    }),
    []
  );

  const onNotificationsToggle = useCallback(
    async (push, form) => {
      try {
        tracker.logEvent(HOW_WE_CONTACT_YOU.ON_TOGGLE);
        await dispatch(
          ConsumerActions.updateConsumer([
            auth.currentSession.uid,
            {
              "configuration.notifications.push": push,
            },
          ])
        ).unwrap();
      } catch (e) {
        form.reset();
        toaster.showError({
          body: e.message,
        });
      }
    },
    [dispatch, toaster, auth, tracker]
  );

  return (
    <Trackable
      loadId={HOW_WE_CONTACT_YOU.LOAD}
      interfaceId={HOW_WE_CONTACT_YOU.INTERFACE_ID}
    >
      <Row className="g-0 h-100">
        <Col lg={5} className={styles.navPanel}>
          <Form onSubmit={noop} initialValues={initialValues}>
            {(formProps) => (
              <form onSubmit={formProps.handleSubmit}>
                <Row className="g-0">
                  <Col className="p-4">
                    <h4>{M.HOW_WE_CONTACT_YOU}</h4>
                    <p className="mb-0">{M.MANAGE_NOTIFICATIONS}</p>
                  </Col>
                </Row>
                <hr className="m-0" />
                <Row className="g-0 p-4">
                  <Col className="me-2">
                    <p className="mb-2">{M.NOTIFICATIONS}</p>
                    <p className={styles.helperText}>
                      {M.STAY_UPDATED_WITH_NOTIFICATIONS}
                    </p>
                  </Col>
                  <Col xs="auto" className="ms-1 d-flex align-items-center">
                    <Field
                      name={Fields.NOTIFICATIONS}
                      component={Toggle}
                      type="switch"
                    />
                    <OnChange name={Fields.NOTIFICATIONS}>
                      {(value) => onNotificationsToggle(value, formProps.form)}
                    </OnChange>
                  </Col>
                </Row>
                <hr className="m-0" />
                <Row className="p-4">
                  <Col className="d-flex justify-content-end">
                    <p className={styles.helperText}>
                      {M.WILL_NEVER_SHARE_INFORMATION}
                    </p>
                  </Col>
                </Row>
              </form>
            )}
          </Form>
        </Col>
      </Row>
    </Trackable>
  );
}

export default ContactYou;
