import { useCallback } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { indexOf, noop } from "lodash";

import {
  LocationPropType,
  PickupPointPropType,
  PickupPointsPropType,
} from "../../../constants/propType";
import { getPickupLocationCode } from "../../../utils/pickupPoint.js";
import PickupFilterButtons from "../../molecules/PickupFilterButtons";
import PickupLocationsMap from "../../molecules/PickupLocationsMap";
import PickupPointsList from "../../molecules/PickupPointsList";
import { ITEM_HEIGHT } from "../SetPickupPoint/constants";
import styles from "./EditPickupPointDesktop.module.scss";

const EditPickupPointDesktop = ({
  currentLocation,
  selectedPickupPoint,
  activePickupPoint,
  pickupPoints,
  onSelectClick,
  onPickupDetailsClick,
  filterBy,
  isFiltersVisible,
  onFilterButtonClick,
  pickupListRef,
  onDirectionsClick,
  onMapPinClick,
}) => {
  const handleMapPinClick = useCallback(
    (pickupPoint) => {
      const isPickupDetailsActive =
        getPickupLocationCode(activePickupPoint) ===
        getPickupLocationCode(pickupPoint);

      if (isPickupDetailsActive) {
        onPickupDetailsClick(null);
      } else {
        const indexOfPickup = indexOf(pickupPoints, pickupPoint);
        pickupListRef?.current?.scroll({
          top: indexOfPickup * ITEM_HEIGHT,
          behavior: "smooth",
        });

        onPickupDetailsClick(pickupPoint);
      }

      onMapPinClick();
    },
    [
      pickupPoints,
      activePickupPoint,
      onPickupDetailsClick,
      pickupListRef,
      onMapPinClick,
    ]
  );

  return (
    <Row className="g-0 h-100">
      <Row className={classNames("g-0 position-relative", styles.mapContainer)}>
        <Col>
          {isFiltersVisible && (
            <PickupFilterButtons
              filterBy={filterBy}
              onFilterButtonClick={onFilterButtonClick}
              className={styles.filterButtonsContainer}
            />
          )}
          <PickupLocationsMap
            selectedPickupPoint={selectedPickupPoint}
            activePickupPoint={activePickupPoint}
            currentLocation={currentLocation}
            pickupLocations={pickupPoints}
            onPickupPinClick={handleMapPinClick}
            mapPadding={{ top: 70, bottom: 45, left: 60, right: 60 }}
          />
        </Col>
      </Row>
      <PickupPointsList
        currentLocation={currentLocation}
        selectedPickupPoint={selectedPickupPoint}
        activePickupPoint={activePickupPoint}
        pickupPoints={pickupPoints}
        onSelectClick={onSelectClick}
        onPickupDetailsClick={onPickupDetailsClick}
        pickupListRef={pickupListRef}
        className={styles.listContainer}
        onDirectionsClick={onDirectionsClick}
      />
    </Row>
  );
};

EditPickupPointDesktop.propTypes = {
  onDirectionsClick: noop,
  onMapPinClick: noop,
};

EditPickupPointDesktop.propTypes = {
  currentLocation: LocationPropType.isRequired,
  selectedPickupPoint: PickupPointPropType,
  activePickupPoint: PickupPointPropType,
  pickupPoints: PickupPointsPropType.isRequired,
  onSelectClick: PropTypes.func,
  onPickupDetailsClick: PropTypes.func,
  filterBy: PropTypes.string,
  isFiltersVisible: PropTypes.bool,
  onFilterButtonClick: PropTypes.func,
  pickupListRef: PropTypes.any,
  onDirectionsClick: PropTypes.func,
  onMapPinClick: PropTypes.func,
};

export default EditPickupPointDesktop;
