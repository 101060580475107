import PropTypes from "prop-types";

import { YOUR_DELIVERY_ADDRESS } from "../../../constants/strings";
import AddressView, { AddressPropType } from "../AddressView";
import EditableCard from "../EditableCard";

const DeliveryAddressCard = ({ onEdit, address = {} }) => {
  return (
    <EditableCard title={YOUR_DELIVERY_ADDRESS} onEdit={onEdit}>
      <AddressView address={address} />
    </EditableCard>
  );
};

DeliveryAddressCard.propTypes = {
  address: AddressPropType,
  onEdit: PropTypes.func,
};

export default DeliveryAddressCard;
