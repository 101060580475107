import { useCallback, useMemo, useState } from "react";
import { find } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Trackable, useTracker } from "@dpdgroupuk/react-event-tracker";

import { locationsApis } from "../../apis";
import { Loader } from "../../components/molecules/Loader";
import Form from "../../components/organisms/EditProfileForm";
import PreferredNeighbour from "../../components/organisms/PreferredNeighbour";
import ProfilePage from "../../components/templates/ProfilePage";
import { EDIT_PREFERRED_NEIGHBOUR } from "../../constants/analytics";
import { Fields } from "../../constants/forms";
import {
  CHANGES_SAVED,
  PREFERRED_NEIGHBOUR,
  PREFERRED_NEIGHBOUR_MESSAGE,
} from "../../constants/strings";
import { useAuth } from "../../features/Auth";
import { useOverlay } from "../../features/Overlay";
import {
  ConsumerAddressesActions,
  ConsumerAddressesSelector,
} from "../../features/Profile";
import { useToaster } from "../../features/Toaster";
import styles from "./EditNeighbour.module.scss";

const EditNeighbour = () => {
  const { udprn } = useParams();

  const auth = useAuth();
  const toaster = useToaster();
  const overlay = useOverlay();
  const dispatch = useDispatch();
  const tracker = useTracker();
  const address = useSelector(
    ConsumerAddressesSelector.getConsumerAddressById(udprn)
  );

  const [neighbourAddresses, setNeighbourAddresses] = useState([]);

  const initialValues = useMemo(
    () => ({
      [Fields.PREFERRED_NEIGHBOUR]:
        address.preferences.neighbours.preferred?.udprn || null,
      [Fields.AVOID_NEIGHBOUR]:
        address.preferences.neighbours.avoid?.udprn || null,
    }),
    [address]
  );

  const loadNeighbourAddresses = useCallback(async ({ postcode }, options) => {
    const { data: addresses } = await locationsApis.searchLocations(
      postcode,
      options
    );
    const neighbourAddresses = addresses.filter((a) => a.udprn !== udprn);
    setNeighbourAddresses(neighbourAddresses);
    return neighbourAddresses;
  }, []);

  const handleSubmit = useCallback(
    async (values) => {
      try {
        overlay.show();
        tracker.logEvent(EDIT_PREFERRED_NEIGHBOUR.ON_CLICK_SAVE);

        await dispatch(
          ConsumerAddressesActions.updateAddress([
            auth.currentSession.uid,
            {
              udprn,
              "preferences.neighbours": {
                preferred:
                  find(neighbourAddresses, ["udprn", values.preferred]) || null,
                avoid:
                  find(neighbourAddresses, ["udprn", values.avoid]) || null,
              },
            },
          ])
        ).unwrap();

        toaster.showSuccess({
          body: CHANGES_SAVED,
          autoHide: true,
        });
      } catch (e) {
        toaster.showError({
          body: e.message,
          reload: e.reload,
        });
      } finally {
        overlay.hide();
      }
    },
    [udprn, auth, dispatch, overlay, toaster, neighbourAddresses, tracker]
  );

  return (
    <Loader promiseFn={loadNeighbourAddresses} postcode={address.postcode}>
      <Trackable
        loadId={EDIT_PREFERRED_NEIGHBOUR.LOAD}
        interfaceId={EDIT_PREFERRED_NEIGHBOUR.INTERFACE_ID}
      >
        <ProfilePage
          title={PREFERRED_NEIGHBOUR}
          subtitle={PREFERRED_NEIGHBOUR_MESSAGE}
          showBackButtonDesktop
          className={styles.container}
        >
          <Form
            initialValues={initialValues}
            onSubmit={handleSubmit}
            toolbar={!!neighbourAddresses.length}
          >
            <PreferredNeighbour
              fluid
              className="p-4"
              addresses={neighbourAddresses}
            />
          </Form>
        </ProfilePage>
      </Trackable>
    </Loader>
  );
};

export default EditNeighbour;
