import classNames from "classnames";
import PropTypes from "prop-types";
import { Card, Col, Row } from "react-bootstrap";

import PickupInfo from "../../atoms/PickupInfo";
import PickupPointTitle from "../../atoms/PickupPointTitle";
import PickupDetails from "../../atoms/PickupAmenities";
import AddressView from "../AddressView";
import styles from "./PreferredPickupCard.module.scss";

const PreferredPickupCard = ({
  name,
  address,
  info,
  amenities,
  selected,
  children,
}) => (
  <Card
    className={classNames(
      styles.card,
      styles.preferredPickupCard,
      selected && styles.selected
    )}
  >
    <Card.Body className="p-0">
      <Row className="g-0">
        <Col md={3} className={classNames("p-0", styles.mapContainer)}>
          {children}
        </Col>
        <Col md={9} className="p-4">
          <Row className="mb-2">
            <Col>
              <PickupPointTitle number={1} name={name} />
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <AddressView address={address} />
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <PickupInfo {...info} />
            </Col>
          </Row>
          <Row>
            <Col>
              <PickupDetails {...amenities} className={styles.details} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Card.Body>
  </Card>
);

PreferredPickupCard.propTypes = {
  name: PropTypes.string.isRequired,
  address: PropTypes.object.isRequired,
  info: PropTypes.object.isRequired,
  details: PropTypes.object,
  children: PropTypes.node,
  selected: PropTypes.bool,
};

export default PreferredPickupCard;
