import React, { useCallback } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Field, Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Trackable, useTracker } from "@dpdgroupuk/react-event-tracker";

import Input from "../../components/atoms/Input";
import ProfilePage from "../../components/templates/ProfilePage";
import { Fields } from "../../constants/forms";
import * as M from "../../constants/strings";
import { useAuth } from "../../features/Auth";
import { useOverlay } from "../../features/Overlay";
import {
  ConsumerEmailModel,
  ConsumerEmailsActions,
  ConsumerEmailsSelector,
} from "../../features/Profile";
import { useToaster } from "../../features/Toaster";
import * as Formatters from "../../utils/formatters";
import * as Validators from "../../utils/validators";
import { Paths } from "../../router";
import { ADD_NOTIFICATION_EMAIL } from "../../constants/analytics";

const AddEmail = () => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const overlay = useOverlay();
  const toaster = useToaster();
  const navigate = useNavigate();
  const tracker = useTracker();
  const { showBackButtonDesktop } = useOutletContext();

  const emails = useSelector(ConsumerEmailsSelector.getConsumerEmails);

  const verifyEmail = useCallback(
    async ({ id, email }) =>
      auth
        .sendVerifyEmail({
          email,
          id,
        })
        .unwrap(),
    [auth]
  );

  const addEmail = useCallback(
    async ({ email }, form) => {
      try {
        overlay.show();

        tracker.logEvent(ADD_NOTIFICATION_EMAIL.ON_CLICK_NEXT);

        const emails = await dispatch(
          ConsumerEmailsActions.getEmails(auth.currentSession.uid)
        ).unwrap();

        ConsumerEmailModel.validateEmailOnAdd(email, emails);

        const emailModel = ConsumerEmailModel.createEmailModel(email);
        await dispatch(
          ConsumerEmailsActions.addEmail([auth.currentSession.uid, emailModel])
        );
        await verifyEmail(emailModel);
        toaster.showInfo({
          body: M.WE_HAVE_SENT_VERIFICATION_EMAIL_TO_$(email),
          autoHide: true,
        });
        navigate(Paths.PROFILE_PERSONAL_INFO, {
          replace: true,
        });
      } catch (e) {
        toaster.showError({
          body: e.message,
          autoHide: true,
        });
      } finally {
        form.change(Fields.EMAIL, null);
        overlay.hide();
      }
    },
    [dispatch, overlay, auth, toaster, verifyEmail, navigate, tracker]
  );

  return (
    <Trackable
      loadId={ADD_NOTIFICATION_EMAIL.LOAD}
      interfaceId={ADD_NOTIFICATION_EMAIL.INTERFACE_ID}
    >
      <ProfilePage
        title={M.ADD_EMAIL_ADDRESS}
        subtitle={M.ENTER_NEW_EMAIL_ADDRESS}
        showBackButtonDesktop={showBackButtonDesktop}
      >
        <Form initialValues={{ [Fields.EMAIL]: null }} onSubmit={addEmail}>
          {(formProps) => (
            <form onSubmit={formProps.handleSubmit}>
              <Container fluid className="p-4">
                <Row className="g-0">
                  <Col>
                    <Field
                      component={Input}
                      name={Fields.EMAIL}
                      label={M.EMAIL_ADDRESS}
                      placeholder={M.ENTER_EMAIL_ADDRESS}
                      maxLength={255}
                      formatOnBlur
                      format={Formatters.formatEmail}
                      validate={Validators.composeValidators(
                        Validators.required,
                        Validators.notEmpty,
                        Validators.email,
                        Validators.maxLength(255),
                        (value) =>
                          Validators.isAlreadyAdded(
                            value,
                            emails.map((e) => e.email),
                            Fields.EMAIL
                          )
                      )}
                    />
                  </Col>
                </Row>
                <Row className="g-0 mb-1">
                  <Col className="d-flex justify-content-end">
                    <Button
                      variant="danger"
                      type="submit"
                      disabled={!formProps.dirty || formProps.invalid}
                    >
                      {M.NEXT}
                    </Button>
                  </Col>
                </Row>
              </Container>
            </form>
          )}
        </Form>
      </ProfilePage>
    </Trackable>
  );
};

export default AddEmail;
