import { SESSION_TYPE } from "./constants";
import { Paths } from "../../router";

export const isPhoneLinkedAccount = (session) => {
  return session.firebase?.identities?.hasOwnProperty("phone");
};

export const isMagicLinkAccountSession = (session) => {
  return (
    session.firebase?.sign_in_provider === "custom" &&
    session.firebase?.identities?.hasOwnProperty("email")
  );
};

export const isEmailAccountVerified = (session, email) => {
  const isMagicLinkAccount = isMagicLinkAccountSession(session);
  if (isMagicLinkAccount) {
    const matchEmail = session.firebase?.identities?.email?.find(
      (e) => e === email
    );

    return !!matchEmail;
  }

  return false;
};

export const isConsumerAccount = (session) => {
  return !!session?.consumerId;
};

export const getSessionType = (session) => {
  if (!session) {
    return SESSION_TYPE.GUEST;
  }
  if (isConsumerAccount(session)) {
    return SESSION_TYPE.CONSUMER_ACCOUNT;
  } else if (isPhoneLinkedAccount(session)) {
    return SESSION_TYPE.PHONE_ACCOUNT;
  } else {
    return SESSION_TYPE.ACCOUNT;
  }
};

export const getRedirectPathBySessionType = (type) => {
  switch (type) {
    case SESSION_TYPE.ACCOUNT:
      return Paths.SIGN_UP;
    case SESSION_TYPE.PHONE_ACCOUNT:
      return Paths.CREATE_PROFILE;
    case SESSION_TYPE.CONSUMER_ACCOUNT:
      return Paths.PROFILE;
    default:
      return Paths.SIGN_IN;
  }
};

/*
find first not phone provider & consider as initial signup provider
@example: {
  "identities": {
    "google.com": [
      "11111111111111"
    ],
    "phone": [
      "+447000000000"
    ]
  },
  "sign_in_provider": "custom"
}
 */
export const getSignUpInitialProviderId = (session) =>
  Object.keys(session.firebase?.identities).find((p) => p !== "phone");
