import PropTypes from "prop-types";

const PickupPointTitle = ({ number, name, className }) => (
  <h5 className="m-0 text-truncate">
    <span className="text-danger">{`${number}. `}</span>
    <span>{name}</span>
  </h5>
);

PickupPointTitle.propTypes = {
  number: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
};

export default PickupPointTitle;
