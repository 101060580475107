import classNames from "classnames";
import { take, takeRight } from "lodash";
import { Badge, Col, Row } from "react-bootstrap";
import { useBreakpoint } from "use-breakpoint";
import PropTypes from "prop-types";

import { Consumer } from "../../../components/atoms/icons";
import EditableCard from "../../../components/molecules/EditableCard";
import {
  EMAIL_ADDRESSES,
  FULL_NAME,
  MOBILE_NUMBER,
  PERSONAL_INFORMATION,
  PERSONAL_INFORMATION_SHORT,
  UNVERIFIED,
} from "../../../constants/strings";
import { BREAKPOINTS } from "../../../constants/ui";
import { EmailPropType } from "../../../constants/propType";
import styles from "./EditInfoCard.module.scss";

const INFO_LABELS = {
  mobile: PERSONAL_INFORMATION_SHORT,
  tablet: PERSONAL_INFORMATION,
  desktop: PERSONAL_INFORMATION,
};

const EditInfoCard = ({ fullName, phoneNumber, emails, onEdit, className }) => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);

  return (
    <EditableCard
      icon={Consumer}
      title={INFO_LABELS[breakpoint]}
      className={classNames("p-0 w-100", styles.container)}
      classes={{ card: className, title: styles.header }}
      onEdit={onEdit}
    >
      <Row>
        <Col xs={6} sm={3} xl={2} className="mb-3 mb-md-0">
          <p className={classNames("fw-bold", styles.text)}>{FULL_NAME}:</p>
          <p className={classNames(styles.text, styles.longContent)}>
            {fullName}
          </p>
        </Col>
        <Col xs={6} sm={3} xl={2} className="mb-3 mb-md-0">
          <p className={classNames("fw-bold", styles.text)}>{MOBILE_NUMBER}:</p>
          <p className={styles.text}>{phoneNumber}</p>
        </Col>
        <Col className={classNames("mb-3 mb-md-0", styles.emailsCol)}>
          <p className={classNames("fw-bold", styles.text)}>
            {EMAIL_ADDRESSES}:
          </p>
          <Row>
            <Col xs={12} xl={6}>
              {take(emails, 3).map(({ email, verified }, index) => (
                <p
                  className={classNames(styles.text, styles.longContent)}
                  key={index}
                >
                  {email}
                  {!verified && (
                    <Badge bg="danger" className={styles.badge}>
                      {UNVERIFIED}
                    </Badge>
                  )}
                </p>
              ))}
            </Col>
            {emails.length > 3 && (
              <Col xs={12} xl={6}>
                {takeRight(emails, 2).map(({ email, verified }, index) => (
                  <p
                    className={classNames(styles.text, styles.longContent)}
                    key={index}
                  >
                    {email}
                    {!verified && (
                      <Badge bg="danger" className={styles.badge}>
                        {UNVERIFIED}
                      </Badge>
                    )}
                  </p>
                ))}
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </EditableCard>
  );
};

EditInfoCard.propTypes = {
  fullName: PropTypes.string,
  phoneNumber: PropTypes.string,
  emails: PropTypes.arrayOf(EmailPropType),
  onEdit: PropTypes.func,
};

export default EditInfoCard;
