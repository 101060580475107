import Apple from "../components/atoms/icons/Apple";
import Facebook from "../components/atoms/icons/Facebook/Facebook";
import Google from "../components/atoms/icons/Google";
import Microsoft from "../components/atoms/icons/Microsoft";
import Yahoo from "../components/atoms/icons/Yahoo";
import {
  APPLE_ACCOUNT,
  FACEBOOK_ACCOUNT,
  GOOGLE_ACCOUNT,
  MICROSOFT_ACCOUNT,
  YAHOO_ACCOUNT,
} from "./strings";

export const PROVIDERS_UI_BY_CODE = {
  "google.com": {
    icon: Google,
    label: GOOGLE_ACCOUNT,
  },
  "microsoft.com": {
    icon: Microsoft,
    label: MICROSOFT_ACCOUNT,
  },
  "facebook.com": {
    icon: Facebook,
    label: FACEBOOK_ACCOUNT,
  },
  "apple.com": {
    icon: Apple,
    label: APPLE_ACCOUNT,
  },
  "yahoo.com": {
    icon: Yahoo,
    label: YAHOO_ACCOUNT,
  },
};
