import classNames from "classnames";

import LinkButton from "../../atoms/LinkButton";

const LinkButtonWithIcon = ({ icon: Icon, buttonText, className, ...rest }) => {
  return (
    <LinkButton
      variant="outline-secondary"
      className={classNames("d-flex flex-row", className)}
      {...rest}
    >
      {Icon && <Icon className={"me-2"} />}
      {buttonText}
    </LinkButton>
  );
};

export default LinkButtonWithIcon;
