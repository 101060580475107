import { useState } from "react";
import PropTypes from "prop-types";
import { Button, Col, Image, Row } from "react-bootstrap";
import { noop } from "lodash";
import OffCanvas from "../../atoms/OffCanvas";
import * as M from "../../../constants/strings";
import DpdDriver from "../../../assets/images/dpd_driver.png";
import styles from "./AddressNotFound.module.scss";

const AddressNotFound = ({ className, onCantFindAddress }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    onCantFindAddress();
    setShow(true);
  };

  return (
    <div className={className}>
      <Button variant="link" className={"text-danger"} onClick={handleShow}>
        {M.CANT_FIND_YOUR_ADDRESS}
      </Button>
      <OffCanvas
        handleClose={handleClose}
        show={show}
        placement={"end"}
        title={M.MY_ADDRESS_IS_NOT_SHOWN_IN_THE_POSTCODE_FINDER}
      >
        <Row>
          <Col className={styles.text}>
            <span>{M.WE_ARE_SORRY_THAT_YOU_CANT_FIND_YOUR_ADDRESS}</span>
          </Col>
        </Row>
        <Row>
          <Col className="mt-3">
            <Image src={DpdDriver} className={styles.image} />
          </Col>
        </Row>
      </OffCanvas>
    </div>
  );
};

AddressNotFound.propTypes = {
  className: PropTypes.string,
  onCantFindAddress: PropTypes.func,
};

AddressNotFound.defaultProps = {
  onCantFindAddress: noop,
};

export default AddressNotFound;
