import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { createWizardSlice } from "../../features/Wizard";

export const SignUpSliceSlice = createWizardSlice("signUp");
export const SignUpSliceSelectors = SignUpSliceSlice.selectors;

export const SignUpSliceReducer = persistReducer(
  {
    key: "signUp",
    storage: storage,
  },
  SignUpSliceSlice.reducer
);
