import classNames from "classnames";
import { Button } from "react-bootstrap";

import styles from "./LinkButton.module.scss";

const LinkButton = ({ children, className, ...rest }) => (
  <Button
    variant="link"
    className={classNames(styles.linkButton, className)}
    {...rest}
  >
    {children}
  </Button>
);

LinkButton.propTypes = Button.propTypes;

export default LinkButton;
