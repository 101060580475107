import Spinner from "react-bootstrap/Spinner";
import Backdrop from "../../atoms/Backdrop";

const Pending = () => (
  <Backdrop open={true} position="absolute">
    <Spinner animation="border" variant="danger" className="m-auto" />
  </Backdrop>
);

export default Pending;
