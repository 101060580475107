import classNames from "classnames";
import { Button, Col, Nav, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate, useOutlet } from "react-router-dom";
import { useBreakpoint } from "use-breakpoint";

import { Trackable, useTracker } from "@dpdgroupuk/react-event-tracker";

import Plus from "../../components/atoms/icons/Plus";
import TabNavItem from "../../components/molecules/TabNavItem/TabNavItem";
import { YOUR_DELIVERY_ADDRESSES } from "../../constants/analytics";
import * as M from "../../constants/strings";
import { BREAKPOINTS } from "../../constants/ui";
import { ConsumerAddressesSelector } from "../../features/Profile";
import { formatAddressLine } from "../../models/address";
import styles from "./DeliveryAddresses.module.scss";

function DeliveryAddresses() {
  const addresses = useSelector(ConsumerAddressesSelector.getConsumerAddresses);
  const outlet = useOutlet();
  const navigate = useNavigate();
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const location = useLocation();
  const tracker = useTracker();

  return (
    <Trackable
      loadId={YOUR_DELIVERY_ADDRESSES.LOAD}
      interfaceId={YOUR_DELIVERY_ADDRESSES.INTERFACE_ID}
    >
      <Row className="g-0 h-100">
        {(breakpoint === "desktop" || !outlet) && (
          <Col lg={5} className={classNames(styles.container, styles.navPanel)}>
            <Row className={classNames("g-0", styles.header)}>
              <Col className="p-4">
                <h5>{M.DELIVERY_ADDRESSES}</h5>
                <span>{M.CLICK_TO_UPDATE_ADDRESS}</span>
              </Col>
            </Row>

            <Row className={classNames("g-0", styles.content)}>
              <Col>
                <Nav
                  activeKey={location.pathname}
                  className={styles.addressListContainer}
                  onClick={() =>
                    tracker.logEvent(YOUR_DELIVERY_ADDRESSES.ON_CLICK_ADDRESS)
                  }
                >
                  {addresses.map((address, index) => (
                    <TabNavItem
                      href={address.udprn}
                      title={formatAddressLine(address)}
                      badge={address?.type}
                      key={index}
                    />
                  ))}
                </Nav>
              </Col>
            </Row>
            <hr className="m-0" />
            <Row className="g-0">
              <Col className="d-flex justify-content-end p-4">
                <Button
                  variant="link"
                  className="d-flex align-items-center text-danger text-end"
                  onClick={() => {
                    navigate("/profile/addresses/add");
                    tracker.logEvent(
                      YOUR_DELIVERY_ADDRESSES.ON_CLICK_ADD_ADDRESS
                    );
                  }}
                  disabled={addresses.length >= 10}
                >
                  <Plus />
                  <span className="ms-2">{M.ADD_ADDRESS}</span>
                </Button>
              </Col>
            </Row>
          </Col>
        )}
        {outlet && (
          <Col xs={12} xl={7} className="h-100">
            <Outlet
              context={{ showBackButtonDesktop: breakpoint === "tablet" }}
            />
          </Col>
        )}
      </Row>
    </Trackable>
  );
}

export default DeliveryAddresses;
