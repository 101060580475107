import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { noop } from "lodash";
import * as M from "../../../constants/strings";
import { STEP_NAME } from "../../../constants";
import { Address } from "../../atoms/icons";
import EditableCard from "../../molecules/EditableCard/EditableCard";
import AddressView, { AddressPropType } from "../../molecules/AddressView";
import { ClassesPropType } from "./propTypes";

const DeliveryAddressCard = ({ classes, data, onEditClick }) => (
  <EditableCard
    icon={Address}
    title={
      <>
        <span className="d-none d-sm-inline">{M.YOUR_DELIVERY_ADDRESS}</span>
        <span className="d-sm-none">{M.DELIVERY_ADDRESS}</span>
      </>
    }
    onEdit={() => onEditClick(STEP_NAME.DELIVERY_ADDRESS)}
    classes={classes}
  >
    <Row className={classes.dataRow}>
      <Col>
        <p className={classes.dataHeader}>{M.FULL_ADDRESS}:</p>
        <AddressView address={data} classes={{ text: classes.text }} />
      </Col>
      {(data.type || data.customAddressType) && (
        <Col>
          <p className={classes.dataHeader}>{M.ADDRESS_NAME}:</p>
          <span className={classes.text}>
            {data.type || data.customAddressType}
          </span>
        </Col>
      )}
      {data.note && (
        <Col xs={12} sm={3}>
          <p className={classes.dataHeader}>{M.ADDRESS_NOTE}:</p>
          <span className={classes.text}>{data.note}</span>
        </Col>
      )}
    </Row>
  </EditableCard>
);

DeliveryAddressCard.defaultProps = {
  classes: {},
  data: {},
  onEditClick: noop,
};

DeliveryAddressCard.propTypes = {
  classes: ClassesPropType,
  onEditClick: PropTypes.func,
  data: AddressPropType,
};

export default DeliveryAddressCard;
