import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadString,
} from "firebase/storage";
import { firebaseStorage } from "../../firebase";
import { getHumanError } from "../ErrorBoundary/utils";

export const uploadImage = async (path, image) => {
  try {
    const storageRef = ref(firebaseStorage, path);
    const snapshot = await uploadString(storageRef, image, "data_url");

    return await getDownloadURL(snapshot.ref);
  } catch (e) {
    throw getHumanError(e);
  }
};

export const deleteImage = async (path) => {
  const storageRef = ref(firebaseStorage, path);
  return await deleteObject(storageRef);
};
