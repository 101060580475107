import { findIndex } from "lodash";
import { createReducer } from "@reduxjs/toolkit";
import {
  addAddress,
  deleteAddress,
  getAddresses,
  updateAddress,
} from "./actions";
import { ConsumerActions } from "../Consumer";
import { mergeWithDotNotation } from "../../../utils/objects";

const initialState = [];

const addressesReducer = createReducer(initialState, {
  [getAddresses.fulfilled]: (state, { payload }) => payload,
  [addAddress.fulfilled]: (state, { payload }) => {
    state.push(payload);
    return state;
  },
  [updateAddress.fulfilled]: (state, { payload }) => {
    const index = findIndex(state, { udprn: payload.udprn });
    state.splice(index, 1, mergeWithDotNotation(state[index], payload));
    return state;
  },
  [deleteAddress.fulfilled]: (state, { payload }) => {
    const index = findIndex(state, { udprn: payload });
    state.splice(index, 1);
    return state;
  },
  [ConsumerActions.deleteConsumer.fulfilled]: (state) => initialState,
});

export default addressesReducer;
