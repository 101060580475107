const Neighbour = (props) => {
  return (
    <svg
      width="4rem"
      height="3.9375rem" // 63px
      viewBox="0 0 64 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_99_4968)">
        <path
          d="M6.72222 49.9528C6.18556 49.9528 5.75 49.5182 5.75 48.9806C5.75 40.4036 12.7286 33.425 21.3056 33.425C24.9611 33.425 28.5136 34.7181 31.3088 37.0669C31.719 37.4121 31.7735 38.0256 31.4274 38.4368C31.0822 38.8481 30.4687 38.9006 30.0575 38.5554C27.6124 36.5011 24.5042 35.3694 21.3065 35.3694C13.801 35.3694 7.69542 41.475 7.69542 48.9806C7.69444 49.5182 7.25889 49.9528 6.72222 49.9528Z"
          fill="#414042"
        />
        <path
          d="M24.2211 30.9361C23.8293 30.9361 23.4589 30.696 23.3121 30.3081C23.1216 29.8054 23.3743 29.2445 23.877 29.0539C26.7441 27.9679 28.5961 25.2992 28.5961 22.2551C28.5961 18.234 25.3256 14.9635 21.3045 14.9635C17.2834 14.9635 14.0128 18.234 14.0128 22.2551C14.0128 25.2992 15.8649 27.967 18.732 29.0539C19.2336 29.2445 19.4864 29.8054 19.2968 30.3081C19.1072 30.8107 18.5434 31.0635 18.0427 30.8729C14.4697 29.5186 12.0684 26.0565 12.0684 22.2561C12.0684 17.1636 16.212 13.02 21.3045 13.02C26.397 13.02 30.5406 17.1636 30.5406 22.2561C30.5406 26.0565 28.1392 29.5186 24.5663 30.8729C24.4516 30.9157 24.3349 30.9361 24.2211 30.9361Z"
          fill="#414042"
        />
        <path
          d="M31.2855 39.7435C30.8529 39.2758 30.4514 38.887 30.0566 38.5554L31.3069 37.067C31.7648 37.452 32.2247 37.8953 32.7128 38.4242L31.2855 39.7435Z"
          fill="#414042"
        />
        <path
          d="M57.2778 49.9528C56.7402 49.9528 56.3056 49.5182 56.3056 48.9806C56.3056 41.475 50.2001 35.3694 42.6945 35.3694C39.4949 35.3694 36.3877 36.5011 33.9426 38.5554C33.5303 38.8996 32.9178 38.8471 32.5727 38.4368C32.2276 38.0256 32.28 37.4121 32.6913 37.0669C35.4864 34.7181 39.038 33.425 42.6945 33.425C51.2714 33.425 58.25 40.4036 58.25 48.9806C58.25 49.5182 57.8155 49.9528 57.2778 49.9528Z"
          fill="currentColor"
        />
        <path
          d="M40.7475 49.3831L37.1435 45.7781C36.7634 45.3979 36.7634 44.7835 37.1435 44.4033C37.5236 44.0232 38.1381 44.0232 38.5182 44.4033L40.7475 46.6336L46.8667 40.5144C47.2468 40.1343 47.8613 40.1343 48.2414 40.5144C48.6216 40.8946 48.6216 41.509 48.2414 41.8892L40.7475 49.3831Z"
          fill="currentColor"
        />
        <path
          d="M45.6088 30.9361C45.217 30.9361 44.8476 30.696 44.6988 30.3081C44.5092 29.8054 44.761 29.2444 45.2637 29.0539C48.1308 27.9679 49.9829 25.2992 49.9829 22.2551C49.9829 18.234 46.7123 14.9635 42.6912 14.9635C38.6701 14.9635 35.3995 18.234 35.3995 22.2551C35.3995 25.2992 37.2516 27.9669 40.1187 29.0539C40.6213 29.2444 40.8731 29.8054 40.6835 30.3081C40.492 30.8107 39.934 31.0635 39.4294 30.8729C35.8565 29.5196 33.4551 26.0565 33.4551 22.2561C33.4551 17.1636 37.5987 13.02 42.6912 13.02C47.7837 13.02 51.9273 17.1636 51.9273 22.2561C51.9273 26.0565 49.5259 29.5186 45.952 30.8729C45.8392 30.9157 45.7235 30.9361 45.6088 30.9361Z"
          fill="currentColor"
        />
        <path
          d="M28.1109 49.9528C27.5742 49.9528 27.1387 49.5182 27.1387 48.9806C27.1387 44.3751 29.1628 40.0322 32.691 37.067C33.1023 36.7218 33.7148 36.7743 34.0609 37.1856C34.406 37.5968 34.3535 38.2103 33.9423 38.5554C30.8545 41.1503 29.0831 44.9507 29.0831 48.9806C29.0831 49.5182 28.6476 49.9528 28.1109 49.9528Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_99_4968">
          <rect
            width="63"
            height="63"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Neighbour;
