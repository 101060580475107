import classNames from "classnames";
import PropTypes from "prop-types";
import { Button, Col, Row } from "react-bootstrap";

import styles from "./ListItemWithIcon.module.scss";

const ListItemWithIcon = ({ children, icon: Icon, onIconClick, footer }) => (
  <Row className={classNames("g-0", styles.container)}>
    <Col className={"d-flex align-items-center"}>{children}</Col>
    {!!onIconClick && (
      <Col xs="auto">
        <Button variant="link" className="p-0" onClick={onIconClick}>
          <Icon />
        </Button>
      </Col>
    )}
    {footer}
  </Row>
);

ListItemWithIcon.propTypes = {
  children: PropTypes.node,
  footer: PropTypes.node,
  icon: PropTypes.func,
  onIconClick: PropTypes.func,
};

export default ListItemWithIcon;
