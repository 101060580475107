import PropTypes from "prop-types";

import * as M from "../../../constants/strings";
import { STEP_NAME } from "../../../constants";
import { PickupPoint } from "../../atoms/icons";
import EditableCard from "../../molecules/EditableCard/EditableCard";
import AddressView, { AddressPropType } from "../../molecules/AddressView";
import Alert from "../../atoms/Alert/Alert";
import { ClassesPropType } from "./propTypes";

const PickupPointCard = ({ classes, available, data, onEditClick }) => (
  <EditableCard
    icon={PickupPoint}
    title={M.PICKUP_POINT}
    onEdit={() => onEditClick(STEP_NAME.PICKUP_POINT)}
    classes={classes}
    editable={available}
  >
    {(!available && (
      <>
        <p className={classes.dataHeader}>{M.PICKUP_POINT}:</p>
        <p className={classes.text}>{M.UNAVAILABLE}</p>
      </>
    )) ||
      (data && (
        <>
          <p className={classes.dataHeader}>{M.PICKUP_POINT}:</p>
          <AddressView
            address={data?.pickupLocation?.address}
            classes={{ text: classes.text }}
          />
        </>
      )) || (
        <Alert
          variant="info"
          title={M.REMINDER}
          message={M.NO_PICKUP_POINT_SETUP}
        />
      )}
  </EditableCard>
);

PickupPointCard.propTypes = {
  classes: ClassesPropType,
  available: PropTypes.bool,
  onEditClick: PropTypes.func,
  data: PropTypes.shape({
    pickupLocation: PropTypes.shape({
      address: AddressPropType,
    }),
  }),
};

export default PickupPointCard;
