import PropTypes from "prop-types";

export const EmailPropType = PropTypes.shape({
  email: PropTypes.string,
  verified: PropTypes.bool,
});

export const DeliveryAddressPropTypes = {
  organisation: PropTypes.string,
  property: PropTypes.string,
  street: PropTypes.string,
  county: PropTypes.string,
  postcode: PropTypes.string,
  town: PropTypes.string,
};

export const DeliveryAddressPropType = PropTypes.shape(
  DeliveryAddressPropTypes
);

export const AddressPropType = PropTypes.shape({
  ...DeliveryAddressPropTypes,
  preferences: PropTypes.shape({
    firstDeliveryAttempt: PropTypes.string,
    safePlace: PropTypes.shape({
      code: PropTypes.string,
      custom: PropTypes.string,
      imageUrl: PropTypes.string,
    }),
    neighbours: PropTypes.shape({
      avoid: PropTypes.string,
      preferred: PropTypes.string,
    }),
    pickupLocationCode: PropTypes.string,
  }),
  type: PropTypes.string,
  avoidSchoolRun: PropTypes.bool,
  needMoreTime: PropTypes.bool,
  note: PropTypes.string,
  imageUrl: PropTypes.string,
  pinpoint: PropTypes.shape({
    what3words: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }),
});

const OpenWindowPropType = PropTypes.shape({
  pickupLocationOpenWindowDay: PropTypes.number,
  pickupLocationOpenWindowEndTime: PropTypes.string,
  pickupLocationOpenWindowStartTime: PropTypes.string,
});

const PickupLocationAvailabilityPropType = PropTypes.shape({
  pickupLocationActiveEnd: PropTypes.string,
  pickupLocationActiveStart: PropTypes.string,
  pickupLocationDeliveryClosed: PropTypes.string,
  pickupLocationDeliveryStart: PropTypes.string,
  pickupLocationDownTime: PropTypes.array,
  pickupLocationOpenWindow: PropTypes.arrayOf(OpenWindowPropType),
  pickupLocationOpeningExceptions: PropTypes.string,
});

export const LocationPropType = PropTypes.shape({
  latitude: PropTypes.number,
  longitude: PropTypes.number,
});

export const PickupPointPropType = PropTypes.shape({
  distance: PropTypes.number,
  number: PropTypes.number,
  pickupLocation: PropTypes.shape({
    address: PropTypes.shape(DeliveryAddressPropTypes),
    addressPoint: LocationPropType,
    depotCode: PropTypes.string,
    depotDescription: PropTypes.string,
    disabledAccess: PropTypes.bool,
    dropOffDigital: PropTypes.bool,
    dropOffDpd: PropTypes.bool,
    dropOffReturn: PropTypes.bool,
    geoServiceCode: PropTypes.arrayOf(PropTypes.string),
    inFlightParcelCapacity: PropTypes.number,
    kind: PropTypes.string,
    languageSpoken: PropTypes.string,
    maxParcelQuantity: PropTypes.number,
    notifyEmail: PropTypes.string,
    openLate: PropTypes.bool,
    openSaturday: PropTypes.bool,
    openSunday: PropTypes.bool,
    parkingAvailable: PropTypes.bool,
    pickupLocationAvailability: PickupLocationAvailabilityPropType,
    pickupLocationCode: PropTypes.string,
    pickupLocationDirections: PropTypes.string,
    pickupLocationDriverWindow: PropTypes.arrayOf(OpenWindowPropType),
    pickupLocationImageUrl: PropTypes.string,
    pickupLocationLogoUrl: PropTypes.string,
    pickupLocationMaxWeight: PropTypes.number,
    pickupLocationStatus: PropTypes.number,
    pickupLocationType: PropTypes.string,
    preventSameDayNotAtHome: PropTypes.string,
    shortName: PropTypes.string,
    tempCloseReason: PropTypes.string,
    tempCloseReopenDate: PropTypes.bool,
  }),
});

export const PickupPointsPropType = PropTypes.arrayOf(PickupPointPropType);
