const Location = (props) => {
  return (
    <svg
      width="4rem" // 64px
      height="3.9375rem" // 63px
      viewBox="0 0 64 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_470_28455)">
        <path
          d="M32.1718 57.75L31.1243 55.1996C30.6243 53.9811 18.875 25.2835 18.875 18.4589C18.875 11.1753 24.8293 5.25 32.1486 5.25C39.4678 5.25 45.4221 11.1753 45.4221 18.4589C45.4221 23.9164 33.6794 53.9095 33.1795 55.1831L32.1718 57.75ZM32.1486 7.45149C26.0494 7.45149 21.0873 12.3894 21.0873 18.4589C21.0873 23.1767 27.8015 41.0473 32.1253 51.7917C35.9559 41.8652 43.2099 22.2763 43.2099 18.4589C43.2099 12.3894 38.2478 7.45149 32.1486 7.45149Z"
          fill="#414042"
        />
        <path
          d="M32.1241 21.7612C33.9568 21.7612 35.4425 20.2827 35.4425 18.459C35.4425 16.6352 33.9568 15.1567 32.1241 15.1567C30.2914 15.1567 28.8057 16.6352 28.8057 18.459C28.8057 20.2827 30.2914 21.7612 32.1241 21.7612Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_470_28455">
          <rect
            width="63"
            height="63"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Location;
