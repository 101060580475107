import { createReducer } from "@reduxjs/toolkit";
import {
  getCurrentSession,
  createSession,
  logoutSession,
  getOrCreateSession,
} from "./actions";

const initialState = {
  isLoading: true,
  error: null,
  currentSession: null,
};

const authReducer = createReducer(initialState, {
  // login user
  [getOrCreateSession.pending]: (state) => {
    state.isLoading = true;
    state.error = null;
  },
  [getOrCreateSession.fulfilled]: (state) => {
    state.isLoading = false;
    state.error = null;
  },
  [getOrCreateSession.rejected]: (state, { payload }) => {
    state.isLoading = false;
    state.error = payload;
  },
  [getCurrentSession.fulfilled]: (state, action) => {
    state.currentSession = action.payload;
  },
  [createSession.fulfilled]: (state, action) => {
    state.currentSession = action.payload;
  },
  [logoutSession.fulfilled]: (state, action) => {
    state.currentSession = null;
  },
});

export default authReducer;
