export const ADDRESS_TYPE_NAME = {
  HOME: "Home",
  WORK: "Work",
  OTHER: "Other",
};

export const IMAGE_NAME = {
  ADDRESS: "address",
  SAFE_PLACE: "safePlace",
  RELOVE_SAFE_PLACE: "reloveSafePlace",
};
