import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  $_NOT_FOUND_REFRESH,
  ACCOUNT_NOT_FOUND_REFRESH,
  MAX_RETRY_TIME_EXCEEDED,
  SOMETHING_WENT_WRONG_TRY_AGAIN,
  YOU_HAVE_ACCOUNT_REFRESH,
} from "../../constants/strings";

export const ENTITY = {
  ADDRESS: "address",
  ACCOUNT: "account",
  EMAIL: "email",
};

export const ERROR_CODE = {
  NOT_FOUND: "not-found",
  STORAGE_RETRY_LIMIT_EXCEEDED: "storage/retry-limit-exceeded",
  ALREADY_EXISTS: "firestore/already-exists",
  AUTH_MAX_LOGIN_ATTEMPTS: "auth/reached-max-login-attempts",
};

const MESSAGE_BY_ENTITY = {
  [ERROR_CODE.NOT_FOUND]: {
    [ENTITY.ADDRESS]: $_NOT_FOUND_REFRESH(ENTITY.ADDRESS),
    [ENTITY.EMAIL]: $_NOT_FOUND_REFRESH(ENTITY.EMAIL),
    [ENTITY.ACCOUNT]: ACCOUNT_NOT_FOUND_REFRESH,
  },
  [ERROR_CODE.ALREADY_EXISTS]: {
    [ENTITY.ACCOUNT]: YOU_HAVE_ACCOUNT_REFRESH,
  },
};

export const getHumanError = (error, entity) => {
  switch (error.code) {
    case ERROR_CODE.NOT_FOUND:
    case ERROR_CODE.ALREADY_EXISTS: {
      return {
        ...error,
        message: MESSAGE_BY_ENTITY[error.code]?.[entity] || error.message,
        originalMessage: error.message,
        reload: true,
      };
    }
    case ERROR_CODE.STORAGE_RETRY_LIMIT_EXCEEDED: {
      return {
        ...error,
        message: MAX_RETRY_TIME_EXCEEDED,
      };
    }
    default: {
      if (!error.message) {
        return {
          ...error,
          message: SOMETHING_WENT_WRONG_TRY_AGAIN,
        };
      }

      return error;
    }
  }
};

export const createFirebaseAsyncThunk = (typePrefix, payloadCreator, options) =>
  createAsyncThunk(typePrefix, async (params, thunkParams) => {
    try {
      if (typeof options === "string") {
        options = {
          entity: options,
        };
      }
      return await payloadCreator(params, thunkParams);
    } catch (error) {
      return thunkParams.rejectWithValue(getHumanError(error, options.entity));
    }
  });
