import { Form } from "react-final-form";
import PropTypes from "prop-types";
import { Button, Col, Row } from "react-bootstrap";
import classNames from "classnames";
import {
  PLEASE_MAKE_CHANGE,
  SAVE,
  SELECT_SAVE,
} from "../../../constants/strings";
import styles from "./EditProfileForm.module.scss";

const EditForm = ({ children, toolbar, ...props }) => {
  return (
    <Form {...props}>
      {(formProps) => (
        <form onSubmit={formProps.handleSubmit}>
          {typeof children === "function" ? children(formProps) : children}
          {toolbar && (
            <Row className="g-0 px-4">
              <Row className="g-0 mb-1">
                <Col className="d-flex justify-content-end">
                  <Button
                    variant="danger"
                    type="submit"
                    disabled={!formProps.dirty || formProps.invalid}
                  >
                    {SAVE}
                  </Button>
                </Col>
              </Row>
              <Row className="g-0">
                <Col className="d-flex justify-content-end ">
                  <p className={classNames("m-0", styles.helperText)}>
                    {formProps.dirty ? SELECT_SAVE : PLEASE_MAKE_CHANGE}
                  </p>
                </Col>
              </Row>
            </Row>
          )}
        </form>
      )}
    </Form>
  );
};

EditForm.defaultProps = {
  toolbar: true,
};

EditForm.propTypes = {
  ...Form.propTypes,
  toolbar: PropTypes.bool,
};

export default EditForm;
