const DPDLogo = (props) => {
  return (
    <svg
      width="5.6875rem" // 91px
      height="2.4375rem" // 39px
      viewBox="0 0 91 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_144_4144)">
        <mask
          id="mask0_144_4144"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="91"
          height="39"
        >
          <rect
            x="0.343018"
            y="0.343018"
            width="90"
            height="38"
            fill="#C4C4C4"
          />
        </mask>
        <g mask="url(#mask0_144_4144)">
          <path
            d="M23.0593 17.3023C22.9745 17.3455 22.8803 17.3671 22.7851 17.3654C22.6899 17.3636 22.5967 17.3384 22.5136 17.292L21.6287 16.7674C21.5507 16.7164 21.4863 16.6472 21.4409 16.5659L21.432 16.5512C21.3782 16.4678 21.3466 16.3721 21.3401 16.2732L21.3177 15.2455C21.3188 15.1507 21.3434 15.0577 21.3893 14.9748C21.4352 14.8918 21.501 14.8215 21.5807 14.77L32.2157 8.59046L17.2911 0.476933C17.1042 0.387812 16.8998 0.341553 16.6927 0.341553C16.4856 0.341553 16.2811 0.387812 16.0943 0.476933L1.16968 8.59046L17.8892 18.2975C17.9698 18.3467 18.0368 18.415 18.0844 18.4965C18.1319 18.5779 18.1585 18.6698 18.1616 18.764V32.9248C18.1565 33.0196 18.1279 33.1118 18.0785 33.1929C18.029 33.2741 17.9601 33.3418 17.878 33.3899L16.9783 33.8882C16.8949 33.9296 16.8027 33.9505 16.7095 33.949H16.6925C16.5931 33.9533 16.4943 33.9324 16.4053 33.8882L15.5024 33.3899C15.4213 33.3412 15.3534 33.2733 15.3048 33.1921C15.2562 33.111 15.2283 33.0192 15.2237 32.9248V20.3012C15.2177 20.2516 15.2011 20.2039 15.1753 20.1612C15.1494 20.1184 15.1148 20.0816 15.0736 20.0532L0.343018 11.5017V28.252C0.343018 28.6269 0.607817 29.089 0.932655 29.2798L16.1033 38.2017C16.2859 38.2944 16.4878 38.3427 16.6927 38.3427C16.8976 38.3427 17.0995 38.2944 17.2821 38.2017L32.455 29.2785C32.7785 29.0872 33.0424 28.6269 33.0424 28.2507V11.5017L23.0593 17.3023Z"
            fill="url(#paint0_linear_144_4144)"
          />
          <path
            d="M56.3089 28.4948C54.6659 28.9287 52.526 29.1432 50.6634 29.1432C45.8872 29.1432 42.7244 26.6082 42.7244 21.9685C42.7244 17.5773 45.6703 14.7331 49.9828 14.7331C50.9439 14.7331 51.9664 14.8551 52.5874 15.1652V8.7926H56.3062L56.3089 28.4948ZM52.59 18.4433C52.0008 18.1649 51.2257 18.0103 50.2938 18.0103C48.0315 18.0103 46.5113 19.4027 46.5113 21.8457C46.5113 24.4749 48.1548 25.9598 50.7911 25.9598C51.2562 25.9598 51.969 25.9281 52.59 25.8052V18.4433ZM86.8459 28.4948C85.2011 28.9287 83.0617 29.1432 81.2005 29.1432C76.4251 29.1432 73.2605 26.6082 73.2605 21.9685C73.2605 17.5773 76.2078 14.7331 80.519 14.7331C81.48 14.7331 82.5034 14.8551 83.1239 15.1652V8.7926H86.8455L86.8459 28.4948ZM83.1244 18.4433C82.5348 18.1649 81.7592 18.0103 80.829 18.0103C78.5655 18.0103 77.0461 19.4027 77.0461 21.8457C77.0461 24.4749 78.6891 25.9598 81.3255 25.9598C81.7901 25.9598 82.5038 25.9281 83.1244 25.8052V18.4433ZM62.1613 18.4129C62.781 18.1649 63.648 18.0729 64.3926 18.0729C66.688 18.0729 68.2697 19.4027 68.2697 21.7818C68.2697 24.5898 66.5178 25.8602 64.1753 25.8928V29.1396C64.2367 29.1396 64.299 29.1396 64.3622 29.1396C69.1684 29.1396 72.0535 26.45 72.0535 21.6566C72.0535 17.2953 68.9834 14.7304 64.4554 14.7304C62.1613 14.7304 59.8964 15.2563 58.4067 15.8739V34.4049H62.16L62.1613 18.4129Z"
            fill="#414042"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_144_4144"
          x1="16.8219"
          y1="12.693"
          x2="2.25644"
          y2="29.012"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DC0032" />
          <stop offset="0.2" stopColor="#D0012D" />
          <stop offset="0.4" stopColor="#C30229" />
          <stop offset="0.6" stopColor="#B70324" />
          <stop offset="0.8" stopColor="#AB041F" />
          <stop offset="1" stopColor="#9F041B" />
        </linearGradient>
        <clipPath id="clip0_144_4144">
          <rect
            width="90"
            height="38"
            fill="white"
            transform="translate(0.343018 0.343018)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DPDLogo;
