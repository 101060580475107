import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import Step from "./Step";
import Animate from "./animate.custom.scss";
import styles from "./Wizard.module.scss";
import { WizardContext } from "./Context";
import Sentry from "../../sentry";

export default class StepWizard extends PureComponent {
  constructor(props) {
    super(props);

    this.state = this.initialState(props);
  }

  componentDidMount() {
    // Provide instance to parent
    this.props.instance(this);
  }

  /** Setup Steps */
  initialState = ({ initialValues = {}, initialReferences = {} } = {}) => {
    const state = {
      activeStep: 0,
      classes: {},
      namedSteps: {},
      values: {},
      references: initialReferences,
    };

    // Set initial classes
    const children = this.getSteps();
    children.forEach((child, i) => {
      // Create namedSteps map
      state.namedSteps[i] =
        (child.props && child.props.stepName) || `step${i + 1}`;
      state.namedSteps[state.namedSteps[i]] = i;
      state.values[state.namedSteps[i]] =
        initialValues[state.namedSteps[i]] || {};
    });

    // Set activeStep to initialStep if exists
    const initialStep = this.props.initialStep - 1;
    if (initialStep && children[initialStep]) {
      state.activeStep = initialStep;
    }

    // Give initial step an intro class
    if (this.props.transitions) {
      state.classes[state.activeStep] = this.props.transitions.intro || "";
    }

    return state;
  };

  getTransitions = () =>
    this.props.transitions || {
      enterRight: `${Animate.animated} ${Animate.fadeInRight}`,
      enterLeft: `${Animate.animated} ${Animate.fadeInLeft}`,
      exitRight: `${Animate.animated} ${Animate.fadeOutRight}`,
      exitLeft: `${Animate.animated} ${Animate.fadeOutLeft}`,
    };

  isInvalidStep = (next) => next < 0 || next >= this.totalSteps;

  setActiveStep = (next) => {
    const active = this.state.activeStep;
    if (active === next) return;
    if (this.isInvalidStep(next)) {
      if (process.env.NODE_ENV !== "production") {
        console.error(`${next + 1} is an invalid step`);
      }
      return;
    }

    const { classes } = this.state;
    const transitions = this.getTransitions();

    if (active < next) {
      // slide left
      classes[active] = transitions.exitLeft;
      classes[next] = transitions.enterRight;
    } else {
      // slide right
      classes[active] = transitions.exitRight;
      classes[next] = transitions.enterLeft;
    }

    this.setState(
      {
        activeStep: next,
        classes,
      },
      () => {
        // Step change callback
        this.onStepChange({
          previousStep: active + 1,
          previousNamedStep: this.state.namedSteps[active],
          activeStep: next + 1,
          activeNamedStep: this.state.namedSteps[next],
        });
      }
    );
  };

  componentDidCatch(error, info) {
    Sentry.captureException(error);
    this.setState(this.initialState(this.props), () => {
      this.props.onComponentDidCatch(error);
    });
  }

  onStepChange = (stats) => {
    // User callback
    this.props.onStepChange(stats);
  };

  /** Getters */
  get currentStep() {
    return this.state.activeStep;
  }

  get currentNamedStep() {
    return this.state.namedSteps[this.currentStep];
  }

  get totalSteps() {
    return this.getSteps().length;
  }

  getSteps = () => React.Children.toArray(this.props.children);

  /** Go to first step */
  firstStep = () => this.goToStep(1);

  /** Go to last step */
  lastStep = () => this.goToStep(this.totalSteps);

  /** Next Step */
  nextStep = (values) => {
    if (values) {
      this.setStepValues(this.state.activeStep + 1, values);
    }

    return this.setActiveStep(this.state.activeStep + 1);
  };

  /** Previous Step */
  previousStep = () => this.setActiveStep(this.state.activeStep - 1);

  /** Go to step index */
  goToStep = (step) => {
    this.setActiveStep(step - 1);
  };

  /** Go to named step */
  goToNamedStep = (step) => {
    if (typeof step === "string" && this.state.namedSteps[step] !== undefined) {
      this.setActiveStep(this.state.namedSteps[step]);
    } else {
      console.error(`Cannot find step with name "${step}"`);
    }
  };

  setStepValues = (step, values) => {
    const namedStep = this.state.namedSteps[step];

    return this.setState(
      {
        values: {
          ...this.state.values,
          [namedStep]: values,
        },
      },
      () => {
        this.props.onStepValuesChange(namedStep, values);
      }
    );
  };

  setReferences = (name, value) => {
    let newReferences = name;
    if (typeof name === "string") {
      newReferences = {
        [name]: value,
      };
    }

    return this.setState(
      {
        references: {
          ...this.state.references,
          ...newReferences,
        },
      },
      () => {
        this.props.onReferencesChange(name, value);
      }
    );
  };

  setValues = (values) => {
    return this.setStepValues(this.currentStep, values);
  };

  // Allows for using HTML elements as a step
  isReactComponent = ({ type }) =>
    typeof type === "function" || typeof type === "object";

  /** Render */
  render() {
    const props = {
      currentStep: this.currentStep,
      currentNamedStep: this.currentNamedStep,
      totalSteps: this.totalSteps,
      /** Functions */
      nextStep: this.nextStep,
      previousStep: this.previousStep,
      goToStep: this.goToStep,
      goToNamedStep: this.goToNamedStep,
      firstStep: this.firstStep,
      lastStep: this.lastStep,

      /** Custom Functionality */
      values: this.state.values[this.currentNamedStep],
      setValues: this.setValues,
      references: this.state.references,
      setReferences: this.setReferences,
    };

    const { classes } = this.state;
    const childrenWithProps = React.Children.map(
      this.getSteps(),
      (child, i) => {
        if (!child) return null;

        props.isActive = i === this.state.activeStep;
        props.transitions = classes[i];

        // Not Lazy Mount || isLazyMount && isActive
        if (
          !this.props.isLazyMount ||
          (this.props.isLazyMount && props.isActive)
        ) {
          return (
            <Step {...props}>
              {this.isReactComponent(child)
                ? React.cloneElement(child, props)
                : child}
            </Step>
          );
        }

        return null;
      }
    );

    return (
      <WizardContext.Provider value={props}>
        <div className={this.props.className}>
          {this.props.nav && React.cloneElement(this.props.nav, props)}
          <div className={styles["step-wrapper"]}>{childrenWithProps}</div>
        </div>
      </WizardContext.Provider>
    );
  }
}

StepWizard.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  initialStep: PropTypes.number,
  initialValues: PropTypes.object,
  initialReferences: PropTypes.object,
  instance: PropTypes.func,
  isLazyMount: PropTypes.bool,
  nav: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
  onStepChange: PropTypes.func,
  onStepValuesChange: PropTypes.func,
  onReferencesChange: PropTypes.func,
  onComponentDidCatch: PropTypes.func,
  transitions: PropTypes.object,
};

StepWizard.defaultProps = {
  children: [],
  className: null,
  initialStep: 1,
  initialValues: {},
  initialReferences: {},
  instance: () => {},
  isLazyMount: false,
  nav: null,
  onStepChange: () => {},
  onStepValuesChange: () => {},
  onReferencesChange: () => {},
  onComponentDidCatch: (error) => {},
  transitions: undefined,
};
