import PropTypes from "prop-types";
import { Button, Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { OnBlur, OnChange, OnFocus } from "react-final-form-listeners";
import { noop } from "lodash";

import { Fields } from "../../../constants/forms";
import * as M from "../../../constants/strings";
import Input from "../../atoms/Input";
import ContentHeader from "../ContentHeader";
import AddressNotFound from "../AddressNotFound";
import { Formatters, Validators } from "../../../utils";
import { AddressModel } from "../../../models";
import { AddressPropType } from "../AddressView";
import { Dropdown } from "../../atoms/Dropdown";
import Container from "../../templates/Login/LoginContainer";
import { compareStrings } from "../../../utils/string";

const ManagePreferences = ({
  submitError,
  addresses = [],
  onClickBack,
  onChangePostcode,
  submitting,
  invalid,
  form,
  onBlurPhone,
  onFocusAddress,
  onSelectAddress,
  onCantFindAddress,
}) => {
  return (
    <Container>
      <ContentHeader
        title={M.MANAGE_DPD_PREFERENCES}
        subtitle={M.BEFORE_START}
        classes={{ container: "text-start" }}
      />
      <Row>
        <Col xs={12} sm={6}>
          <Field
            name={Fields.PHONE_NUMBER}
            type="tel"
            component={Input}
            formatOnBlur
            format={Formatters.formatUKPhoneNumber}
            label={M.MOBILE_NUMBER}
            maxLength={15}
            validate={Validators.composeValidators(
              Validators.required,
              Validators.phoneNumber
            )}
            keyboardType="numeric"
            required
          />
          <OnBlur name={Fields.PHONE_NUMBER}>{onBlurPhone}</OnBlur>
        </Col>
        <Col xs={12} sm={6}>
          <Field
            name={Fields.POSTCODE}
            label={M.POSTCODE}
            component={Input}
            maxLength={8}
            validate={Validators.composeValidators(
              Validators.required,
              Validators.postcode
            )}
            format={Formatters.formatPostcode}
            required
            formatOnBlur
          />
          <OnChange name={Fields.POSTCODE}>
            {(value, prevValue) =>
              (!compareStrings(value, prevValue) || !addresses.length) &&
              onChangePostcode(value, form)
            }
          </OnChange>
        </Col>
        <Col xs={12}>
          <Field
            name={Fields.UDPRN}
            component={Dropdown}
            label={M.SELECT_ADDRESS}
            disabled={!addresses.length}
            values={addresses.map(AddressModel.formatAddressOption)}
            validate={Validators.composeValidators(Validators.required)}
            required
          />
          <OnFocus name={Fields.UDPRN}>{onFocusAddress}</OnFocus>
          <OnChange name={Fields.UDPRN}>{onSelectAddress}</OnChange>
        </Col>
      </Row>
      {submitError && (
        <Row>
          <Col>
            <p className="text-danger text-end">{submitError}</p>
          </Col>
        </Row>
      )}
      <Row className="d-flex justify-content-sm-between">
        <Col lg="auto" className="d-flex justify-content-center">
          <AddressNotFound onCantFindAddress={onCantFindAddress} />
        </Col>
        <Col lg="auto" className="d-flex justify-content-between">
          <Button
            variant="outline-danger"
            className="me-3"
            onClick={onClickBack}
          >
            {M.BACK}
          </Button>
          <Button
            variant="danger"
            type="submit"
            disabled={submitting || invalid}
          >
            {M.NEXT}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

ManagePreferences.defaultProps = {
  addresses: [],
  onClickBack: noop,
  onChangePostcode: noop,
  onBlurPhone: noop,
  onFocusAddress: noop,
  onSelectAddress: noop,
  onCantFindAddress: noop,
};

ManagePreferences.propTypes = {
  submitError: PropTypes.string,
  addresses: PropTypes.arrayOf(AddressPropType),
  onClickBack: PropTypes.func.isRequired,
  onChangePostcode: PropTypes.func,
  onBlurPhone: PropTypes.func,
  onFocusAddress: PropTypes.func,
  onSelectAddress: PropTypes.func,
  onCantFindAddress: PropTypes.func,
};

export default ManagePreferences;
