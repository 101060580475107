const HomeMarker = (props) => {
  return (
    <svg
      width="54"
      height="63"
      viewBox="0 0 54 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_d_423_50042)">
        <path
          d="M3 6C3 2.68629 5.68629 0 9 0H45C48.3137 0 51 2.68629 51 6V42C51 45.3137 48.3137 48 45 48H39H33L27 56.64L21 48H15H9C5.68629 48 3 45.3137 3 42V6Z"
          fill="#438DED"
        />
        <path
          d="M32.5893 47.7148L27 55.7634L21.4107 47.7148L21.2615 47.5H21H15H9C5.96243 47.5 3.5 45.0376 3.5 42V6C3.5 2.96243 5.96243 0.5 9 0.5H45C48.0376 0.5 50.5 2.96243 50.5 6V42C50.5 45.0376 48.0376 47.5 45 47.5H39H33H32.7385L32.5893 47.7148Z"
          stroke="white"
        />
      </g>
      <mask
        id="mask0_423_50042"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="10"
        y="7"
        width="34"
        height="34"
      >
        <rect
          x="10.6799"
          y="7.68018"
          width="32.64"
          height="32.64"
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#mask0_423_50042)">
        <path
          d="M18.8399 33.5203H22.9199V25.3603H31.0799V33.5203H35.1599V21.2803L26.9999 15.1603L18.8399 21.2803V33.5203ZM16.1199 36.2403V19.9203L26.9999 11.7603L37.8799 19.9203V36.2403H28.3599V28.0803H25.6399V36.2403H16.1199Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_423_50042"
          x="0"
          y="0"
          width="54"
          height="62.6401"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.99 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_423_50042"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_423_50042"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default HomeMarker;
