import React from "react";
import PropTypes from "prop-types";
import { ToastContainer } from "react-bootstrap";
import useBreakpoint from "use-breakpoint";
import useToasterController from "./useToasterController";
import ToasterContext from "./Context";
import Toast from "../../components/molecules/Toast";
import { BREAKPOINTS, TOAST_POSITION } from "../../constants/ui";

const ToasterProvider = ({ children }) => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);

  const values = useToasterController();
  return (
    <ToasterContext.Provider value={values}>
      {children}
      <ToastContainer
        containerPosition="fixed"
        position={TOAST_POSITION[breakpoint]}
        className="p-3"
      >
        {values.toasts.map(({ id, body, onClose, ...params }) => (
          <Toast
            key={id}
            {...params}
            onClose={() => {
              onClose && onClose();
              values.removeToast(id);
            }}
          >
            {body}
          </Toast>
        ))}
      </ToastContainer>
    </ToasterContext.Provider>
  );
};

ToasterProvider.propTypes = {
  children: PropTypes.node,
};

export default ToasterProvider;
