import PropTypes from "prop-types";

import * as M from "../../../constants/strings";
import styles from "./ClosedForLunchStatus.module.scss";

const ClosedForLunchStatus = ({ closedForLunch }) => (
  <div className="d-flex justify-content-between display-4">
    <span>{M.CLOSED_FOR_LUNCH_BETWEEN}</span>
    <span className={styles.closedBetweenText}>{closedForLunch}</span>
  </div>
);

ClosedForLunchStatus.propTypes = {
  closedForLunch: PropTypes.string,
};

export default ClosedForLunchStatus;
