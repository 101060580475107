const Facebook = (props) => {
  return (
    <svg
      width="1.5rem"
      height="1.5rem"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.3333 22C5.61111 21.162 2 17.0279 2 12.0559C2 6.52514 6.5 2 12 2C17.5 2 22 6.52514 22 12.0559C22 17.0279 18.3889 21.162 13.6667 22L13.1111 21.5531H10.8889L10.3333 22Z"
        fill="url(#paint0_linear_333_8330)"
      />
      <path
        d="M16.4194 14.8276L16.8839 12.069H14.0968V10.1379C14.0968 9.36552 14.3871 8.75862 15.6645 8.75862H17V6.22069C16.2452 6.11034 15.4323 6 14.6774 6C12.2968 6 10.6129 7.37931 10.6129 9.86207V12.069H8V14.8276H10.6129V21.8345C11.1935 21.9448 11.7742 22 12.3548 22C12.9355 22 13.5161 21.9448 14.0968 21.8345V14.8276H16.4194Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_333_8330"
          x1="12"
          y1="21.4156"
          x2="12"
          y2="2"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0062E0" />
          <stop offset="1" stopColor="#19AFFF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Facebook;
