import { useMemo, useCallback } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Trackable, useTracker } from "@dpdgroupuk/react-event-tracker";

import * as M from "../../constants/strings";
import { useAuth } from "../../features/Auth";
import { useOverlay } from "../../features/Overlay";
import { useToaster } from "../../features/Toaster";
import {
  ConsumerAddressesActions,
  ConsumerAddressesSelector,
} from "../../features/Profile";
import {
  PinPointLocation,
  useInitValues,
  createAddressModel as createAddressModelFromPinpoint,
} from "../../features/Preferences/PinPointLocation";
import { getAddressPoint } from "../../models/address";
import EditProfileForm from "../../components/organisms/EditProfileForm";
import ProfilePage from "../../components/templates/ProfilePage";
import Alert from "../../components/atoms/Alert";
import { EDIT_PINPOINT_ADDRESS } from "../../constants/analytics";

function DeliveryAddressPinPoint() {
  const auth = useAuth();
  const dispatch = useDispatch();
  const overlay = useOverlay();
  const toaster = useToaster();
  const { udprn } = useParams();
  const tracker = useTracker();

  const consumerAddresses = useSelector(
    ConsumerAddressesSelector.getConsumerAddresses
  );
  const consumerAddress = consumerAddresses.find(
    (address) => address.udprn === udprn
  );
  const addressPoint = useMemo(
    () => getAddressPoint(consumerAddress),
    [consumerAddress]
  );
  const values = useMemo(
    () => ({
      udprn:
        (consumerAddress.pinpoint?.longitude &&
          consumerAddress.pinpoint?.latitude &&
          consumerAddress.udprn) ||
        null,
      what3words: consumerAddress.pinpoint?.what3words,
      lngLat: {
        lng: consumerAddress.pinpoint?.longitude,
        lat: consumerAddress.pinpoint?.latitude,
      },
    }),
    [consumerAddress]
  );
  const initialValues = useInitValues(values);
  const handleSavePinPointLocation = useCallback(
    async (values) => {
      try {
        overlay.show();
        tracker.logEvent(EDIT_PINPOINT_ADDRESS.ON_CLICK_SAVE);

        await dispatch(
          ConsumerAddressesActions.updateAddress([
            auth.currentSession.uid,
            { udprn, pinpoint: createAddressModelFromPinpoint(values) },
          ])
        ).unwrap();

        toaster.showSuccess({
          body: M.CHANGES_SAVED,
          autoHide: true,
        });
      } catch (e) {
        toaster.showError({
          body: e.message,
          reload: e.reload,
        });
      } finally {
        overlay.hide();
      }
    },
    [udprn, auth, dispatch, overlay, toaster, tracker]
  );

  return (
    <Trackable
      loadId={EDIT_PINPOINT_ADDRESS.LOAD}
      interfaceId={EDIT_PINPOINT_ADDRESS.INTERFACE_ID}
    >
      <ProfilePage
        title={M.PINPOINT_ADDRESS}
        subtitle={M.PINPOINT_ADDRESS_MESSAGE}
        initialValues={initialValues}
        showBackButtonDesktop
      >
        {!addressPoint && (
          <Alert
            variant="warning"
            title={M.PINPOINT_LOCATION_UNAVAILABLE}
            message={M.PINPOINT_LOCATION_UNAVAILABLE_MESSAGE_DETAILS}
            className="m-4"
          />
        )}

        {addressPoint && (
          <EditProfileForm
            initialValues={initialValues}
            onSubmit={handleSavePinPointLocation}
          >
            <Row className="g-0 p-4">
              <Col>
                <PinPointLocation
                  deliveryAddress={consumerAddress}
                  initialValues={initialValues}
                />
              </Col>
            </Row>
          </EditProfileForm>
        )}
      </ProfilePage>
    </Trackable>
  );
}

export default DeliveryAddressPinPoint;
