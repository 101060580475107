import classNames from "classnames";
import { Card, Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";

import { EDIT } from "../../../constants/strings";
import ButtonWithIcon from "../../atoms/ButtonWithIcon";
import styles from "./EditableCard.module.scss";

const EditableCard = ({
  onEdit,
  children,
  icon: Icon,
  title,
  className,
  classes = {},
  editable,
  variant,
  subTitle,
}) => {
  return (
    <Card
      className={classNames(
        styles.card,
        variant === "secondary" && styles.secondary,
        className,
        classes.card
      )}
    >
      <Card.Body className={styles.cardBody}>
        <Row
          className={classNames(
            "g-0",
            styles.titleContainer,
            classes.titleContainer
          )}
        >
          <Col>
            <Row className="d-flex justify-content-between g-0">
              <Col xs={10} className="d-flex">
                <Card.Title
                  className={classNames(
                    "align-items-center",
                    styles.title,
                    classes.title
                  )}
                >
                  {Icon && <Icon className={styles.icon} />}
                  {title}
                </Card.Title>
              </Col>
              {editable && (
                <Col xs={2} className="d-text text-end">
                  <ButtonWithIcon
                    label={EDIT}
                    variant="outline-danger"
                    className={styles.button}
                    onClick={onEdit}
                  />
                </Col>
              )}
            </Row>
            {variant === "secondary" && subTitle && (
              <Row>
                <Col>{subTitle}</Col>
              </Row>
            )}
          </Col>
        </Row>
        <Row className={classNames("g-0", classes.contentContainer)}>
          <Col className={classNames("g-0", styles.content)}>{children}</Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

EditableCard.defaultProps = {
  editable: true,
  variant: "primary",
};

EditableCard.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  onEdit: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  icon: PropTypes.func,
  classes: PropTypes.object,
  editable: PropTypes.bool,
  variant: PropTypes.string,
  subTitle: PropTypes.string,
};

export default EditableCard;
