import classNames from "classnames";
import PropTypes from "prop-types";
import { useForm, useFormState } from "react-final-form";
import { Button, Col, Row } from "react-bootstrap";
import { noop } from "lodash";

import * as M from "../../constants/strings";
import { SETUP_LATER } from "../../constants/strings";
import { useWizard } from "../../features/Wizard";
import LinkButton from "../../components/atoms/LinkButton";

const NavigationBar = ({ required, onClickSkip, onClickBack }) => {
  const { currentStep, previousStep, totalSteps } = useWizard();
  const form = useForm();
  const formState = useFormState();

  return (
    <Row className="g-0 d-flex justify-content-between align-items-center">
      <Col xs="auto" className={classNames({ invisible: !currentStep })}>
        <Button
          variant="outline-danger"
          onClick={() => {
            previousStep();
            onClickBack();
          }}
        >
          {M.BACK}
        </Button>
      </Col>

      {!required && (
        <Col className="d-flex justify-content-center">
          <Col className="d-flex justify-content-center">
            <LinkButton onClick={onClickSkip}>{SETUP_LATER}</LinkButton>
          </Col>
        </Col>
      )}
      <Col xs="auto">
        <Button
          variant="danger"
          disabled={formState.invalid || formState.submitting}
          onClick={() => form.submit()}
        >
          {currentStep + 1 === totalSteps ? M.CONFIRM : M.NEXT}
        </Button>
      </Col>
    </Row>
  );
};

NavigationBar.propTypes = {
  required: PropTypes.bool,
  onClickSkip: PropTypes.func,
  onClickBack: PropTypes.func,
};

NavigationBar.defaultProps = {
  required: false,
  onClickBack: noop,
};

export default NavigationBar;
