import React, { useCallback } from "react";
import { Field } from "react-final-form";
import { Button, ButtonToolbar, Col, Container, Row } from "react-bootstrap";

import { useAuth } from "../../features/Auth";
import { Fields } from "../../constants/forms";
import { Formatters, Validators } from "../../utils";
import { useOverlay } from "../../features/Overlay";
import { ConsumerSelector } from "../../features/Profile";
import { StepPropType, WizardForm } from "../../features/Wizard";
import * as M from "../../constants/strings";
import Input from "../../components/atoms/Input";
import { useToaster } from "../../features/Toaster";
import { useSelector } from "react-redux";

const VerifyPhone = ({ nextStep, setValues, values }) => {
  const auth = useAuth();
  const overlay = useOverlay();
  const toaster = useToaster();
  const consumer = useSelector(ConsumerSelector.getConsumer);

  const onSubmit = useCallback(
    async (formValues) => {
      try {
        overlay.show();
        setValues({
          ...values,
          ...formValues,
        });
        const verificationId = await auth
          .verifyUpdatePhoneNumber(formValues[Fields.PHONE_NUMBER])
          .unwrap();
        nextStep({ verificationId, pin: null, resendPinTimer: 60 });
      } catch (e) {
        toaster.showError({
          body: e.message,
        });
      } finally {
        overlay.hide();
      }
    },
    [overlay, auth, values, nextStep, setValues, toaster]
  );

  return (
    <WizardForm onSubmit={onSubmit}>
      {(formProps) => (
        <Container fluid className="g-0">
          <Row className="g-0">
            <Col>
              <Field
                name={Fields.PHONE_NUMBER}
                type="tel"
                component={Input}
                formatOnBlur={true}
                format={Formatters.formatUKPhoneNumber}
                label={M.ENTER_MOBILE_NUMBER}
                placeholder={M.MOBILE_NUMBER}
                maxLength={15}
                keyboardType="numeric"
                validate={Validators.composeValidators(
                  Validators.required,
                  Validators.phoneNumber,
                  Validators.differentTo(
                    consumer.phoneNumber,
                    M.THIS_NUMBER_IS_ALREADY_IN_USE
                  )
                )}
                required
              />
            </Col>
          </Row>
          <Row className="g-0">
            <Col xs={12}>
              <ButtonToolbar className="justify-content-end">
                <Button
                  variant="danger"
                  type="submit"
                  disabled={formProps.submitting || formProps.invalid}
                >
                  {M.NEXT}
                </Button>
              </ButtonToolbar>
            </Col>
          </Row>
        </Container>
      )}
    </WizardForm>
  );
};

VerifyPhone.propTypes = StepPropType;

export default VerifyPhone;
