export const SHORTEST_VALID_UK_PHONE_NUMBER_REGEXP = /[^\d{7}]?(7[0-9]{9})$/;

export const PHONE_NUMBER_REGEXP = /^(\+44|0044)?0?7[0-9]{9}$/;

export const UK_POST_CODE_FULL =
  /^(GIR[ ]?0AA)|([a-zA-Z]{1,2}(([0-9]{1,2})|([0-9][a-zA-Z]))[ ]?[0-9][a-zA-Z]{2})$/;

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const WHAT_3_WORDS_PATTERN = /^[a-zA-Z]+\.[a-zA-Z]+\.[a-zA-Z]+$/;
