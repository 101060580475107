import { useCallback, useMemo, useRef } from "react";
import classNames from "classnames";
import { find, noop } from "lodash";
import PropTypes from "prop-types";
import "mapbox-gl/dist/mapbox-gl.css";
import Map, { Marker } from "react-map-gl";
import { useBreakpoint } from "use-breakpoint";

import {
  LocationPropType,
  PickupPointPropType,
  PickupPointsPropType,
} from "../../../constants/propType";
import { BREAKPOINTS } from "../../../constants/ui";
import {
  calculateMapFitBounds,
  getPickupLocationCode,
} from "../../../utils/pickupPoint";
import HomePin from "../../atoms/icons/HomePin";
import PickupPin from "../../atoms/icons/PickupPin";
import styles from "./PickupLocationsMap.module.scss";

const { REACT_APP_MAPBOX_API_TOKEN } = process.env;

const PickupShop = ({
  pickupShop,
  number,
  interactive,
  onPickupClick,
  isSelected,
  isActive,
}) => {
  const onClick = useCallback(
    () => onPickupClick(pickupShop),
    [onPickupClick, pickupShop]
  );

  return (
    <Marker
      latitude={pickupShop.pickupLocation.addressPoint.latitude}
      longitude={pickupShop.pickupLocation.addressPoint.longitude}
      key={pickupShop.pickupLocationCode}
      anchor="bottom"
      onClick={onClick}
      style={{ zIndex: (isSelected && 2) || (isActive && 3) || 1 }}
    >
      <PickupPin
        className={classNames(
          styles.pickupPin,
          (isSelected && "text-danger") || (isActive && "text-dark"),
          interactive && styles.interactivePickupPin
        )}
        label={number}
      />
    </Marker>
  );
};

const PickupLocationsMap = ({
  pickupLocations = [],
  selectedPickupPoint,
  activePickupPoint,
  currentLocation,
  onPickupPinClick,
  mapPadding,
  interactive,
}) => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const selectedPickupLocationCode = getPickupLocationCode(selectedPickupPoint);
  const activePickupLocationCode = getPickupLocationCode(activePickupPoint);
  const fitSelectedPickup =
    selectedPickupPoint &&
    find(pickupLocations, [
      "pickupLocation.pickupLocationCode",
      selectedPickupLocationCode,
    ]);
  const fitActivePickup =
    activePickupPoint &&
    find(pickupLocations, [
      "pickupLocation.pickupLocationCode",
      activePickupLocationCode,
    ]);
  const MapRef = useRef();
  const defaultMapPadding =
    breakpoint === "mobile"
      ? { top: 86, bottom: 60, left: 40, right: 40 }
      : { top: 106, bottom: 80, left: 60, right: 60 };

  const pickupPointsToFit =
    fitSelectedPickup || fitActivePickup
      ? [
          fitSelectedPickup && selectedPickupPoint,
          fitActivePickup && activePickupPoint,
        ].filter((pickupPoint) => pickupPoint)
      : pickupLocations;

  const fitBounds = calculateMapFitBounds({
    currentLocation,
    pickupLocations: pickupPointsToFit,
  });

  const pickupMapPadding = mapPadding || defaultMapPadding;

  const initialState = useMemo(
    () => ({
      bounds: fitBounds,
      fitBoundsOptions: {
        padding: pickupMapPadding,
      },
    }),
    []
  );

  useMemo(() => {
    if (MapRef.current) {
      MapRef.current.fitBounds(fitBounds, {
        padding: pickupMapPadding,
        duration: 2000,
      });
    }
  }, [fitBounds, pickupMapPadding]);

  return (
    <Map
      initialViewState={initialState}
      mapStyle="mapbox://styles/mapbox/streets-v11" // eslint-disable-line react/style-prop-object
      mapboxAccessToken={REACT_APP_MAPBOX_API_TOKEN}
      attributionControl={false}
      interactive={interactive}
      ref={MapRef}
      fitBounds={fitBounds}
      style={{
        display: "flex",
        flex: 1,
      }}
    >
      {currentLocation && (
        <Marker
          longitude={currentLocation.longitude}
          latitude={currentLocation.latitude}
          anchor="bottom"
        >
          <HomePin className={styles.currentLocation} />
        </Marker>
      )}

      {pickupLocations.map((pickupPoint, index) => {
        const pickupLocationCode = getPickupLocationCode(pickupPoint);

        return (
          <PickupShop
            key={pickupLocationCode}
            pickupShop={pickupPoint}
            number={pickupPoint.number || index + 1}
            onPickupClick={onPickupPinClick}
            interactive={interactive}
            isSelected={pickupLocationCode === selectedPickupLocationCode}
            isActive={pickupLocationCode === activePickupLocationCode}
          />
        );
      })}
    </Map>
  );
};

PickupShop.propTypes = {
  pickupShop: PropTypes.object.isRequired,
  number: PropTypes.number,
  interactive: PropTypes.bool,
  onPickupClick: PropTypes.func,
  isSelected: PropTypes.bool,
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

PickupLocationsMap.propTypes = {
  currentLocation: LocationPropType.isRequired,
  pickupLocations: PickupPointsPropType.isRequired,
  selectedPickupPoint: PickupPointPropType,
  activePickupPoint: PickupPointPropType,
  onPickupPinClick: PropTypes.func,
  mapPadding: PropTypes.object,
  interactive: PropTypes.bool,
};

PickupLocationsMap.defaultProps = {
  interactive: true,
  onPickupPinClick: noop,
};

export default PickupLocationsMap;
