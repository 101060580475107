import classNames from "classnames";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./ConfirmDetails.module.scss";
import {
  PersonalInformationCard,
  DeliveryAddressCard,
  NeighboursCard,
  PickupPointCard,
  PinPointLocationCard,
  SafePlaceCard,
} from "../ConfirmDetailsCards";
import FirstDeliveryAttempt from "../FirstDeliveryAttempt";

const ConfirmDetails = ({ data, onEditClick }) => (
  <Container fluid className={classNames("p-md-4", styles.container)}>
    <FirstDeliveryAttempt data={data} classes={styles} />
    <hr className="mb-4 mt-0" />
    <Row>
      <Col lg={6} xxl={4} className="d-flex justify-content-lg-end mb-4">
        <PersonalInformationCard
          classes={styles}
          onEditClick={onEditClick}
          data={data.personalInformation}
        />
      </Col>
      <Col lg={6} xxl={4} className="d-flex justify-content-xxl-center mb-4">
        <DeliveryAddressCard
          classes={styles}
          data={data.deliveryAddress}
          onEditClick={onEditClick}
        />
      </Col>
      <Col
        lg={6}
        xxl={4}
        className="d-flex justify-content-lg-end justify-content-xxl-start mb-4"
      >
        <PinPointLocationCard
          classes={styles}
          available={data.isPinpointLocationAvailable}
          data={data.pinpointLocation}
          onEditClick={onEditClick}
        />
      </Col>
      <Col lg={6} xxl={4} className="d-flex justify-content-xxl-end mb-4">
        <NeighboursCard
          classes={styles}
          data={data.neighbours}
          available={data.isNeighbourAddressesAvailable}
          onEditClick={onEditClick}
        />
      </Col>
      <Col
        lg={6}
        xxl={4}
        className="d-flex justify-content-lg-end justify-content-xxl-center mb-4"
      >
        <SafePlaceCard
          classes={styles}
          data={data.safePlace}
          onEditClick={onEditClick}
        />
      </Col>
      <Col lg={6} xxl={4} className="d-flex justify-content-xxl-start mb-4">
        <PickupPointCard
          classes={styles}
          available={data.isPickupPointsAvailable}
          data={data.pickupPoint}
          onEditClick={onEditClick}
        />
      </Col>
    </Row>
  </Container>
);

export default ConfirmDetails;
