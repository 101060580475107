import React, { useCallback } from "react";
import { Button, ButtonToolbar, Col, Row } from "react-bootstrap";
import { Trackable, useTracker } from "@dpdgroupuk/react-event-tracker";

import { WizardForm } from "../../features/Wizard";
import { Field } from "react-final-form";
import * as M from "../../constants/strings";
import Checkbox from "../../components/atoms/Checkbox";
import Tick from "../../components/atoms/icons/Tick";
import Cross from "../../components/atoms/icons/Cross";
import Container from "../../components/templates/Login/LoginContainer";
import { useOverlay } from "../../features/Overlay";
import { useToaster } from "../../features/Toaster";
import { TERMS_AND_CONDITIONS } from "../../constants/analytics";
import { useDispatch } from "react-redux";
import { ProfileActions } from "../../features/Profile";

const DPD_WILL_USE_DATA_FOR = [
  "To keep you informed about your parcels",
  "Ensure our services continue to meet your needs",
  "For your parcel preferences to be stored for a great delivery experience",
];

const DPD_WILL_NEVER_USE_DATA_FOR = [
  "Sell your data to any third parties",
  "Keep your data for longer than what is needed",
  "Use your data to send you marketing information",
];

const StyledListItem = ({ icon: Icon, text }) => (
  <li className="d-flex mb-2">
    <i>
      <Icon />
    </i>
    <span className="ms-4 text-secondary">{text}</span>
  </li>
);

const TermsAndConditions = ({ nextStep }) => {
  const overlay = useOverlay();
  const toaster = useToaster();
  const tracker = useTracker();
  const dispatch = useDispatch();

  const handleBackClick = useCallback(async () => {
    tracker.logEvent(TERMS_AND_CONDITIONS.ON_BACK);

    try {
      overlay.show();
      await dispatch(ProfileActions.logout());
    } catch (e) {
      toaster.showError({
        body: e.message,
      });
    } finally {
      overlay.hide();
    }
  }, [overlay, toaster, tracker, dispatch]);

  const handleSubmit = useCallback(() => {
    tracker.logEvent(TERMS_AND_CONDITIONS.ON_NEXT);
    nextStep();
  }, [nextStep, tracker]);

  return (
    <Trackable
      loadId={TERMS_AND_CONDITIONS.LOAD}
      interfaceId={TERMS_AND_CONDITIONS.INTERFACE_ID}
    >
      <WizardForm initialValues={{ agree: false }} onSubmit={handleSubmit}>
        {({ values }) => (
          <Container>
            <Row>
              <Col>
                <p className="fw-bold">This is how DPD will use your data:</p>
                <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
                  {DPD_WILL_USE_DATA_FOR.map((reason, index) => (
                    <StyledListItem icon={Tick} text={reason} />
                  ))}
                </ul>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="fw-bold">We will never use your data to:</p>
                <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
                  {DPD_WILL_NEVER_USE_DATA_FOR.map((reason, index) => (
                    <StyledListItem icon={Cross} text={reason} />
                  ))}
                </ul>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="text-secondary">
                  Please see our{" "}
                  <a
                    href="https://www.dpd.co.uk/dpo-privacy-notice-1.jsp"
                    target="_blank"
                    rel="noreferrer"
                    className="text-decoration-none"
                  >
                    Privacy Notice
                  </a>{" "}
                  and{" "}
                  <a
                    href={"https://www.dpd.co.uk/ts-and-cs.jsp"}
                    target="_blank"
                    rel="noreferrer"
                    className="text-decoration-none"
                  >
                    Terms & Conditions
                  </a>{" "}
                  for more details on how your personal data will be used.
                </p>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-start">
                <Field
                  name="agree"
                  component={Checkbox}
                  label="I agree to the Terms & Conditions"
                  type="checkbox"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <ButtonToolbar className="justify-content-between justify-content-lg-end">
                  <Button
                    variant="outline-danger"
                    className="me-3"
                    onClick={handleBackClick}
                  >
                    {M.BACK}
                  </Button>
                  <Button
                    variant="danger"
                    type="submit"
                    disabled={!values.agree}
                  >
                    {M.NEXT}
                  </Button>
                </ButtonToolbar>
              </Col>
            </Row>
          </Container>
        )}
      </WizardForm>
    </Trackable>
  );
};

export default TermsAndConditions;
