const FacePensive = (props) => {
  return (
    <svg
      width="4rem" // 64px
      height="4rem"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_2282_173961"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="64"
        height="64"
      >
        <rect width="64" height="64" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2282_173961)">
        <path
          d="M37 45.3333C37.9167 45.3333 38.6667 46.0833 38.6667 47C38.6667 47.9167 37.9167 48.6667 37 48.6667H27C26.0834 48.6667 25.3334 47.9167 25.3334 47C25.3334 46.0833 26.0834 45.3333 27 45.3333H37ZM48 33.1667C48.7396 33.7188 48.8855 34.7604 48.3334 35.5C45.1667 39.7188 38.8334 39.7188 35.6667 35.5C35.1146 34.7604 35.2605 33.7188 36 33.1667C36.7396 32.6146 37.7813 32.7604 38.3334 33.5C40.1667 35.9479 43.8334 35.9479 45.6667 33.5C46.2188 32.7604 47.2605 32.6146 48 33.1667ZM18.3334 33.5C20.1667 35.9479 23.8334 35.9479 25.6667 33.5C26.2188 32.7604 27.2605 32.6146 28 33.1667C28.7396 33.7188 28.8855 34.7604 28.3334 35.5C25.1667 39.7188 18.8334 39.7188 15.6667 35.5C15.1146 34.7604 15.2636 33.7188 16 33.1667C16.7396 32.6146 17.7813 32.7604 18.3334 33.5ZM13.8427 27.75C13.4313 26.9271 13.7646 25.9167 14.5875 25.5104L16.9688 24.3229C18.4792 23.5625 19.8021 22.4896 20.8646 21.1771L22.3646 19.2917C22.9375 18.5729 23.9896 18.3646 24.6146 19.0313C25.4271 19.6042 25.5417 20.6563 24.9688 21.2813L23.4688 23.25C22.1042 24.9479 20.3959 26.3333 18.4584 27.3021L16.0834 28.4896C15.2552 28.9063 14.2542 28.5729 13.8427 27.75ZM49.4167 25.5104C50.2396 25.9167 50.573 26.9271 50.1563 27.75C49.75 28.5729 48.7396 28.9063 47.9167 28.4896L45.448 27.3021C43.6042 26.3333 41.8021 24.9479 40.5313 23.25L39.0313 21.2813C38.3646 20.6563 38.573 19.6042 39.2917 19.0313C40.0105 18.3646 40.9688 18.5729 41.6355 19.2917L43.1355 21.1771C44.198 22.4896 45.5209 23.5625 47.0313 24.3229L49.4167 25.5104ZM58.6667 32C58.6667 46.7292 46.7292 58.6667 32 58.6667C17.2709 58.6667 5.33337 46.7292 5.33337 32C5.33337 17.2708 17.2709 5.33334 32 5.33334C46.7292 5.33334 58.6667 17.2708 58.6667 32ZM32 8.66667C19.1146 8.66667 8.66671 19.1146 8.66671 32C8.66671 44.8854 19.1146 55.3333 32 55.3333C44.8855 55.3333 55.3334 44.8854 55.3334 32C55.3334 19.1146 44.8855 8.66667 32 8.66667Z"
          fill="#414042"
        />
      </g>
    </svg>
  );
};

export default FacePensive;
