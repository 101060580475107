import { useSelector } from "react-redux";
import { Navigate, useParams } from "react-router";
import { find, isEmpty } from "lodash";
import { ConsumerAddressesSelector } from "../../features/Profile";
import { Paths } from "../../router";

const ProtectedAddressRoute = ({ children }) => {
  const { udprn } = useParams();
  const addresses = useSelector(ConsumerAddressesSelector.getConsumerAddresses);

  if (addresses && !isEmpty(addresses) && !find(addresses, ["udprn", udprn])) {
    return <Navigate to={Paths.PROFILE_ADDRESSES} replace />;
  }

  return children;
};

export default ProtectedAddressRoute;
