import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { createWizardSlice } from "../../features/Wizard";

export const OnboardingSlice = createWizardSlice("onboarding");

export const OnBoardingSelectors = OnboardingSlice.selectors;

export const OnBoardingReducer = persistReducer(
  {
    key: "onboarding",
    storage: storage,
  },
  OnboardingSlice.reducer
);
