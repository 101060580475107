import { useCallback } from "react";

import { pick } from "lodash";
import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Trackable, useTracker } from "@dpdgroupuk/react-event-tracker";

import ImageDropzone from "../../components/molecules/ImageDropzone";
import Form from "../../components/organisms/EditProfileForm";
import Page from "../../components/templates/ProfilePage";
import { EDIT_ADD_PHOTO } from "../../constants/analytics";
import { Fields } from "../../constants/forms";
import {
  ACCEPT_IMAGE_MIME_TYPE,
  MAX_FILE_SIZE,
} from "../../constants/safePlace";
import {
  ACCEPT_SAFE_PLACE_IMAGE_HINT,
  ADD_PHOTO,
  ADD_YOUR_PROPERTY_PHOTO,
  CHANGES_SAVED,
  DRAG_AND_DROP_FILE_HERE,
  UNABLE_TO_LOAD_IMAGE,
  YOUR_PROPERTY_IMAGE,
} from "../../constants/strings";
import { useAuth } from "../../features/Auth";
import { useOverlay } from "../../features/Overlay";
import {
  ConsumerAddressesActions,
  ConsumerAddressesSelector,
} from "../../features/Profile";
import { uploadAddressPhoto } from "../../features/Profile/Addresses/service";
import { useToaster } from "../../features/Toaster";

const AddPhoto = () => {
  const auth = useAuth();
  const { udprn } = useParams();
  const overlay = useOverlay();
  const toaster = useToaster();
  const dispatch = useDispatch();
  const tracker = useTracker();

  const address = useSelector(
    ConsumerAddressesSelector.getConsumerAddressById(udprn)
  );

  const onClickBack = useCallback(() => {
    tracker.logEvent(EDIT_ADD_PHOTO.ON_CLICK_BACK);
  }, [tracker]);

  const handleSubmit = useCallback(
    async (values) => {
      try {
        overlay.show();
        tracker.logEvent(EDIT_ADD_PHOTO.ON_CLICK_SAVE);

        if (values.imageUrl) {
          values.imageUrl = await uploadAddressPhoto(
            auth.currentSession.uid,
            udprn,
            values.imageUrl
          );
        }
        await dispatch(
          ConsumerAddressesActions.updateAddress([
            auth.currentSession.uid,
            { udprn, imageUrl: values.imageUrl },
          ])
        ).unwrap();

        toaster.showSuccess({
          body: CHANGES_SAVED,
          autoHide: true,
        });
      } catch (e) {
        toaster.showError({
          body: e.message,
          reload: e.reload,
        });
      } finally {
        overlay.hide();
      }
    },
    [udprn, auth, dispatch, overlay, toaster, tracker]
  );

  const handleLoadImageError = useCallback(
    (err, form) => {
      form.change(Fields.IMAGE_URL, null);
      toaster.showError({
        body: UNABLE_TO_LOAD_IMAGE,
      });
    },
    [toaster]
  );

  return (
    <Trackable
      loadId={EDIT_ADD_PHOTO.LOAD}
      interfaceId={EDIT_ADD_PHOTO.INTERFACE_ID}
    >
      <Page
        title={ADD_PHOTO}
        subtitle={ADD_YOUR_PROPERTY_PHOTO}
        onClickBack={onClickBack}
        showBackButtonDesktop
      >
        <Form
          initialValues={pick(address, [Fields.IMAGE_URL])}
          onSubmit={handleSubmit}
        >
          {({ form }) => (
            <Row className="g-0 p-4">
              <Col>
                <Field
                  name={Fields.IMAGE_URL}
                  component={ImageDropzone}
                  accept={ACCEPT_IMAGE_MIME_TYPE}
                  maxSize={MAX_FILE_SIZE} // 10Mb
                  title={YOUR_PROPERTY_IMAGE}
                  hint={ACCEPT_SAFE_PLACE_IMAGE_HINT}
                  placeholder={DRAG_AND_DROP_FILE_HERE}
                  onLoadImageError={(err) => handleLoadImageError(err, form)}
                />
              </Col>
            </Row>
          )}
        </Form>
      </Page>
    </Trackable>
  );
};

export default AddPhoto;
