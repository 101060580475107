import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { getConfig } from "./selectors";
import { setConfig } from "./actions";
import { remoteConfig } from "../../firebase";
import { fetchAndActivate, getAll } from "firebase/remote-config";
import { parseAll } from "./model";
import { ConfigContext } from "./Context";

const ConfigProvider = ({ children }) => {
  const config = useSelector(getConfig);
  const dispatch = useDispatch();

  React.useEffect(() => {
    remoteConfig.defaultConfig = config;

    fetchAndActivate(remoteConfig).then(() => {
      const newConfig = parseAll(getAll(remoteConfig));
      return dispatch(setConfig(newConfig));
    });
  }, []);

  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
};

export default ConfigProvider;
