export const BREAKPOINTS = {
  mobile: 0,
  tablet: 768,
  desktop: 1200,
};

export const TOAST_POSITION = {
  mobile: "top-center",
  tablet: "bottom-end",
  desktop: "bottom-end",
};
