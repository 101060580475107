import { useCallback, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { cloneDeep, get, set } from "lodash";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { locationsApis } from "../../apis";
import Logout from "../../components/atoms/icons/Logout/Logout";
import { Loader } from "../../components/molecules/Loader";
import AddressesCardsList from "../../components/molecules/AddressesCardsList";
import EditInfoCard from "../../components/molecules/EditInfoCard";
import {
  ACCOUNT_OVERVIEW,
  QUICK_OVERVIEW_OF_YOUR_ACCOUNT,
  SIGN_OUT,
} from "../../constants/strings";
import { useOverlay } from "../../features/Overlay";
import { ProfileSelectors, ProfileActions } from "../../features/Profile";
import { getAddressPoint } from "../../models/address";
import { Paths } from "../../router";
import { getPickupName } from "../../utils/pickupPoint";
import styles from "./AccountOverview.module.scss";
import { useToaster } from "../../features/Toaster";
import { Trackable, useTracker } from "@dpdgroupuk/react-event-tracker";
import { ACCOUNT_OVERVIEW as AO } from "../../constants/analytics";

const Header = ({ onClickSignOut }) => (
  <Row className={classNames("g-0 p-4", styles.header)}>
    <Col>
      <h5>{ACCOUNT_OVERVIEW}</h5>
      <span>{QUICK_OVERVIEW_OF_YOUR_ACCOUNT}</span>
    </Col>
    <Col className="d-flex justify-content-end ms-3" xs="auto">
      <Button
        variant="link"
        className="p-0 d-flex align-items-center text-decoration-none"
        onClick={onClickSignOut}
      >
        <p className="m-0 d-none d-md-block">{SIGN_OUT}</p>
        <Logout className="ms-2" />
      </Button>
    </Col>
  </Row>
);

Header.propTypes = {
  onClickSignOut: PropTypes.func,
};

function AccountOverview() {
  const overlay = useOverlay();
  const navigate = useNavigate();
  const toaster = useToaster();
  const tracker = useTracker();
  const dispatch = useDispatch();
  const profile = useSelector(ProfileSelectors.getProfile);
  const fullName = [
    get(profile, "consumer.firstName"),
    get(profile, "consumer.lastName"),
  ].join(" ");
  const phoneNumber = get(profile, "consumer.phoneNumber");
  const emails = get(profile, "emails", []);
  const profileAddresses = get(profile, "addresses", []);
  const [addresses, setAddresses] = useState(profileAddresses);

  const loadPickupShopsData = useCallback(async () => {
    const addressesCopy = cloneDeep(addresses);

    await Promise.all(
      addressesCopy.map(async (address) => {
        const pickupLocationCode = get(
          address,
          "preferences.pickupLocationCode"
        );

        if (pickupLocationCode) {
          const { data } = await locationsApis.getPickupLocationsById(
            pickupLocationCode
          );
          const pickupName = getPickupName({ pickupLocation: data });

          set(address, "preferences.pickupName", pickupName);
        }

        const addressPoint = getAddressPoint(address);

        if (!addressPoint) {
          set(address, "pinpoint.unavailable", true);
        }

        return address;
      })
    );

    setAddresses(addressesCopy);
  }, []);

  const handleSignOutClick = useCallback(
    overlay.showWhile(async () => {
      try {
        tracker.logEvent(AO.ON_CLICK_SIGN_OUT);
        await dispatch(ProfileActions.logout());
      } catch (e) {
        toaster.showError({
          body: e.message,
        });
      }
    }),
    [toaster, tracker, dispatch]
  );

  return (
    <Loader promiseFn={loadPickupShopsData}>
      <Trackable loadId={AO.LOAD} interfaceId={AO.INTERFACE_ID}>
        <Row className="g-0">
          <Col className={styles.container}>
            <Header onClickSignOut={handleSignOutClick} />
            <hr className="m-0" />

            <Row className={classNames("g-0 p-4", styles.content)}>
              <Col>
                <EditInfoCard
                  fullName={fullName}
                  phoneNumber={phoneNumber}
                  emails={emails}
                  onEdit={() => {
                    tracker.logEvent(AO.ON_CLICK_PERSONAL_INFORMATION_EDIT);
                    navigate(Paths.PROFILE_PERSONAL_INFO);
                  }}
                  className="g-0 mb-4 mb-lg-0"
                />
                <hr className="d-none d-lg-block" />
                <AddressesCardsList
                  addresses={addresses}
                  onEdit={(address) => {
                    tracker.logEvent(AO.ON_CLICK_YOUR_DELIVERY_ADDRESSES_EDIT);
                    navigate(`/profile/addresses/${address.udprn}`);
                  }}
                  onAddNewAddress={() => {
                    tracker.logEvent(AO.ON_CLICK_NEW_DELIVERY_ADDRESS);
                    navigate("/profile/addresses/add");
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Trackable>
    </Loader>
  );
}

export default AccountOverview;
