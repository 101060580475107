import { v4 as uuidv4 } from "uuid";
import pacakgeJson from "../../../../package.json";
import * as M from "../../../constants/strings";
import { find } from "lodash";

export const createEmailModel = (email, verified = false) => ({
  id: uuidv4(),
  email,
  platform: pacakgeJson.name,
  verified,
});

export const validateEmailOnAdd = (email, emails) => {
  if (emails.length >= 5) {
    throw new Error(M.ADD_FIVE_EMAILS);
  } else if (find(emails, ["email", email])) {
    throw new Error(M.THIS_EMAIL_ADDED);
  }
};
