import { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { Button, ButtonToolbar, Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useNavigate } from "react-router-dom";
import { OnBlur } from "react-final-form-listeners";
import { Trackable, useTracker } from "@dpdgroupuk/react-event-tracker";

import EmailOnLaptop from "../../components/atoms/icons/EmailOnLaptop";
import Input from "../../components/atoms/Input";
import ContentHeader from "../../components/molecules/ContentHeader";
import Container from "../../components/templates/Login/LoginContainer";
import { Fields } from "../../constants/forms";
import * as M from "../../constants/strings";
import { EMAIL_ADDRESS } from "../../constants/strings";
import { useAuth } from "../../features/Auth";
import { useOverlay } from "../../features/Overlay";
import { StepPropType, WizardForm } from "../../features/Wizard";
import { Paths } from "../../router";
import { Validators } from "../../utils";
import * as Formatters from "../../utils/formatters";
import { ENTER_EMAIL } from "../../constants/analytics";

const EnterEmail = ({ expired, nextStep }) => {
  const auth = useAuth();
  const overlay = useOverlay();
  const navigate = useNavigate();
  const [submitError, setSubmitError] = useState(null);
  const tracker = useTracker();

  const onSubmit = useCallback(
    async (formValues) => {
      tracker.logEvent(ENTER_EMAIL.ON_NEXT);

      try {
        overlay.show();
        await auth.sendMagicLink(formValues.email).unwrap();
        nextStep({
          sentEmails: 1,
        });
      } catch (e) {
        setSubmitError(e.message);
      } finally {
        overlay.hide();
      }
    },
    [overlay, auth, nextStep, tracker]
  );

  const onBackClick = useCallback(() => {
    tracker.logEvent(ENTER_EMAIL.ON_BACK);

    if (expired) {
      navigate(Paths.SIGN_IN);
    } else {
      navigate(-1);
    }
  }, [expired, navigate, tracker]);

  return (
    <Trackable loadId={ENTER_EMAIL.LOAD} interfaceId={ENTER_EMAIL.INTERFACE_ID}>
      <WizardForm onSubmit={onSubmit}>
        {({ submitting, invalid }) => (
          <Container>
            <ContentHeader
              fluid
              title={
                expired
                  ? "Sorry, but it looks like the link you’re trying to use has now expired"
                  : "Sign in using your email address"
              }
              subtitle={
                expired
                  ? "Not to worry as we can quickly send you a new magic link so that you can sign back into your DPD preferences"
                  : "By choosing to sign in using your email address, we'll just need to check if you already have an account or we'll give you the option to quickly create a new one."
              }
              icon={EmailOnLaptop}
              classes={{ container: "text-start", title: "my-2" }}
            />
            <Row>
              <Col xs={12}>
                <Field
                  type="email"
                  component={Input}
                  name={Fields.EMAIL}
                  label={"Enter your email address"}
                  placeholder={`${EMAIL_ADDRESS} *`}
                  maxLength={255}
                  formatOnBlur
                  format={Formatters.formatEmail}
                  validate={Validators.composeValidators(
                    Validators.required,
                    Validators.email,
                    Validators.maxLength(255)
                  )}
                  required
                />
                <OnBlur name={Fields.EMAIL}>
                  {() => {
                    tracker.logEvent(ENTER_EMAIL.ON_INPUT);
                  }}
                </OnBlur>
              </Col>
            </Row>
            {submitError && (
              <Row>
                <Col>
                  <p className="text-danger">{submitError}</p>
                </Col>
              </Row>
            )}
            <Row>
              <Col xs={12}>
                <ButtonToolbar className="justify-content-between justify-content-lg-end">
                  <Button
                    variant="outline-danger"
                    className="me-3"
                    onClick={onBackClick}
                  >
                    {expired ? M.BACK_TO_HOME : M.BACK}
                  </Button>
                  <Button
                    variant="danger"
                    type="submit"
                    disabled={submitting || invalid}
                  >
                    {M.NEXT}
                  </Button>
                </ButtonToolbar>
              </Col>
            </Row>
          </Container>
        )}
      </WizardForm>
    </Trackable>
  );
};

EnterEmail.defaultProps = {
  expired: false,
};

EnterEmail.propTypes = {
  expired: PropTypes.bool,
  ...StepPropType,
};

export default EnterEmail;
