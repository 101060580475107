import { Form, FormSpy } from "react-final-form";
import PropTypes from "prop-types";
import { useCallback, useMemo } from "react";
import { useWizard } from "./Context";

const WizardForm = ({
  Nav,
  subscribeOnChange,
  formClassName,
  children,
  onClickBack,
  ...formProps
}) => {
  const { setValues, values, nextStep } = useWizard();

  // NOTE: Initial values can be set after asynchrounous operation, please be aware how often you change them (better not more than once)
  const stepInitialValues = useMemo(
    () => formProps.initialValues || values,
    [formProps.initialValues]
  );

  const onChangeFormValues = useCallback(
    ({ values: formValues }) => {
      setValues(formValues);
    },
    [setValues]
  );

  const onClickSkip = useCallback(() => {
    setValues(stepInitialValues);
    nextStep();
  }, [stepInitialValues, nextStep]);

  return (
    <Form
      {...formProps}
      className={formClassName}
      initialValues={stepInitialValues}
    >
      {(fProps) => (
        <form onSubmit={fProps.handleSubmit}>
          {typeof children === "function" ? children(fProps) : children}
          {Nav && (
            <Nav
              onClickSkip={onClickSkip}
              disabledNext={fProps.submitting || fProps.invalid}
              onClickNext={() => fProps.form.submit()}
              onClickBack={onClickBack}
            />
          )}

          {subscribeOnChange && (
            <FormSpy
              subscription={{ values: true, valid: true }}
              onChange={onChangeFormValues}
            />
          )}
        </form>
      )}
    </Form>
  );
};

WizardForm.defaultProps = {
  debug: console.log,
  validateOnBlur: false,
  subscribeOnChange: true,
};

WizardForm.propTypes = {
  ...Form.propTypes,
  subscribeOnChange: PropTypes.bool,
  Nav: PropTypes.elementType,
  formClassName: PropTypes.string,
  onClickBack: PropTypes.func,
};

export default WizardForm;
