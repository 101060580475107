import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import { Col } from "react-bootstrap";
import { forwardRef } from "react";
import classNames from "classnames";

const Toggle = forwardRef(
  (
    { label, helperText, input, meta = {}, required, classes = {}, ...props },
    ref
  ) => {
    const inputLabel = required ? `${label} *` : label;

    return (
      <Form.Group
        as={Col}
        controlId={input?.name}
        className={classes.container}
      >
        <div className="d-flex justify-content-between">
          {inputLabel && (
            <Form.Label className={classes.label}>{inputLabel}</Form.Label>
          )}
          <Form.Check
            type="switch"
            id={input.name}
            ref={ref}
            {...input}
            checked={input.type === "switch" ? input.value : input.checked}
            isInvalid={meta.touched && !!meta.error}
          />
        </div>
        <span className={classNames("display-4", classes.helperText)}>
          {helperText}
        </span>
        <Form.Control.Feedback type="invalid" className="d-block">
          {meta.touched && meta.error}
        </Form.Control.Feedback>
      </Form.Group>
    );
  }
);

Toggle.propTypes = {
  ref: PropTypes.any,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  input: PropTypes.object,
  required: PropTypes.bool,
  classes: PropTypes.object,
};

export default Toggle;
