import classNames from "classnames";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";

import { PICKUP_POINT_TYPE } from "../../../constants/pickupPoint";
import * as M from "../../../constants/strings";
import FilterButton from "../../atoms/FilterButton";

const PickupFilterButtons = ({ filterBy, onFilterButtonClick, className }) => (
  <Row className={classNames("g-0 d-flex justify-content-end", className)}>
    <Col xs="auto">
      <FilterButton
        type={PICKUP_POINT_TYPE.SHOP}
        onClick={onFilterButtonClick}
        selected={filterBy === PICKUP_POINT_TYPE.SHOP}
      >
        {M.SHOPS}
      </FilterButton>
    </Col>
    <Col xs="auto" className="ms-2">
      <FilterButton
        type={PICKUP_POINT_TYPE.LOCKER}
        onClick={onFilterButtonClick}
        selected={filterBy === PICKUP_POINT_TYPE.LOCKER}
      >
        {M.LOCKERS}
      </FilterButton>
    </Col>
  </Row>
);

PickupFilterButtons.propTypes = {
  filterBy: PropTypes.string,
  onFilterButtonClick: PropTypes.func,
  className: PropTypes.string,
};

export default PickupFilterButtons;
