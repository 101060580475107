const Google = (props) => {
  return (
    <svg
      width="1.5rem"
      height="1.5rem"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.6 12.2268C21.6 11.5177 21.5364 10.8358 21.4182 10.1813H12V14.0495H17.3818C17.15 15.2995 16.4455 16.3586 15.3864 17.0677V19.5768H18.6182C20.5091 17.8359 21.6 15.2722 21.6 12.2268Z"
        fill="#4285F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0003 22.0003C14.7003 22.0003 16.9639 21.1049 18.6185 19.5776L15.3866 17.0685C14.4912 17.6685 13.3457 18.023 12.0003 18.023C9.39569 18.023 7.19114 16.2639 6.40477 13.9003H3.06385V16.4912C4.70931 19.7594 8.09114 22.0003 12.0003 22.0003Z"
        fill="#34A853"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.40458 13.8999C6.20458 13.2999 6.09094 12.659 6.09094 11.9999C6.09094 11.3408 6.20458 10.6999 6.40458 10.0999V7.50896H3.06366C2.38638 8.85896 2.00002 10.3862 2.00002 11.9999C2.00002 13.6135 2.38638 15.1408 3.06366 16.4908L6.40458 13.8999Z"
        fill="#FBBC05"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0003 5.97732C13.4684 5.97732 14.7866 6.48187 15.823 7.47278L18.6912 4.60459C16.9594 2.99094 14.6957 2.00003 12.0003 2.00003C8.09114 2.00003 4.70931 4.24095 3.06385 7.50915L6.40477 10.1001C7.19114 7.73642 9.39569 5.97732 12.0003 5.97732Z"
        fill="#EA4335"
      />
    </svg>
  );
};

export default Google;
