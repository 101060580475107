import { ReduxifyWizard } from "../../features/Wizard";
import { AddNewAddressSlice } from "./slice";
import DeliveryAddressStep from "./DeliveryAddressStep";
import PreferredNeighbourStep from "./PreferredNeighbourStep";
import SafePlaceStep from "./SafePlaceStep";
import PinpointLocationStep from "./PinpointLocationStep";
import ConfirmDetailsStep from "./ConfirmDetailsStep";
import PickupPointStep from "./PickupPointStep";

import { STEP_NAME } from "../../constants";
import Step from "./Step";

const AddNewAddress = () => {
  return (
    <ReduxifyWizard
      slice={AddNewAddressSlice}
      className="d-flex flex-column-reverse"
      isLazyMount={true}
    >
      <Step
        stepName={STEP_NAME.DELIVERY_ADDRESS}
        component={DeliveryAddressStep}
      />
      <Step
        stepName={STEP_NAME.PINPOINT_LOCATION}
        component={PinpointLocationStep}
      />
      <Step
        stepName={STEP_NAME.NEIGHBOURS}
        component={PreferredNeighbourStep}
      />
      <Step stepName={STEP_NAME.SAFE_PLACE} component={SafePlaceStep} />
      <Step stepName={STEP_NAME.PICKUP_POINT} component={PickupPointStep} />
      <Step
        stepName={STEP_NAME.CONFIRM_DETAILS}
        component={ConfirmDetailsStep}
      />
    </ReduxifyWizard>
  );
};

export default AddNewAddress;
