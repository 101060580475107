import LoginTemplate from "../../components/templates/Login";
import { ReduxifyWizard } from "../../features/Wizard";
import TermsAndConditions from "./TermsAndConditions";
import VerifyPhone from "./VerifyPhone";
import VerifyPin from "./VerifyPin";
import CreateProfile from "./CreateProfile";
import { Step } from "./components";
import { SignUpSliceSlice } from "./slice";

function Page() {
  return (
    <LoginTemplate>
      <ReduxifyWizard slice={SignUpSliceSlice} isLazyMount={true}>
        <Step stepName="terms" component={TermsAndConditions} />
        <Step stepName="phone" component={VerifyPhone} />
        <Step stepName="pin" component={VerifyPin} />
        <Step stepName="profile" component={CreateProfile} />
      </ReduxifyWizard>
    </LoginTemplate>
  );
}

export default Page;
