import { configureStore } from "@reduxjs/toolkit";
import { persistStore } from "redux-persist";

import { AuthReducer } from "../features/Auth";
import { VerifyEmailReducer } from "../pages/VerifyEmail";
import { SignUpSliceReducer } from "../pages/SignUp";
import { OnBoardingReducer } from "../pages/Onboarding";
import { ProfileReducer } from "../features/Profile";
import { EditPhoneReducer } from "../pages/EditPhone";
import { AddNewAddressReducer } from "../pages/AddNewAddress";
import { ConfigReducer } from "../features/Config";
import { sentryReduxEnhancer } from "../sentry";

export const store = configureStore({
  reducer: {
    auth: AuthReducer,
    config: ConfigReducer,
    editPhone: EditPhoneReducer,
    signUp: SignUpSliceReducer,
    verifyEmail: VerifyEmailReducer,
    onboarding: OnBoardingReducer,
    profile: ProfileReducer,
    addNewAddress: AddNewAddressReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  enhancers: [sentryReduxEnhancer],
});

export const persistor = persistStore(store);
