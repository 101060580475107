import React, { useCallback, useEffect, useRef, useState } from "react";
import { pick } from "lodash";
import PropTypes from "prop-types";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import VerifyPin from "../../components/molecules/VerifyPin";
import { Fields } from "../../constants/forms";
import * as M from "../../constants/strings";
import { useAuth } from "../../features/Auth";
import { useOverlay } from "../../features/Overlay";
import { ConsumerActions } from "../../features/Profile";
import { useToaster } from "../../features/Toaster";
import { Paths } from "../../router";
import { getVerifyPhoneValues } from "./selectors";

const VerifyPinContainer = ({ setValues, values, previousStep }) => {
  const auth = useAuth();
  const overlay = useOverlay();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toaster = useToaster();
  const phoneNumberValues = useSelector(getVerifyPhoneValues);
  const [pinInputDisabled, setPinInputsDisabled] = useState(false);
  const pinInputRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (values.resendPinTimer > 0) {
        setValues({
          ...values,
          resendPinTimer: values.resendPinTimer - 1,
        });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [values, setValues]);

  const handleSubmit = useCallback(
    async (pin) => {
      if (pin.length === 6 && values.verificationId) {
        try {
          overlay.show();
          setPinInputsDisabled(true);
          setValues({ ...values, pin });
          const { customToken } = await auth
            .confirmUpdatePhoneNumber({
              pin,
              verificationId: values.verificationId,
            })
            .unwrap();
          // re-login with custom token to get new session with updated claims
          // auth.currentSession.phone_number should be updated already
          await auth.signInWithCustomToken(customToken).unwrap();
          await dispatch(
            ConsumerActions.updateConsumer([
              auth.currentSession.uid,
              phoneNumberValues,
            ])
          ).unwrap();

          toaster.showSuccess({
            body: M.MOBILE_NUMBER_VERIFIED,
          });

          navigate(Paths.PROFILE_PERSONAL_INFO, {
            replace: true,
          });
        } catch (e) {
          toaster.showError({
            body: e.message,
          });
          pinInputRef.current?.clear();
        } finally {
          setPinInputsDisabled(false);
          overlay.hide();
        }
      }
    },
    [
      overlay,
      auth,
      values,
      setValues,
      navigate,
      phoneNumberValues,
      setPinInputsDisabled,
      toaster,
      dispatch,
    ]
  );

  const handleResendPin = useCallback(async () => {
    const verificationId = await auth
      .verifyPhoneNumber(pick(phoneNumberValues, [Fields.PHONE_NUMBER]))
      .unwrap();

    pinInputRef.current?.clear();
    pinInputRef.current?.focus();

    setValues({
      verificationId,
      pin: null,
      resendPinTimer: 60,
    });
  }, [auth, setValues, phoneNumberValues, pinInputRef]);

  return (
    <Container fluid className="g-0">
      <VerifyPin
        subtitle={M.WE_HAVE_SENT_PIN_TO_$_ENTER_THE_PIN(
          phoneNumberValues?.phoneNumber
        )}
        inputRef={pinInputRef}
        onChange={handleSubmit}
        inputDisabled={pinInputDisabled}
        resendPinIn={values.resendPinTimer}
        onResendPinClick={handleResendPin}
        onBackClick={() => previousStep()}
      />
    </Container>
  );
};

VerifyPinContainer.propTypes = {
  setValues: PropTypes.func,
  values: PropTypes.object,
  previousStep: PropTypes.func,
};

export default VerifyPinContainer;
