import { useCallback, useState } from "react";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import PropTypes from "prop-types";
import { firebaseStorage } from "../../firebase";

export const useStorageUploader = () => {
  const [isUploading, setIsUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [progress, setProgress] = useState(null);

  const upload = useCallback(
    async (path, file) => {
      const storageRef = ref(firebaseStorage, path);
      const uploadTask = uploadBytesResumable(storageRef, file);
      setIsUploading(true);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );

          // update progress
          setProgress(percent);
        },
        (error) => {
          setUploadError(error);
          setIsUploading(false);
        },
        () => {
          setIsUploading(false);
        }
      );

      const snapshot = await uploadTask;

      const url = await getDownloadURL(snapshot.ref);

      setDownloadUrl(url);
      return url;
    },
    [setProgress, setUploadError, setIsUploading]
  );

  const deleteObj = useCallback(
    async (path) => {
      try {
        setIsDeleting(true);
        const storageRef = ref(firebaseStorage, path);
        await deleteObject(storageRef);
      } finally {
        setIsDeleting(false);
      }
    },
    [setIsDeleting]
  );

  return {
    progress,
    uploadError,
    isUploading,
    downloadUrl,
    upload,
    deleteObj,
    isDeleting,
  };
};

export const StorageUploaderPropTypes = PropTypes.shape({
  progress: PropTypes.number,
  uploadError: PropTypes.string,
  isUploading: PropTypes.bool,
  downloadUrl: PropTypes.string,
  upload: PropTypes.func,
  isDeleting: PropTypes.func,
});
