const Address = (props) => {
  return (
    <svg
      width="4rem" // 64px
      height="3.9375rem" // 63px
      viewBox="0 0 64 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_99_4962)">
        <path
          d="M51.1039 32.1952C50.8668 32.1952 50.6296 32.1044 50.4489 31.9237L31.6462 13.1201L12.8444 31.9237C12.4822 32.286 11.8966 32.286 11.5344 31.9237C11.1721 31.5615 11.1721 30.9759 11.5344 30.6137L31.6462 10.5L51.759 30.6137C52.1212 30.9759 52.1212 31.5615 51.759 31.9237C51.5783 32.1044 51.3411 32.1952 51.1039 32.1952Z"
          fill="#414042"
        />
        <path
          d="M32.5727 51.9568H14.9679V32.4981C14.9679 31.9867 15.382 31.5716 15.8944 31.5716C16.4067 31.5716 16.8208 31.9867 16.8208 32.4981V50.1038H32.5727C33.085 50.1038 33.4991 50.518 33.4991 51.0303C33.4991 51.5426 33.0841 51.9568 32.5727 51.9568Z"
          fill="#414042"
        />
        <path
          d="M47.3971 35.2785C46.8847 35.2785 46.4706 34.8643 46.4706 34.352V32.4981C46.4706 31.9867 46.8847 31.5716 47.3971 31.5716C47.9094 31.5716 48.3235 31.9867 48.3235 32.4981V34.352C48.3235 34.8643 47.9094 35.2785 47.3971 35.2785Z"
          fill="#414042"
        />
        <path
          d="M42.7634 57.5138C42.5596 57.5138 42.3567 57.4471 42.1881 57.3127C41.118 56.465 35.6787 51.9883 33.6349 47.1605C33.6349 47.1605 33.6349 47.1595 33.634 47.1595C33.0151 45.7263 32.6668 44.3106 32.5991 42.9478C32.5825 42.819 32.5723 42.6337 32.5723 42.4466C32.5723 39.5161 35.0654 37.1314 38.1311 37.1314C40.0628 37.1314 41.7666 38.0773 42.7634 39.5115C43.7603 38.0773 45.4641 37.1314 47.3958 37.1314C50.4615 37.1314 52.9546 39.5161 52.9546 42.4466C52.9546 42.6337 52.9435 42.8181 52.924 43.0015C52.861 44.3106 52.5127 45.7263 51.8938 47.1595C51.8929 47.1595 51.8929 47.1605 51.8929 47.1605C49.8491 51.9883 44.4098 56.465 43.3397 57.3127C43.1702 57.4471 42.9663 57.5138 42.7634 57.5138ZM35.3637 46.4934C36.7303 49.6703 40.073 53.1556 42.7625 55.3931C45.4521 53.1556 48.7957 49.6693 50.1613 46.4934C50.165 46.4832 50.1696 46.4739 50.1734 46.4647L50.1752 46.4637C50.7163 45.2214 51.0201 44.0086 51.0776 42.8551C51.0943 42.6847 51.1017 42.567 51.1017 42.4466C51.1017 40.5371 49.4396 38.9843 47.3958 38.9843C45.352 38.9843 43.6899 40.538 43.6899 42.4466C43.6899 42.9589 43.2758 43.373 42.7634 43.373C42.2511 43.373 41.837 42.9589 41.837 42.4466C41.837 40.5371 40.1749 38.9843 38.1311 38.9843C36.0873 38.9843 34.4252 40.538 34.4252 42.4466C34.4252 42.567 34.4326 42.6847 34.4447 42.8023C34.5058 44.0086 34.8106 45.2223 35.3517 46.4647C35.3554 46.4739 35.36 46.4841 35.3637 46.4934Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_99_4962">
          <rect
            width="63"
            height="63"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Address;
