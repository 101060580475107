import { useCallback, useMemo, useState } from "react";
import { get, keys, omit } from "lodash";

import { PROVIDERS_UI_BY_CODE } from "../../../constants/providers";
import * as M from "../../../constants/strings";
import { useAuth } from "../../../features/Auth";
import { useOverlay } from "../../../features/Overlay";
import { useToaster } from "../../../features/Toaster";
import Delete from "../../atoms/icons/Delete";
import Email from "../../atoms/icons/Email";
import Modal from "../../atoms/Modal";
import ListItemWithIcon from "../../molecules/ListItemWithIcon";
import styles from "./ProvidersInfo.module.scss";
import { Trackable, useTracker } from "@dpdgroupuk/react-event-tracker";
import {
  DELETE_AUTH_MODAL,
  YOUR_PERSONAL_INFORMATION,
} from "../../../constants/analytics";

const ProvidersInfo = () => {
  const auth = useAuth();
  const toaster = useToaster();
  const overlay = useOverlay();
  const tracker = useTracker();
  const [emailIdentity, providersWithoutEmail, providers] = useMemo(
    () => [
      get(auth, "currentSession.firebase.identities.email"),
      keys(
        omit(get(auth, "currentSession.firebase.identities"), [
          "phone",
          "email",
        ])
      ),
      keys(omit(get(auth, "currentSession.firebase.identities"), ["phone"])),
    ],
    [auth.currentSession]
  );

  const [unlinkModalShow, setUnlinkModalShow] = useState(false);
  const [providerToDelete, setProviderToDelete] = useState();

  const handleProviderDelete = useCallback(async () => {
    setUnlinkModalShow(false);
    overlay.show();
    tracker.logEvent(DELETE_AUTH_MODAL.ON_CLICK_DELETE_AUTH);

    auth
      .unlinkAccounts([providerToDelete])
      .unwrap()
      .then(() => auth.reloadSession().unwrap())
      .then(
        () => {
          toaster.showSuccess({
            title: M.DPD_PREFERENCES,
            body: M.AUTH_PROVIDER_DELETED_MESSAGE(
              PROVIDERS_UI_BY_CODE[providerToDelete]?.label
            ),
          });
        },
        () => auth.reloadSession().unwrap()
      )
      .catch((e) => {
        toaster.showError({
          body: e.message,
        });
      })
      .finally(() => {
        overlay.hide();
      });
  }, [auth, toaster, overlay, setUnlinkModalShow, providerToDelete, tracker]);

  return (
    <>
      {emailIdentity && (
        <ListItemWithIcon>
          <Email className={styles.providerIcon} />
          <div className={"d-flex flex-column"}>
            <span>{M.EMAIL_LOGIN}</span>
            <span className={"text-secondary"}>{emailIdentity}</span>
          </div>
        </ListItemWithIcon>
      )}
      <div className={styles.listContainer}>
        {providersWithoutEmail.map((provider, index) => {
          const { icon: Icon, label } = PROVIDERS_UI_BY_CODE[provider];
          return (
            <ListItemWithIcon
              onIconClick={
                providers?.length > 1 &&
                (() => {
                  tracker.logEvent(
                    YOUR_PERSONAL_INFORMATION.ON_CLICK_AUTH_DELETE
                  );
                  setProviderToDelete(provider);
                  setUnlinkModalShow(true);
                })
              }
              icon={Delete}
              key={index}
            >
              {Icon && <Icon className={styles.providerIcon} />}
              {label}
            </ListItemWithIcon>
          );
        })}
      </div>
      <Modal
        show={unlinkModalShow}
        title={M.YOUR_DPD_PROFILE}
        acceptButtonText={M.YES_UNLINK_ACCOUNT}
        handleClose={() => {
          tracker.logEvent(DELETE_AUTH_MODAL.ON_CLICK_CLOSE);
          setUnlinkModalShow(false);
        }}
        handleAccept={handleProviderDelete}
        closeButton
        centered
      >
        <Trackable
          loadId={DELETE_AUTH_MODAL.LOAD}
          interfaceId={DELETE_AUTH_MODAL.INTERFACE_ID}
          modal
        >
          <h6>{M.UNLINK_ACCOUNT_PROMPT}</h6>
          <p>{M.UNLINK_ACCOUNT_MESSAGE}</p>
        </Trackable>
      </Modal>
    </>
  );
};

export default ProvidersInfo;
